import { api } from "api"
import _get from "lodash/get"
import { CONFIGURATION } from "sharedConstants"

export const listConfigurationHistory = (
  workspaceId,
  configurationId,
  offset,
  limit,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.HISTORY.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().configuration.history.list(
          workspaceId,
          configurationId,
          offset,
          limit,
          loadFullStructure
        )
      })()
    }
  })

export const revertConfigurationTo = (
  workspaceId,
  configurationId,
  historyId,
  loadFullStructure = 0,
  updateRedux = false
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.MODIFY,
    meta: {
      workspaceId
    },
    payload: {
      promise: (async () => {
        const response = await api().configuration.history.revertTo(
          workspaceId,
          configurationId,
          historyId,
          loadFullStructure
        )
        if (updateRedux) {
          dispatch({
            type: CONFIGURATION.HISTORY.ACTION.GET_LAST_TWO,
            payload: {
              promise: async () => {
                return await api().configuration.history.list(
                  workspaceId,
                  configurationId,
                  0,
                  2,
                  loadFullStructure
                )
              }
            }
          })
        }
        return _get(response, "configuration", {})
      })()
    }
  })

export const retrieveConfigurationHistoryItem = (
  workspaceId,
  configurationId,
  historyId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.HISTORY.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        return await api().configuration.history.retrieve(
          workspaceId,
          configurationId,
          historyId,
          loadFullStructure
        )
      })()
    }
  })

export const clearCachedConfigurationHistory = () => dispatch =>
  dispatch({
    type: CONFIGURATION.HISTORY.ACTION.CLEAR_CACHE,
    payload: {}
  })
