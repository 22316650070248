import _toString from "lodash/toString"
import { createSelector } from "reselect"
import { Map, List } from "immutable"

import { initFuseImmutableSearch } from "helpers/table.helper"

export const getIsConfigurationsFetching = state => state.configurations.get("isFetching")

export const isConfigurationsFulfilled = (state, workspaceId) => {
  return state.configurations.getIn(["data", _toString(workspaceId), "isFulfilled"]) === true
}

export const getIsWorkspaceConfigurationsModifying = (state, workspaceId) => {
  return state.configurations.getIn(["data", _toString(workspaceId), "isModifying"]) === true
}

export const getWorkspaceConfigurationsData = (state, workspaceId) => {
  if (state.configurations.getIn(["data", _toString(workspaceId)])) {
    return state.configurations.getIn(["data", _toString(workspaceId), "configurations"])
  }
  return null
}

export const getWorkspaceConfigurationData = (state, workspaceId, configurationId) => {
  if (state.configurations.getIn(["data", _toString(workspaceId)])) {
    return state.configurations.getIn([
      "data",
      _toString(workspaceId),
      "configurations",
      _toString(configurationId)
    ])
  }
  return null
}

export const getWorkspaceConfigurationsFuseData = createSelector(
  [getWorkspaceConfigurationsData],
  configurations => {
    if (configurations) {
      return Map({
        isFulfilled: true,
        data: initFuseImmutableSearch(List(configurations.toArray()), ["name"])
      })
    } else {
      return Map({
        isFulfilled: false,
        data: initFuseImmutableSearch(List(), ["name"])
      })
    }
  }
)
