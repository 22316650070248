import { WORKSPACE } from "sharedConstants"

import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import { WorkspaceJobFull } from "models/workspaceJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map({
  data: null,
  selectionSettings: null
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WORKSPACE.JOB.ACTION.LIST_PROJECT:
    case `${WORKSPACE.JOB.ACTION.LIST_PROJECT}_FULFILLED`:
      return state
        .set(
          "data",
          List(_map(payload.workspace_jobs, wsJob => new WorkspaceJobFull(fromJS(wsJob))))
        )
        .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
    default:
      return state
  }
}
