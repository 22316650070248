import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, reduxForm, Field } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import ReactTooltip from "react-tooltip"

// ui components
import TextField from "components/UI/elements/TextField"

import { renderTooltip } from "helpers/disabledTooltip.helper"

import "./NameForm.css"

class NameForm extends Component {
  constructor(props) {
    super(props)
    const name = _get(this.props, "initialValues.name", "Undefined name")
    this.state = {
      fontSize: this.getFontSizeBasedOnName(name)
    }
  }

  componentDidUpdate(prevProps) {
    const namePrev = _get(prevProps, "initialValues.name")
    const nameNow = _get(this.props, "initialValues.name")
    if (namePrev !== nameNow) {
      this.updateFontSize(nameNow)
    }
  }

  getFontSizeBasedOnName = name => {
    let fontSize = "large"
    if (name.length > 34) {
      fontSize = "x-small"
    } else if (name.length > 28) {
      fontSize = "small"
    } else if (name.length > 22) {
      fontSize = "medium"
    } else if (name.length > 16) {
      fontSize = "big"
    }
    return fontSize
  }

  updateFontSize = name => {
    const fontSize = this.getFontSizeBasedOnName(name)
    if (this.state.fontSize !== fontSize) {
      this.setState({
        fontSize
      })
    }
  }

  onSubmit = values => {
    if (!values.name) {
      this.setState({
        error: true
      })
    } else {
      const { handleNameChange, toggleEditMode } = this.props
      toggleEditMode()()
      if (handleNameChange) {
        handleNameChange(values.name)
      }
    }
  }

  onChange = evt => {
    const value = _get(evt, "target.value")
    if (!value) {
      this.setState({
        error: true
      })
    } else {
      if (this.state.error) {
        this.setState({
          error: false
        })
      }
      this.updateFontSize(value)
    }
  }

  toggleNameForm = () => {
    const { toggleEditMode, editMode } = this.props
    toggleEditMode(() => {
      if (!editMode) {
        // will be opened now
        this.fieldRef.focus()
      } else {
        this.setState({
          error: false
        })
      }
    })()
  }

  cancelEditing = () => {
    const name = _get(this.props, "initialValues.name", "Undefined name")
    this.props.reset()
    this.toggleNameForm()
    this.updateFontSize(name)
  }

  render() {
    const {
      handleSubmit,
      isEditable,
      form,
      size,
      label,
      initialValues,
      readOnlySign,
      editMode,
      readyToEdit,
      currentlyEditing = "",
      tooltipId = ""
    } = this.props
    const { error, fontSize } = this.state
    const name = _get(initialValues, "name", "Undefined name")

    let showTooltipOnHover = false
    if (this.fieldRef) {
      if (this.fieldRef.scrollWidth > this.fieldRef.clientWidth) {
        showTooltipOnHover = true
      }
    }

    return (
      <React.Fragment>
        {isEditable && (
          <Form
            name={form}
            className={`name-form ${size === "big" ? "big" : "small"}`}
            autoComplete="off"
            onSubmit={handleSubmit(this.onSubmit)}
            data-tip={currentlyEditing.length > 0 && !editMode}
            data-for={currentlyEditing.length > 0 && !editMode ? tooltipId : false}
          >
            <div
              className={`tooltip-trigger ${editMode ? "edit" : ""}`}
              data-tip
              data-for={form}
              data-tip-disable={!(showTooltipOnHover && !editMode)}
            />
            <Field
              name="name"
              component={TextField}
              label={label}
              disabled={!editMode}
              setFieldToBeFocused={input => {
                this.fieldRef = input
              }}
              className={editMode ? `edit-mode ${fontSize}` : fontSize}
              onChange={this.onChange}
            />
            {editMode && (
              <React.Fragment>
                <button type="button" className="close-icon-button" onClick={this.cancelEditing}>
                  <FontAwesomeIcon className="icon" icon={["far", "times"]} />
                </button>
                {!error && (
                  <button type="submit" className="link-button edit-mode">
                    Save
                  </button>
                )}
              </React.Fragment>
            )}
            {!editMode && isEditable && !currentlyEditing && (
              <button
                type="button"
                className="link-button"
                onClick={this.toggleNameForm}
                disabled={!readyToEdit}
              >
                Edit
              </button>
            )}
            {showTooltipOnHover && (
              <ReactTooltip id={form} place="right" className="tooltip">
                {name}
              </ReactTooltip>
            )}
          </Form>
        )}
        {!isEditable && (
          <div className={`name-not-editable ${size === "big" ? "big" : "small"}`}>
            <label className="label">{label}</label>
            <h2>
              {name}
              {readOnlySign ? (
                <React.Fragment>
                  {" "}
                  <span className="read-only-sign">
                    <FontAwesomeIcon icon={["fal", "eye"]} className="eye" /> View-only
                  </span>
                </React.Fragment>
              ) : (
                ""
              )}
            </h2>
          </div>
        )}
        {renderTooltip(tooltipId, currentlyEditing)}
      </React.Fragment>
    )
  }
}

NameForm.defaultProps = {
  readyToEdit: true
}

NameForm.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  handleNameChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  readOnlySign: PropTypes.bool,
  currentlyEditing: PropTypes.string,
  tooltipId: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  readyToEdit: PropTypes.bool
}

export default reduxForm({
  enableReinitialize: true
})(NameForm)
