import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _each from "lodash/each"
import _join from "lodash/join"
import _sortBy from "lodash/sortBy"
import _get from "lodash/get"
import _toInteger from "lodash/toInteger"
import { List, Record } from "immutable"

// actions
import { showToast } from "actions/toast.action"

// ui components
import DayOfMonthForm from "./SubForms/DayOfMonthForm"
import DayOfWeekForm from "./SubForms/DayOfWeekForm"

// constants
import { REPEAT, ONCE, SCHEDULE_FORM_TYPE, TOAST } from "sharedConstants"

class SetScheduleForm extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      // default initial type
      type: SCHEDULE_FORM_TYPE.DAY_OF_WEEK,
      notificationEmails: this.props.notificationEmails
    }
  }

  componentDidMount() {
    const { schedules } = this.props
    if (schedules.size) {
      const schedule = schedules.first()

      if (schedule.get("day") !== "*") {
        this.setState({
          type: SCHEDULE_FORM_TYPE.DAY_OF_MONTH
        })
      } else {
        // day of week
        this.setState({
          type: SCHEDULE_FORM_TYPE.DAY_OF_WEEK
        })
      }
    }
  }

  selectFormVariant = type => () => {
    if (this.state.type === type) {
      return
    }
    this.setState({
      type
    })
  }

  submitForm = async data => {
    const { modifyEntity, entity, onCloseAction, showToast } = this.props
    const { type, notificationEmails } = this.state
    if (
      type === SCHEDULE_FORM_TYPE.DAY_OF_MONTH ||
      (type === SCHEDULE_FORM_TYPE.DAY_OF_WEEK && data.type === ONCE)
    ) {
      let schedules = []
      if (data.type === ONCE) {
        _each(data.schedules, schedule => {
          schedules.push({
            minute: schedule.minutes,
            hour: schedule.hours,
            day: "*",
            month: "*",
            day_of_week: _join(_sortBy(schedule.days), ",")
          })
        })
      } else {
        _each(data.schedules, schedule => {
          schedules.push({
            minute: schedule.minutes,
            hour: schedule.hours,
            day: _join(_sortBy(schedule.days), ","),
            month: "*",
            day_of_week: "*"
          })
        })
      }

      const settings = entity.settings
        .merge({
          schedules,
          notification_emails: notificationEmails
        })
        .toJS()

      await modifyEntity(entity.id, { settings }, { settings: entity.settings.toJS() })
        .then(() => {
          showToast("Schedule has been set.", TOAST.TYPE.SUCCESS)
          onCloseAction()
        })
        .catch(err => {
          if (_get(err, "type") === "nothing_changed") {
            onCloseAction()
          }
        })
    } else if (type === SCHEDULE_FORM_TYPE.DAY_OF_WEEK) {
      if (data.type === REPEAT) {
        let schedules = []
        let fromToVariant = null
        if (data.schedule.from && data.schedule.to) {
          if (data.schedule.from !== data.schedule.to) {
            if (_toInteger(data.schedule.from) > _toInteger(data.schedule.to)) {
              fromToVariant = "crossing"
            } else {
              fromToVariant = "regular"
            }
          }
        }
        switch (data.schedule.unit) {
          case "Min":
            if (fromToVariant === "crossing") {
              const hours = [`${data.schedule.from}-23`, `0-${data.schedule.to}`]
              schedules = hours.map(hour => ({
                minute: `${data.schedule.startingAt ? `${data.schedule.startingAt}-59` : "*"}/${
                  data.schedule.value
                }`,
                hour,
                day: "*",
                month: "*",
                day_of_week: _join(_sortBy(data.schedule.days), ",")
              }))
            } else {
              let hour = "*"
              if (fromToVariant === "regular") {
                hour = `${data.schedule.from}-${data.schedule.to}`
              }
              schedules.push({
                minute: `${data.schedule.startingAt ? `${data.schedule.startingAt}-59` : "*"}/${
                  data.schedule.value
                }`,
                hour,
                day: "*",
                month: "*",
                day_of_week: _join(_sortBy(data.schedule.days), ",")
              })
            }
            break
          case "Hour":
            if (fromToVariant === "crossing") {
              let overDay = _toInteger(data.schedule.from)
              while (overDay < 24) {
                overDay += _toInteger(data.schedule.value)
              }
              const endScheduleFrom = overDay - 24
              const hours = [`${data.schedule.from}-23`]
              if (endScheduleFrom < _toInteger(data.schedule.to)) {
                hours.push(`${endScheduleFrom}-${data.schedule.to}`)
              } else if (endScheduleFrom === _toInteger(data.schedule.to)) {
                hours.push(`${endScheduleFrom}`)
              }
              schedules = hours.map(hour => ({
                minute: "0",
                hour: `${hour}/${data.schedule.value}`,
                day: "*",
                month: "*",
                day_of_week: _join(_sortBy(data.schedule.days), ",")
              }))
            } else if (fromToVariant === "regular") {
              schedules.push({
                minute: "0",
                hour: `${data.schedule.from}-${data.schedule.to}/${data.schedule.value}`,
                day: "*",
                month: "*",
                day_of_week: _join(_sortBy(data.schedule.days), ",")
              })
            } else {
              schedules.push({
                minute: "0",
                hour: `${data.schedule.startingAt ? `${data.schedule.startingAt}-23` : "*"}/${
                  data.schedule.value
                }`,
                day: "*",
                month: "*",
                day_of_week: _join(_sortBy(data.schedule.days), ",")
              })
            }
            break
          default:
            // this should never happen
            break
        }
        const settings = entity.settings
          .merge({
            schedules,
            notification_emails: notificationEmails
          })
          .toJS()

        await modifyEntity(entity.id, { settings }, { settings: entity.settings.toJS() })
          .then(() => {
            showToast("Schedule has been set.", TOAST.TYPE.SUCCESS)
            onCloseAction()
          })
          .catch(err => {
            if (_get(err, "type") === "nothing_changed") {
              onCloseAction()
            }
          })
      }
    }
  }

  addNotificationEmail = email => {
    const { notificationEmails } = this.state
    if (notificationEmails.includes(email)) {
      return { error: "Email already added" }
    } else {
      this.setState({
        notificationEmails: notificationEmails.push(email)
      })
      return { value: email }
    }
  }

  deleteNotificationEmail = email => {
    this.setState(prevState => ({
      notificationEmails: prevState.notificationEmails.filterNot(
        notificationEmail => notificationEmail === email
      )
    }))
  }

  render() {
    const { onCloseAction, schedules, deleteEntireSchedulesFunc } = this.props
    const { type, notificationEmails } = this.state

    return (
      <div>
        {type === SCHEDULE_FORM_TYPE.DAY_OF_MONTH && (
          <div className="day-of-month">
            <DayOfMonthForm
              onSubmit={this.submitForm}
              schedules={schedules}
              notificationEmails={notificationEmails}
              handleEmailAdd={this.addNotificationEmail}
              handleEmailDelete={this.deleteNotificationEmail}
              handleFormTypeChange={this.selectFormVariant}
              handleFormClose={onCloseAction}
              handleEntireSchedulesDelete={deleteEntireSchedulesFunc}
            />
          </div>
        )}
        {type === SCHEDULE_FORM_TYPE.DAY_OF_WEEK && (
          <div className="day-of-week">
            <DayOfWeekForm
              onSubmit={this.submitForm}
              schedules={schedules}
              notificationEmails={notificationEmails}
              handleEmailAdd={this.addNotificationEmail}
              handleEmailDelete={this.deleteNotificationEmail}
              handleFormTypeChange={this.selectFormVariant}
              handleFormClose={onCloseAction}
              handleEntireSchedulesDelete={deleteEntireSchedulesFunc}
            />
          </div>
        )}
      </div>
    )
  }
}

SetScheduleForm.propTypes = {
  entity: PropTypes.instanceOf(Record).isRequired,
  onCloseAction: PropTypes.func.isRequired,
  schedules: PropTypes.instanceOf(List).isRequired,
  notificationEmails: PropTypes.instanceOf(List).isRequired,
  modifyEntity: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  deleteEntireSchedulesFunc: PropTypes.func
}

export default connect(null, { showToast })(SetScheduleForm)
