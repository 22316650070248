import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _toInteger from "lodash/toInteger"
import _noop from "lodash/noop"
import PropTypes from "prop-types"
import { Map } from "immutable"
import ReactTooltip from "react-tooltip"

import { fetchWorkersCount } from "actions/workersCount.action"
import { getWorkersCount } from "selectors/workersCount.selector"
import { TOAST } from "sharedConstants"

class WorkersCount extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      workersCountValue: props.initialValue ? props.initialValue : ""
    }
  }

  componentDidMount() {
    const { fetchWorkersCount } = this.props
    fetchWorkersCount()
      .then(() => {
        if (!this.state.workersCountValue) {
          this.setState({
            workersCountValue: this.getWorkersCountValue()
          })
        }
      })
      .catch(_noop)
  }

  getWorkersCountValue = () => {
    const { initialValue, maxWorkersCount } = this.props
    if (initialValue) {
      return initialValue
    } else {
      return maxWorkersCount
    }
  }

  changeWorkersCountValue = evt => {
    const { maxWorkersCount } = this.props
    const { value } = evt.target
    if ((value > 0 && value <= maxWorkersCount) || value === "") {
      this.setState({
        workersCountValue: value ? _toInteger(value) : ""
      })
    }
  }

  saveWorkersCountValue = (newValue = null) => {
    const { workersCountValue } = this.state
    const { initialValue, settings, modifyEntity, maxWorkersCount } = this.props
    const value = newValue !== null ? _toInteger(newValue) : _toInteger(workersCountValue)
    if (!value || value > maxWorkersCount) {
      this.setState({
        workersCountValue: this.getWorkersCountValue()
      })
    } else {
      if (initialValue !== value) {
        let settingsToStore = {}
        if (Map.isMap(settings)) {
          settingsToStore = settings.toJS()
        }
        modifyEntity(
          {
            settings: {
              ...settingsToStore,
              max_workers_used: value
            }
          },
          {},
          "No. of workers available for the workspace has been updated.",
          TOAST.TYPE.SUCCESS
        )
          .then(() => {
            this.setState({
              workersCountValue: this.getWorkersCountValue()
            })
          })
          .catch(_noop)
      }
    }
  }

  increaseWorkersCount = () => {
    const { maxWorkersCount } = this.props
    const { workersCountValue } = this.state
    if (workersCountValue < maxWorkersCount) {
      this.saveWorkersCountValue(workersCountValue + 1)
    }
  }

  decreaseWorkersCount = () => {
    const { workersCountValue } = this.state
    if (workersCountValue > 1) {
      this.saveWorkersCountValue(workersCountValue - 1)
    }
  }

  render() {
    const { maxWorkersCount } = this.props
    const { workersCountValue } = this.state
    return (
      <React.Fragment>
        <div
          className={`workers-count ${maxWorkersCount === 1 ? "disabled" : ""}`}
          data-tip
          data-for="available-workers-tooltip"
          data-testid="wrapper"
        >
          {maxWorkersCount > 0 && (
            <React.Fragment>
              <p className="label">
                <FontAwesomeIcon className="icon" icon={["far", "cog"]} />{" "}
                <span>Workers available</span>
              </p>
              <div className="text-field">
                <button
                  type="button"
                  className="minus"
                  onClick={this.decreaseWorkersCount}
                  disabled={maxWorkersCount === 1}
                  data-testid="minus-button"
                >
                  -
                </button>
                <div className="input-wrapper">
                  <input
                    className="workers-count-input"
                    type="number"
                    min="1"
                    max={`${maxWorkersCount}`}
                    value={workersCountValue}
                    onChange={this.changeWorkersCountValue}
                    onBlur={() => this.saveWorkersCountValue()}
                    disabled={maxWorkersCount === 1}
                    data-testid="input"
                  />
                  <span className="max-workers-count">/ {maxWorkersCount}</span>
                </div>

                <button
                  type="button"
                  onClick={this.increaseWorkersCount}
                  disabled={maxWorkersCount === 1}
                  data-testid="plus-button"
                >
                  +
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
        {maxWorkersCount === 1 && (
          <ReactTooltip id="available-workers-tooltip" place="bottom" className="workers-tooltip">
            The instance has maximum 1 worker available.
          </ReactTooltip>
        )}
      </React.Fragment>
    )
  }
}

WorkersCount.propTypes = {
  initialValue: PropTypes.number,
  modifyEntity: PropTypes.func.isRequired,
  settings: PropTypes.instanceOf(Map),
  maxWorkersCount: PropTypes.number,
  fetchWorkersCount: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    maxWorkersCount: getWorkersCount(state)
  }
}

export default connect(mapStateToProps, { fetchWorkersCount })(WorkersCount)
