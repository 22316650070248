import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class TextField extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      passwordFieldContentVisible: false
    }
  }

  toggleFieldType = () => {
    this.setState(prevState => ({
      passwordFieldContentVisible: !prevState.passwordFieldContentVisible
    }))
  }

  render() {
    const {
      input,
      label,
      meta: { touched, error },
      type,
      fullWidth,
      className,
      placeholder = "",
      autoFocus = false,
      multiline = false,
      rows = "3",
      setFieldToBeFocused = () => {},
      ...fieldAttrs
    } = this.props
    const { passwordFieldContentVisible } = this.state
    const errorMessage = touched ? error : ""
    let inputType = type ? type : "text"
    if (inputType === "password" && passwordFieldContentVisible) {
      inputType = "text"
    }
    return (
      <div
        data-testid="field-wrapper"
        className={`text-field ${errorMessage ? "error" : ""} ${
          type === "password" ? "redux-password-field" : ""
        } ${className ? className : ""}`}
      >
        {label && <label data-testid="label">{label}</label>}
        {multiline && (
          <textarea
            {...input}
            className={fullWidth ? "full-width" : "shrinked"}
            placeholder={placeholder}
            autoFocus={autoFocus}
            rows={rows}
            ref={input => setFieldToBeFocused(input)}
            data-testid="textarea-field"
          />
        )}
        {!multiline && type === "password" && (
          <div className="password-wrapper">
            <input
              {...input}
              type={inputType}
              className={`password ${fullWidth ? "full-width" : "shrinked"}`}
              placeholder={placeholder ? placeholder : ""}
              autoFocus={autoFocus}
              ref={input => setFieldToBeFocused(input)}
              data-testid="password-field"
              {...fieldAttrs}
            />
            <span
              className="password-visible-icon"
              onClick={this.toggleFieldType}
              data-testid="eye-button"
            >
              {passwordFieldContentVisible && (
                <FontAwesomeIcon icon={["far", "eye-slash"]} className="icon" />
              )}
              {!passwordFieldContentVisible && (
                <FontAwesomeIcon icon={["far", "eye"]} className="icon" />
              )}
            </span>
          </div>
        )}
        {!multiline && type !== "password" && (
          <input
            {...input}
            type={inputType}
            className={`${fullWidth ? "full-width" : "shrinked"}`}
            placeholder={placeholder ? placeholder : ""}
            autoFocus={autoFocus}
            ref={input => setFieldToBeFocused(input)}
            data-testid="input-field"
            {...fieldAttrs}
          />
        )}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    )
  }
}

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  className: PropTypes.string
}

export default TextField
