import React, { useEffect, useMemo, useState } from "react"
import styles from "./UserPermissionsList.module.scss"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import CheckboxField from "components/UI/elements/CheckboxField"
import { api } from "api"
import SearchField from "components/UI/elements/SearchField"
import { initFuseSearch } from "helpers/table.helper"
import Avatar from "components/UI/elements/Avatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { getRoutePath } from "routes"
import ReactTooltip from "react-tooltip"
import { useDispatch } from "react-redux"
import { hideLoading, showLoading } from "react-redux-loading-bar"

export default function UserPermissionsList() {
  const [showDeleted, setShowDeleted] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [data, setData] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoading())
      try {
        const { acl_reports } = await api().user.aclReport()
        setData(acl_reports)
      } catch {
        setData(undefined)
      } finally {
        dispatch(hideLoading())
      }
    }
    fetchData()
  }, [dispatch])

  const fuse = useMemo(() => data && initFuseSearch(data, ["name"]), [data])
  const filteredData = useMemo(() => {
    const filteredData = searchTerm ? fuse?.search(searchTerm) : data
    return showDeleted ? filteredData : filteredData?.map(removeDeleted)
  }, [data, fuse, searchTerm, showDeleted])

  return (
    <>
      <PaperHeader className={styles.header} size="small">
        <h3>User permissions</h3>
        <SearchField
          input={{ value: searchTerm, onChange: setSearchTerm }}
          meta={{}}
          handleClear={() => setSearchTerm("")}
          className={styles.search}
          placeholder="Search for name"
          autoFocus
        />
        <div className={styles.showDeleted}>
          <CheckboxField
            input={{
              value: showDeleted,
              onChange: e => {
                setShowDeleted(e.target.checked)
              }
            }}
            label="Show deleted workspaces & DAWGs"
            meta={{}}
          />
        </div>
      </PaperHeader>
      <Paper hasHeader>
        {filteredData?.length === 0 && <div className={styles.noResults}>No users found.</div>}

        {filteredData?.length > 0 && (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div>User</div>
              <div>Workspaces</div>
              <div>DAWGs</div>
            </div>

            {filteredData.map(user => (
              <div className={styles.tableRow} key={user.id}>
                <div className={styles.tableCell}>
                  <div
                    className={`${styles.user} ${
                      user.disabled === 1 || user.deleted === 1 ? styles.inactive : ""
                    }`}
                  >
                    <Avatar
                      name={user.name}
                      email={user.email}
                      gravatarSize={100}
                      disabledLook={user.disabled === 1 || user.deleted === 1}
                    />
                    <div>
                      <div className={styles.name}>
                        {user.name}
                        {user.disabled === 1 && (
                          <div className={styles.badge}>
                            <FontAwesomeIcon icon={["far", "power-off"]} /> Disabled
                          </div>
                        )}
                        {user.deleted === 1 && (
                          <div className={styles.badge}>
                            <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Deleted
                          </div>
                        )}
                      </div>
                      <div className={styles.role}>{user.role === "admin" ? "Admin" : "User"}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <div className={styles.list}>
                    {user.owned_workspaces.map(linkedWorkspace)}
                    {user.workspaces_permissions.map(linkedWorkspace)}
                    {user.role === "admin" && (
                      <div className={styles.adminAccess}>
                        Administrator has access to all workspaces
                      </div>
                    )}
                    {user.owned_workspaces.length === 0 &&
                      user.workspaces_permissions.length === 0 &&
                      user.role !== "admin" && (
                        <div>
                          User has access to <strong>0 workspaces</strong>
                        </div>
                      )}
                  </div>
                </div>
                <div className={styles.tableCell}>
                  <div className={styles.list}>
                    {user.owned_dawgs.map(linkedDawg)}
                    {user.dawgs_permissions.map(linkedDawg)}
                    {user.role === "admin" && (
                      <div className={styles.adminAccess}>
                        Administrator has access to all DAWGs
                      </div>
                    )}
                    {user.owned_dawgs.length === 0 &&
                      user.dawgs_permissions.length === 0 &&
                      user.role !== "admin" && (
                        <div>
                          User has access to <strong>0 DAWGs</strong>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Paper>
    </>
  )
}

function removeDeleted(userData) {
  return {
    ...userData,
    owned_dawgs: userData.owned_dawgs.filter(dawg => !dawg.deleted),
    owned_workspaces: userData.owned_workspaces.filter(workspace => !workspace.deleted),
    dawgs_permissions: userData.dawgs_permissions.filter(dawg => !dawg.deleted),
    workspaces_permissions: userData.workspaces_permissions.filter(workspace => !workspace.deleted)
  }
}

function linkedWorkspace(workspace) {
  return linkedEntity(workspace, "workspace")
}

function linkedDawg(dawg) {
  return linkedEntity(dawg, "dawg")
}

function linkedEntity(entity, type) {
  return (
    <div
      className={`${styles.linkedEntity} ${
        entity.disabled === 1 || entity.deleted === 1 ? styles.inactive : ""
      }`}
      key={entity.id}
    >
      {entity.permission === "read" ? (
        <div className={styles.icon} data-tip data-for={`ws-${entity.id}-view`}>
          <ReactTooltip id={`ws-${entity.id}-view`}>
            <span>View permission</span>
          </ReactTooltip>
          <FontAwesomeIcon icon={["fas", "eye"]} />
        </div>
      ) : entity.permission === "write" ? (
        <div className={styles.icon} data-tip data-for={`ws-${entity.id}-edit`}>
          <ReactTooltip id={`ws-${entity.id}-edit`}>
            <span>Edit permission</span>
          </ReactTooltip>
          <FontAwesomeIcon icon={["fas", "pencil"]} />
        </div>
      ) : (
        <div className={styles.icon} data-tip data-for={`ws-${entity.id}-owner`}>
          <ReactTooltip id={`ws-${entity.id}-owner`}>
            <span>Owner</span>
          </ReactTooltip>
          <FontAwesomeIcon icon={["fas", "crown"]} />
        </div>
      )}
      {entity.deleted ? (
        <span className={styles.name}>{entity.name}</span>
      ) : (
        <Link
          className={styles.link}
          to={getRoutePath(type === "workspace" ? "workspace.show" : "dawg.show", {
            id: entity.id
          })}
        >
          {entity.name}
        </Link>
      )}
      {entity.disabled === 1 && (
        <div className={styles.badge}>
          <FontAwesomeIcon icon={["far", "power-off"]} /> Disabled
        </div>
      )}
      {entity.deleted === 1 && (
        <div className={styles.badge}>
          <FontAwesomeIcon icon={["fas", "trash-alt"]} /> Deleted
        </div>
      )}
    </div>
  )
}
