import _toInteger from "lodash/toInteger"

export const getWorkspaces = state => state.workspaces.get("workspaces")

export const getWorkspacesSelectionSettings = state => state.workspaces.get("selectionSettings")

export const getWorkspacesHasMoreItems = state => state.workspaces.get("hasMoreItems")

export const getLastWorkspacesJobs = state => state.workspaces.get("lastJobs")

export const getWorkspaceRecord = (state, id) => {
  if (state.workspaces.get("workspaces")) {
    return state.workspaces.get("workspaces").find(workspace => workspace.id === _toInteger(id))
  } else {
    return null
  }
}
