import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import { Map } from "immutable"
import _toString from "lodash/toString"
import _get from "lodash/get"
import _noop from "lodash/noop"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Waypoint } from "react-waypoint"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button"

// helpers
import { shortenFilename } from "helpers/filename.helper"
import { BASE_API_URL } from "api"
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"
import { copyStringToClipboard } from "helpers/string.helper"
import { TOAST } from "sharedConstants"

import "./DataInCard.css"

class DataInCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      inputs: props.inputs
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputs !== this.props.inputs) {
      this.setState({
        inputs: this.props.inputs
      })
    }
  }

  cancelEditing = () => {
    this.setState(
      {
        inputs: this.props.inputs
      },
      this.props.toggleEditMode()
    )
  }

  handleFilterChange = (configurationId, filterId) => evt => {
    const { value } = evt.target
    this.setState(prevState => ({
      inputs: prevState.inputs.setIn([_toString(configurationId), "filters", filterId], value)
    }))
  }

  removeFilter = (configurationId, filterId) => () => {
    this.setState(prevState => ({
      inputs: prevState.inputs.deleteIn([_toString(configurationId), "filters", filterId])
    }))
  }

  addFilter = configurationId => () => {
    this.setState(prevState => ({
      inputs: prevState.inputs.setIn(
        [_toString(configurationId), "filters"],
        prevState.inputs.getIn([_toString(configurationId), "filters"]).push("*")
      )
    }))
  }

  saveInputs = async () => {
    try {
      await this.props.onInputsSave(this.state.inputs)
      this.props.toggleEditMode()()
    } catch (err) {
      if (_get(err, "type") === "nothing_changed") {
        this.props.toggleEditMode()()
      }
    }
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { dataPreviews, loadMoreFiles } = this.props
    const { filesLoading } = this.state

    if (dataPreviews.get("hasMoreItems") && !filesLoading) {
      return (
        <Waypoint
          onEnter={() => {
            this.setState({
              filesLoading: true
            })
            loadMoreFiles(
              dataPreviews.get("selectionSettings").offset +
                dataPreviews.get("selectionSettings").limit
            )
              .then(() => {
                this.setState({
                  filesLoading: false
                })
              })
              .catch(err => {})
          }}
          bottomOffset={-40}
        />
      )
    }
  }

  render() {
    const {
      dataPreviews,
      configurations,
      components,
      isEditable,
      lastConfigurationsStatuses,
      editMode,
      toggleEditMode,
      readyToEdit,
      showToast,
      match: {
        params: { id, cid }
      },
      currentlyEditing = ""
    } = this.props
    const { inputs, filesLoading } = this.state

    if (!dataPreviews) {
      return null
    }

    const files = dataPreviews.get("data")
    const filesTotalCount = dataPreviews.get("filesTotalCount")
    return (
      <React.Fragment>
        <PaperHeader size="small" className="data-in-card-header">
          <h3>Data in</h3>
          <div className="right-part">
            {filesTotalCount !== undefined && (
              <h3>{`${filesTotalCount} ${filesTotalCount === 1 ? "file" : "files"} total`}</h3>
            )}
            {Map.isMap(inputs) && inputs.size > 0 && Map.isMap(components) && (
              <React.Fragment>
                <div className="vertical-line" />
                {!editMode && (
                  <Button
                    color="primary"
                    size="small"
                    type="button"
                    onClick={readyToEdit ? toggleEditMode() : _noop}
                    disabled={!isEditable}
                    currentlyEditing={currentlyEditing}
                    tooltipId="edit-conf-datain-filters-tooltip-id"
                    className={!readyToEdit ? "cursor-wait" : ""}
                  >
                    Edit
                  </Button>
                )}
                {editMode && (
                  <React.Fragment>
                    <Button color="link" size="small" type="button" onClick={this.cancelEditing}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      type="button"
                      className="save-button"
                      onClick={this.saveInputs}
                    >
                      Save
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </PaperHeader>
        {(filesTotalCount > 0 || (Map.isMap(inputs) && inputs.size > 0)) && (
          <Paper hasHeader={true} className="data-in-card-content">
            <div className="inputs">
              {Map.isMap(inputs) &&
                inputs.size > 0 &&
                Map.isMap(components) &&
                inputs
                  .map((input, key) => {
                    const inputConf = configurations.get(_toString(key))
                    const inputComponent = components.get(_toString(inputConf.component_id))
                    const inputConfStatus = lastConfigurationsStatuses.get(
                      _toString(inputConf.id),
                      "transparent"
                    )

                    return (
                      <div
                        className={`input-config ${filesTotalCount === 0 ? "no-files" : ""}`}
                        key={key}
                      >
                        <Link
                          className="conf-link left-part"
                          to={{
                            pathname: getRoutePath("workspace.configuration.show", {
                              id,
                              cid: inputConf.id
                            }),
                            state: {
                              previous: this.props.location.pathname
                            }
                          }}
                        >
                          <img
                            src={getComponentIconSrc(inputComponent.icon, inputConf.disabled === 1)}
                            alt={inputComponent.name}
                            className={`${inputConfStatus} ${
                              inputConf.disabled === 1 ? "disabled" : ""
                            }`}
                          />
                          <h4>{inputConf.name}</h4>
                        </Link>
                        <div className="right-part">
                          <p className="label">Filters</p>
                          {input
                            .get("filters")
                            .map((filter, idx) => (
                              <div className="filter-item text-field" key={idx}>
                                <input
                                  type="text"
                                  name="filter"
                                  value={filter}
                                  onChange={this.handleFilterChange(key, idx)}
                                  disabled={!editMode}
                                  className="filter-input"
                                />
                                {idx > 0 && editMode && (
                                  <IconButton
                                    className="delete-row-button"
                                    color="red faded"
                                    onClick={this.removeFilter(key, idx)}
                                  >
                                    <FontAwesomeIcon icon={["far", "trash-alt"]} />
                                  </IconButton>
                                )}
                              </div>
                            ))
                            .toArray()}
                          {editMode && (
                            <Button
                              className="add-more-button"
                              color="transparent-primary"
                              size="small"
                              onClick={this.addFilter(key)}
                            >
                              + Add more
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })
                  .toArray()}
            </div>
            {filesTotalCount > 0 && (
              <React.Fragment>
                <div className="files">
                  {Map.isMap(components) && (
                    <div className={`content ${filesTotalCount > 4 ? "scrollable" : ""}`}>
                      {files
                        .map((file, idx) => {
                          const parentConf = configurations.get(
                            _toString(file.parent_configuration_id)
                          )
                          const parentComponent = components.get(_toString(parentConf.component_id))
                          return (
                            <div className="row" key={idx}>
                              <Link
                                to={{
                                  pathname: getRoutePath("workspace.configuration.data.preview", {
                                    id,
                                    cid,
                                    type: "in",
                                    did: file.id
                                  }),
                                  state: {
                                    file,
                                    sourceConfiguration: parentConf,
                                    previous: this.props.location.pathname
                                  }
                                }}
                              >
                                <div
                                  className="in-file-item"
                                  data-for={`in-file-tooltip-${idx}`}
                                  data-tip
                                >
                                  {shortenFilename(file.file_name, 45)}
                                  <span
                                    className="copy-button"
                                    onClick={evt => {
                                      evt.preventDefault()
                                      copyStringToClipboard(file.file_name)
                                      showToast(
                                        "Filename has been copied to clipboard.",
                                        TOAST.TYPE.SUCCESS
                                      )
                                    }}
                                  >
                                    <FontAwesomeIcon icon={["fas", "copy"]} />
                                  </span>
                                </div>
                              </Link>
                              <IconButton
                                color="grey"
                                type="anchor"
                                href={`${BASE_API_URL}/configuration_file?file_download_token=${file.file_download_token}`}
                                download
                                target="_blank"
                                className="download-button"
                              >
                                <FontAwesomeIcon icon={["far", "download"]} />
                              </IconButton>
                              <ReactTooltip
                                place="bottom"
                                id={`in-file-tooltip-${idx}`}
                                className="tooltip-bubble"
                              >
                                <div className="tooltip-content">
                                  <img
                                    src={getComponentIconSrc(parentComponent.icon, true)}
                                    alt={parentConf.name}
                                  />
                                  <div className="right-part">
                                    <h4>{parentConf.name}</h4>
                                    <p>{file.file_name}</p>
                                  </div>
                                </div>
                              </ReactTooltip>
                            </div>
                          )
                        })
                        .toArray()}
                      {filesLoading && <div className="row loading-row" />}
                      {this.renderWaypoint()}
                    </div>
                  )}
                </div>
                <div className="download-all">
                  <Link
                    to={getRoutePath("workspace.configuration.data.preview.list", {
                      id,
                      cid,
                      type: "in"
                    })}
                    className="button transparent-grey small shrinked"
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "search"]} /> View all files
                  </Link>
                  <Button
                    color="transparent-grey"
                    size="small"
                    href={`${BASE_API_URL}/configuration_input_files?download_token=${dataPreviews.get(
                      "downloadToken"
                    )}`}
                    download
                    target="_blank"
                  >
                    <FontAwesomeIcon className="icon" icon={["far", "download"]} /> Download all
                    files
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

DataInCard.defaultProps = {
  readyToEdit: true
}

DataInCard.propTypes = {
  dataPreviews: PropTypes.instanceOf(Map),
  configurations: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map),
  inputs: PropTypes.instanceOf(Map),
  onInputsSave: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  loadMoreFiles: PropTypes.func.isRequired,
  lastConfigurationsStatuses: PropTypes.instanceOf(Map).isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool,
  showToast: PropTypes.func.isRequired
}

export default withRouter(DataInCard)
