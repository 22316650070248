import React from "react"
import PropTypes from "prop-types"

import "./IconButton.css"

const IconButton = ({ color, children, className, type = "button", ...buttonAttrs }) => {
  if (type === "anchor") {
    return (
      <a {...buttonAttrs} className={`icon-button ${className ? className : ""} ${color}`}>
        {children}
      </a>
    )
  } else {
    return (
      <button
        {...buttonAttrs}
        type={type}
        className={`icon-button ${className ? className : ""} ${color}`}
      >
        {children}
      </button>
    )
  }
}

IconButton.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string
}

export default IconButton
