export const USER = {
  ACTION: {
    LOGGING_IN: "USER_LOGGING_IN",
    LOGGED_IN: "USER_LOGGED_IN",
    LOGGED_OUT: "USER_LOGGED_OUT",
    RETRIEVE_LOGGED_IN_USER: "RETRIEVE_LOGGED_IN_USER",
    DISABLE_LOGOUT: "USER_LOGOUT_DISABLED",
    ENABLE_LOGOUT: "USER_LOGOUT_ENABLED",
    MODIFY_AUTH_USER: "MODIFY_AUTH_USER",
    LIST: "USER_LIST",
    RETRIEVE: "USER_RETRIEVE",
    CREATE: "USER_CREATE",
    DELETE: "USER_DELETE",
    MODIFY: "USER_MODIFY",
    REFRESH_TOKEN: "REFRESH_USER_TOKEN",
    SET_UI_ATTRIBUTE: "SET_UI_ATTRIBUTE"
  },
  TRASH: {
    ACTION: {
      LIST: "LIST_TRASHED_USERS"
    }
  },
  ROLE: {
    ADMIN: "admin",
    USER: "user",
    SYSTEM: "system"
  }
}

export const PASSWORD_SET = {
  REFERRER: {
    INVITATION: "invitation",
    PASSWORD_SET: "password-set"
  }
}

export const TOAST = {
  ACTION: {
    SHOW: "SHOW_TOAST"
  },
  TYPE: {
    ERROR: "TOAST_ERROR",
    SUCCESS: "TOAST_SUCCESS",
    INFO: "TOAST_INFO"
  }
}

export const WORKSPACE = {
  ACTION: {
    LIST: "WORKSPACE_LIST",
    CREATE: "WORKSPACE_CREATE",
    DELETE: "WORKSPACE_DELETE",
    MODIFY: "WORKSPACE_MODIFY",
    RETRIEVE: "WORKSPACE_RETRIEVE",
    LIST_LAST_JOBS: "LIST_LAST_WORKSPACE_JOBS"
  },
  LOADING_LIMIT: 20,
  JOB: {
    ACTION: {
      RUN: "WORKSPACE_JOB_RUN",
      LIST: "WORKSPACE_JOB_LIST",
      REFRESH_LAST: "REFRESH_LAST_WORKSPACE_JOBS",
      CLEAR_CACHE: "CLEAR_WORKSPACE_JOBS_REDUCER",
      RETRIEVE: "WORKSPACE_JOB_RETRIEVE",
      CANCEL: "WORKSPACE_JOB_CANCEL",
      LIST_PROJECT: "PROJECT_WORKSPACE_JOB_LIST"
    },
    LOADING_LIMIT: 20
  },
  HISTORY: {
    ACTION: {
      LIST: "WORKSPACE_HISTORY_LIST",
      CLEAR_CACHE: "CLEAR_WORKSPACE_HISTORY_CACHE",
      RETRIEVE: "WORKSPACE_HISTORY_RETRIEVE",
      GET_LAST_TWO: "WORKSPACE_HISTORY_GET_LAST_TWO"
    },
    LOADING_LIMIT: 20
  },
  CONFIGURATION: {
    JOB: {
      ACTION: {
        LIST: "WORKSPACE_CONFIGURATION_JOBS_LIST"
      },
      LOADING_LIMIT: 20
    }
  }
}

export const DAWG = {
  ACTION: {
    LIST: "DAWG_LIST",
    CREATE: "DAWG_CREATE",
    DELETE: "DAWG_DELETE",
    MODIFY: "DAWG_MODIFY",
    RETRIEVE: "DAWG_RETRIEVE",
    LIST_LAST_JOBS: "DAWGS_LAST_JOBS_LIST"
  },
  JOB: {
    ACTION: {
      RUN: "DAWG_JOB_RUN",
      LIST: "DAWG_JOB_LIST",
      REFRESH_LAST: "REFRESH_LAST_DAWG_JOBS",
      CLEAR_CACHE: "CLEAR_DAWG_JOBS_REDUCER",
      RETRIEVE: "DAWG_JOB_RETRIEVE",
      CANCEL: "DAWG_JOB_CANCEL"
    },
    LOADING_LIMIT: 20
  },
  HISTORY: {
    ACTION: {
      LIST: "DAWG_HISTORY_LIST",
      CLEAR_CACHE: "CLEAR_DAWG_HISTORY_CACHE",
      RETRIEVE: "DAWG_HISTORY_RETRIEVE",
      GET_LAST_TWO: "DAWG_HISTORY_GET_LAST_TWO"
    }
  },
  WORKSPACE: {
    ACTION: {
      MODIFY: "MODIFY_DAWG_WORKSPACE",
      MODIFY_BULK: "MODIFY_BULK_DAWG_WORKSPACE",
      LIST: "LIST_DAWG_WORKSPACE",
      ADD: "ADD_WORKSPACE_TO_DAWG",
      DELETE: "REMOVE_WORKSPACE_FROM_DAWG"
    },
    JOB: {
      ACTION: {
        LIST: "LIST_DAWG_WORKSPACE_JOB"
      }
    }
  },
  LOADING_LIMIT: 20
}

export const TAG = {
  ACTION: {
    LIST: "TAG_LIST",
    ASSIGN_TAG_TO_WORKSPACE: "ASSIGN_TAG_TO_WORKSPACE",
    DELETE_TAG_FROM_WORKSPACE: "DELETE_TAG_FROM_WORKSPACE",
    ASSIGN_TAG_TO_DAWG: "ASSIGN_TAG_TO_DAWG",
    DELETE_TAG_FROM_DAWG: "DELETE_TAG_FROM_DAWG",
    CREATE: "TAG_CREATE",
    MODIFY: "TAG_MODIFY",
    DELETE: "TAG_DELETE"
  }
}

export const TABLE = {
  ACTION: {
    SET_SORT_OPTIONS: "SET_TABLE_SORT",
    SET_SELECTED_TAGS: "SET_SELECTED_TAGS",
    SET_SORTING_SEARCHTEXT_AND_TAGS: "SET_SORTING_SEARCHTEXT_AND_TAGS",
    SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
    SET_FILTER_BY: "SET_FILTER_BY"
  }
}

export const OAUTH = {
  ACTION: {
    LIST: "OAUTH_LIST",
    MODIFY: "OAUTH_MODIFY"
  }
}

export const ERROR = {
  ACTION: {
    ADD: "ERROR_MESSAGE_ADD",
    REMOVE: "ERROR_MESSAGE_REMOVE"
  }
}

export const SUCCESS = {
  ACTION: {
    ADD: "SUCCESS_MESSAGE_ADD",
    REMOVE: "SUCCESS_MESSAGE_REMOVE"
  }
}

export const COMPONENT = {
  ACTION: {
    LIST: "COMPONENT_LIST"
  }
}

export const CONFIGURATION = {
  ACTION: {
    LIST: "CONFIGURATION_LIST",
    CREATE: "CONFIGURATION_CREATE",
    MODIFY: "CONFIGURATION_MODIFY",
    MODIFY_BULK: "CONFIGURATIONS_MODIFY_BULK",
    CLONE: "CONFIGURATION_CLONE",
    DELETE: "CONFIGURATION_DELETE",
    REVERT: "CONFIGURATION_REVERT",
    RETRIEVE: "CONFIGURATION_RETRIEVE"
  },
  JOB: {
    ACTION: {
      RUN: "CONFIGURATION_JOB_RUN",
      LIST: "CONFIGURATION_JOB_LIST",
      REFRESH_LAST: "CONFIGURATION_JOB_REFRESH",
      LIST_PROJECT: "PROJECT_CONFIGURATION_JOB_LIST",
      CLEAR_CACHE: "CONFIGURATION_JOB_CLEAR_REDUCER_CACHE",
      CANCEL: "CONFIGURATION_JOB_CANCEL",
      RETRIEVE: "CONFIGURATION_JOB_RETRIEVE"
    },
    LOADING_LIMIT: 20
  },
  HISTORY: {
    ACTION: {
      LIST: "CONFIGURATION_HISTORY_LIST",
      CLEAR_CACHE: "CLEAR_CONFIGURATION_HISTORY_CACHE",
      RETRIEVE: "CONFIGURATION_HISTORY_RETRIEVE",
      GET_LAST_TWO: "CONFIGURATION_HISTORY_GET_LAST_TWO"
    },
    LOADING_LIMIT: 20
  },
  DATA_PREVIEW: {
    IN: {
      ACTION: {
        LIST: "IN_DATA_PREVIEW_LIST"
      }
    },
    OUT: {
      ACTION: {
        LIST: "OUT_DATA_PREVIEW_LIST"
      }
    },
    LOADING_LIMIT: 50
  }
}

export const LOG = {
  LOADING_LIMIT: 20
}

export const TRASH = {
  ACTION: {
    LIST: "TRASH_ITEMS_LIST",
    RESTORE_USER: "TRASH_USER_RESTORE",
    RESTORE_CONFIGURATION: "TRASH_CONFIGURATION_RESTORE",
    RESTORE_WORKSPACE: "TRASH_WORKSPACE_RESTORE"
  },
  LOADING_LIMIT: 20
}

export const ACL = {
  ACTION: {
    LIST: "WORKSPACE_ACL_LIST",
    CREATE: "WORKSPACE_ACL_CREATE",
    RETRIEVE: "WORKSPACE_ACL_FOR_USER",
    MODIFY: "MODIFY_USERS_WORKSPACE_ACL",
    DELETE: "DELETE_USERS_WORKSPACE_ACL"
  },
  DAWG: {
    ACTION: {
      LIST: "DAWG_ACL_LIST",
      CREATE: "DAWG_ACL_CREATE",
      RETRIEVE: "DAWG_ACL_RETRIEVE",
      MODIFY: "DAWG_ACL_MODIFY",
      DELETE: "DELETE_USERS_DAWG_ACL"
    }
  },
  USER: {
    ACTION: {
      LIST: "USERS_WORKSPACES_ACLS_RETRIEVE"
    },
    DAWG: {
      ACTION: {
        LIST: "USERS_DAWGS_ACLS_RETRIEVE"
      }
    }
  }
}

export const SYSTEM = {
  ACTION: {
    GET_WORKERS_COUNT: "GET_WORKERS_COUNT"
  }
}

export const REDUCERS = {
  ACTION: {
    RESET: "REDUCERS_RESET"
  }
}

export const SEARCH = {
  ACTION: {
    LIST: "SEARCH_LIST",
    CLEAR: "SEARCH_CLEAR"
  },
  LOADING_LIMIT: 20
}

export const ADD_INPUTBOX_TYPE = {
  EMAIL: "INPUTBOX_EMAIL"
}

export const REPEAT = "Repeat"
export const ONCE = "Once"

export const SCHEDULE_FORM_TYPE = {
  DAY_OF_WEEK: "DAY_OF_WEEK",
  DAY_OF_MONTH: "DAY_OF_MONTH"
}

export const PERMISSION = {
  READ: "read",
  WRITE: "write"
}

export const MODAL = {
  TYPE: {
    DELETE: "delete",
    SUCCESS: "success",
    CANCEL: "cancel",
    ERASE: "erase"
  }
}

export const INVITE_FORM = {
  ACTION: {
    SET_DEFAULT_READ_PERMISSIONS: "SET_DEFAULT_READ_PERMISSIONS",
    MARK_ALL_WORKSPACES: "MARK_ALL_WORKSPACES",
    SET_PERMISSIONS_MARK_ALL: "SET_PERMISSIONS_MARK_ALL",
    SET_ACTIVE_USER: "SET_ACTIVE_USER",
    TOGGLE_ALL_SWITCH: "TOGGLE_ALL_SWITCH",
    TOGGLE_ALL_USER_PERMISSIONS: "TOGGLE_ALL_USER_PERMISSIONS"
  }
}

export const MOMENT = {
  DATE_TIME_WITH_SECONDS: "HH:mm:ss, D.M.YYYY",
  DATE_TIME_FORMAT: "HH:mm, D.M.YYYY",
  DATE_FORMAT: "D.M.YYYY"
}

export const GANTT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const INTERVAL = {
  WORKSPACE_LIST: 5000,
  DAWG_LIST: 5000,
  WORKSPACE_SHOW: 5000,
  DAWG_SHOW: 5000,
  CONFIGURATION_SHOW: 5000,
  CONFIGURATION_JOB_REFRESH: 5000,
  WORKSPACE_JOB_REFRESH: 5000,
  LAST_WORKSPACE_CONFIGURATION_JOBS_REFRESH: 5000,
  DAWG_WS_JOBLIST_REFRESH: 5000,
  DAWG_WS_JOB_REFRESH: 5000
}
