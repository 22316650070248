import _toInteger from "lodash/toInteger"

export const getDawgs = state => state.dawgs.get("dawgs")
export const getDawgsSelectionSettings = state => state.dawgs.get("selectionSettings")
export const getDawgsHasMoreItem = state => state.dawgs.get("hasMoreItems")
export const getLastDawgsJobs = state => state.dawgs.get("lastJobs")
export const getDawgRecord = (state, id) => {
  if (state.dawgs.get("dawgs")) {
    return state.dawgs.get("dawgs").find(dawg => dawg.id === _toInteger(id))
  } else {
    return null
  }
}
