import { api } from "api"
import _get from "lodash/get"
import { WORKSPACE } from "sharedConstants"

export const listWorkspaceHistory = (
  workspaceId,
  offset,
  limit,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: WORKSPACE.HISTORY.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().workspace.history.list(workspaceId, offset, limit, loadFullStructure)
      })()
    }
  })

export const revertWorkspaceTo = (
  workspaceId,
  historyId,
  loadFullStructure = 0,
  updateRedux = false
) => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().workspace.history.revertTo(
          workspaceId,
          historyId,
          loadFullStructure
        )
        if (updateRedux) {
          dispatch({
            type: WORKSPACE.HISTORY.ACTION.GET_LAST_TWO,
            payload: {
              promise: async () => {
                return await api().workspace.history.list(workspaceId, 0, 2, loadFullStructure)
              }
            }
          })
        }
        return _get(response, "workspace", {})
      })()
    }
  })

export const retrieveWorkspaceHistoryItem = (
  workspaceId,
  historyId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: WORKSPACE.HISTORY.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        return await api().workspace.history.retrieve(workspaceId, historyId, loadFullStructure)
      })()
    }
  })

export const clearCachedWorkspaceHistory = () => dispatch =>
  dispatch({
    type: WORKSPACE.HISTORY.ACTION.CLEAR_CACHE,
    payload: {}
  })
