import _toString from "lodash/toString"

export const getWorkspaceAclsData = (state, workspaceId) => {
  if (state.acls.get("workspaces")) {
    return state.acls.getIn(["workspaces", _toString(workspaceId)])
  } else {
    return null
  }
}

export const getDawgAclsData = (state, dawgId) => {
  if (state.acls.get("dawgs")) {
    return state.acls.getIn(["dawgs", _toString(dawgId)])
  } else {
    return null
  }
}
