import { api } from "api"
import { COMPONENT } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchComponentList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: COMPONENT.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().component.list(offset, limit, loadFullStructure)
          )
          .setDataPath("components")
          .setLoadFullStructure(0)
          .run()
        return data
      })()
    }
  })
}
