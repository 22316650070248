import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./InfoTooltip.css"

const InfoTooltip = ({ children, tooltipId, place = "top", className = "", ...otherProps }) => {
  return (
    <span className={`info-tooltip ${className}`}>
      <FontAwesomeIcon
        icon={["fas", "info-circle"]}
        data-tip
        data-for={tooltipId}
        className="labels-tooltip-icon"
      />
      <ReactTooltip className="tooltip" id={tooltipId} place={place} {...otherProps}>
        {children}
      </ReactTooltip>
    </span>
  )
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipId: PropTypes.string.isRequired,
  place: PropTypes.string,
  className: PropTypes.string
}

export default InfoTooltip
