import { Map, List } from "immutable"

const getChangedKeys = (left, right, omitKeys) => {
  let diffKeys = []
  let it = left.keys()
  let key = it.next()

  while (!key.done) {
    if (!omitKeys.includes(key.value)) {
      const value = left.get(key.value)
      if (Map.isMap(value) || List.isList(value)) {
        if (!value.equals(right.get(key.value))) {
          diffKeys.push(key.value)
        }
      } else {
        if (value !== right.get(key.value)) {
          diffKeys.push(key.value)
        }
      }
    }
    key = it.next()
  }
  return diffKeys
}

export const getDawgChangedKeys = (left, right) => {
  return getChangedKeys(left, right, [
    "id",
    "user",
    "frontend_settings",
    "last_run",
    "last_run_duration",
    "author",
    "created"
  ])
}

export const getWorkspaceChangedKeys = (left, right) => {
  return getChangedKeys(left, right, ["created", "frontend_settings", "last_run", "id", "user_id"])
}

export const getConfigurationChangedKeys = (left, right) => {
  return getChangedKeys(left, right, [
    "created",
    "frontend_settings",
    "workspace_id",
    "id",
    "component_id",
    "user_id"
  ])
}
