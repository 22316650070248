import { SEARCH } from "sharedConstants"
import { api } from "api"

export const fulltextSearch = (searchText, offset, limit, loadFullStructure) => async dispatch =>
  dispatch({
    type: SEARCH.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().configurationSettigsSearch(searchText, offset, limit, loadFullStructure)
      })()
    }
  })

export const clearFulltextSearch = () => dispatch =>
  dispatch({
    type: SEARCH.ACTION.CLEAR,
    payload: null
  })
