import { Record } from "immutable"

const Component = Record({
  id: 0,
  name: "",
  type: "",
  description: "",
  default_settings: {},
  settings_template: {},
  icon: "",
  in_progress: 0,
  hidden: 0
})

export default Component
