import React, { PureComponent } from "react"
import { withRouter } from "react-router-dom"

import { getRoutePath } from "routes"

import "./FooterContainer.css"

import meiroGreyLogoSrc from "images/meiro-wide-grey.png"
const meiroClientLogoSrc = `${process.env.PUBLIC_URL}/img/client/logo.png`

class FooterContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      clientLogoExists: true,
      clientLogoLoaded: false
    }
  }

  onClientLogoError = () => {
    this.setState({
      clientLogoExists: false
    })
  }

  onClientLogoLoad = () => {
    this.setState({
      clientLogoLoaded: true
    })
  }

  render() {
    const { clientLogoExists, clientLogoLoaded } = this.state
    const pathname = window.location.pathname

    if (pathname === getRoutePath("workspace.list") || pathname === getRoutePath("dashboard")) {
      return null
    }

    return (
      <div className="footer-container">
        <div className="footer-content">
          <img src={meiroGreyLogoSrc} className="meiro-footer-logo" alt="meiro footer logo" />
          {clientLogoExists && clientLogoLoaded && <div className="vertical-delimiter" />}
          {clientLogoExists && (
            <img
              src={meiroClientLogoSrc}
              alt="client footer logo"
              onError={this.onClientLogoError}
              onLoad={this.onClientLogoLoad}
              className={clientLogoLoaded ? "client-logo loaded" : "client-logo"}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(FooterContainer)
