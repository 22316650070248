import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Gantt from "5minute-frappe-gantt"
import { withRouter } from "react-router-dom"

// helpers, constants
import { getRoutePath } from "routes"
import { GANTT_DATETIME_FORMAT } from "sharedConstants"

import "./WorkspaceGanttChart.scss"

class WorkspaceGanttChart extends PureComponent {
  gantt = null
  metaKey = false

  componentDidMount() {
    const { tasks, history, workspaceId, startDate, endDate } = this.props
    this.gantt = new Gantt(this.containerDOM, tasks, {
      on_click: task => {
        const { deleted } = task.configuration
        if (!deleted) {
          if (this.metaKey) {
            const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
            window.open(
              publicUrl.origin +
                getRoutePath("workspace.configuration.configurationJob.show", {
                  id: workspaceId,
                  cid: task.configuration.id,
                  aid: task.job_id
                }),
              "_blank"
            )
          } else {
            history.push({
              pathname: getRoutePath("workspace.configuration.configurationJob.show", {
                id: workspaceId,
                cid: task.configuration.id,
                aid: task.job_id
              })
            })
          }
        }
      },
      language: "en",
      date_format: GANTT_DATETIME_FORMAT,
      bar_height: 28,
      header_height: 25,
      is_editable: false,
      popup_trigger: null,
      min_columns_count: 3,
      width: 918,
      responsive: true,
      hide_horizontal_lines: true,
      padding: 15,
      start_date: startDate,
      end_date: endDate,
      start_end_force: true,
      popup_enabled: false
    })

    const chart = document.getElementById("ws-gantt-chart")
    if (chart) {
      const width = chart.offsetWidth
      const scrollWidth = chart.scrollWidth
      const diff = scrollWidth - width
      if (diff) {
        const scrollableArea = document.getElementById("scrollable-area")
        if (scrollableArea) {
          if (scrollableArea.scrollLeft === 0) {
            scrollableArea.scrollLeft = diff
            const workspaceNames = document.getElementsByClassName("workspace-gantt-workspace-name")
            for (const el of workspaceNames) {
              el.setAttribute("style", `left: ${diff}px`)
            }
          }
        }
      }
    }

    // command/ctrl key event handler
    window.addEventListener("keydown", this.handleKeyDown, false)
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    window.removeEventListener("keydown", this.handleKeyDown, false)
  }

  handleKeyUp = evt => {
    if (!evt.metaKey) {
      this.metaKey = false
    }
  }

  handleKeyDown = evt => {
    if (evt.metaKey) {
      this.metaKey = true
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      this.gantt.refresh(this.props.tasks, this.props.startDate, this.props.endDate)
    }
  }

  render() {
    return (
      <div
        id={`ws-gantt-chart-${this.props.workspaceId}`}
        ref={el => (this.containerDOM = el)}
        className="workspace-gantt-chart"
      />
    )
  }
}

WorkspaceGanttChart.propTypes = {
  tasks: PropTypes.array.isRequired,
  workspaceName: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
}

export default withRouter(WorkspaceGanttChart)
