import { Record } from "immutable"

const WorkspaceJob = Record({
  id: 0,
  user_id: 0,
  workspace_id: 0,
  status: "",
  stats: null,
  created: "",
  origin_created: ""
})

export const WorkspaceJobFull = Record({
  id: 0,
  user: null,
  workspace: null,
  status: "",
  stats: null,
  created: "",
  origin_created: ""
})

export default WorkspaceJob
