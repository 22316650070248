import React, { PureComponent } from "react"
import { Map, Record } from "immutable"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import PropTypes from "prop-types"

import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import SearchForm from "components/UI/components/SearchForm"
import Button from "components/UI/elements/Button"
import Dag from "components/UI/DawgWsShared/Dag"

import { getRoutePath } from "routes"

import "./DataFlow.css"

class DataFlow extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dag: Map({
        previewMode: false,
        // 1 means sort, 0 means cancel
        callbackValue: null
      }),
      highlightedEntities: Map({
        active: false,
        ids: []
      })
    }
  }

  togglePreviewMode = (cancel = false) => () => {
    this.setState(prevState => ({
      dag: prevState.dag
        .set("previewMode", !prevState.dag.get("previewMode"))
        .set("callbackValue", cancel ? 0 : null)
    }))
  }

  setDagCallbackValue = value => () => {
    this.setState(prevState => ({
      dag: prevState.dag.set("callbackValue", value)
    }))
  }

  searchEntities = searchTerm => {
    const entitiesFuseData = this.props.entitiesFuseSearchInstance.get("data")
    let entitiesList = searchTerm
      ? entitiesFuseData.search(searchTerm).toArray()
      : entitiesFuseData.list.toArray()

    if (searchTerm) {
      this.setState({
        highlightedEntities: Map({
          active: true,
          ids: entitiesList.map(ent => ent.id)
        })
      })
    } else {
      this.setState({
        highlightedEntities: Map({
          active: false,
          ids: []
        })
      })
    }
  }

  render() {
    const {
      entityName,
      entity,
      isEntitiesFulfilled,
      components,
      isEditable,
      entityStatuses,
      isChildrenFetching,
      isChildrenModifying,
      childEntities,
      modifyEntity,
      deleteEntity,
      runEntityJob,
      cancelEntityJob,
      modifyEntitiesBulk
    } = this.props
    const { dag, highlightedEntities } = this.state

    const childEntity = entityName === "workspace" ? "configuration" : "workspace"
    const componentsReady = entityName === "workspace" ? Map.isMap(components) : true

    return (
      <div className="data-flow-section">
        <PaperHeader size={`small data-flow-header ${dag.get("previewMode") ? "preview" : ""}`}>
          {!dag.get("previewMode") && (
            <React.Fragment>
              <h3>Data flow</h3>
              <SearchForm
                placeholder={`Find ${childEntity}`}
                onSubmit={this.searchEntities}
                className="entity-search"
              />
              <Link
                className="button primary big"
                to={getRoutePath(
                  entityName === "workspace"
                    ? "workspace.configuration.create"
                    : "dawg.workspace.add",
                  {
                    id: entity.id
                  }
                )}
                data-cy="create-entity-button"
              >
                + {entityName === "workspace" ? "Create" : "Add"} {childEntity}
              </Link>
            </React.Fragment>
          )}
          {dag.get("previewMode") && (
            <React.Fragment>
              <h3>Data Flow preview</h3>
              <div className="right-part">
                <p>Do you wish to organize this {entityName}?</p>
                <Button color="white" size="small" onClick={this.togglePreviewMode(true)}>
                  Cancel
                </Button>
                <Button color="primary" size="small" onClick={this.setDagCallbackValue(1)}>
                  Confirm
                </Button>
              </div>
            </React.Fragment>
          )}
        </PaperHeader>
        <Paper
          hasHeader={true}
          className={`data-flow-container ${dag.get("previewMode") ? "preview" : ""}`}
        >
          {isEntitiesFulfilled && componentsReady && (
            <Dag
              rootEntityName={entityName}
              entity={entity}
              isChildrenFetching={isChildrenFetching}
              isChildrenModifying={isChildrenModifying}
              childEntities={childEntities}
              isEditable={isEditable}
              highlightedEntities={highlightedEntities}
              entityStatuses={entityStatuses}
              preview={dag.get("previewMode")}
              arrangementCallbackValue={dag.get("callbackValue")}
              togglePreviewMode={this.togglePreviewMode()}
              modifyEntity={modifyEntity}
              deleteEntity={deleteEntity}
              runEntityJob={runEntityJob}
              cancelEntityJob={cancelEntityJob}
              modifyEntitiesBulk={modifyEntitiesBulk}
            />
          )}
        </Paper>
        <CSSTransition in={dag.get("previewMode")} timeout={200} classNames="fade" unmountOnExit>
          <div className="dag-preview-overlay" />
        </CSSTransition>
      </div>
    )
  }
}

DataFlow.propTypes = {
  entitiesFuseSearchInstance: PropTypes.instanceOf(Map).isRequired,
  entityName: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Record),
  isEntitiesFulfilled: PropTypes.bool.isRequired,
  components: PropTypes.instanceOf(Map),
  isEditable: PropTypes.bool.isRequired,
  entityStatuses: PropTypes.instanceOf(Map).isRequired,
  isChildrenFetching: PropTypes.bool.isRequired,
  isChildrenModifying: PropTypes.bool,
  childEntities: PropTypes.instanceOf(Map),
  modifyEntity: PropTypes.func.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  runEntityJob: PropTypes.func.isRequired,
  cancelEntityJob: PropTypes.func.isRequired,
  modifyEntitiesBulk: PropTypes.func.isRequired
}

export default DataFlow
