import _get from "lodash/get"
import { api } from "api"
import { CONFIGURATION } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchConfigurationList = (workspaceId, loadFullStructure = 0) => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: CONFIGURATION.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().configuration.list(workspaceId, offset, limit, loadFullStructure)
          )
          .setDataPath("configurations")
          .setLoadFullStructure(loadFullStructure)
          .run()
        return {
          workspaceId,
          data
        }
      })()
    }
  })
}

export const retrieveConfiguration = (
  workspaceId,
  configurationId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        const response = await api().configuration.retrieve(
          workspaceId,
          configurationId,
          loadFullStructure
        )
        return _get(response, "configuration", {})
      })()
    }
  })

export const createConfiguration = (workspaceId, data) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api().configuration.create(workspaceId, data)
        return _get(response, "configuration", {})
      })()
    }
  })

export const deleteConfiguration = (workspaceId, configurationId) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api().configuration.delete(workspaceId, configurationId)
        return {
          workspaceId,
          configurationId
        }
      })()
    }
  })

export const modifyConfigurationsBulk = (
  workspaceId,
  data,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.MODIFY_BULK,
    meta: {
      workspaceId
    },
    payload: {
      promise: (async () => {
        const response = await api().configuration.modifyBulk(workspaceId, data, loadFullStructure)
        return _get(response, "configurations", [])
      })()
    }
  })

export const modifyConfiguration = (
  workspaceId,
  configurationId,
  data,
  originData = {},
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.MODIFY,
    meta: {
      workspaceId
    },
    payload: {
      promise: (async () => {
        const response = await api().configuration.modify(
          workspaceId,
          configurationId,
          data,
          originData,
          loadFullStructure
        )
        return _get(response, "configuration", {})
      })()
    }
  })

export const cloneConfiguration = (
  workspaceId,
  configurationId,
  data,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.ACTION.CLONE,
    payload: {
      promise: (async () => {
        const response = await api().configuration.clone(
          workspaceId,
          configurationId,
          data,
          loadFullStructure
        )
        return _get(response, "configuration", {})
      })()
    }
  })
