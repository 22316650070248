import React from "react"
import PropTypes from "prop-types"
import { Map } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// ui components
import Duration from "../Duration"

import "./StatusElement.css"

const StatusElement = ({
  align,
  status,
  history,
  created,
  showDuration,
  showActualRunningDuration,
  durationOneLine
}) => {
  let icon
  switch (status) {
    case "waiting":
      icon = <FontAwesomeIcon data-testid="hourglass" icon={["fas", "hourglass"]} />
      break
    case "warning":
      icon = <FontAwesomeIcon data-testid="exclamation" icon={["fas", "exclamation"]} />
      break
    case "running":
      icon = <FontAwesomeIcon data-testid="play" icon={["fas", "play"]} />
      break
    case "error":
      icon = <FontAwesomeIcon data-testid="minus" icon={["fas", "minus"]} />
      break
    case "finished":
      icon = <FontAwesomeIcon data-testid="check" icon={["fas", "check"]} />
      break
    case "canceled":
      icon = <FontAwesomeIcon data-testid="times" icon={["fas", "times"]} />
      break
    default:
      icon = <FontAwesomeIcon data-testid="hourglass" icon={["fas", "hourglass"]} />
      break
  }

  return (
    <div
      className={`status-element ${align} ${status} ${
        showDuration || showActualRunningDuration ? "with-duration" : ""
      } ${durationOneLine ? "one-line-duration" : ""}`}
      data-testid="status-element"
    >
      {(align === "left" || align === "center") && (
        <div className={`col ${align === "left" ? "left-icon" : "center-icon"}`}>{icon}</div>
      )}
      <div className="col">
        <span className="status-text">{status}</span>
        {showDuration === true && (
          <span className="duration" data-testid="duration">
            <Duration status={status} history={history} created={created} />
          </span>
        )}
        {showActualRunningDuration && (
          <span className="actual-duration" data-testid="actual-duration">
            <Duration status={status} history={history} created={created} showRunningTime={true} />
          </span>
        )}
      </div>
      {align === "right" && <div className="col right-icon">{icon}</div>}
    </div>
  )
}

StatusElement.propTypes = {
  align: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Map),
  created: PropTypes.string,
  showDuration: PropTypes.bool,
  showActualRunningDuration: PropTypes.bool,
  durationOneLine: PropTypes.bool
}

export default StatusElement
