import { TABLE } from "sharedConstants"

export const setSortingOptions = (form, column, order) => async dispatch => {
  dispatch({
    type: `${form}_${TABLE.ACTION.SET_SORT_OPTIONS}`,
    payload: {
      promise: () => {
        return { column, order }
      }
    }
  })
}

export const setFilterBy = (form, attribute) => async dispatch => {
  dispatch({
    type: `${form}_${TABLE.ACTION.SET_FILTER_BY}`,
    payload: {
      promise: () => {
        return { attribute }
      }
    }
  })
}

export const setFilterByTags = (form, selectedTags) => async dispatch => {
  dispatch({
    type: `${form}_${TABLE.ACTION.SET_SELECTED_TAGS}`,
    payload: {
      promise: () => {
        return { selectedTags }
      }
    }
  })
}

export const setFilterAndSorting = (form, column, order, selectedTags) => async dispatch => {
  dispatch({
    type: `${form}_${TABLE.ACTION.SET_SORTING_AND_TAGS}`,
    payload: {
      promise: () => {
        return { column, order, selectedTags }
      }
    }
  })
}
