import { WORKSPACE, TAG } from "sharedConstants"
import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import { createWorkspaceModel } from "models/workspace.model"
import WorkspaceJobModel from "models/workspaceJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map({
  workspaces: null,
  selectionSettings: new SelectionSettingModel(),
  hasMoreItems: false,
  lastJobs: null
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${WORKSPACE.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        return state
          .set("workspaces", List(_map(payload.workspaces, ws => createWorkspaceModel(ws))))
          .set("selectionSettings", fromJS(new SelectionSettingModel(payload.selection_settings)))
          .set("hasMoreItems", payload.workspaces.length === payload.selection_settings.limit)
      } else {
        return state
          .set(
            "workspaces",
            state.get("workspaces").concat(_map(payload.workspaces, ws => createWorkspaceModel(ws)))
          )
          .set("selectionSettings", fromJS(new SelectionSettingModel(payload.selection_settings)))
          .set("hasMoreItems", payload.workspaces.length === payload.selection_settings.limit)
      }

    case `${WORKSPACE.ACTION.RETRIEVE}_FULFILLED`:
      /*
       * Two situations may occur:
       * 1) state is null -> direct acces to workspace detail
       * 2) state is List() -> access to workspace detail from workspace list
       */
      if (state.get("workspaces") === null) {
        return state.set("workspaces", List([createWorkspaceModel(payload)]))
      } else {
        const isAlreadyInList = state.get("workspaces").find(ws => ws.id === payload.id)
        if (isAlreadyInList) {
          return state.set(
            "workspaces",
            state
              .get("workspaces")
              .map(ws => (ws.id === payload.id ? createWorkspaceModel(payload) : ws))
          )
        } else {
          return state.set(
            "workspaces",
            state.get("workspaces").push(createWorkspaceModel(payload))
          )
        }
      }

    case `${WORKSPACE.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "workspaces",
        state
          .get("workspaces")
          .map(workspace =>
            workspace.id === payload.id ? createWorkspaceModel(payload) : workspace
          )
      )

    case `${WORKSPACE.ACTION.DELETE}_FULFILLED`:
      // payload === workspace id
      return state.set(
        "workspaces",
        state.get("workspaces").filterNot(workspace => workspace.id === payload)
      )

    case `${TAG.ACTION.ASSIGN_TAG_TO_WORKSPACE}_FULFILLED`: {
      const workspaceIndex = state.get("workspaces").findIndex(ws => ws.id === payload.workspace.id)
      if (workspaceIndex !== -1) {
        return state.setIn(
          ["workspaces", workspaceIndex, "tags"],
          state.getIn(["workspaces", workspaceIndex, "tags"]).concat(payload.tag)
        )
      }
      return state
    }

    case `${TAG.ACTION.DELETE_TAG_FROM_WORKSPACE}_FULFILLED`: {
      const workspaceIndex = state.get("workspaces").findIndex(ws => ws.id === payload.workspace_id)
      if (workspaceIndex !== -1) {
        return state.setIn(
          ["workspaces", workspaceIndex, "tags"],
          state.getIn(["workspaces", workspaceIndex, "tags"]).filterNot(tag => {
            return tag.id === payload.tag_id
          })
        )
      }
      return state
    }

    case WORKSPACE.ACTION.LIST_LAST_JOBS:
      if (state.get("lastJobs") === null) {
        return state.set(
          "lastJobs",
          Map(
            _mapKeys(
              _map(payload, lastJob => new WorkspaceJobModel(fromJS(lastJob.last_job))),
              "workspace_id"
            )
          )
        )
      } else {
        return state.set(
          "lastJobs",
          state.get("lastJobs").merge(
            _mapKeys(
              _map(payload, lastJob => new WorkspaceJobModel(fromJS(lastJob.last_job))),
              "workspace_id"
            )
          )
        )
      }

    // create action calls dispatches LIST action because we haven't got fetched all the data ->
    // it's not possible to insert newly create item somewhere if there exists some filter or sorting rule
    // case `${WORKSPACE.ACTION.CREATE}_FULFILLED`:
    // case `${WORKSPACE.ACTION.CLONE}_FULFILLED`:

    default:
      return state
  }
}
