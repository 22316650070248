import _get from "lodash/get"
import { api } from "api"
import { ACL } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchWorkspaceAcl = workspaceId => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ACL.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().acl.list(workspaceId, offset, limit, loadFullStructure, "id", "DESC")
          )
          .setDataPath("workspace_users")
          .setLoadFullStructure(0)
          .run()
        return {
          workspaceId,
          data
        }
      })()
    }
  })
}

export const fetchDawgAcl = dawgId => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ACL.DAWG.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().acl.dawg.list(dawgId, offset, limit, loadFullStructure, "id", "DESC")
          )
          .setDataPath("dawg_users")
          .setLoadFullStructure(0)
          .run()
        return {
          dawgId,
          data
        }
      })()
    }
  })
}

export const fetchUsersAcl = userId => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ACL.USER.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().acl.user.retrieve(userId, offset, limit, loadFullStructure, 1)
          )
          .setDataPath("user_workspaces")
          .setLoadFullStructure(0)
          .run()
        return data
      })()
    }
  })
}

export const fetchDawgUsersAcl = userId => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ACL.USER.DAWG.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().acl.user.dawg.retrieve(userId, offset, limit, loadFullStructure, 1)
          )
          .setDataPath("user_dawgs")
          .setLoadFullStructure(0)
          .run()
        return data
      })()
    }
  })
}

export const createAcl = (workspaceId, data) => async dispatch =>
  dispatch({
    type: ACL.ACTION.CREATE,
    payload: (async () => {
      const response = await api().acl.create(workspaceId, data)
      return _get(response, "workspace_user", {})
    })()
  })

export const createDawgAcl = (dawgId, data) => async dispatch =>
  dispatch({
    type: ACL.DAWG.ACTION.CREATE,
    payload: (async () => {
      const response = await api().acl.dawg.create(dawgId, data)
      return _get(response, "dawg_user", {})
    })()
  })

export const modifyAcl = (workspaceId, userId, data) => async dispatch =>
  dispatch({
    type: ACL.ACTION.MODIFY,
    payload: (async () => {
      const response = await api().acl.modify(workspaceId, userId, data)
      return _get(response, "workspace_user", {})
    })()
  })

export const modifyDawgAcl = (dawgId, userId, data) => async dispatch =>
  dispatch({
    type: ACL.DAWG.ACTION.MODIFY,
    payload: (async () => {
      const response = await api().acl.dawg.modify(dawgId, userId, data)
      return _get(response, "dawg_user", {})
    })()
  })

export const deleteAcl = acl => async dispatch =>
  dispatch({
    type: ACL.ACTION.DELETE,
    payload: (async () => {
      await api().acl.delete(acl.workspace_id, acl.user_id)
      return {
        workspaceId: acl.workspace_id,
        aclId: acl.id
      }
    })()
  })

export const deleteDawgAcl = acl => async dispatch =>
  dispatch({
    type: ACL.DAWG.ACTION.DELETE,
    payload: (async () => {
      await api().acl.dawg.delete(acl.dawg_id, acl.user_id)
      return {
        dawgId: acl.dawg_id,
        aclId: acl.id
      }
    })()
  })
