import _isEmpty from "lodash/isEmpty"
import _isArray from "lodash/isArray"
import _toInteger from "lodash/toInteger"
import _toString from "lodash/toString"

class AutoIncrementSettingsProcessor {
  constructor(path = [], start = 1, dataType = "int") {
    this.path = path
    this.start = start
    this.dataType = dataType
  }

  _autoIncrementRecursion(part, pathIndex) {
    if (_isArray(part)) {
      // if pathIndex === this.path.length - 2, then we have to increment attribute
      // with name this.path[this.path.length - 1] now
      if (pathIndex === this.path.length - 2) {
        const attribute = this.path[this.path.length - 1]
        part.forEach((item, index) => {
          const value = index + _toInteger(this.start)
          item[attribute] = this.dataType === "string" ? _toString(value) : value
        })
      } else {
        part.forEach(item => {
          this._autoIncrementRecursion(item, pathIndex + 1)
        })
      }
    } else {
      this._autoIncrementRecursion(part[this.path[pathIndex + 1]], pathIndex + 1)
    }
  }

  preProcessSettings(settings) {
    return settings
  }

  postProcessSettings(settings) {
    if (!_isEmpty(this.path)) {
      try {
        this._autoIncrementRecursion(settings[this.path[0]], 0)
      } catch (err) {
        console.log(err)
        return settings
      }
    }
    return settings
  }
}

export default AutoIncrementSettingsProcessor
