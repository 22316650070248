import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Record, Map } from "immutable"
import _noop from "lodash/noop"
import { Waypoint } from "react-waypoint"
import { withRouter } from "react-router-dom"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers
import { getRoutePath } from "routes"
import PendingPromise from "helpers/pendingPromise.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBack } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"
import { capitalize } from "helpers/string.helper"

// constants
import { MODAL, TOAST } from "sharedConstants"

const LOADING_LIMIT = 20

class EntityHistoryList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirmModal: Map({
        open: false,
        historyId: null,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      listEntityHistory,
      match: {
        params: { id }
      }
    } = this.props
    listEntityHistory(id, 0, LOADING_LIMIT, 0).catch(_noop)
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
  }

  loadMoreHistoryItems = () => {
    const {
      listEntityHistory,
      selectionSettings,
      match: {
        params: { id }
      }
    } = this.props

    listEntityHistory(
      id,
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      0
    ).catch(_noop)
  }

  renderWaypoint = () => {
    const { hasMoreHistoryItems } = this.props
    if (hasMoreHistoryItems) {
      return <Waypoint onEnter={this.loadMoreHistoryItems} bottomOffset={-250} />
    }
  }

  confirmEntityRevert = () => {
    if (!this.state.confirmModal.get("isLoading")) {
      const {
        revertEntityTo,
        showToast,
        entityName,
        match: {
          params: { id }
        }
      } = this.props
      const { confirmModal } = this.state

      this.setState(prevState => ({
        confirmModal: prevState.confirmModal.set("isLoading", true)
      }))

      const revertToPromise = this.pendingPromises.create(
        revertEntityTo(id, confirmModal.get("historyId"), 0, true)
      )
      revertToPromise.promise
        .then(() => {
          this.setState(prevState => ({
            confirmModal: prevState.confirmModal.set("open", false)
          }))
          showToast(`${capitalize(entityName)} has been reverted.`, TOAST.TYPE.SUCCESS)
          this.pendingPromises.remove(revertToPromise)
        })
        .catch(() => {
          this.pendingPromises.remove(revertToPromise)
        })
    }
  }

  toggleConfirmModal = (historyId = null) => evt => {
    evt.preventDefault()
    this.setState(prevState => ({
      confirmModal: prevState.confirmModal
        .set("historyId", historyId)
        .set("open", !prevState.confirmModal.get("open"))
        .set("isLoading", false)
    }))
  }

  render() {
    const {
      historyList,
      isHistoryListFulfilled,
      usersAcl,
      history,
      entityName,
      getEntityChangedKeys,
      match: {
        params: { id, cid }
      }
    } = this.props
    const { confirmModal } = this.state
    const isEditable = hasWritePermission(usersAcl)

    let backPath,
      nextPath,
      pathParams = {}
    if (entityName === "workspace") {
      backPath = "workspace.show"
      nextPath = "workspace.workspaceHistory.show"
      pathParams = { id }
    } else if (entityName === "configuration") {
      backPath = "workspace.configuration.show"
      nextPath = "workspace.configuration.configurationHistory.list"
      pathParams = { id, cid }
    } else {
      backPath = "dawg.show"
      nextPath = "dawg.dawgHistory.show"
      pathParams = { id }
    }

    return (
      <section className="history-list-page">
        {isHistoryListFulfilled && List.isList(historyList) && (
          <React.Fragment>
            <PaperHeader size="small" className="history-list-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(history, getRoutePath(backPath, pathParams))}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>{capitalize(entityName)} Version History</h3>
              </div>
            </PaperHeader>
            <Paper hasHeader={true} className="history-list-content">
              {historyList.size > 0 && (
                <React.Fragment>
                  <div className="table table-content-clickable-row">
                    <div className="thead">
                      <div className="table-row">
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                        <div className="status table-head user-column">User</div>
                        <div className="table-head modified-column">Modified</div>
                        <div className="table-head">Changed</div>
                        <div className="table-head action-column">&nbsp;</div>
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {historyList.map(historyItem => {
                        return (
                          <Link
                            key={historyItem.id ? historyItem.id : 0}
                            className="table-row"
                            to={getRoutePath(nextPath, {
                              ...pathParams,
                              hid: historyItem.id ? historyItem.id : 0
                            })}
                          >
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                            <div className="table-cell author-text">
                              <Username userId={historyItem.getIn([entityName, "user_id"])} />
                            </div>
                            <div className="table-cell">
                              <DateTimeWithTooltip
                                dateTime={historyItem.getIn([entityName, "created"])}
                                uniqueTooltipId={`history-item-date-tooltip-${historyItem.id}`}
                              />
                            </div>
                            <div className="table-cell">
                              {getEntityChangedKeys(
                                historyItem.get(entityName),
                                historyItem.getIn(["previous_history", entityName], Map())
                              ).join(", ")}
                            </div>
                            <div className="table-cell action-column">
                              <IconButton
                                color="green"
                                onClick={this.toggleConfirmModal(historyItem.id)}
                                disabled={!isEditable || !historyItem.id}
                              >
                                <FontAwesomeIcon icon={["far", "undo"]} />
                              </IconButton>
                            </div>
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                  {this.renderWaypoint()}
                </React.Fragment>
              )}
            </Paper>
            <ConfirmModal
              open={confirmModal.get("open")}
              type={MODAL.TYPE.SUCCESS}
              handleClose={this.toggleConfirmModal()}
              handleConfirm={this.confirmEntityRevert}
              title={`Revert ${entityName}`}
              text={`Do you really want to revert ${entityName} to selected history item?`}
              isLoading={confirmModal.get("isLoading")}
            />
          </React.Fragment>
        )}
      </section>
    )
  }
}

EntityHistoryList.propTypes = {
  historyList: PropTypes.instanceOf(List).isRequired,
  isHistoryListFulfilled: PropTypes.bool.isRequired,
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  hasMoreHistoryItems: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  listEntityHistory: PropTypes.func.isRequired,
  revertEntityTo: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getEntityChangedKeys: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired
}

export default withRouter(EntityHistoryList)
