import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ColorRadioGroup.css"

class ColorRadioGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: null
    }
  }

  handleMouseHover = color => () => {
    this.setState({
      isHovering: color
    })
  }

  render() {
    const {
      input,
      meta: { touched, error },
      colors
    } = this.props
    const { isHovering } = this.state
    const errorMessage = touched ? error : ""
    return (
      <div className="colors-wrapper">
        <div className={`colors-options-group ${isHovering || input.value ? "active" : ""}`}>
          {colors.map(color => (
            <React.Fragment key={color}>
              <input
                {...input}
                type="radio"
                id={color}
                value={color}
                className="color-opt"
                checked={color === input.value}
              />
              <label
                htmlFor={color}
                onMouseEnter={this.handleMouseHover(color)}
                onMouseLeave={this.handleMouseHover()}
                className={isHovering === color || input.value === color ? "marked" : ""}
              >
                <span className={color}>
                  {input.value === color && (
                    <FontAwesomeIcon icon={["fas", "check"]} className="check-icon" />
                  )}
                </span>
              </label>
            </React.Fragment>
          ))}
        </div>
        {errorMessage && <p className="error-message">Pick one of the colors please.</p>}
      </div>
    )
  }
}

ColorRadioGroup.propTypes = {
  colors: PropTypes.array.isRequired
}

export default ColorRadioGroup
