import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { shortenString } from "helpers/string.helper"

import "./DawgLink.css"

const DawgLink = ({ name, route, maxChars }) => (
  <Link
    className="dawg-link"
    to={{
      pathname: route,
      state: {
        previous: true
      }
    }}
    data-testid="dawg-link"
  >
    {maxChars ? shortenString(name, maxChars, "dots") : name}
  </Link>
)

DawgLink.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  maxChars: PropTypes.number
}

export default DawgLink
