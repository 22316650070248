import { USER } from "sharedConstants"
import _get from "lodash/get"

const initialState = {
  token: "",
  data: null,
  isFetching: null,
  loginTimestamp: null,
  // disabled logout button when f.e. configuration is in edit mode
  logoutDisabled: false,
  ui: {
    workspacesListLayout: "one-col"
  }
}

export default function authenticatedUserUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case USER.ACTION.LOGGING_IN:
      return {
        ...state,
        isFetching: true
      }
    case `${USER.ACTION.LOGGED_IN}_FULFILLED`: {
      return {
        ...state,
        isFetching: false,
        token: _get(payload, "token", ""),
        loginTimestamp: Date.now()
      }
    }
    case USER.ACTION.RETRIEVE_LOGGED_IN_USER: {
      const { ...data } = payload
      return {
        ...state,
        data,
        isFetching: false
      }
    }
    case `${USER.ACTION.REFRESH_TOKEN}_FULFILLED`:
      return {
        ...state,
        loginTimestamp: Date.now(),
        token: _get(payload, "token", "")
      }
    case USER.ACTION.LOGGED_OUT:
      return {
        ...initialState,
        ui: {
          ...state.ui
        },
        isFetching: false
      }
    case `${USER.ACTION.MODIFY_AUTH_USER}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        data: {
          ...payload
        }
      }
    case `${USER.ACTION.MODIFY}_FULFILLED`:
      if (payload.id === state.data.id) {
        return {
          ...state,
          isFetching: false,
          data: {
            ...payload
          }
        }
      }
      return state
    case USER.ACTION.DISABLE_LOGOUT:
      return {
        ...state,
        logoutDisabled: true
      }
    case USER.ACTION.ENABLE_LOGOUT:
      return {
        ...state,
        logoutDisabled: false
      }
    case USER.ACTION.SET_UI_ATTRIBUTE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.attribute]: payload.value
        }
      }
    }
    default:
      return state
  }
}
