import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _noop from "lodash/noop"
import { List, Map } from "immutable"
import ReactTooltip from "react-tooltip"

// ui components
import SetScheduleForm from "./SetScheduleForm"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"

// constants, helpers
import { MODAL, TOAST, MOMENT } from "sharedConstants"
import {
  generateSchedulesReadableObject,
  getSetSchedulesDescription,
  computeNextSchedulesDates
} from "helpers/schedules.helper"

import "./SetScheduleCard.css"

class SetScheduleCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      deleteDialog: Map({
        open: false,
        isLoading: false
      }),
      nextSchedulesDates: List(),
      nextSchedulesCheckInterval: null,
      schedulesReadable: List()
    }
  }

  checkAndUpdateNextSchedulesDates = () => {
    const { entity } = this.props
    const { nextSchedulesDates } = this.state
    if (nextSchedulesDates.size) {
      const dateNow = moment.utc()
      if (dateNow > nextSchedulesDates.first()) {
        // re-compute next schedules because the first one is old
        const schedules = entity.getIn(["settings", "schedules"])
        const nextSchedulesDates = computeNextSchedulesDates(schedules)
        this.setState({
          nextSchedulesDates
        })
      }
    }
  }

  generateSchedulesReadableObject = () => {
    const schedules = this.props.entity.getIn(["settings", "schedules"])
    const schedulesReadable = generateSchedulesReadableObject(schedules)
    this.setState({
      schedulesReadable
    })
  }

  toggleDeleteDialog = () => {
    this.setState(prevState => ({
      deleteDialog: prevState.deleteDialog
        .set("open", !prevState.deleteDialog.get("open"))
        .set("isLoading", false)
    }))
  }

  deleteSchedule = () => {
    if (!this.state.deleteDialog.get("isLoading")) {
      const { entity, modifyEntity, showToast } = this.props
      const settings = entity.settings
        .delete("schedules")
        .delete("notification_emails")
        .toJS()

      this.setState(prevState => ({
        deleteDialog: prevState.deleteDialog.set("isLoading", true)
      }))
      modifyEntity(entity.id, { settings }, { settings: entity.settings.toJS() })
        .then(() => {
          showToast("Schedule has been deleted.", TOAST.TYPE.SUCCESS)
          this.toggleDeleteDialog()
          this.disabledScheduleForm()
        })
        .catch(() => {
          this.setState(prevState => ({
            deleteDialog: prevState.deleteDialog.set("isLoading", true)
          }))
        })
    }
  }

  componentDidMount() {
    this.generateSchedulesReadableObject()

    const schedules = this.props.entity.getIn(["settings", "schedules"])
    if (List.isList(schedules) && schedules.size) {
      const nextSchedulesDates = computeNextSchedulesDates(schedules)
      const nextSchedulesCheckInterval = setInterval(this.checkAndUpdateNextSchedulesDates, 60000)
      this.setState({
        nextSchedulesDates,
        nextSchedulesCheckInterval
      })
    }
  }

  componentDidUpdate(prevProps) {
    const schedules = this.props.entity.getIn(["settings", "schedules"])
    const prevSchedules = prevProps.entity.getIn(["settings", "schedules"])
    if (schedules !== prevSchedules) {
      clearInterval(this.state.nextSchedulesCheckInterval)
      let nextSchedulesDates = List()
      let nextSchedulesCheckInterval = null
      if (List.isList(schedules) && schedules.size) {
        nextSchedulesDates = computeNextSchedulesDates(schedules)
        nextSchedulesCheckInterval = setInterval(this.checkAndUpdateNextSchedulesDates, 60000)
      }
      this.setState({
        nextSchedulesDates,
        nextSchedulesCheckInterval
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.nextSchedulesCheckInterval)
  }

  enabledScheduleForm = () => {
    const { editMode, toggleEditMode } = this.props
    if (!editMode) {
      toggleEditMode()()
    }
  }

  disabledScheduleForm = () => {
    const { editMode, toggleEditMode } = this.props
    this.generateSchedulesReadableObject()
    if (editMode) {
      toggleEditMode()()
    }
  }

  renderReadableSchedules = () => {
    const { schedulesReadable } = this.state
    return getSetSchedulesDescription(schedulesReadable)
  }

  render() {
    const {
      entity,
      isEditable,
      editMode,
      readyToEdit,
      modifyEntity,
      currentlyEditing = ""
    } = this.props
    const { schedulesReadable, nextSchedulesDates, deleteDialog } = this.state
    const schedules = entity.getIn(["settings", "schedules"], List())
    const notificationEmails = entity.getIn(["settings", "notification_emails"], List())

    const deleteModal = (
      <ConfirmModal
        open={deleteDialog.get("open")}
        type={MODAL.TYPE.DELETE}
        handleClose={this.toggleDeleteDialog}
        handleConfirm={this.deleteSchedule}
        title="Delete schedule"
        action="delete"
        what="schedule"
        isLoading={deleteDialog.get("isLoading")}
      />
    )

    if (editMode) {
      return (
        <React.Fragment>
          <SetScheduleForm
            entity={entity}
            modifyEntity={modifyEntity}
            onCloseAction={this.disabledScheduleForm}
            schedules={schedules}
            notificationEmails={notificationEmails}
            deleteEntireSchedulesFunc={schedulesReadable.size > 0 ? this.toggleDeleteDialog : null}
          />
          {deleteModal}
        </React.Fragment>
      )
    } else {
      return (
        <div>
          <PaperHeader size="small" className="schedule-header">
            <h3>Schedules</h3>
            <div className="schedule-actions">
              {schedulesReadable.size === 0 && (
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  onClick={readyToEdit ? this.enabledScheduleForm : _noop}
                  disabled={!isEditable}
                  currentlyEditing={currentlyEditing}
                  tooltipId="create-entity-schedule-tooltip-id"
                  className={!readyToEdit ? "cursor-wait" : ""}
                  data-cy="create-schedule"
                >
                  Create schedule
                </Button>
              )}
              {schedulesReadable.size > 0 && (
                <div className="edit-schedule-actions">
                  <Button
                    type="button"
                    color="white-red"
                    size="small"
                    onClick={readyToEdit ? this.toggleDeleteDialog : _noop}
                    disabled={!isEditable}
                    tooltipId="delete-schedule-tooltip-id"
                    currentlyEditing={currentlyEditing}
                    className={
                      !readyToEdit ? "cursor-wait delete-entire-schedule" : "delete-entire-schedule"
                    }
                  >
                    <FontAwesomeIcon className="icon" icon={["far", "trash-alt"]} /> Delete
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    size="small"
                    onClick={readyToEdit ? this.enabledScheduleForm : _noop}
                    disabled={!isEditable}
                    tooltipId="edit-schedule-tooltip-id"
                    currentlyEditing={currentlyEditing}
                    className={!readyToEdit ? "cursor-wait" : ""}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
          </PaperHeader>
          {schedulesReadable.size > 0 && (
            <Paper hasHeader={true} className="schedules-info">
              <div className="schedules-readable">
                <h4>Set schedules (in UTC)</h4>
                <p>{this.renderReadableSchedules()}</p>
              </div>
              {nextSchedulesDates.size > 0 && (
                <table className="common-schedules-info">
                  <tbody>
                    <tr>
                      <td className="next-schedules-title">Next schedules (local time)</td>
                      <td className="align-right">
                        <div className="next-dates" data-tip data-for="schedules-utc-tooltip">
                          {nextSchedulesDates.map((momentObject, idx) => (
                            <div key={idx}>
                              {momentObject.local().format(MOMENT.DATE_TIME_FORMAT)}
                            </div>
                          ))}
                        </div>
                        <ReactTooltip id="schedules-utc-tooltip" className="tooltip" place="bottom">
                          Schedules in UTC:
                          {nextSchedulesDates.map((momentObject, idx) => (
                            <div key={idx}>
                              {momentObject.utc().format(MOMENT.DATE_TIME_FORMAT)}
                            </div>
                          ))}
                        </ReactTooltip>
                      </td>
                    </tr>
                    {notificationEmails.size > 0 && (
                      <tr>
                        <td>Notify errors</td>
                        <td className="align-right">
                          {notificationEmails.map((email, idx) => {
                            if (idx === 0) {
                              return email
                            } else {
                              return `, ${email}`
                            }
                          })}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </Paper>
          )}
          {deleteModal}
        </div>
      )
    }
  }
}

SetScheduleCard.defaultProps = {
  readyToEdit: true
}

SetScheduleCard.propTypes = {
  entity: PropTypes.object.isRequired,
  modifyEntity: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool
}

export default SetScheduleCard
