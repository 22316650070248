import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Map } from "immutable"
import TimeAgo from "react-timeago"
import moment from "moment"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _toString from "lodash/toString"
import _toInteger from "lodash/toInteger"
import { Waypoint } from "react-waypoint"

import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import SearchForm from "components/UI/components/SearchForm"
import LoadingCircle from "components/UI/elements/LoadingCircle"
import Tag from "components/UI/elements/Tag"
import StatusElement from "components/UI/elements/StatusElement"

import { getRoutePath } from "routes"
import { goBack } from "helpers/backButton.helper"

import WorkspaceModel from "models/workspace.model"
import SelectionSettingsModel from "models/selectionSettings.model"

import { addWorkspaceToDawg } from "actions/dawgWorkspace.action"
import { showToast } from "actions/toast.action"

import { api } from "api"
import { TOAST } from "sharedConstants"
import PendingPromise from "helpers/pendingPromise.helper"

import "./DawgWorkspaceAdd.css"

const LOADING_LIMIT = 20

class DawgWorkspaceAdd extends PureComponent {
  runningWsJobCall = false
  intervalId = null

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      workspaces: List(),
      selectionSettings: new SelectionSettingsModel(),
      hasMoreItems: false,
      workspaceStatuses: Map()
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this.fetchWorkspaces(0)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  onSearchSubmit = searchTerm => {
    this.fetchWorkspaces(0, searchTerm)
  }

  fetchWorkspaces = async (offset, searchTerm = "") => {
    this.setState({ loading: true })
    try {
      const response = await api().workspace.list(
        offset,
        LOADING_LIMIT,
        1,
        "available_to_graph",
        "ASC",
        searchTerm,
        [],
        1,
        1
      )
      const workspaceList = List(response.workspaces.map(ws => new WorkspaceModel(ws)))
      this.setState(prevState => ({
        loading: false,
        workspaces: offset === 0 ? workspaceList : prevState.workspaces.concat(workspaceList),
        selectionSettings: new SelectionSettingsModel(response.selection_settings),
        hasMoreItems: response.workspaces.length === LOADING_LIMIT
      }))
      this.fetchDawgWorkspacesLastJobs(workspaceList)
    } catch (err) {
      this.setState({
        loading: false
      })
    }
  }

  loadMoreWorkspaces = async () => {
    const { selectionSettings } = this.state
    this.fetchWorkspaces(
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.name_filter
    )
  }

  addWorkspaceToDawg = workspaceId => () => {
    const {
      addWorkspaceToDawg,
      showToast,
      history,
      match: {
        params: { id }
      }
    } = this.props
    addWorkspaceToDawg(id, workspaceId)
      .then(() => {
        showToast("Workspace has been added to DAWG.", TOAST.TYPE.SUCCESS)
        history.push(getRoutePath("dawg.show", { id }))
      })
      .catch(_noop)
  }

  renderWaypoint = () => {
    const { hasMoreItems } = this.state
    if (hasMoreItems) {
      return <Waypoint onEnter={this.loadMoreWorkspaces} bottomOffset={-250} />
    }
  }

  fetchDawgWorkspacesLastJobs = async (workspacesParam = null) => {
    if (!this.runningWsJobCall) {
      const workspaces = workspacesParam ? workspacesParam : this.state.workspaces
      this.runningWsJobCall = true
      const { workspaceStatuses } = this.state
      const [...workspaceIds] = workspaces.map(ws => _toInteger(ws.id))
      if (workspaceIds.length > 0) {
        const lastWsJobsPromise = this.pendingPromises.create(
          api().workspace.listLastJobs(workspaceIds.map(val => _toInteger(val)))
        )
        try {
          const response = await lastWsJobsPromise.promise
          if (response.last_workspace_jobs.length > 0) {
            const currentStatuses = Map(
              response.last_workspace_jobs.reduce((returnObj, job) => {
                returnObj[job.workspace_id] = Map({
                  id: _get(job, "last_job.id"),
                  status: _get(job, "last_job.status"),
                  created: _get(job, "last_job.origin_created"),
                  history: Map(_get(job, "last_job.stats.statuses_history", {}))
                })
                return returnObj
              }, {})
            )

            if (!workspaceStatuses.equals(currentStatuses)) {
              this.setState({
                workspaceStatuses: currentStatuses
              })

              if (!this.intervalId) {
                this.intervalId = setInterval(() => {
                  this.fetchDawgWorkspacesLastJobs()
                }, 10000)
              }
            }
          }
        } catch (err) {
        } finally {
          this.runningWsJobCall = false
        }
      } else {
        this.runningWsJobCall = false
      }
    }
  }

  renderWorkspace = workspace => {
    const { workspaceStatuses } = this.state
    const wsJob = workspaceStatuses.get(_toString(workspace.id))
    return (
      <div className={`workspace-item ${!workspace.dawg ? "" : "disabled"} `} key={workspace.id}>
        <div className={`upper-part ${Map.isMap(wsJob) ? wsJob.get("status") : "not-run"}`}>
          <div className="workspace-item-header">
            <h3 className="workspace-name">{workspace.name}</h3>
            {Map.isMap(wsJob) && (
              <div className="status">
                <StatusElement
                  align="left"
                  status={wsJob.get("status")}
                  history={wsJob.get("history")}
                  created={wsJob.get("created")}
                  showDuration={true}
                />
              </div>
            )}
          </div>
          <div className="workspace-item-body">
            <div className="dawg">
              {workspace.dawg && (
                <React.Fragment>
                  <span className="label">DAWG:</span>
                  <h4>{workspace.dawg.name}</h4>
                </React.Fragment>
              )}
              {!workspace.dawg && (
                <Button
                  size="small"
                  color="primary"
                  onClick={this.addWorkspaceToDawg(workspace.id)}
                >
                  Add to DAWG
                </Button>
              )}
            </div>
            <div className="last-run">
              <span className="label">Activity</span>
              <span className="last-run-ago">
                {!workspace.last_run && "–"}
                {workspace.last_run && (
                  <TimeAgo
                    date={moment
                      .utc(workspace.last_run)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")}
                  />
                )}
              </span>
            </div>
            {!workspace.dawg}
          </div>
        </div>
        {workspace.tags.length !== 0 && (
          <div className="tags">
            <span className="label">Tags</span>
            <div className="tags-content">
              {workspace.tags.map(tag => {
                return (
                  <Tag key={tag.id} color={tag.color}>
                    {tag.name}
                  </Tag>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props
    const { workspaces, loading } = this.state

    return (
      <section className="dawg-workspace-add">
        <PaperHeader size="small" className="dawg-workspace-add-header">
          <div className="navigation-block">
            <Button
              className="back-link"
              onClick={goBack(history, getRoutePath("dawg.show", { id }))}
              size="small"
              color="none"
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
            </Button>
            <h3>Add workspace to DAWG</h3>
          </div>
          <SearchForm
            onSubmit={this.onSearchSubmit}
            placeholder="Search name"
            className="workspace-search"
            initialValues={{ search: "" }}
          />
        </PaperHeader>
        <div className="workspaces-section">
          {loading === false && workspaces.size === 0 && (
            <p className="no-workspaces-found">No workspaces found.</p>
          )}
          <div className="left-col">
            {workspaces
              .map((workspace, idx) => {
                if (idx % 2 === 0) {
                  return this.renderWorkspace(workspace)
                }
                return null
              })
              .toArray()}
          </div>
          <div className="right-col">
            {workspaces
              .map((workspace, idx) => {
                if (idx % 2 === 1) {
                  return this.renderWorkspace(workspace)
                }
                return null
              })
              .toArray()}
          </div>
          {this.renderWaypoint()}
        </div>
        {loading && <LoadingCircle className="workspaces-loading" />}
      </section>
    )
  }
}

export default connect(null, { addWorkspaceToDawg, showToast })(DawgWorkspaceAdd)
