import React, { PureComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSTransition } from "react-transition-group"
import _get from "lodash/get"
import _sortBy from "lodash/sortBy"
import _isEmpty from "lodash/isEmpty"
import _map from "lodash/map"
import moment from "moment"
import { OrderedMap, Map, List } from "immutable"
import { Line } from "react-chartjs-2"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import IntegrationsActivity from "./IntegrationsActivity"
import IconButton from "components/UI/elements/IconButton"

// helpers, constants
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import { MOMENT } from "sharedConstants"

import "./WorkspacesActivityDrawer.css"

const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  layout: {
    padding: 5
  },
  scales: {
    yAxes: [
      {
        display: false
      }
    ],
    xAxes: [
      {
        display: false
      }
    ]
  },
  onHover: function(e, chartElement) {
    e.target.style.cursor = chartElement[0] ? "pointer" : "default"
  }
}

class WorkspacesActivityDrawer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
      data: null,
      loading: true,
      error: "",
      stardDate: "",
      endDate: "",
      timeSpan: -2,
      chartData: {},
      jobCountsInHours: {}
    }
    this.pendingPromises = new PendingPromise()
    this.sliderRef = React.createRef()
  }

  toggleDrawer = () => {
    this.setState(prevState => ({
      opened: !prevState.opened,
      error: ""
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.opened !== this.state.opened) {
      if (this.state.opened) {
        this.retrieveData()
        document.body.classList.add("prevent-scrolling")
        this.sliderRef.current.focus()
      } else {
        this.pendingPromises.cancelAll()
        document.body.classList.remove("prevent-scrolling")
      }
    }
  }

  retrieveData = async () => {
    this.setState({
      loading: true
    })
    const { timeSpan } = this.state
    let jobs = []
    const nowRoundedUpToHours = moment
      .utc()
      .add(1, "h")
      .millisecond(0)
      .second(0)
      .minute(0)
    const tresholdDate = nowRoundedUpToHours.clone().subtract(48, "h")
    const startDate = nowRoundedUpToHours.clone().subtract(Math.abs(timeSpan), "h")
    const endDate = nowRoundedUpToHours.subtract(Math.abs(timeSpan) - 2, "h")

    let dataPromise = null
    let offset = 0
    const limit = 100
    let continueLoading = true

    const jobAppendIfValid = job => {
      const finished = moment.utc(job.created)
      if (finished >= tresholdDate) {
        jobs.push(job)
      } else {
        continueLoading = false
      }
    }

    try {
      do {
        dataPromise = this.pendingPromises.create(
          api().project.configuration.job.list(offset, limit, 1, "created", "DESC", 1)
        )

        const response = await dataPromise.promise
        if (response.configuration_jobs.length === 0) {
          break
        }
        response.configuration_jobs.forEach(jobAppendIfValid)
        offset += limit
      } while (continueLoading)
      const jobCountsInHours = {}
      const iterDate = tresholdDate.local()
      for (let i = 0; i < 48; i++) {
        jobCountsInHours[`${iterDate.date()}-${iterDate.hour()}`] = 0
        iterDate.add(1, "h")
      }
      jobs = _sortBy(jobs, ["id"])
      let data = OrderedMap()
      jobs.forEach(job => {
        if (job.origin_created) {
          const startSelector = moment
            .utc(job.origin_created)
            .local()
            .format("D-H")
          const endSelector = moment
            .utc(job.created)
            .local()
            .format("D-H")
          if (startSelector === endSelector) {
            jobCountsInHours[startSelector]++
          } else {
            jobCountsInHours[startSelector]++
            jobCountsInHours[endSelector]++
          }
        } else {
          const selector = moment
            .utc(job.created)
            .local()
            .format("D-H")
          jobCountsInHours[selector]++
        }
        const workspaceId = job.configuration.workspace.id.toString()
        if (data.has(workspaceId)) {
          data = data.withMutations(map => {
            map.setIn([workspaceId, "jobs"], map.getIn([workspaceId, "jobs"]).concat(job))
          })
        } else {
          data = data.withMutations(map => {
            map.set(
              workspaceId,
              Map({
                name: job.configuration.workspace.name,
                jobs: List([job])
              })
            )
          })
        }
      })
      const startDateLocal = startDate.clone().local()
      const endDateLocal = endDate.clone().local()
      const endDateAdapted = endDateLocal.clone().subtract(1, "s")
      const pointColors = _map(jobCountsInHours, (val, key) => {
        const keySplit = key.split("-")
        const keyDateTime = moment()
          .date(keySplit[0])
          .hour(keySplit[1])
        if (keyDateTime.isBetween(startDateLocal, endDateAdapted)) {
          return "#fabe53"
        } else {
          return "#C4C4C4"
        }
      })
      const chartData = {
        labels: _map(jobCountsInHours, (val, key) => {
          const keySplit = key.split("-")
          return `${keySplit[1]}:00-${keySplit[1]}:59`
        }),
        datasets: [
          {
            label: "# of jobs",
            data: _map(jobCountsInHours, val => val),
            fill: false,
            borderColor: "#C4C4C4",
            borderJoinStyle: "miter",
            borderWidth: 2,
            pointRadius: 3,
            pointHoverRadius: 4,
            pointBackgroundColor: pointColors,
            pointBorderColor: pointColors
          }
        ]
      }
      this.setState({
        loading: false,
        data,
        chartData,
        error: "",
        startDate: startDateLocal,
        endDate: endDateLocal,
        jobCountsInHours
      })
    } catch (err) {
      if (!_get(err, "isCanceled")) {
        const message = _get(err, "response.data.message", "")
        this.setState({
          loading: false,
          error: message
        })
      }
      if (dataPromise !== null) {
        this.pendingPromises.remove(dataPromise)
      }
    }
  }

  getUpdatedChartData = (startDate, endDate) => {
    const { chartData, jobCountsInHours } = this.state
    if (!_isEmpty(chartData) && !_isEmpty(jobCountsInHours)) {
      const endDateAdapted = endDate.clone().subtract(1, "s")
      const pointColors = _map(jobCountsInHours, (val, key) => {
        const keySplit = key.split("-")
        const keyDateTime = moment()
          .date(keySplit[0])
          .hour(keySplit[1])
        if (keyDateTime.isBetween(startDate, endDateAdapted)) {
          return "#fabe53"
        } else {
          return "#C4C4C4"
        }
      })
      const updatedChartData = { ...chartData }
      updatedChartData.datasets[0].pointBackgroundColor = pointColors
      updatedChartData.datasets[0].pointBorderColor = pointColors
      return updatedChartData
    }
    return {}
  }

  changeRangeValue = evt => {
    const prevTimeSpan = this.state.timeSpan
    const actualTimeSpan = parseInt(evt.target.value)
    const diff = Math.abs(Math.abs(prevTimeSpan) - Math.abs(actualTimeSpan))

    if (prevTimeSpan < actualTimeSpan) {
      // slider moved to right
      this.setState(prevState => ({
        timeSpan: actualTimeSpan,
        startDate: prevState.startDate.clone().add(diff, "h"),
        endDate: prevState.endDate.clone().add(diff, "h"),
        chartData: this.getUpdatedChartData(
          prevState.startDate.clone().add(diff, "h"),
          prevState.endDate.clone().add(diff, "h")
        )
      }))
    } else {
      // slider moved to left
      this.setState(prevState => ({
        timeSpan: actualTimeSpan,
        startDate: prevState.startDate.clone().subtract(diff, "h"),
        endDate: prevState.endDate.clone().subtract(diff, "h"),
        chartData: this.getUpdatedChartData(
          prevState.startDate.clone().subtract(diff, "h"),
          prevState.endDate.clone().subtract(diff, "h")
        )
      }))
    }
  }

  changeRangeValueBy = value => () => {
    if (value === 1 && this.state.timeSpan < -2) {
      this.setState(prevState => ({
        timeSpan: prevState.timeSpan + 1,
        startDate: prevState.startDate.clone().add(1, "h"),
        endDate: prevState.endDate.clone().add(1, "h"),
        chartData: this.getUpdatedChartData(
          prevState.startDate.clone().add(1, "h"),
          prevState.endDate.clone().add(1, "h")
        )
      }))
    } else if (value === -1 && this.state.timeSpan > -48) {
      this.setState(prevState => ({
        timeSpan: prevState.timeSpan - 1,
        startDate: prevState.startDate.clone().subtract(1, "h"),
        endDate: prevState.endDate.clone().subtract(1, "h"),
        chartData: this.getUpdatedChartData(
          prevState.startDate.clone().subtract(1, "h"),
          prevState.endDate.clone().subtract(1, "h")
        )
      }))
    }
  }

  onChartNodeClick = elements => {
    if (elements[0]) {
      const index = elements[0]._index
      const prevTimeSpan = this.state.timeSpan
      let actualTimeSpan = index - 48
      if (actualTimeSpan > -2) {
        actualTimeSpan = -2
      }
      const diff = Math.abs(Math.abs(prevTimeSpan) - Math.abs(actualTimeSpan))

      if (prevTimeSpan < actualTimeSpan) {
        // slider moved to right
        this.setState(prevState => ({
          timeSpan: actualTimeSpan,
          startDate: prevState.startDate.clone().add(diff, "h"),
          endDate: prevState.endDate.clone().add(diff, "h"),
          chartData: this.getUpdatedChartData(
            prevState.startDate.clone().add(diff, "h"),
            prevState.endDate.clone().add(diff, "h")
          )
        }))
      } else {
        // slider moved to left
        this.setState(prevState => ({
          timeSpan: actualTimeSpan,
          startDate: prevState.startDate.clone().subtract(diff, "h"),
          endDate: prevState.endDate.clone().subtract(diff, "h"),
          chartData: this.getUpdatedChartData(
            prevState.startDate.clone().subtract(diff, "h"),
            prevState.endDate.clone().subtract(diff, "h")
          )
        }))
      }
    }
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    document.body.classList.remove("prevent-scrolling")
  }

  render() {
    const { opened, loading, error, data, startDate, endDate, timeSpan, chartData } = this.state

    return (
      <React.Fragment>
        <CSSTransition in={opened} timeout={150} classNames="fade" unmountOnExit>
          <div className="workspaces-activity-drawer-overlay" onClick={this.toggleDrawer} />
        </CSSTransition>
        <section className={`workspaces-activity-drawer ${opened ? "opened" : ""}`}>
          <div className="drawer-toggle-button-wrapper">
            <div className="left-arc-triangle" />
            <button className="drawer-toggle-button" onClick={this.toggleDrawer}>
              {opened && (
                <React.Fragment>
                  <FontAwesomeIcon icon={["fas", "arrow-alt-circle-down"]} className="icon" /> Hide
                </React.Fragment>
              )}
              {!opened && (
                <React.Fragment>
                  <FontAwesomeIcon icon={["fas", "arrow-alt-circle-up"]} className="icon" />{" "}
                  Activity Overview
                </React.Fragment>
              )}
            </button>
            <div className="right-arc-triangle" />
          </div>
          <div className="content-wrapper">
            <div className="wrapper">
              <PaperHeader
                titleText="Integrations activity overview"
                size="small"
                className="workspaces-activity-header"
              >
                {!_isEmpty(chartData) && (
                  <div className="count-chart-wrapper">
                    <Line
                      data={chartData}
                      options={lineChartOptions}
                      getElementAtEvent={this.onChartNodeClick}
                    />
                  </div>
                )}
                <div className="range" data-value={timeSpan}>
                  <label htmlFor="time-range" className="range-label">
                    Time span: <span>{timeSpan}h</span>{" "}
                    {startDate && (
                      <span className="day">({startDate.format(MOMENT.DATE_FORMAT)})</span>
                    )}
                  </label>
                  <div className="input-box">
                    <input
                      type="range"
                      id="time-range"
                      name="range-picker"
                      min="-48"
                      max="-2"
                      value={timeSpan}
                      onChange={this.changeRangeValue}
                      step="1"
                      ref={this.sliderRef}
                    />
                    <div className="bar" />
                    <span className="label-left">-48h</span>
                    <span className="label-right">-2h</span>
                    <IconButton
                      type="button"
                      color="primary"
                      className="left-button"
                      onClick={this.changeRangeValueBy(-1)}
                    >
                      <FontAwesomeIcon icon={["far", "chevron-left"]} />
                    </IconButton>
                    <IconButton
                      type="button"
                      color="primary"
                      className="right-button"
                      onClick={this.changeRangeValueBy(1)}
                    >
                      <FontAwesomeIcon icon={["far", "chevron-right"]} />
                    </IconButton>
                  </div>
                </div>
              </PaperHeader>
              <Paper hasHeader={true} className={loading ? "loading" : ""}>
                {error && <p>{error}</p>}
                {Map.isMap(data) && data.size > 0 && startDate && endDate && (
                  <div className="gantt-paper" id="scrollable-area">
                    <IntegrationsActivity data={data} startDate={startDate} endDate={endDate} />
                  </div>
                )}
                {Map.isMap(data) && data.size === 0 && startDate && endDate && (
                  <p>No activity in past 48 hours.</p>
                )}
              </Paper>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default WorkspacesActivityDrawer
