import { List } from "immutable"

export const getDashboardWsJobs = state => {
  const data = state.projectWorkspaceJobs.get("data")
  if (List.isList(data)) {
    return data.take(10)
  }
  return null
}

export const getProjectWsJobs = state => state.projectWorkspaceJobs.get("data")

export const getProjectWsJobsSelectionSettings = state =>
  state.projectWorkspaceJobs.get("selectionSettings")
