import { Record, List, fromJS } from "immutable"
import UserModel from "./user.model"
import DawgModel from "./dawg.model"

const Workspace = Record({
  id: 0,
  user: null,
  name: "",
  description: "",
  settings: null,
  tags: List(),
  frontend_settings: null,
  input_settings: null,
  variables: null,
  disabled: 0,
  continue_on_failure: 0,
  created: "",
  last_run: "",
  last_activity: "",
  owner: null,
  dawg: null,
  disk_usage: 0,
  high_disk_usage: 0
})

export const WorkspaceSimple = Record({
  id: 0,
  user_id: null,
  name: "",
  description: "",
  settings: null,
  tag_ids: List(),
  frontend_settings: null,
  input_settings: null,
  variables: null,
  disabled: 0,
  continue_on_failure: 0,
  created: "",
  last_run: "",
  last_activity: "",
  owner_id: null,
  dawg_id: null,
  disk_usage: null,
  high_disk_usage: 0
})

export const createWorkspaceModel = workspace =>
  new Workspace({
    ...workspace,
    user: new UserModel(workspace.user),
    settings: fromJS(workspace.settings),
    tags: List(workspace.tags),
    frontend_settings: fromJS(workspace.frontend_settings),
    input_settings: fromJS(workspace.input_settings),
    variables: fromJS(workspace.variables),
    owner: new UserModel(workspace.owner),
    dawg: workspace.dawg
      ? new DawgModel({
          id: workspace.dawg.id,
          name: workspace.dawg.name
        })
      : null
  })

export default Workspace
