import { Record } from "immutable"

const Acl = Record({
  id: 0,
  permission: "",
  created: "",
  user_id: null,
  workspace_id: null
})

export const DawgAclModel = Record({
  id: 0,
  permission: "",
  created: "",
  user_id: null,
  dawg_id: null
})

export default Acl
