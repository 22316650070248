import React, { PureComponent } from "react"
import _get from "lodash/get"
import _includes from "lodash/includes"
import _size from "lodash/size"
import AceEditor from "react-ace"
import ReactTooltip from "react-tooltip"

class FieldTemplate extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hideTooltip: false
    }
  }

  autoHideTooltip = () => {
    const { hideTooltip } = this.state
    if (!hideTooltip) {
      this.timer = setTimeout(() => {
        this.setState({
          hideTooltip: true
        })
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const {
      id,
      classNames,
      label,
      help,
      hidden,
      required,
      description,
      errors,
      children
    } = this.props
    const { hideTooltip } = this.state
    const type = _get(children, "props.schema.type", "object")
    const code = _get(children, "props.uiSchema.code")

    if (hidden) {
      return children
    }

    if (code) {
      const readOnly = _get(children, "props.readonly")
      const id = _get(children, "props.idSchema.$id")
      return (
        <React.Fragment>
          <div
            data-tip
            data-for={id}
            className={`text-field ${classNames} ${
              _size(errors.props.errors) ? "field-error" : ""
            }`}
            onMouseEnter={this.autoHideTooltip}
          >
            {_includes(["string", "integer", "number"], type) && label && (
              <label htmlFor={id}>
                {label}
                {required ? <span>*</span> : null}
              </label>
            )}
            <div className={`ace-editor-wrapper ${readOnly ? "view" : "edit-white"}`}>
              <AceEditor
                mode={code}
                theme="meiro"
                onChange={newValue =>
                  children.props.onChange(
                    newValue ? newValue : undefined,
                    children.props.errorSchema
                  )
                }
                name={children.props.name}
                value={children.props.formData}
                editorProps={{ $blockScrolling: true }}
                maxLines={50}
                height="16px"
                width="100%"
                readOnly={readOnly}
                wrapEnabled={true}
              />
            </div>
            {!readOnly && description}
            {errors}
          </div>
          {readOnly && !hideTooltip && (
            <ReactTooltip id={id} place="bottom">
              Tap edit for change
            </ReactTooltip>
          )}
        </React.Fragment>
      )
    }

    return (
      <div
        className={`text-field ${classNames} ${_size(errors.props.errors) ? "field-error" : ""}`}
      >
        {_includes(["string", "integer", "number"], type) && label && (
          <label htmlFor={id}>
            {label}
            {required ? <span>*</span> : null}
          </label>
        )}
        {description}
        {children}
        {errors}
        {help}
      </div>
    )
  }
}

export default FieldTemplate
