import _get from "lodash/get"
import { api } from "api"
import { WORKSPACE, DAWG } from "sharedConstants"

export const runWorkspaceJob = (workspaceId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: WORKSPACE.JOB.ACTION.RUN,
    payload: {
      promise: (async () => await api().workspace.job.run(workspaceId, loadFullStructure))()
    }
  })

export const listWorkspaceJob = (
  workspaceId,
  offset,
  limit,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: WORKSPACE.JOB.ACTION.LIST,
    payload: {
      promise: (async () =>
        await api().workspace.job.list(workspaceId, offset, limit, loadFullStructure))()
    }
  })

export const refreshLastWorkspaceJob = (workspaceId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: WORKSPACE.JOB.ACTION.REFRESH_LAST,
    payload: {
      workspace_job: _get(
        await api().workspace.job.list(workspaceId, 0, 1, loadFullStructure),
        "workspace_jobs[0]",
        null
      )
    }
  })

export const clearCachedWorkspaceJobs = () => dispatch =>
  dispatch({
    type: WORKSPACE.JOB.ACTION.CLEAR_CACHE,
    payload: {}
  })

export const cancelWorkspaceJob = (workspaceId, jobId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: WORKSPACE.JOB.ACTION.CANCEL,
    payload: {
      promise: (async () =>
        await api().workspace.job.cancel(workspaceId, jobId, loadFullStructure))()
    }
  })

export const retrieveWorkspaceJob = (
  workspaceId,
  jobId,
  loadFullStructure = 0,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: WORKSPACE.JOB.ACTION.RETRIEVE,
      payload: {
        promise: (async () =>
          await api().workspace.job.retrieve(workspaceId, jobId, loadFullStructure))()
      }
    })
  } else {
    return dispatch({
      type: WORKSPACE.JOB.ACTION.RETRIEVE,
      payload: await api().workspace.job.retrieve(workspaceId, jobId, loadFullStructure)
    })
  }
}

export const listProjectWorkspaceJob = (
  offset,
  limit,
  loadFullStructure,
  orderBy,
  orderDir,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: WORKSPACE.JOB.ACTION.LIST_PROJECT,
      payload: {
        promise: (async () => {
          return await api().project.workspace.job.list(
            offset,
            limit,
            loadFullStructure,
            orderBy,
            orderDir,
            0
          )
        })()
      }
    })
  } else {
    return dispatch({
      type: WORKSPACE.JOB.ACTION.LIST_PROJECT,
      payload: await api().project.workspace.job.list(
        offset,
        limit,
        loadFullStructure,
        orderBy,
        orderDir,
        0
      )
    })
  }
}

export const listDawgWorkspaceJob = (
  dawgId,
  offset,
  limit,
  loadFullStructure,
  orderBy,
  orderDir,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: DAWG.WORKSPACE.JOB.ACTION.LIST,
      meta: {
        dawgId
      },
      payload: {
        promise: (async () => {
          return await api().dawg.workspaceJob.list(
            dawgId,
            offset,
            limit,
            loadFullStructure,
            orderBy,
            orderDir,
            0
          )
        })()
      }
    })
  } else {
    return dispatch({
      type: DAWG.WORKSPACE.JOB.ACTION.LIST,
      meta: {
        dawgId
      },
      payload: await api().dawg.workspaceJob.list(
        dawgId,
        offset,
        limit,
        loadFullStructure,
        orderBy,
        orderDir,
        0
      )
    })
  }
}
