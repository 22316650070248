import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./WorkspaceIcon.css"

const WorkspaceIcon = () => {
  return (
    <div className="workspace-icon" data-testid="workspace-icon">
      <FontAwesomeIcon className="code-branch" icon={["fas", "code-branch"]} />
    </div>
  )
}

export default WorkspaceIcon
