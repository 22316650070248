import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"

import Button from "components/UI/elements/Button"
import PasswordField from "components/UI/components/PasswordField"
import TextField from "components/UI/elements/TextField"
import { PASSWORD_SET, TOAST } from "sharedConstants"
import { logout } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import { getRoutePath } from "routes"
import { api } from "api"

import logo from "images/meiro-symbol-white-transparent.svg"
import "./PasswordSet.css"

class PasswordSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      heading: "Set new password",
      passwordFieldLabel: "New password",
      submitButtonText: "Set password"
    }
  }

  componentDidMount() {
    const { search } = this.props.location

    if (search) {
      const params = new URLSearchParams(search)
      const email = params.get("email")
      const resetToken = params.get("reset_token")
      const referrer = params.get("referrer")
      if (referrer === PASSWORD_SET.REFERRER.INVITATION) {
        this.setState({
          heading: "You've got invited",
          passwordFieldLabel: "Create password",
          submitButtonText: "Create account",
          referrer,
          email,
          resetToken
        })
      } else {
        this.setState({
          referrer,
          email,
          resetToken
        })
      }
    }
    document.body.classList.add("toastify-on-top")
  }

  componentWillUnmount() {
    document.body.classList.remove("toastify-on-top")
  }

  onSubmit = async values => {
    this.setState({ loading: true })
    const { email, resetToken, referrer } = this.state
    const { history, showToast, logout } = this.props
    const data = {
      email,
      password_reset_token: resetToken,
      password: values.password
    }

    await api()
      .user.setPassword(data)
      .then(() => {
        logout()
        showToast(
          referrer === PASSWORD_SET.REFERRER.INVITATION
            ? "Password has been created. Please feel free to login!"
            : "Password has been successfully reset.",
          TOAST.TYPE.SUCCESS
        )
        history.push(getRoutePath("login"))
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const { handleSubmit } = this.props
    const { passwordFieldLabel, submitButtonText, heading, email, referrer, loading } = this.state
    const passwordFormName = "forgetPassword"

    return (
      <section className="password-set user-form-screens">
        <div className="form-wrapper">
          <img src={logo} className="logo" alt="meiro logo" />
          <div className="form-body">
            <h2 className="title">{heading}</h2>
            {referrer === PASSWORD_SET.REFERRER.INVITATION && (
              <p className="invited-person">
                on <strong>{email}</strong> email
              </p>
            )}
            <form
              id={passwordFormName}
              autoComplete="off"
              onSubmit={handleSubmit(this.onSubmit)}
              className="form-row"
            >
              <PasswordField passwordFieldLabel={passwordFieldLabel} autoFocus />
              <Field
                name="repeat_password"
                component={TextField}
                fullWidth={true}
                placeholder="Repeat password"
                type="password"
                className="repeat-password"
              />
            </form>
            <Button
              type="submit"
              color="primary"
              size="big"
              fullWidth={true}
              className={loading ? "loading" : ""}
              form={passwordFormName}
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
        <header className="caption">
          <div className="content-wrapper">
            <h1>Meiro Integrations</h1>
            <h2>Your way out of the data labyrinth.</h2>
          </div>
        </header>
      </section>
    )
  }
}

const validate = values => {
  const errors = {}
  if (values.password && values.password !== values.repeat_password) {
    errors.repeat_password = "Password doesn't match"
  }
  return errors
}

PasswordSet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

PasswordSet = reduxForm({
  form: "PasswordSetForm",
  touchOnBlur: false,
  validate
})(connect(null, { logout, showToast })(PasswordSet))

export default PasswordSet
