export const selectStyles = (size = "big", border = "all", maxMenuListHeight = "300px") => {
  let height = "44px"
  if (size === "small") {
    height = "34px"
  } else if (size === "tag") {
    height = "24px"
  }
  return {
    control: (base, state) => ({
      ...base,
      ":hover": {
        borderColor: "#999999"
      },
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: state.isFocused ? "#fabe53 !important" : "#C4C4C4",
      boxShadow: state.isFocused ? "0 1px 3px 0 rgba(254, 127, 102, 0.25)" : "none",
      borderRadius: "4px",
      borderTopLeftRadius: border === "no-left" ? "0" : "4px",
      borderBottomLeftRadius: border === "no-left" ? "0" : "4px",
      borderLeftWidth: border === "no-left" ? "0" : "1px",
      height: height,
      minHeight: "24px",
      outline: "none",
      backgroundColor: "#ffffff",
      fontSize: size === "tag" ? "12px" : "15px",
      cursor: "pointer"
    }),
    valueContainer: (base, state) => ({
      ...base,
      opacity: "0.7"
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: "4px",
      marginTop: "2px"
    }),
    menuList: (base, state) => ({
      ...base,
      maxHeight: maxMenuListHeight
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#fabe53" : state.isFocused ? "#fef8ee" : null,
      color: state.isSelected ? "#FFFFFF" : null,
      cursor: "pointer",
      ":active": {
        backgroundColor: "#ffb433",
        color: "#ffffff"
      },
      fontSize: size === "tag" ? "12px" : "15px"
    }),
    noOptionsMessage: base => ({
      ...base,
      color: "#ed382a",
      fontSize: size === "tag" ? "12px" : "15px"
    })
  }
}

//edit valueContainer for font style
