import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"

import { renderTooltip } from "helpers/disabledTooltip.helper"

import "./DeleteDataCheckbox.css"

const DeleteDataCheckbox = ({
  value,
  onChange,
  readyToEdit,
  disabled = false,
  currentlyEditing = "",
  tooltipId = "data-delete-checkbox",
  className = ""
}) => (
  <div
    className={`checkbox-field delete-data-checkbox ${className}`}
    data-tip={currentlyEditing.length > 0}
    data-for={currentlyEditing.length > 0 ? tooltipId : false}
  >
    <label
      className={`delete-data-checkbox-label ${
        disabled || currentlyEditing.length > 0 ? "disabled" : ""
      } ${!readyToEdit ? "cursor-wait" : ""}`}
    >
      Delete data after execution
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        disabled={disabled || currentlyEditing.length > 0 || !readyToEdit}
        className="checkbox"
      />
      <span className="checkmark">
        <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
      </span>
    </label>
    {renderTooltip(tooltipId, currentlyEditing)}
  </div>
)

DeleteDataCheckbox.defaultProps = {
  readyToEdit: true
}

DeleteDataCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  currentlyEditing: PropTypes.string,
  tooltipId: PropTypes.string,
  readyToEdit: PropTypes.bool
}

export default DeleteDataCheckbox
