import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import { Link, withRouter } from "react-router-dom"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers, constants
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"

import "./EntityChildrenJobsCard.css"
import { capitalize } from "helpers/string.helper"

class EntityChildrenJobsCard extends PureComponent {
  render() {
    const {
      jobs,
      rootEntityName,
      match: {
        params: { id: entityId }
      }
    } = this.props

    const entityName = rootEntityName === "dawg" ? "workspace" : "configuration"
    return (
      <React.Fragment>
        <PaperHeader
          size="small"
          className="entity-children-jobs-card-header"
          titleText={`${capitalize(entityName)} activity`}
        >
          <Link
            to={getRoutePath(
              entityName === "configuration"
                ? "workspace.configurationJob.list"
                : "dawg.workspaceJob.list",
              {
                id: entityId
              }
            )}
            className="button small primary"
          >
            View all
          </Link>
        </PaperHeader>
        {List.isList(jobs) && jobs.size > 0 && (
          <Paper hasHeader={true} className="entity-children-jobs-card-list">
            {jobs.map(job => {
              const icon =
                entityName === "workspace"
                  ? "workspace.png"
                  : job.getIn(["configuration", "component", "icon"], "dummy.png")
              let entityPath,
                entityPathParams = {},
                jobPath,
                jobPathParams = {}
              if (entityName === "workspace") {
                entityPath = "workspace.show"
                entityPathParams = { id: job.getIn(["workspace", "id"]) }
                jobPath = "workspace.workspaceJob.show"
                jobPathParams = {
                  id: job.getIn(["workspace", "id"]),
                  aid: job.id
                }
              } else {
                entityPath = "workspace.configuration.show"
                entityPathParams = { id: entityId, cid: job.getIn(["configuration", "id"]) }
                jobPath = "workspace.configuration.configurationJob.show"
                jobPathParams = {
                  id: entityId,
                  cid: job.getIn(["configuration", "id"]),
                  aid: job.id
                }
              }

              return (
                <div className="job-item" key={job.id}>
                  <div className="content-border">
                    <Link
                      to={{
                        pathname: getRoutePath(entityPath, entityPathParams),
                        state: { previous: this.props.location.pathname }
                      }}
                      className="left-part"
                    >
                      <img className="component-icon" src={getComponentIconSrc(icon)} alt="icon" />
                      <div className="text-wrapper">
                        <h4>{job.getIn([entityName, "name"])}</h4>
                        <span className="date-info">
                          <DateTimeWithTooltip
                            dateTime={job.created}
                            uniqueTooltipId={`entity-job-date-tooltip-${job.id}`}
                          />
                        </span>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: getRoutePath(jobPath, jobPathParams),
                        state: {
                          previous: this.props.location.pathname
                        }
                      }}
                      className="right-part"
                    >
                      <StatusElement
                        align="right"
                        status={job.status}
                        history={job.stats ? job.stats.get("statuses_history") : Map()}
                        created={job.origin_created ? job.origin_created : job.created}
                        showActualRunningDuration
                      />
                    </Link>
                  </div>
                </div>
              )
            })}
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

EntityChildrenJobsCard.propTypes = {
  jobs: PropTypes.instanceOf(List),
  rootEntityName: PropTypes.string.isRequired
}

export default withRouter(EntityChildrenJobsCard)
