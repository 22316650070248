import { SEARCH } from "sharedConstants"
import { ConfigurationFullStructure as ConfigurationModel } from "models/configuration.model"
import SelectionSettingModel from "models/selectionSettings.model"
import { Map, List } from "immutable"

const initialState = Map({
  data: List(),
  selectionSettings: null,
  hasMoreItems: false
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${SEARCH.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        return state
          .set("data", List(payload.configurations.map(item => new ConfigurationModel(item))))
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.configurations.length === SEARCH.LOADING_LIMIT)
      } else {
        return state
          .set(
            "data",
            state
              .get("data")
              .concat(payload.configurations.map(item => new ConfigurationModel(item)))
          )
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.configurations.length === SEARCH.LOADING_LIMIT)
      }
    case SEARCH.ACTION.CLEAR:
      return initialState
    default:
      return state
  }
}
