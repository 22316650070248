import _toInteger from "lodash/toInteger"

export const getConfigurationHistoryList = state => state.configurationHistory.get("data")

export const getConfigurationHistorySelectionSettings = state =>
  state.configurationHistory.get("selectionSettings")

export const hasConfigurationHistoryMoreItems = state =>
  state.configurationHistory.get("hasMoreItems")

export const getConfigurationHistoryItem = (state, historyItemId) => {
  if (historyItemId === "0") {
    return state.configurationHistory.get("data").find(historyItem => historyItem.id === null)
  } else {
    return state.configurationHistory
      .get("data")
      .find(historyItem => historyItem.id === _toInteger(historyItemId))
  }
}

export const isConfigurationHistoryFulfilled = state =>
  state.configurationHistory.get("isFulfilled")
