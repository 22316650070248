import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { loadingBarReducer } from "react-redux-loading-bar"
import _reduce from "lodash/reduce"
import _get from "lodash/get"

import { WORKSPACE, TABLE, INVITE_FORM, PERMISSION } from "../sharedConstants"

import authenticatedUser from "./authenticatedUser.reducer"
import usersAcls from "./usersAcl.reducer"
import workspaces from "./workspace.reducer"
import users from "./user.reducer"
import tags from "./tag.reducer"
import oauthRepos from "./oauth.reducer"
import acls from "./acl.reducer"
import toast from "./toast.reducer"
import components from "./component.reducer"
import configurations from "./configuration.reducer"
import trash from "./trash.reducer"
import projectConfigurationJobs from "./projectConfigurationJob.reducer"
import projectWorkspaceJobs from "./projectWorkspaceJob.reducer"
import configurationJobs from "./configurationJob.reducer"
import workspaceJobs from "./workspaceJob.reducer"
import workspaceHistory from "./workspaceHistory.reducer"
import configurationHistory from "./configurationHistory.reducer"
import dataPreviews from "./dataPreview.reducer"
import workspaceConfigurationJobs from "./workspaceConfigurationJob.reducer"
import fulltextSearch from "./fulltextSearch.reducer"
import dawgs from "./dawg.reducer"
import dawgJobs from "./dawgJob.reducer"
import dawgHistory from "./dawgHistory.reducer"
import dawgWorkspaces from "./dawgWorkspace.reducer"
import dawgWorkspaceJobs from "./dawgWorkspaceJob.reducer"
import workersCount from "./workersCount.reducer"

const rootReducer = combineReducers({
  authenticatedUser,
  usersAcls,
  workspaces,
  users,
  tags,
  oauthRepos,
  acls,
  components,
  configurations,
  trash,
  toast,
  projectConfigurationJobs,
  projectWorkspaceJobs,
  configurationJobs,
  workspaceJobs,
  workspaceHistory,
  configurationHistory,
  dataPreviews,
  workspaceConfigurationJobs,
  fulltextSearch,
  dawgs,
  dawgJobs,
  dawgHistory,
  dawgWorkspaces,
  dawgWorkspaceJobs,
  workersCount,
  form: formReducer.plugin({
    WorkspaceFilter: (state = {}, action) => {
      switch (action.type) {
        case `WorkspaceFilter_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order
            }
          }
        case `WorkspaceFilter_${TABLE.ACTION.SET_SELECTED_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              selectedTags: action.payload.selectedTags
            }
          }
        case `WorkspaceFilter_${TABLE.ACTION.SET_SORTING_AND_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
              selectedTags: action.payload.selectedTags
            }
          }
        case `WorkspaceFilter_${TABLE.ACTION.SET_FILTER_BY}`: {
          return {
            ...state,
            values: {
              ...state.values,
              filterBy: action.payload.attribute
            }
          }
        }
        default:
          return state
      }
    },
    DawgFilter: (state = {}, action) => {
      switch (action.type) {
        case `DawgFilter_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order
            }
          }
        case `DawgFilter_${TABLE.ACTION.SET_SELECTED_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              selectedTags: action.payload.selectedTags
            }
          }
        case `DawgFilter_${TABLE.ACTION.SET_SORTING_AND_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
              selectedTags: action.payload.selectedTags
            }
          }
        case `DawgFilter_${TABLE.ACTION.SET_FILTER_BY}`: {
          return {
            ...state,
            values: {
              ...state.values,
              filterBy: action.payload.attribute
            }
          }
        }
        default:
          return state
      }
    },
    UsersSearch: (state = { search: "", orderBy: "name", orderDir: "ASC" }, action) => {
      switch (action.type) {
        case `UsersSearch_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order
            }
          }
        default:
          return state
      }
    },
    // reset sorting/filtering when creating new workspace
    SearchForm: (state, action) => {
      switch (action.type) {
        case `${WORKSPACE.ACTION.CREATE}_FULFILLED`:
          // return default state
          return {
            ...state,
            values: {
              search: ""
            }
          }
        default:
          return state
      }
    },
    CreateUserForm: (state, action) => {
      switch (action.type) {
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_ACTIVE_USER}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "active-user": action.payload.index
            }
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_DEFAULT_READ_PERMISSIONS}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map(inviteUser => {
                return {
                  ...inviteUser,
                  "workspace-permission": {
                    ...action.payload.workspaceIds.reduce((acc, cur) => {
                      acc[`id-${cur}`] = PERMISSION.READ
                      return acc
                    }, {}),
                    ...inviteUser["workspace-permission"]
                  }
                }
              })
            }
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.TOGGLE_ALL_USER_PERMISSIONS}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "workspace-permission": _reduce(
                      inviteUser["workspace-permission"],
                      (res, val, key) => {
                        res[key] = _get(inviteUser, `workspace-selected.${key}`, false)
                          ? action.payload.value
                          : val
                        return res
                      },
                      {}
                    )
                  }
                }
                return inviteUser
              })
            }
          }
        }

        case `CreateUserForm_${INVITE_FORM.ACTION.MARK_ALL_WORKSPACES}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "workspace-selected": _reduce(
                      inviteUser["workspace-permission"],
                      (res, val, key) => {
                        res[key] = action.payload.value
                        return res
                      },
                      {}
                    )
                  }
                }
                return inviteUser
              })
            }
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.TOGGLE_ALL_SWITCH}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "toggle-all": action.payload.value
                  }
                }
                return inviteUser
              })
            }
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_PERMISSIONS_MARK_ALL}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  // add default permissions if necessary and mark all workspaces
                  return {
                    ...inviteUser,
                    "workspace-permission": {
                      ...action.payload.workspaceIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = PERMISSION.READ
                        return acc
                      }, {}),
                      ...inviteUser["workspace-permission"]
                    },
                    "workspace-selected": {
                      ...action.payload.workspaceIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = action.payload.value
                        return acc
                      }, {})
                    }
                  }
                } else {
                  // add default permissions otherwise, nothing to mark
                  return {
                    ...inviteUser,
                    "workspace-permission": {
                      ...action.payload.workspaceIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = PERMISSION.READ
                        return acc
                      }, {}),
                      ...inviteUser["workspace-permission"]
                    }
                  }
                }
              })
            }
          }
        }
        default:
          return state
      }
    }
  }),
  loadingBar: loadingBarReducer
})

export default rootReducer
