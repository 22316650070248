import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import { listProjectConfigurationJob } from "actions/configurationJob.action"

import {
  getProjectConfJobs,
  getProjectConfJobsSelectionSettings
} from "selectors/projectConfigurationJob.selector"

import PageComponent from "./PageComponent"

class ProjectConfigurationJobList extends PureComponent {
  render() {
    const { jobs, selectionSettings, listProjectConfigurationJob } = this.props

    return (
      <PageComponent
        jobs={jobs}
        selectionSettings={selectionSettings}
        entityName="configuration"
        listProjectJob={listProjectConfigurationJob}
      />
    )
  }
}

const mapStateToProps = state => ({
  jobs: getProjectConfJobs(state),
  selectionSettings: getProjectConfJobsSelectionSettings(state)
})

ProjectConfigurationJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  listProjectConfigurationJob: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  listProjectConfigurationJob
})(ProjectConfigurationJobList)
