import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import cytoscape from "cytoscape"
import edgehandles from "cytoscape-edgehandles"
import klay from "cytoscape-klay"
import _noop from "lodash/noop"
import _toString from "lodash/toString"
import _toInteger from "lodash/toInteger"
import _includes from "lodash/includes"
import { List, Map, Record } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { showToast } from "actions/toast.action"

import { getComponentsData } from "selectors/component.selector"

// cytoscape config
import cytoscapeDefaults from "./CytoscapeConf"

// ui elements
import IconButton from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"

// constants, helpers
import { MODAL, TOAST } from "sharedConstants"
import { getComponentIconSrc } from "helpers/component.helper"
import { getUndefinedWorkspaceVariables } from "helpers/validators.helper"

import "./Dag.css"
import { getRoutePath } from "routes"
import exclamationImg from "./images/exclamation.png"

const DELETE_TYPE = {
  ENTITY: "entity",
  EDGE: "edge"
}

//register extensions
cytoscape.use(edgehandles)
cytoscape.use(klay)

class Dag extends PureComponent {
  cy = null
  eh = null
  nodesPositionChangeCompleted = true

  constructor(props) {
    super(props)

    this.state = {
      deleteModal: Map({
        open: false,
        type: "",
        entity: null,
        isLoading: false
      }),
      runModal: Map({
        open: false,
        entity: null,
        htmlText: [],
        isLoading: false
      }),
      cancelModal: Map({
        open: false,
        entity: null,
        isLoading: false
      }),
      creatingEdge: false,
      quickTipShown: false,
      quickTipPage: 1
    }
  }

  componentDidMount() {
    const { isEditable, history, rootEntityName } = this.props
    const { id: entityId } = this.props.match.params

    if (this.cy === null) {
      const container = {
        container: this.containerDOM
      }

      this.cy = cytoscape(Object.assign({}, container, cytoscapeDefaults))

      // command/ctrl key event handler

      window.addEventListener("keydown", this.handleKeyDown, false)
      window.addEventListener("keyup", this.handleKeyUp, false)

      // show configuration section on node tap
      this.cy.on("vclick", "node[?clickable]", evt => {
        if (!evt.originalEvent.shiftKey) {
          const node = evt.target
          if (this.eh) {
            this.eh.hide()
          }
          const targetPath =
            rootEntityName === "workspace"
              ? `/workspaces/${entityId}/configurations/${node.id()}`
              : `/workspaces/${node.id()}`
          if (evt.originalEvent.metaKey || evt.originalEvent.ctrlKey) {
            window.open(`${process.env.PUBLIC_URL}${targetPath}`, "_blank")
          } else {
            history.push({
              pathname: targetPath,
              state: {
                previous: true
              }
            })
          }
        } else {
          // shift click - show action nodes because edgehandle is also shown
          if (isEditable) {
            const element = evt.target
            this.cy.add({
              group: "nodes",
              data: {
                id: "delete-node",
                entityId: element.data().id,
                label: ""
              },
              position: {
                x: element.position("x") - 41,
                y: element.position("y") - 41
              },
              locked: true
            })
            this.cy.add({
              group: "nodes",
              data: {
                id: "disable-node",
                entityId: element.data().id,
                disabled: element.data().disabled,
                label: ""
              },
              position: {
                x: element.position("x") - 41,
                y: element.position("y") + 41
              },
              locked: true
            })
            if (
              _includes(
                ["running", "waiting"],
                this.props.entityStatuses.getIn([_toString(element.data().id), "status"])
              )
            ) {
              this.cy.add({
                group: "nodes",
                data: {
                  id: "cancel-node",
                  entityId: element.data().id,
                  label: ""
                },
                position: {
                  x: element.position("x") + 41,
                  y: element.position("y") + 41
                },
                locked: true
              })
            } else {
              this.cy.add({
                group: "nodes",
                data: {
                  id: "action-node",
                  entityId: element.data().id,
                  label: ""
                },
                position: {
                  x: element.position("x") + 41,
                  y: element.position("y") + 41
                },
                locked: true
              })
            }
          }
        }
      })

      if (isEditable) {
        // create edge handles behaviour
        this.eh = this.cy.edgehandles({
          preview: false,
          handleNodes: "node[?clickable]",
          hoverDelay: 100,
          loopAllowed: () => {
            return false
          },
          handlePosition: node => {
            return "right top"
          },
          start: () => {
            this.cy.remove("#delete-edge")
            this.cy.remove("#delete-node")
            this.cy.remove("#disable-node")
            this.cy.remove("#action-node")
            this.cy.remove("#cancel-node")
            this.cy.edges().removeClass("highlighted")
            this.setState({
              creatingEdge: true
            })
          },
          stop: () => {
            this.setState({
              creatingEdge: false
            })
          }
        })

        // remove delete button / edgehandles button on mouseout from container
        document.getElementById("cytoscape").addEventListener("mouseout", () => {
          this.eh.hide()
          this.cy.remove("#delete-edge")
          this.cy.remove("#delete-node")
          this.cy.remove("#disable-node")
          this.cy.remove("#action-node")
          this.cy.remove("#cancel-node")
          this.cy.edges().removeClass("highlighted")
        })

        // show delete/disable/run button on clickable node mouseover
        this.cy.on("mouseover", "node[?clickable]", evt => {
          if (!this.state.creatingEdge) {
            const element = evt.target
            this.cy.remove("#delete-edge")
            this.cy.remove("#delete-node")
            this.cy.edges().removeClass("highlighted")
            this.cy.add({
              group: "nodes",
              data: {
                id: "delete-node",
                entityId: element.data().id,
                label: ""
              },
              position: {
                x: element.position("x") - 41,
                y: element.position("y") - 41
              },
              locked: true
            })
            this.cy.remove("#disable-node")
            this.cy.add({
              group: "nodes",
              data: {
                id: "disable-node",
                entityId: element.data().id,
                disabled: element.data().disabled,
                label: ""
              },
              position: {
                x: element.position("x") - 41,
                y: element.position("y") + 41
              },
              locked: true
            })
            this.cy.remove("#action-node")
            this.cy.remove("#cancel-node")
            if (
              _includes(
                ["running", "waiting"],
                this.props.entityStatuses.getIn([_toString(element.data().id), "status"])
              )
            ) {
              this.cy.add({
                group: "nodes",
                data: {
                  id: "cancel-node",
                  entityId: element.data().id,
                  label: ""
                },
                position: {
                  x: element.position("x") + 41,
                  y: element.position("y") + 41
                },
                locked: true
              })
            } else {
              this.cy.add({
                group: "nodes",
                data: {
                  id: "action-node",
                  entityId: element.data().id,
                  label: ""
                },
                position: {
                  x: element.position("x") + 41,
                  y: element.position("y") + 41
                },
                locked: true
              })
            }

            // highlight edges
            element.connectedEdges().addClass("highlighted")
          }
        })

        this.cy.on("mousedown", "node[?clickable]", evt => {
          this.cy.remove("#delete-node")
          this.cy.remove("#disable-node")
          this.cy.remove("#action-node")
          this.cy.remove("#cancel-node")
          this.cy.edges().removeClass("highlighted")

          const element = evt.target
          if (!element.selected() && !evt.originalEvent.shiftKey) {
            // clicked element is not selected, deselect all
            this.cy.$("node:selected").unselect()
          }
        })

        // show delete button on edge mouseover
        this.cy.on("mouseover", "edge", evt => {
          if (!this.state.creatingEdge) {
            const element = evt.target
            this.eh.hide()
            this.cy.remove("#delete-edge")
            this.cy.remove("#delete-node")
            this.cy.remove("#disable-node")
            this.cy.remove("#action-node")
            this.cy.remove("#cancel-node")
            this.cy.edges().removeClass("highlighted")
            this.cy.add({
              group: "nodes",
              data: {
                id: "delete-edge",
                source: element.data().source,
                target: element.data().target,
                label: ""
              },
              position: element.midpoint(),
              locked: true
            })
          }
        })

        // delete edge
        this.cy.on("tap", "#delete-edge", evt => {
          const nodeData = evt.target.data()
          let entity
          if (rootEntityName === "workspace") {
            entity = this.props.childEntities
              .get(nodeData.target)
              .deleteIn(["input_settings", "inputs", nodeData.source])
          } else {
            const el = this.props.childEntities.get(nodeData.target)
            entity = el.setIn(
              ["input_settings", "inputs"],
              el
                .getIn(["input_settings", "inputs"])
                .filterNot(val => val === _toInteger(nodeData.source))
            )
          }

          this.setState(prevState => ({
            deleteModal: prevState.deleteModal
              .set("open", true)
              .set("type", DELETE_TYPE.EDGE)
              .set("entity", entity)
          }))
        })

        // delete node
        this.cy.on("tap", "#delete-node", evt => {
          const element = evt.target
          const entity = this.props.childEntities.get(element.data().entityId)
          this.setState(prevState => ({
            deleteModal: prevState.deleteModal
              .set("open", true)
              .set("type", rootEntityName === "workspace" ? "configuration" : "workspace")
              .set("entity", entity)
          }))
        })

        // enable/disable node
        this.cy.on("tap", "#disable-node", evt => {
          const { childEntities, modifyEntity, showToast, rootEntityName } = this.props
          const element = evt.target
          const entity = childEntities.get(element.data().entityId)

          const ids =
            rootEntityName === "workspace"
              ? [entity.workspace_id, entity.id]
              : [entity.dawg_id, entity.id]
          modifyEntity(...ids, { disabled: entity.disabled ? 0 : 1 })
            .then(() => {
              showToast(
                entity.disabled
                  ? `${
                      rootEntityName === "workspace" ? "Configuration" : "Workspace"
                    } has been enabled.`
                  : `${
                      rootEntityName === "workspace" ? "Configuration" : "Workspace"
                    } has been disabled`,
                TOAST.TYPE.SUCCESS
              )
            })
            .catch(_noop)
        })

        this.cy.on("tap", "#action-node", evt => {
          const element = evt.target
          const entity = this.props.childEntities.get(element.data().entityId)
          const { entity: rootEntity, rootEntityName } = this.props
          let htmlText = [
            <p key="0">
              Do you really want to run selected{" "}
              {rootEntityName === "workspace" ? "configuration" : "workspace"}?
            </p>
          ]
          if (rootEntityName === "workspace" && rootEntity && entity) {
            const undefinedPlaceholders = getUndefinedWorkspaceVariables(
              Map.isMap(entity.settings) ? entity.settings.toJS() : {},
              rootEntity.variables
            )
            if (undefinedPlaceholders.length > 0) {
              htmlText = [
                <p key="0">Do you really want to run selected configuration?</p>,
                <h4 key="1" className="notice">
                  Notice:
                </h4>,
                <p key="2">
                  Configuration contains placeholder(s) undefined in workspace variable(s):{" "}
                  <strong>{undefinedPlaceholders.join(", ")}</strong>.
                </p>
              ]
            }
          }

          this.setState(prevState => ({
            runModal: prevState.runModal
              .set("open", true)
              .set("entity", entity)
              .set("htmlText", htmlText)
          }))
        })

        this.cy.on("tap", "#cancel-node", evt => {
          const element = evt.target
          const entity = this.props.childEntities.get(element.data().entityId)

          this.setState(prevState => ({
            cancelModal: prevState.cancelModal.set("open", true).set("entity", entity)
          }))
        })

        // create edge
        this.cy.on("ehcomplete", (event, sourceNode, targetNode, addedEles) => {
          const { childEntities, modifyEntity, showToast, rootEntityName } = this.props
          const sourceNodeId = sourceNode.data("id")
          const targetNodeId = targetNode.data("id")

          if (_toInteger(targetNodeId)) {
            let entity = childEntities.get(targetNodeId)
            const originInputSettings = entity ? entity.input_settings : {}
            if (entity.hasIn(["input_settings", "inputs"])) {
              if (rootEntityName === "workspace") {
                entity = entity.setIn(
                  ["input_settings", "inputs"],
                  entity.getIn(["input_settings", "inputs"]).merge({
                    [sourceNodeId]: { filters: ["*"] }
                  })
                )
              } else {
                // dawg
                if (
                  !entity.getIn(["input_settings", "inputs"]).includes(_toInteger(sourceNodeId))
                ) {
                  entity = entity.setIn(
                    ["input_settings", "inputs"],
                    entity.getIn(["input_settings", "inputs"]).push(_toInteger(sourceNodeId))
                  )
                }
              }
            } else {
              // inputs does not exist yet
              if (Map.isMap(entity.input_settings)) {
                if (rootEntityName === "workspace") {
                  // conf
                  entity = entity.setIn(
                    ["input_settings", "inputs"],
                    Map({ [sourceNodeId]: { filters: ["*"] } })
                  )
                } else {
                  // dawg
                  entity = entity.setIn(
                    ["input_settings", "inputs"],
                    List([_toInteger(sourceNodeId)])
                  )
                }
              } else {
                if (rootEntityName === "workspace") {
                  entity = entity.set(
                    "input_settings",
                    Map({ inputs: { [sourceNodeId]: { filters: ["*"] } } })
                  )
                } else {
                  entity = entity.set(
                    "input_settings",
                    Map({ inputs: List([_toInteger(sourceNodeId)]) })
                  )
                }
              }
            }

            const ids =
              rootEntityName === "workspace"
                ? [entity.workspace_id, entity.id]
                : [entity.dawg_id, entity.id]
            modifyEntity(
              ...ids,
              {
                input_settings: Map.isMap(entity.input_settings)
                  ? entity.input_settings.toJS()
                  : entity.input_settings
              },
              {
                input_settings: Map.isMap(originInputSettings)
                  ? originInputSettings.toJS()
                  : originInputSettings
              }
            )
              .then(() => {
                showToast("Edge has been created.", TOAST.TYPE.SUCCESS)
              })
              .catch(this._renderDag)
          }
        })

        // changing node/nodes position/positions
        this.cy.on("dragfree", "node[?clickable]", evt => {
          const selectedElements = this.cy.$("node[?clickable]:selected")
          if (selectedElements.length < 2) {
            // single node move, contains only "modifiedNodesCount" attribute
            const { childEntities, modifyEntity, rootEntityName } = this.props
            const node = evt.target
            const entity = childEntities.get(node.id())
            const nodePosition = node.position()

            const ids =
              rootEntityName === "workspace"
                ? [entity.workspace_id, entity.id]
                : [entity.dawg_id, entity.id]
            modifyEntity(...ids, {
              frontend_settings: {
                position: nodePosition
              }
            }).catch(_noop)
          } else {
            // multiple selected elements move
            if (this.nodesPositionChangeCompleted) {
              const {
                childEntities,
                modifyEntitiesBulk,
                match: {
                  params: { id: rootEntityId }
                }
              } = this.props
              this.nodesPositionChangeCompleted = false
              const entitiesUpdate = []
              selectedElements.forEach(el => {
                if (el.data().clickable) {
                  const entity = childEntities.get(el.id())
                  let feSettings = entity.frontend_settings
                  if (Map.isMap(feSettings)) {
                    feSettings = feSettings
                      .setIn(["position", "x"], el.position().x)
                      .setIn(["position", "y"], el.position().y)
                      .toJS()
                  } else {
                    feSettings = {
                      position: {
                        x: el.position().x,
                        y: el.position().y
                      }
                    }
                  }
                  entitiesUpdate.push({
                    id: entity.id,
                    frontend_settings: feSettings
                  })
                }
              })

              modifyEntitiesBulk(rootEntityId, entitiesUpdate)
                .then(res => {
                  this.nodesPositionChangeCompleted = true
                })
                .catch(err => {
                  this.nodesPositionChangeCompleted = true
                })
            }
          }
        })

        // move element's status node with position change
        this.cy.on("position", "node[?clickable]", evt => {
          const node = evt.target
          const statusEl = this.cy.getElementById(`${node.id()}-status`)
          statusEl
            .unlock()
            .position({
              x: node.position("x") + 40,
              y: node.position("y")
            })
            .lock()
        })

        // quick tip
        let quickTipAlreadyClosed
        try {
          quickTipAlreadyClosed = window.localStorage.getItem("miDataFlowQuickTipClosed")
        } catch (err) {}
        if (quickTipAlreadyClosed !== "true") {
          this.setState({
            quickTipShown: true
          })
        }
      }
    }

    if (!this.props.isChildrenFetching) {
      this._renderDag(true)
    }
  }

  handleKeyUp = evt => {
    if (!evt.metaKey && !evt.ctrlKey) {
      this.cy.userZoomingEnabled(false)
      this.cy.autounselectify(false)
    }
  }

  handleKeyDown = evt => {
    if (evt.metaKey || evt.ctrlKey) {
      this.cy.userZoomingEnabled(true)
      this.cy.autounselectify(true)
    }
  }

  quickTipClose = evt => {
    evt.stopPropagation()
    this.setState({
      quickTipShown: false
    })
    try {
      window.localStorage.setItem("miDataFlowQuickTipClosed", "true")
    } catch (err) {}
  }

  quickTipOpen = evt => {
    evt.stopPropagation()
    this.setState({
      quickTipShown: true
    })
    try {
      window.localStorage.setItem("miDataFlowQuickTipClosed", "false")
    } catch (err) {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.childEntities !== this.props.childEntities) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        // changed workspace via toast notification link (from another workspace detail)
        this._renderDag(true)
      } else {
        // configuration add, configuration position change
        // it's also fired when user come to previously visited workspace, so it's necessary to
        // center DAG because other users could make some changes in workspace
        if (prevProps.isChildrenFetching && !this.props.isChildrenFetching) {
          this._renderDag(true)
        } else {
          // position change, don't center DAG
          this._renderDag(false)
        }
      }
    }
    if (prevProps.entityStatuses !== this.props.entityStatuses) {
      this._setNodesStatuses()
    }
    if (prevProps.highlightedEntities !== this.props.highlightedEntities) {
      this._setHighlightedNodes()
    }
    if (
      this.props.arrangementCallbackValue !== null &&
      prevProps.arrangementCallbackValue !== this.props.arrangementCallbackValue
    ) {
      if (this.props.arrangementCallbackValue === 1) {
        // save arrangement
        this.arrangeDagConfirm()
      } else if (this.props.arrangementCallbackValue === 0) {
        // cancel arrangement
        this._renderDag(true)
      }
    }
  }

  _setNodesStatuses = () => {
    const { entityStatuses } = this.props
    entityStatuses.forEach((job, key) => {
      const color = this._getBorderColor(job.get("status"))
      const el = this.cy.getElementById(key)
      el.data("border", color)
      const statusEl = this.cy.getElementById(`${key}-status`)
      statusEl.data("color", color).data("statuschip", job.get("status"))
    })
  }

  _setHighlightedNodes = () => {
    const { highlightedEntities } = this.props
    if (highlightedEntities.get("active")) {
      // set transparent flag to nodes not included in ids array
      if (this.cy) {
        const nodes = this.cy.$("node[?clickable]")
        nodes.forEach(element => {
          if (_includes(highlightedEntities.get("ids"), _toInteger(element.data().id))) {
            element.data("transparent", false)
          } else {
            element.data("transparent", true)
          }
        })
      }
    } else {
      // unset all transparent flags
      if (this.cy) {
        const nodes = this.cy.$("node[?clickable]")
        nodes.data("transparent", false)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    window.removeEventListener("keydown", this.handleKeyDown, false)
    this.cy.elements().remove()
    this.cy.destroy()
  }

  _getBorderColor = status => {
    switch (status) {
      case "running":
        return "#229ace"
      case "waiting":
      case "warning":
        return "#fabe53"
      case "canceled":
        return "#494a4a"
      case "error":
        return "#ed382a"
      case "finished":
        return "#3aa545"
      default:
        return "#C4C4C4"
    }
  }

  _renderDag = (center = false) => {
    const { childEntities, components, entityStatuses, isEditable, rootEntityName } = this.props

    let edges = List()
    const statusNodes = []
    const elements = childEntities.map(entity => {
      if (entity.hasIn(["input_settings", "inputs"])) {
        const inputEdges =
          rootEntityName === "workspace"
            ? entity.getIn(["input_settings", "inputs"]).keySeq()
            : entity.getIn(["input_settings", "inputs"], List())
        const mappings = inputEdges.map(stringNodeId => ({
          data: {
            id: `${stringNodeId}-${entity.id}`,
            source: stringNodeId,
            target: _toString(entity.id),
            label: ""
          }
        }))
        edges = edges.concat(mappings)
      }
      let icon =
        rootEntityName === "workspace"
          ? components.getIn([_toString(entity.component_id), "icon"])
          : "workspace.png"
      const isHidden =
        rootEntityName === "workspace"
          ? components.getIn([_toString(entity.component_id), "hidden"])
          : 0
      if (!icon) {
        icon = "dummy.png"
      }

      statusNodes.push({
        data: {
          id: `${entity.id}-status`,
          clickable: false,
          statuschip: true,
          icon: "waiting",
          color: "transparent",
          label: ""
        },
        position: {
          x: entity.getIn(["frontend_settings", "position", "x"], 0) + 40,
          y: entity.getIn(["frontend_settings", "position", "y"], 0)
        },
        locked: true
      })

      const nodeIcon = entity.disabled ? getComponentIconSrc(icon, true) : getComponentIconSrc(icon)
      return {
        data: {
          id: entity.id,
          label: entity.name,
          isEntity: true,
          disabled: entity.disabled === 1,
          clickable: true,
          icon: isHidden === 1 ? [nodeIcon, exclamationImg] : nodeIcon,
          border: this._getBorderColor(entityStatuses.getIn([_toString(entity.id), "status"])),
          hidden: isHidden === 1
        },
        locked: !isEditable,
        position: Map.isMap(entity.getIn(["frontend_settings", "position"]))
          ? entity.getIn(["frontend_settings", "position"]).toJS()
          : { x: 0, y: 0 }
      }
    })

    if (this.cy) {
      this.cy.json({
        elements: elements
          .toList()
          .concat(edges)
          .concat(statusNodes)
          .toJS()
      })

      if (center) {
        this.centerDag()
      }

      this._setNodesStatuses()
    }
  }

  centerDag = () => {
    if (this.cy !== null) {
      this.cy.fit(this.cy.$("node"), 15)
    }
  }

  closeDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: prevState.deleteModal.set("open", false).set("isLoading", false)
    }))
  }

  confirmDeleteModal = () => {
    if (!this.state.deleteModal.get("isLoading")) {
      const { deleteEntity, modifyEntity, showToast, rootEntityName } = this.props
      const { deleteModal } = this.state
      const entity = deleteModal.get("entity")
      const ids =
        rootEntityName === "workspace"
          ? [entity.workspace_id, entity.id]
          : [entity.dawg_id, entity.id]
      if (deleteModal.get("type") !== DELETE_TYPE.EDGE) {
        this.setState(prevState => ({
          deleteModal: prevState.deleteModal.set("isLoading", true)
        }))

        deleteEntity(...ids)
          .then(() => {
            this.closeDeleteModal()
            showToast(
              `${rootEntityName === "workspace" ? "Configuration" : "Workspace"} has been deleted${
                rootEntityName === "dawg" ? " from the DAWG" : ""
              }.`,
              TOAST.TYPE.SUCCESS
            )
          })
          .catch(() => {
            this.setState(prevState => ({
              deleteModal: prevState.deleteModal.set("isLoading", false)
            }))
          })
      } else {
        this.setState(prevState => ({
          deleteModal: prevState.deleteModal.set("isLoading", true)
        }))
        modifyEntity(...ids, {
          input_settings: entity.input_settings.toJS()
        })
          .then(() => {
            this.closeDeleteModal()
            showToast("Edge has been deleted.", TOAST.TYPE.SUCCESS)
          })
          .catch(() => {
            this.setState(prevState => ({
              deleteModal: prevState.deleteModal.set("isLoading", false)
            }))
          })
      }
    }
  }

  closeRunModal = () => {
    this.setState(prevState => ({
      runModal: prevState.runModal.set("open", false).set("isLoading", false)
    }))
  }

  confirmRunModal = () => {
    if (!this.state.runModal.get("isLoading")) {
      const {
        rootEntityName,
        runEntityJob,
        showToast,
        match: {
          params: { id: rootEntityId }
        }
      } = this.props
      const { runModal } = this.state
      const entity = runModal.get("entity")
      this.setState(prevState => ({
        runModal: prevState.runModal.set("isLoading", true)
      }))
      const ids = rootEntityName === "workspace" ? [entity.workspace_id, entity.id] : [entity.id]
      runEntityJob(...ids)
        .then(response => {
          const job = response.value.job ? response.value.job : response.value.workspace_job
          this.closeRunModal()
          const routeParams = {
            id: rootEntityName === "workspace" ? rootEntityId : job.workspace_id,
            aid: job.id
          }
          if (rootEntityName === "workspace") {
            routeParams["cid"] = job.configuration_id
          }
          showToast(
            `${rootEntityName === "workspace" ? "Configuration" : "Workspace"} job has started.`,
            TOAST.TYPE.SUCCESS,
            getRoutePath(
              rootEntityName === "workspace"
                ? "workspace.configuration.configurationJob.show"
                : "workspace.workspaceJob.show",
              routeParams
            )
          )
        })
        .catch(() => {
          this.setState(prevState => ({
            runModal: prevState.runModal.set("isLoading", false)
          }))
        })
    }
  }

  closeCancelModal = () => {
    this.setState(prevState => ({
      cancelModal: prevState.cancelModal.set("open", false).set("isLoading", false)
    }))
  }

  confirmCancelModal = () => {
    if (!this.state.cancelModal.get("isLoading")) {
      const { cancelEntityJob, showToast, entityStatuses, rootEntityName } = this.props
      const { cancelModal } = this.state
      const entity = cancelModal.get("entity")
      const jobId = entityStatuses.getIn([_toString(entity.id), "id"])
      if (jobId) {
        this.setState(prevState => ({
          cancelModal: prevState.cancelModal.set("isLoading", true)
        }))
        const ids = rootEntityName === "workspace" ? [entity.workspace_id, entity.id] : [entity.id]
        cancelEntityJob(...ids, jobId)
          .then(() => {
            this.closeCancelModal()
            showToast(
              `${
                rootEntityName === "workspace" ? "Configuration" : "Workspace"
              } job is being cancelled.`,
              TOAST.TYPE.SUCCESS
            )
          })
          .catch(() => {
            this.setState(prevState => ({
              cancelModal: prevState.cancelModal.set("isLoading", false)
            }))
          })
      }
    }
  }

  moveQuickTipPage = direction => () => {
    const { quickTipPage } = this.state
    if (direction === "prev" && quickTipPage !== 1) {
      this.setState({
        quickTipPage: quickTipPage - 1
      })
    } else if (direction === "next" && quickTipPage !== 3) {
      this.setState({
        quickTipPage: quickTipPage + 1
      })
    }
  }

  arrangeDag = () => {
    if (this.cy) {
      const layout = this.cy.layout({
        nodeDimensionsIncludeLabels: true,
        animate: true,
        animationDuration: 500,
        name: "klay",
        klay: {
          addUnnecessaryBendpoints: false,
          aspectRatio: 1.6,
          borderSpacing: 20,
          compactComponents: false,
          crossingMinimization: "LAYER_SWEEP",
          cycleBreaking: "GREEDY",
          direction: "UNDEFINED",
          edgeRouting: "ORTHOGONAL",
          edgeSpacingFactor: 0.6,
          feedbackEdges: false,
          fixedAlignment: "NONE",
          inLayerSpacingFactor: 1.0,
          layoutHierarchy: false,
          linearSegmentsDeflectionDampening: 0.3,
          mergeEdges: false,
          mergeHierarchyCrossingEdges: true,
          nodeLayering: "NETWORK_SIMPLEX",
          nodePlacement: "LINEAR_SEGMENTS",
          randomizationSeed: 1,
          routeSelfLoopInside: false,
          separateConnectedComponents: true,
          spacing: 25,
          thoroughness: 100
        }
      })
      layout.run()
      this.props.togglePreviewMode()
    }
  }

  arrangeDagConfirm = () => {
    if (this.nodesPositionChangeCompleted) {
      const {
        childEntities,
        modifyEntitiesBulk,
        match: {
          params: { id: entityId }
        }
      } = this.props
      this.nodesPositionChangeCompleted = false
      const entitiesUpdate = []
      this.cy.elements("node[isEntity]").forEach(el => {
        if (el.data().clickable) {
          const entity = childEntities.get(el.id())
          let feSettings = entity.frontend_settings
          if (Map.isMap(feSettings)) {
            if (
              feSettings.getIn(["position", "x"]) === el.position().x &&
              feSettings.getIn(["position", "y"]) === el.position().y
            ) {
              // entity position is same
              feSettings = null
            } else {
              feSettings = feSettings
                .setIn(["position", "x"], el.position().x)
                .setIn(["position", "y"], el.position().y)
                .toJS()
            }
          } else {
            feSettings = {
              position: {
                x: el.position().x,
                y: el.position().y
              }
            }
          }
          if (feSettings !== null) {
            entitiesUpdate.push({
              id: entity.id,
              frontend_settings: feSettings
            })
          }
        }
      })

      if (entitiesUpdate.length > 0) {
        this.props.togglePreviewMode()
        modifyEntitiesBulk(entityId, entitiesUpdate)
          .then(res => {
            this.nodesPositionChangeCompleted = true
          })
          .catch(err => {
            this._renderDag(true)
            this.nodesPositionChangeCompleted = true
          })
      } else {
        this.props.togglePreviewMode()
        this.nodesPositionChangeCompleted = true
      }
    }
  }

  render() {
    const { deleteModal, runModal, cancelModal, quickTipShown, quickTipPage } = this.state
    const { isEntitiesModifying, preview, rootEntityName } = this.props

    return (
      <React.Fragment>
        <section className={`data-flow ${isEntitiesModifying ? "modifying" : ""}`}>
          <div className={`data-flow-wrapper ${isEntitiesModifying ? "modifying" : ""}`}>
            <div id="cytoscape" ref={el => (this.containerDOM = el)} className="cytoscape" />
            {!preview && (
              <React.Fragment>
                <IconButton
                  className="center-button faded"
                  onClick={this.centerDag}
                  color="default"
                >
                  <FontAwesomeIcon icon={["far", "location"]} />
                </IconButton>
                <IconButton
                  className="arrange-button faded"
                  onClick={this.arrangeDag}
                  color="default"
                >
                  <FontAwesomeIcon icon={["far", "sort-size-down-alt"]} />
                </IconButton>
                <div className={`quick-tip page-${quickTipPage} ${quickTipShown ? "" : "closed"}`}>
                  {quickTipShown && (
                    <React.Fragment>
                      <IconButton
                        className="times quick-tip-close-button"
                        color="white"
                        onClick={this.quickTipClose}
                      >
                        <FontAwesomeIcon icon={["far", "times"]} />
                      </IconButton>
                      <p className="text">
                        {quickTipPage === 1 && (
                          <React.Fragment>
                            <strong>Quick tip!</strong> You can zoom in/out the data flow by
                            pressing CTRL&nbsp;/&nbsp;⌘&nbsp;+&nbsp;scroll.
                          </React.Fragment>
                        )}
                        {quickTipPage === 2 && (
                          <React.Fragment>
                            <strong>Quick tip!</strong> Hold Shift&nbsp;+&nbsp;left mouse click:
                            select or deselect multiple nodes.
                            <br />
                            Hold Shift&nbsp;+&nbsp;select area by mouse: select multiple nodes.
                            <br />
                            Click on the blank space of data flow: deselect multiple nodes.
                            <br />
                            Drag and drop selected nodes.
                          </React.Fragment>
                        )}
                        {quickTipPage === 3 && (
                          <React.Fragment>
                            <strong>Quick tip!</strong> You can open entity in a new window by
                            pressing CTRL&nbsp;/&nbsp;⌘&nbsp;+&nbsp;left mouse click.
                          </React.Fragment>
                        )}
                      </p>
                      <div className="pagination">
                        <span
                          onClick={this.moveQuickTipPage("prev")}
                          className={`control ${quickTipPage === 1 ? "disabled" : ""}`}
                        >
                          <FontAwesomeIcon icon={["far", "chevron-left"]} />
                        </span>
                        <span className="page">{quickTipPage}</span>
                        <span
                          onClick={this.moveQuickTipPage("next")}
                          className={`control ${quickTipPage === 3 ? "disabled" : ""}`}
                        >
                          <FontAwesomeIcon icon={["far", "chevron-right"]} />
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  {!quickTipShown && (
                    <FontAwesomeIcon
                      onClick={this.quickTipOpen}
                      icon={["fas", "info-circle"]}
                      className="show-quick-tip-icon"
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
          <ConfirmModal
            open={deleteModal.get("open")}
            type={MODAL.TYPE.DELETE}
            handleClose={this.closeDeleteModal}
            handleConfirm={this.confirmDeleteModal}
            title={`Delete ${deleteModal.get("type")}`}
            htmlText={[
              <p key={0}>
                Do you want to delete this {deleteModal.get("type")}
                {deleteModal.get("type") === "workspace" ? " from the DAWG" : ""}?
              </p>
            ]}
            isLoading={deleteModal.get("isLoading")}
          />
          <ConfirmModal
            open={runModal.get("open")}
            type={MODAL.TYPE.SUCCESS}
            handleClose={this.closeRunModal}
            handleConfirm={this.confirmRunModal}
            title={`Run ${rootEntityName === "workspace" ? "configuration" : "workspace"}`}
            htmlText={runModal.get("htmlText")}
            isLoading={runModal.get("isLoading")}
          />
          <ConfirmModal
            open={cancelModal.get("open")}
            type={MODAL.TYPE.CANCEL}
            handleClose={this.closeCancelModal}
            handleConfirm={this.confirmCancelModal}
            title={`Cancel ${rootEntityName === "workspace" ? "configuration" : "workspace"} job`}
            action="cancel"
            what={`${rootEntityName === "workspace" ? "configuration" : "workspace"} job`}
            isLoading={cancelModal.get("isLoading")}
          />
        </section>
        {preview && <div className="interaction-preventer" />}
      </React.Fragment>
    )
  }
}

Dag.propTypes = {
  rootEntityName: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Record),
  isChildrenFetching: PropTypes.bool.isRequired,
  isEntitiesModifying: PropTypes.bool,
  childEntities: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map),
  isEditable: PropTypes.bool.isRequired,
  highlightedEntities: PropTypes.instanceOf(Map).isRequired,
  entityStatuses: PropTypes.instanceOf(Map).isRequired,
  preview: PropTypes.bool.isRequired,
  arrangementCallbackValue: PropTypes.number,
  togglePreviewMode: PropTypes.func.isRequired,
  modifyEntity: PropTypes.func.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  runEntityJob: PropTypes.func.isRequired,
  cancelEntityJob: PropTypes.func.isRequired,
  modifyEntitiesBulk: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  components: getComponentsData(state)
})

export default withRouter(
  connect(mapStateToProps, {
    showToast
  })(Dag)
)
