import { Record } from "immutable"

const DawgJob = Record({
  id: 0,
  user_id: 0,
  dawg_id: 0,
  status: "",
  stats: null,
  created: "",
  origin_created: ""
})

export default DawgJob
