import { api } from "api"
import { CONFIGURATION } from "sharedConstants"
import { reset } from "redux-form"

export const fetchInDataPreviews = (
  workspaceId,
  configurationId,
  offset,
  limit,
  name_filter = ""
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.DATA_PREVIEW.IN.ACTION.LIST,
    payload: {
      promise: (async () =>
        await api().configuration.dataPreview.in.list(
          workspaceId,
          configurationId,
          offset,
          limit,
          0,
          name_filter
        ))()
    },
    meta: {
      configurationId
    }
  })

export const fetchOutDataPreviews = (
  workspaceId,
  configurationId,
  offset,
  limit,
  name_filter = ""
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.DATA_PREVIEW.OUT.ACTION.LIST,
    payload: {
      promise: (async () =>
        await api().configuration.dataPreview.out.list(
          workspaceId,
          configurationId,
          offset,
          limit,
          0,
          name_filter
        ))()
    },
    meta: {
      configurationId
    }
  })

export const resetFilterForm = () => dispatch => dispatch(reset("data-in-out-files-filter"))
