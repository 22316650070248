import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Map } from "immutable"
import _pick from "lodash/pick"
import _noop from "lodash/noop"

// actions
import { fetchTagsList, deleteTag, createTag, modifyTag } from "actions/tag.action"
import { showToast } from "actions/toast.action"

// selectors
import { getTagsSortedByName } from "selectors/tag.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button"
import TagForm from "./TagForm"
import ConfirmModal from "components/UI/components/ConfirmModal"

// helpers, constants
import { TOAST, MODAL } from "sharedConstants"

import "./TagList.css"

class TagList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: null,
      tagForm: Map({
        show: false,
        tag: null
      }),
      deleteModalOpen: false
    }
  }

  componentDidMount() {
    this.props.fetchTagsList()
  }

  handleMouseHover = tagId => () => {
    this.setState({
      isHovering: tagId
    })
  }

  showTagForm = tag => () => {
    this.setState(prevState => ({
      tagForm: prevState.tagForm.set("show", true).set("tag", tag)
    }))
  }

  hideTagForm = () => {
    this.setState(prevState => ({
      tagForm: prevState.tagForm.set("show", false).set("tag", null)
    }))
  }

  submitTagForm = values => {
    const { tagForm } = this.state
    const { createTag, modifyTag, showToast } = this.props
    if (tagForm.get("tag")) {
      // modify
      modifyTag(tagForm.getIn(["tag", "id"]), values, _pick(tagForm.get("tag"), ["name", "color"]))
        .then(() => {
          showToast("Tag has been modified.", TOAST.TYPE.SUCCESS)
          this.hideTagForm()
        })
        .catch(_noop)
    } else {
      // create
      createTag(values)
        .then(() => {
          showToast("Tag has been created.", TOAST.TYPE.SUCCESS)
          this.hideTagForm()
        })
        .catch(_noop)
    }
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen
    }))
  }

  deleteTag = () => {
    const { tagForm } = this.state
    const { deleteTag, showToast } = this.props
    deleteTag(tagForm.getIn(["tag", "id"]))
      .then(() => {
        this.toggleDeleteModal()
        this.hideTagForm()
        showToast("Tag has been deleted.", TOAST.TYPE.SUCCESS)
      })
      .catch(this.toggleDeleteModal)
  }

  render() {
    const { tags } = this.props
    const { isHovering, tagForm, deleteModalOpen } = this.state

    return (
      <section className="admin-tags">
        <PaperHeader className="admin-tags-header" titleText="Manage tags" size="small">
          <Button
            type="button"
            color="primary"
            size="small"
            onClick={this.showTagForm(null)}
            disabled={tagForm.get("show") && !tagForm.get("tag")}
            data-cy="create-tag-button"
          >
            + Create tag
          </Button>
        </PaperHeader>
        <Paper hasHeader={true} className="admin-tags-content">
          <h4>Tags</h4>
          <div className={`tags-list ${isHovering || tagForm.get("tag") !== null ? "active" : ""}`}>
            {tags.map(tag => (
              <Tag
                key={tag.id}
                color={tag.color ? tag.color : "primary"}
                onMouseEnter={this.handleMouseHover(tag.id)}
                onMouseLeave={this.handleMouseHover(null)}
                className={
                  isHovering === tag.id || tagForm.getIn(["tag", "id"]) === tag.id
                    ? "is-hovering"
                    : ""
                }
                onClick={this.showTagForm(tag)}
                data-cy={tag.name}
              >
                {tag.name}
              </Tag>
            ))}
          </div>
        </Paper>
        {tagForm.get("show") && (
          <div className="tag-form-wrapper">
            <TagForm
              handleClose={this.hideTagForm}
              handleTagDelete={this.toggleDeleteModal}
              onSubmit={this.submitTagForm}
              initialValues={_pick(tagForm.get("tag"), ["name", "color"])}
              type={tagForm.get("tag") ? "EDIT" : "CREATE"}
            />
          </div>
        )}
        <ConfirmModal
          open={deleteModalOpen}
          type={MODAL.TYPE.DELETE}
          handleClose={this.toggleDeleteModal}
          handleConfirm={this.deleteTag}
          title="Delete tag"
          action="delete"
          what="tag"
          item={tagForm.getIn(["tag", "name"], "")}
        />
      </section>
    )
  }
}

TagList.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  tags: getTagsSortedByName(state)
})

export default connect(mapStateToProps, {
  fetchTagsList,
  deleteTag,
  showToast,
  createTag,
  modifyTag
})(TagList)
