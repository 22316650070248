import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { Waypoint } from "react-waypoint"
import { Map, List, Record } from "immutable"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _toString from "lodash/toString"
import TimeAgo from "react-timeago"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getFormValues } from "redux-form"
import ReactTooltip from "react-tooltip"

// selectors
import { getTrashData, getHasMoreItems, getSelectionSettings } from "selectors/trash.selector"
import { getUsersWorkspaceAclsData } from "selectors/usersAcl.selector"

// actions
import { showToast } from "actions/toast.action"
import { fetchTrashItems, restoreItem } from "actions/trash.action"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import SearchForm from "components/UI/components/SearchForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import ConfigurationModal from "./ConfigurationModal"

// helpers
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"

// constants
import { TRASH, MODAL, TOAST, USER, PERMISSION } from "sharedConstants"

import "./TrashList.css"

class TrashList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      restoreConfirmModal: Map({
        item: null,
        open: false,
        isLoading: false
      }),
      filterType: null,
      configDetailModal: Map({
        item: null,
        open: false
      })
    }
  }

  async componentDidMount() {
    try {
      this.props.fetchTrashItems(0, TRASH.LOADING_LIMIT)
    } catch (err) {
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onSearchSubmit = filterName => {
    this.props
      .fetchTrashItems(0, TRASH.LOADING_LIMIT, filterName, this.state.filterType)
      .catch(_noop)
  }

  _loadMoreTrashItems = async () => {
    const { fetchTrashItems, selectionSettings } = this.props
    this.setState({ isLoading: true })
    try {
      await fetchTrashItems(
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        selectionSettings.searched_text,
        selectionSettings.item_type
      )
    } catch (err) {
    } finally {
      this.setState({ isLoading: false })
    }
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { isLoading } = this.state
    const { hasMoreItems } = this.props

    if (!isLoading && hasMoreItems) {
      return <Waypoint onEnter={this._loadMoreTrashItems} bottomOffset={-250} />
    }
  }

  restoreConfirmModalToggle = (item = null) => () => {
    this.setState(prevState => ({
      restoreConfirmModal: prevState.restoreConfirmModal
        .set("open", !prevState.restoreConfirmModal.get("open"))
        .set("item", item)
        .set("isLoading", false)
    }))
  }

  configDetailModalToggle = (item = null) => () => {
    this.setState(prevState => ({
      configDetailModal: prevState.configDetailModal
        .set("open", !prevState.configDetailModal.get("open"))
        .set("item", item ? item : prevState.configDetailModal.get("item"))
    }))
  }

  restoreConfirmModalConfirm = () => {
    const { showToast, restoreItem } = this.props
    const { restoreConfirmModal } = this.state

    if (!restoreConfirmModal.get("isLoading")) {
      this.setState({
        restoreConfirmModal: restoreConfirmModal.set("isLoading", true)
      })
      restoreItem(restoreConfirmModal.get("item"))
        .then(response => {
          switch (restoreConfirmModal.getIn(["item", "type"])) {
            case "user":
              showToast(
                "User has been restored.",
                TOAST.TYPE.SUCCESS,
                getRoutePath("administration.users")
              )
              break
            case "configuration":
              if (response.value.workspace.deleted) {
                showToast(
                  `Configuration has been restored. Please restore also workspace: '${response.value.workspace.name}' in order to run this configuration.`,
                  TOAST.TYPE.SUCCESS
                )
              } else {
                showToast(
                  "Configuration has been restored.",
                  TOAST.TYPE.SUCCESS,
                  getRoutePath("workspace.configuration.show", {
                    id: response.value.workspace.id,
                    cid: response.value.id
                  })
                )
              }
              break
            case "workspace":
              showToast(
                "Workspace has been restored.",
                TOAST.TYPE.SUCCESS,
                getRoutePath("workspace.show", { id: response.value.id })
              )
              break
            default:
              // this should never happen
              break
          }
          this.restoreConfirmModalToggle(restoreConfirmModal.get("item"))()
        })
        .catch(() => {
          this.setState({
            restoreConfirmModal: restoreConfirmModal.set("isLoading", false)
          })
        })
    }
  }

  getTrashedItemAuthor = item => {
    if (item.type !== "user") {
      return item.entity.user.name
    }
    return "–"
  }

  toggleFilterType = type => () => {
    this.setState(
      prevState => ({
        filterType: prevState.filterType === type ? null : type
      }),
      () => {
        const { searchValues, fetchTrashItems } = this.props
        const { filterType } = this.state
        const searchTerm = _get(searchValues, "search", "")

        fetchTrashItems(0, TRASH.LOADING_LIMIT, searchTerm, filterType).catch(_noop)
      }
    )
  }

  renderConfigurationName = configuration => {
    return (
      <div className="configuration-name-wrapper">
        <div className="configuration-name" onClick={this.configDetailModalToggle(configuration)}>
          <img
            src={getComponentIconSrc(configuration.component.icon)}
            alt="component icon"
            data-tip
            data-for={`component-toltip-${configuration.id}`}
          />
          <div className="conf-ws-name-wrapper">
            <span className="conf-name">{configuration.name}</span>
            <span className="ws-name">{configuration.workspace.name}</span>
          </div>
          <ReactTooltip id={`component-toltip-${configuration.id}`} place="bottom">
            {configuration.component.name}
          </ReactTooltip>
        </div>
      </div>
    )
  }

  renderUserName = user => {
    return (
      <div className="username">
        <div className="user-name">{user.name}</div>
        <div className="user-email">{user.email}</div>
      </div>
    )
  }

  isUserAbleToRestore = item => {
    const { usersAcls, authenticatedUser } = this.props
    if (_get(authenticatedUser, "data.role") === USER.ROLE.ADMIN) {
      return true
    }

    const workspaceId =
      item.type === "workspace" ? _get(item, "entity.id") : _get(item, "entity.workspace.id")
    if (workspaceId) {
      const permission = usersAcls.getIn([_toString(workspaceId), "permission"])
      return permission === PERMISSION.WRITE
    }

    return false
  }

  render() {
    const { trash, authenticatedUser } = this.props
    const { restoreConfirmModal, filterType, configDetailModal } = this.state

    const isAdmin = _get(authenticatedUser, "data.role") === USER.ROLE.ADMIN
    return (
      <section className="trash-list">
        <PaperHeader className="trash-header">
          <h3>Trash</h3>
          <SearchForm
            placeholder="Search for name"
            className="trash-search"
            initialValues={{ search: "" }}
            onSubmit={this.onSearchSubmit}
          />
          <div className="filter-type">
            <label>Filter:</label>
            <Button
              type="button"
              color={filterType === "workspace" ? "primary" : "white"}
              size="big"
              onClick={this.toggleFilterType("workspace")}
              data-cy="workspace-filter"
            >
              <FontAwesomeIcon className="icon" icon={["fas", "code-branch"]} />
              Workspace
            </Button>
            <Button
              type="button"
              color={filterType === "configuration" ? "primary" : "white"}
              size="big"
              onClick={this.toggleFilterType("configuration")}
            >
              <FontAwesomeIcon className="icon" icon={["far", "cogs"]} />
              Configuration
            </Button>
            {isAdmin && (
              <Button
                type="button"
                color={filterType === "user" ? "primary" : "white"}
                size="big"
                onClick={this.toggleFilterType("user")}
              >
                <FontAwesomeIcon className="icon" icon={["far", "user"]} />
                User
              </Button>
            )}
          </div>
        </PaperHeader>
        <Paper hasHeader={true}>
          <table className="table trash">
            <thead>
              <tr>
                <th>Name</th>
                <th className="author-column">Author</th>
                <th className="type-column">Type</th>
                <th className="deleted-date">Deleted</th>
                <th className="action-column one-icon">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {List.isList(trash) &&
                trash.map(item => (
                  <tr key={`${item.type}_${item.entity.id}`}>
                    <td className="first-cell">
                      {item.type === "configuration" && this.renderConfigurationName(item.entity)}
                      {item.type === "user" && this.renderUserName(item.entity)}
                      {item.type === "workspace" && item.entity.name}
                    </td>
                    <td className="text-grey">{this.getTrashedItemAuthor(item)}</td>
                    <td>
                      <span className={`chip ${item.type}`}>{item.type}</span>
                    </td>
                    <td className="text-grey">
                      <TimeAgo
                        date={moment
                          .utc(item.entity.created)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                        data-cy="deleted-ago"
                      />
                    </td>
                    <td className="align-right action-column one-icon">
                      <IconButton
                        onClick={this.restoreConfirmModalToggle(item)}
                        type="button"
                        color="green"
                        disabled={!this.isUserAbleToRestore(item)}
                        data-cy="restore-item"
                      >
                        <FontAwesomeIcon icon={["far", "redo"]} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {this.renderWaypoint()}
        </Paper>
        <ConfirmModal
          open={restoreConfirmModal.get("open")}
          type={MODAL.TYPE.SUCCESS}
          handleClose={this.restoreConfirmModalToggle(restoreConfirmModal.get("item"))}
          handleConfirm={this.restoreConfirmModalConfirm}
          title="Restore item"
          action="restore"
          what={restoreConfirmModal.getIn(["item", "type"], "")}
          item={_get(restoreConfirmModal.get("item"), "entity.name", "")}
          isLoading={restoreConfirmModal.get("isLoading")}
        />
        <ConfigurationModal
          open={configDetailModal.get("open")}
          handleClose={this.configDetailModalToggle()}
          configuration={configDetailModal.get("item")}
        />
      </section>
    )
  }
}

TrashList.propTypes = {
  fetchTrashItems: PropTypes.func.isRequired,
  restoreItem: PropTypes.func.isRequired,
  trash: PropTypes.instanceOf(List),
  hasMoreItems: PropTypes.bool.isRequired,
  selectionSettings: PropTypes.instanceOf(Record),
  searchValues: PropTypes.object
}

const mapStateToProps = state => ({
  trash: getTrashData(state),
  hasMoreItems: getHasMoreItems(state),
  selectionSettings: getSelectionSettings(state),
  searchValues: getFormValues("SearchForm")(state),
  usersAcls: getUsersWorkspaceAclsData(state),
  authenticatedUser: state.authenticatedUser
})

export default connect(mapStateToProps, { fetchTrashItems, restoreItem, showToast })(TrashList)
