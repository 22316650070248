import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { reduxForm, Field } from "redux-form"
import ReactMarkdown from "react-markdown"
import _size from "lodash/size"
import _get from "lodash/get"
import removeMd from "remove-markdown"
import _noop from "lodash/noop"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import TextField from "components/UI/elements/TextField"

// code block renderer
import "./CodeBlock"

// css
import "./DescriptionCard.css"
import CodeBlock from "./CodeBlock"

class DescriptionCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      loading: false
    }
  }

  toggleForm = () => {
    this.setState({
      loading: false
    })
    this.props.toggleEditMode()()
  }

  toggleDescriptionExpand = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }))
  }

  onSubmit = values => {
    const { initialValues } = this.props
    if (!this.state.loading) {
      this.setState({ loading: true })
      this.props
        .onSubmit(values, initialValues)
        .then(() => {
          this.toggleForm()
        })
        .catch(err => {
          if (_get(err, "type") === "nothing_changed") {
            this.toggleForm()
          } else {
            this.setState({ loading: false })
          }
        })
    }
  }

  cancelEditing = () => {
    this.props.reset()
    this.toggleForm()
  }

  render() {
    const { expanded, loading } = this.state
    const {
      isEditable,
      handleSubmit,
      initialValues,
      form,
      editMode,
      readyToEdit,
      viewOnly,
      currentlyEditing = ""
    } = this.props

    const desc = _get(initialValues, "description", "")
    const plainDescription = removeMd(desc)
    const expandable = plainDescription.length > 400

    return (
      <React.Fragment>
        <form name={form} autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <PaperHeader size="small" className="description-header">
            <h3>Description</h3>
            {!viewOnly && (
              <React.Fragment>
                {editMode && (
                  <div className="form-enabled-buttons">
                    <Button color="link" onClick={this.cancelEditing} size="small" type="button">
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      size="small"
                      className={`save-button ${loading ? "loading" : ""}`}
                    >
                      Save
                    </Button>
                  </div>
                )}
                {!editMode && (
                  <Button
                    type="button"
                    color="primary"
                    size="small"
                    onClick={readyToEdit ? this.toggleForm : _noop}
                    disabled={!isEditable}
                    currentlyEditing={currentlyEditing}
                    tooltipId="workspace-description-tooltip"
                    className={!readyToEdit ? "cursor-wait" : ""}
                  >
                    Edit
                  </Button>
                )}
              </React.Fragment>
            )}
          </PaperHeader>
          {(editMode || _size(desc) > 0) && (
            <Paper hasHeader={true} className="description-content">
              {!editMode && (
                <div
                  className={`${expandable ? "expandable" : ""} ${
                    expanded ? "expanded" : "hidden"
                  }`}
                >
                  <ReactMarkdown source={desc} renderers={{ code: CodeBlock }} />
                  {expandable && (
                    <React.Fragment>
                      <div className="gradient" />
                      <span className="show-more-less" onClick={this.toggleDescriptionExpand}>
                        {expanded ? "Show less" : "Show more"}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              )}
              {editMode && (
                <React.Fragment>
                  <Field
                    name="description"
                    component={TextField}
                    placeholder="Description"
                    fullWidth={true}
                    multiline={true}
                    rows="5"
                    autoFocus={true}
                  />
                  {!viewOnly && (
                    <div className="form-footer">
                      <div className="tips">
                        TIPS:
                        <span className="headline"># Headline</span>
                        <span className="bold">**bold**</span>
                        <span className="italics">_italics_</span>
                        <span className="strikethrough">~~strike~~</span>
                        <span className="escape-char">`inline code`</span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </Paper>
          )}
        </form>
      </React.Fragment>
    )
  }
}

DescriptionCard.defaultProps = {
  readyToEdit: true,
  viewOnly: false
}

DescriptionCard.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool,
  viewOnly: PropTypes.bool
}

DescriptionCard = reduxForm({
  touchOnBlur: false,
  enableReinitialize: true
})(DescriptionCard)

export default DescriptionCard
