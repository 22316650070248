import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Record } from "immutable"

// selectors
import { getWorkspaceHistoryItem } from "selectors/workspaceHistory.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

// actions
import { retrieveWorkspaceHistoryItem, revertWorkspaceTo } from "actions/workspaceHistory.action"
import { showToast } from "actions/toast.action"

import EntityHistoryDetail from "components/UI/DawgWsShared/EntityHistoryDetail"
import { getWorkspaceChangedKeys } from "helpers/objectChanges.helper.js"

class WorkspaceHistoryDetail extends PureComponent {
  render() {
    const {
      historyItem,
      usersAcl,
      retrieveWorkspaceHistoryItem,
      revertWorkspaceTo,
      showToast
    } = this.props

    return (
      <EntityHistoryDetail
        usersAcl={usersAcl}
        historyItem={historyItem}
        revertEntityTo={revertWorkspaceTo}
        retrieveEntityHistoryItem={retrieveWorkspaceHistoryItem}
        showToast={showToast}
        getEntityChangedKeys={getWorkspaceChangedKeys}
        entityName="workspace"
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  historyItem: getWorkspaceHistoryItem(state, ownProps.match.params.hid),
  usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id)
})

WorkspaceHistoryDetail.propTypes = {
  usersAcl: PropTypes.instanceOf(Record),
  historyItem: PropTypes.instanceOf(Record),
  revertWorkspaceTo: PropTypes.func.isRequired,
  retrieveWorkspaceHistoryItem: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  revertWorkspaceTo,
  retrieveWorkspaceHistoryItem,
  showToast
})(WorkspaceHistoryDetail)
