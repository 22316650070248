import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import { listWorkspaceHistory, revertWorkspaceTo } from "actions/workspaceHistory.action"
import { showToast } from "actions/toast.action"

// selectors
import {
  getWorkspaceHistoryList,
  getWorkspaceHistorySelectionSettings,
  hasWorkspaceHistoryMoreItems,
  isWorkspaceHistoryFulfilled
} from "selectors/workspaceHistory.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

import EntityHistoryList from "components/UI/DawgWsShared/EntityHistoryList"

// helpers
import { getWorkspaceChangedKeys } from "helpers/objectChanges.helper"

class WorkspaceHistoryList extends PureComponent {
  render() {
    const {
      historyList,
      selectionSettings,
      hasMoreHistoryItems,
      usersAcl,
      isHistoryListFulfilled,
      listWorkspaceHistory,
      revertWorkspaceTo,
      showToast
    } = this.props

    return (
      <EntityHistoryList
        historyList={historyList}
        isHistoryListFulfilled={isHistoryListFulfilled}
        selectionSettings={selectionSettings}
        hasMoreHistoryItems={hasMoreHistoryItems}
        usersAcl={usersAcl}
        listEntityHistory={listWorkspaceHistory}
        revertEntityTo={revertWorkspaceTo}
        showToast={showToast}
        getEntityChangedKeys={getWorkspaceChangedKeys}
        entityName="workspace"
      />
    )
  }
}

WorkspaceHistoryList.propTypes = {
  historyList: PropTypes.instanceOf(List).isRequired,
  isHistoryListFulfilled: PropTypes.bool.isRequired,
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  hasMoreHistoryItems: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  listWorkspaceHistory: PropTypes.func.isRequired,
  revertWorkspaceTo: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    historyList: getWorkspaceHistoryList(state),
    selectionSettings: getWorkspaceHistorySelectionSettings(state),
    hasMoreHistoryItems: hasWorkspaceHistoryMoreItems(state),
    usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id),
    isHistoryListFulfilled: isWorkspaceHistoryFulfilled(state)
  }
}

export default connect(mapStateToProps, { listWorkspaceHistory, revertWorkspaceTo, showToast })(
  WorkspaceHistoryList
)
