import { CONFIGURATION } from "sharedConstants"
import DataPreviewModel from "models/dataPreview.model"
import SelectionSettingsModel from "models/selectionSettings.model"
import { Map, OrderedMap } from "immutable"
import _toString from "lodash/toString"
import _mapKeys from "lodash/mapKeys"
import _map from "lodash/map"

/**
 * Number of cached in/out data preview files for each visited configuration is limited
 * to {CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT}. It means that when user leaves Data
 * Preview list page (up in page structure), the number of stored files should be decreased
 * to first {CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT}. Also, when the user completely
 * leaves browsing workspace, this reducer should be se to initial state.
 *
 * state example:
 * Map({
 *  configurationId: Map({
 *    in: Map({
 *      selectionSettings: SelectionSettingsModel,
 *      hasMoreItems: true/false,
 *      data: OrderedMap({ id: DataPreviewModel }),
 *      isFetching: false,
 *      downloadToken: "",
 *      filesTotalCount: 0
 *    }),
 *    out: Map({
 *      selectionSettings: SelectionSettingsModel,
 *      hasMoreItems: true/false,
 *      data: OrderedMap({ id: DataPreviewModel }),
 *      isFetching: false,
 *      downloadToken: "",
 *      filesTotalCount: 0
 *    })
 *  })
 * })
 */

const initialState = Map()

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case `${CONFIGURATION.DATA_PREVIEW.IN.ACTION.LIST}_PENDING`: {
      return state
        .filter((_, key) => key === _toString(meta.configurationId))
        .setIn([_toString(meta.configurationId), "in", "isFetching"], true)
    }
    case `${CONFIGURATION.DATA_PREVIEW.OUT.ACTION.LIST}_PENDING`: {
      return state
        .filter((_, key) => key === _toString(meta.configurationId))
        .setIn([_toString(meta.configurationId), "out", "isFetching"], true)
    }
    case `${CONFIGURATION.DATA_PREVIEW.IN.ACTION.LIST}_FULFILLED`: {
      if (payload.selection_settings.offset !== 0) {
        return state
          .setIn(
            [_toString(meta.configurationId), "in", "selectionSettings"],
            new SelectionSettingsModel(payload.selection_settings)
          )
          .setIn(
            [_toString(meta.configurationId), "in", "hasMoreItems"],
            payload.input_data_preview.length === payload.selection_settings.limit
          )
          .setIn([_toString(meta.configurationId), "in", "isFetching"], false)
          .setIn(
            [_toString(meta.configurationId), "in", "data"],
            state.getIn([_toString(meta.configurationId), "in", "data"]).merge(
              _mapKeys(
                _map(payload.input_data_preview, file => new DataPreviewModel(file)),
                "id"
              )
            )
          )
          .setIn([_toString(meta.configurationId), "in", "downloadToken"], payload.download_token)
          .setIn(
            [_toString(meta.configurationId), "in", "filesTotalCount"],
            payload.files_total_count
          )
          .setIn(
            [_toString(meta.configurationId), "in", "totalDataVolume"],
            payload.total_data_volume
          )
      } else {
        return state.setIn(
          [_toString(meta.configurationId), "in"],
          Map({
            selectionSettings: new SelectionSettingsModel(payload.selection_settings),
            hasMoreItems: payload.input_data_preview.length === payload.selection_settings.limit,
            data: OrderedMap(
              _mapKeys(
                _map(payload.input_data_preview, file => new DataPreviewModel(file)),
                "id"
              )
            ),
            isFetching: false,
            downloadToken: payload.download_token,
            filesTotalCount: payload.files_total_count,
            totalDataVolume: payload.total_data_volume
          })
        )
      }
    }
    case `${CONFIGURATION.DATA_PREVIEW.OUT.ACTION.LIST}_FULFILLED`: {
      if (payload.selection_settings.offset !== 0) {
        return state
          .setIn(
            [_toString(meta.configurationId), "out", "selectionSettings"],
            new SelectionSettingsModel(payload.selection_settings)
          )
          .setIn(
            [_toString(meta.configurationId), "out", "hasMoreItems"],
            payload.output_data_preview.length === payload.selection_settings.limit
          )
          .setIn([_toString(meta.configurationId), "out", "isFetching"], false)
          .setIn(
            [_toString(meta.configurationId), "out", "data"],
            state.getIn([_toString(meta.configurationId), "out", "data"]).merge(
              _mapKeys(
                _map(payload.output_data_preview, file => new DataPreviewModel(file)),
                "id"
              )
            )
          )
          .setIn([_toString(meta.configurationId), "out", "downloadToken"], payload.download_token)
          .setIn(
            [_toString(meta.configurationId), "out", "filesTotalCount"],
            payload.files_total_count
          )
          .setIn(
            [_toString(meta.configurationId), "out", "totalDataVolume"],
            payload.total_data_volume
          )
      } else {
        return state.setIn(
          [_toString(meta.configurationId), "out"],
          Map({
            selectionSettings: new SelectionSettingsModel(payload.selection_settings),
            hasMoreItems: payload.output_data_preview.length === payload.selection_settings.limit,
            data: OrderedMap(
              _mapKeys(
                _map(payload.output_data_preview, file => new DataPreviewModel(file)),
                "id"
              )
            ),
            isFetching: false,
            downloadToken: payload.download_token,
            filesTotalCount: payload.files_total_count,
            totalDataVolume: payload.total_data_volume
          })
        )
      }
    }
    default:
      return state
  }
}
