import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TimeAgo from "react-timeago"
import moment from "moment"
import { Record, Map } from "immutable"
import _toString from "lodash/toString"
import _noop from "lodash/noop"
import ReactTooltip from "react-tooltip"

// UI components-elements
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import NameForm from "components/UI/components/NameForm"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import WsConfStickyNav from "components/UI/elements/WsConfStickyNav"

// helpers
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"
import { shortenString } from "helpers/string.helper"

import "./Header.css"

class Header extends PureComponent {
  changeConfigurationName = name => {
    const { handleConfigurationModify, configuration } = this.props
    handleConfigurationModify(
      { name },
      { name: configuration.name },
      "Configuration's name has been modified."
    ).catch(_noop)
  }

  toggleConfigurationDisabled = () => {
    const { configuration, handleConfigurationModify } = this.props
    handleConfigurationModify(
      { disabled: configuration.disabled ? 0 : 1 },
      {},
      configuration.disabled
        ? "Configuration has been enabled."
        : "Configuration has been disabled."
    )
  }

  toggleConfigurationContinueOnFailure = () => {
    const { configuration, handleConfigurationModify } = this.props
    handleConfigurationModify(
      { continue_on_failure: configuration.continue_on_failure ? 0 : 1 },
      {},
      configuration.continue_on_failure
        ? "Workspace job will stop on configuration failure"
        : "Workspace job will continue on configuration failure"
    )
  }

  renderComponentBox = () => {
    const { components, configuration } = this.props
    const component = components.get(_toString(configuration.component_id))
    if (!component) return null

    let icon = component.icon
    if (!icon) {
      icon = "dummy.png"
    }

    return (
      <div className={`component ${component.hidden ? "deprecated" : ""}`}>
        <div className="icon">
          <img src={getComponentIconSrc(icon)} alt="component icon" />
        </div>
        <div className="desc">
          <h4>
            {component.name}{" "}
            {component.hidden === 1 && (
              <React.Fragment>
                <FontAwesomeIcon
                  data-tip
                  data-for="deprecated-component"
                  icon={["fas", "info-circle"]}
                />
                <ReactTooltip className="tooltip-bubble" id="deprecated-component" place="bottom">
                  The component is deprecated, please consider a replacement.
                </ReactTooltip>
              </React.Fragment>
            )}
          </h4>
          <h5>{component.type}</h5>
        </div>
      </div>
    )
  }

  renderBackButton = () => {
    const { backFunction } = this.props
    return (
      <Button onClick={backFunction} size="small" className="back-button" color="none">
        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
      </Button>
    )
  }

  renderConfigurationName = () => {
    const { configuration } = this.props
    const name = configuration.name
    let className = ""
    if (name.length > 35) {
      className = "x-small"
    } else if (name.length > 30) {
      className = "small"
    } else if (name.length > 25) {
      className = "mid"
    }
    return <h2 className={className}>{shortenString(name, 40)}</h2>
  }

  renderConfActionButtons = placement => {
    const {
      configuration,
      handleConfigurationDelete,
      handleConfigurationClone,
      handleDataErase,
      isEditable,
      currentlyEditing = ""
    } = this.props
    return (
      <React.Fragment>
        <Button
          type="button"
          size="small"
          color="white-red"
          onClick={handleConfigurationDelete}
          className="bigger-spacing"
          disabled={!isEditable}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-delete-configuration-tooltip-id`}
          data-cy="delete-configuration-btn"
        >
          <FontAwesomeIcon className="icon" icon={["far", "trash-alt"]} /> Delete
        </Button>
        <Button
          type="button"
          size="small"
          color="white-red"
          onClick={handleDataErase}
          disabled={!isEditable}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-erase-configuration-data-tooltip-id`}
        >
          <FontAwesomeIcon className="icon" icon={["far", "eraser"]} /> Erase data
        </Button>
        <Button
          type="button"
          size="small"
          color="white"
          onClick={handleConfigurationClone}
          disabled={!isEditable}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-clone-configuration-data-tooltip-id`}
          data-cy="clone-configuration-btn"
        >
          <FontAwesomeIcon className="icon" icon={["far", "clone"]} /> Clone
        </Button>
        <ToggleSwitch
          width="155px"
          name={`${placement}-disabled-toggle`}
          leftValue="0"
          rightValue="1"
          leftText="Enabled"
          rightText="Disabled"
          checked={_toString(configuration.disabled)}
          handleToggle={this.toggleConfigurationDisabled}
          disabled={!isEditable}
          className={`enabled-disabled ${configuration.disabled ? "red" : "green"}`}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-configuration-disabled-tooltip-id`}
          data-cy="enable-disable-configuration-switch"
        />
      </React.Fragment>
    )
  }

  renderRunButton = placement => {
    const {
      handleConfigurationRun,
      handleConfigurationCancel,
      configurationStatus,
      isEditable,
      currentlyEditing = ""
    } = this.props

    return (
      <Button
        type="button"
        size="small"
        color={["waiting", "running"].includes(configurationStatus) ? "red" : "primary"}
        onClick={
          ["waiting", "running"].includes(configurationStatus)
            ? handleConfigurationCancel
            : handleConfigurationRun
        }
        disabled={!isEditable}
        currentlyEditing={currentlyEditing}
        tooltipId={`${placement}-run-configuration-tooltip-id`}
        className="run-button"
      >
        {["waiting", "running"].includes(configurationStatus) && (
          <React.Fragment>
            <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> Cancel
          </React.Fragment>
        )}

        {!["waiting", "running"].includes(configurationStatus) && (
          <React.Fragment>
            <FontAwesomeIcon className="icon" icon={["far", "play"]} /> Run
          </React.Fragment>
        )}
      </Button>
    )
  }

  render() {
    const {
      configuration,
      isEditable,
      components,
      toggleNameFormEditMode,
      nameFormEditMode,
      isConfigurationReloading,
      currentlyEditing = ""
    } = this.props

    return (
      <div>
        <div className="above-paper-left">{this.renderBackButton()}</div>
        <div className="above-paper-right action-buttons" data-cy="configuration-action-buttons">
          <p>
            Saved{" "}
            {
              <TimeAgo
                date={moment
                  .utc(configuration.created)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss")}
              />
            }
          </p>
          <Link
            className="bigger-spacing version-history"
            to={getRoutePath("workspace.configuration.configurationHistory.list", {
              id: configuration.workspace_id,
              cid: configuration.id
            })}
          >
            Version history
          </Link>
          {this.renderConfActionButtons("regular-header")}
        </div>
        <PaperHeader className="configuration-detail-header">
          <div className="conf-name-component-icon-wrapper">
            <div className="configuration-name-form-wrapper">
              <NameForm
                initialValues={{ name: configuration.name }}
                handleNameChange={this.changeConfigurationName}
                isEditable={isEditable}
                currentlyEditing={currentlyEditing}
                toggleEditMode={toggleNameFormEditMode}
                editMode={nameFormEditMode}
                size="big"
                form="configuration-name-form"
                label="Name your configuration"
                tooltipId="configuration-name-form-tooltip-id"
                readOnlySign={!isEditable}
                readyToEdit={!isConfigurationReloading}
              />
            </div>
            {Map.isMap(components) && this.renderComponentBox()}
          </div>
          <div className="action-section">
            <ToggleSwitch
              width="185px"
              name="continue_on_failure"
              leftValue="1"
              rightValue="0"
              leftText="continue on failure"
              rightText="stop on errors"
              checked={_toString(configuration.continue_on_failure)}
              handleToggle={this.toggleConfigurationContinueOnFailure}
              disabled={!isEditable}
              currentlyEditing={currentlyEditing}
              tooltipId="continue-on-failure-configuration-tooltip-id"
              className={`multiline ${configuration.continue_on_failure ? "green" : "red"}`}
              data-cy="stop-continue-on-failure"
            />
            {this.renderRunButton("regular-header")}
          </div>
        </PaperHeader>
        <WsConfStickyNav>
          <div className="left-side">
            {this.renderBackButton()}
            {this.renderConfigurationName()}
          </div>
          <div className="right-side">
            {this.renderConfActionButtons("sticky-header")}
            <div className="vertical-line" />
            {this.renderRunButton("sticky-header")}
          </div>
        </WsConfStickyNav>
      </div>
    )
  }
}

Header.propTypes = {
  configuration: PropTypes.instanceOf(Record).isRequired,
  handleConfigurationModify: PropTypes.func.isRequired,
  backFunction: PropTypes.func.isRequired,
  handleConfigurationDelete: PropTypes.func.isRequired,
  handleConfigurationClone: PropTypes.func.isRequired,
  handleConfigurationRun: PropTypes.func.isRequired,
  handleConfigurationCancel: PropTypes.func.isRequired,
  handleDataErase: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  currentlyEditing: PropTypes.string,
  components: PropTypes.instanceOf(Map),
  configurationStatus: PropTypes.string,
  toggleNameFormEditMode: PropTypes.func.isRequired,
  nameFormEditMode: PropTypes.bool.isRequired,
  isConfigurationReloading: PropTypes.bool.isRequired
}

export default Header
