import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { List, Map } from "immutable"
import _includes from "lodash/includes"
import _get from "lodash/get"
import _last from "lodash/last"
import _first from "lodash/first"
import _map from "lodash/map"
import _toInteger from "lodash/toInteger"
import _isNaN from "lodash/isNaN"
import _inRange from "lodash/inRange"
import _toString from "lodash/toString"
import _isInteger from "lodash/isInteger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select, { components } from "react-select"
import ReactTooltip from "react-tooltip"

// ui components
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import Button from "components/UI/elements/Button"
import AddInputbox from "components/UI/components/AddInputbox/AddInputbox"
import Paper from "components/UI/elements/Paper"
import CommonHeader from "./CommonHeader"
import { DropdownIndicator } from "components/UI/elements/SelectField"

// helpers
import { convertToRomanNum } from "helpers/romanNumeral.helper"
import { selectStyles } from "helpers/customSelectStyle.helper"

// constants
import { ADD_INPUTBOX_TYPE, REPEAT, ONCE, SCHEDULE_FORM_TYPE } from "sharedConstants"

const repeatOptions = [
  { value: "1 Min", label: "1", type: "Min" },
  { value: "2 Min", label: "2", type: "Min" },
  { value: "3 Min", label: "3", type: "Min" },
  { value: "4 Min", label: "4", type: "Min" },
  { value: "5 Min", label: "5", type: "Min" },
  { value: "6 Min", label: "6", type: "Min" },
  { value: "10 Min", label: "10", type: "Min" },
  { value: "12 Min", label: "12", type: "Min" },
  { value: "15 Min", label: "15", type: "Min" },
  { value: "20 Min", label: "20", type: "Min" },
  { value: "30 Min", label: "30", type: "Min" },
  { value: "1 Hour", label: "1", type: "Hour" },
  { value: "2 Hour", label: "2", type: "Hour" },
  { value: "3 Hour", label: "3", type: "Hour" },
  { value: "4 Hour", label: "4", type: "Hour" },
  { value: "6 Hour", label: "6", type: "Hour" },
  { value: "8 Hour", label: "8", type: "Hour" },
  { value: "12 Hour", label: "12", type: "Hour" }
]

const Option = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="select-option">
        <span className="value">{data.label}</span>
        <span className="type">{data.type}</span>
      </div>
    </components.Option>
  )
}

const SingleValue = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="select-single-value">
        <span className="value">{data.label}</span>
        <span className="type">{data.type}</span>
      </div>
    </components.Option>
  )
}

class DayOfWeekForm extends PureComponent {
  constructor(props) {
    super(props)

    const { schedules } = this.props
    const repeatDefaults = Map({
      unit: "",
      value: "",
      startingAt: "",
      from: "",
      to: "",
      days: List()
    })
    const onceDefaults = Map({
      activeTab: 0,
      schedules: List([Map({ minutes: "", hours: "", days: List() })])
    })
    if (schedules.size === 0) {
      this.state = {
        checked: REPEAT,
        repeat: repeatDefaults,
        once: onceDefaults,
        loading: false
      }
    } else {
      const schedule = schedules.first()
      if (_includes(schedule.get("day_of_week"), "*")) {
        this.state = {
          checked: REPEAT,
          repeat: repeatDefaults,
          once: onceDefaults,
          loading: false
        }
      } else {
        if (_includes(schedule.get("minute"), "/") || _includes(schedule.get("hour"), "/")) {
          // TODO here!
          const unit = _includes(schedule.get("minute"), "/") ? "Min" : "Hour"
          let value = ""
          let startingAt = ""
          let from = ""
          let to = ""
          if (unit === "Min") {
            const parts = schedule.get("minute").split("/")
            value = _last(parts)
            startingAt = _first(_first(parts).split("-"))
            if (schedule.get("hour").includes("-")) {
              //from - to exists
              const parts = schedule.get("hour").split("-")
              from = parts[0]
              if (schedules.size > 1) {
                // crosing the day
                to = schedules.getIn([1, "hour"]).split("-")[1]
              } else {
                to = parts[1]
              }
            }
          } else {
            const parts = schedule.get("hour").split("/")
            value = _last(parts)
            if (_first(parts).includes("-")) {
              const hourParts = _first(parts).split("-")
              if (schedules.size > 1) {
                // crosing the day
                from = hourParts[0]
                to = schedules
                  .getIn([1, "hour"])
                  .split("/")[0]
                  .split("-")[1]
              } else {
                if (_toInteger(value) > _toInteger(hourParts[0]) && hourParts[1] === "23") {
                  // Delayed by
                  startingAt = hourParts[0]
                } else {
                  // from to
                  from = hourParts[0]
                  to = hourParts[1]
                }
              }
            }
          }
          const days = List(_map(schedule.get("day_of_week").split(","), day => _toInteger(day)))
          this.state = {
            checked: REPEAT,
            repeat: Map({
              unit,
              value: `${value} ${unit}`,
              days,
              startingAt: startingAt !== "*" ? startingAt : "",
              from,
              to
            }),
            once: onceDefaults,
            loading: false
          }
        } else {
          // once
          const schedulesTransformed = schedules.map(schedule => {
            const minutes = moment({
              minute: _toInteger(schedule.get("minute"))
            }).format("mm")
            const hours = moment.utc({ hour: _toInteger(schedule.get("hour")) }).hours()
            const days = List(_map(schedule.get("day_of_week").split(","), day => _toInteger(day)))
            return Map({ minutes, hours, days })
          })
          this.state = {
            checked: ONCE,
            repeat: repeatDefaults,
            once: Map({
              activeTab: 0,
              schedules: schedulesTransformed
            }),
            loading: false
          }
        }
      }
    }
  }

  addSchedule = () => {
    this.setState(prevState => ({
      once: prevState.once.set("activeTab", prevState.once.get("schedules").size).set(
        "schedules",
        prevState.once.get("schedules").push(
          Map({
            minutes: "",
            hours: "",
            days: List()
          })
        )
      )
    }))
  }

  deleteSchedule = () => {
    const { once } = this.state
    if (once.get("schedules").size === 1) {
      // erase last schedule, don't delete tab
      this.setState({
        once: Map({
          activeTab: 0,
          schedules: List([Map({ minutes: "", hours: "", days: List() })])
        })
      })
    } else {
      this.setState(prevState => ({
        once: prevState.once.set("activeTab", 0).set(
          "schedules",
          prevState.once
            .get("schedules")
            .filterNot((schedule, sidx) => prevState.once.get("activeTab") === sidx)
        )
      }))
    }
  }

  selectActiveSchedule = idx => () => {
    this.setState(prevState => ({
      once: prevState.once.set("activeTab", idx)
    }))
  }

  handleRepeatFormSubmit = () => {
    if (!this.state.loading) {
      const { repeat } = this.state
      if (repeat.get("days").size === 0) {
        this.setState(prevState => ({
          repeat: prevState.repeat.set("dayError", "Please select at least one day")
        }))
      } else if (!repeat.get("value")) {
        this.setState(prevState => ({
          repeat: prevState.repeat.set("timeError", "Please select a value")
        }))
      } else {
        // valid
        this.setState({ loading: true })
        const { onSubmit } = this.props
        onSubmit({
          type: REPEAT,
          schedule: {
            unit: repeat.get("unit"),
            value: _toInteger(repeat.get("value").split(" ")[0]),
            days: repeat.get("days").toArray(),
            startingAt: repeat.get("startingAt"),
            from: repeat.get("from"),
            to: repeat.get("to")
          }
        }).catch(() => {
          this.setState({ loading: false })
        })
      }
    }
  }

  handleRepeatValueInputChange = option => {
    const { repeat } = this.state
    this.setState({
      repeat: repeat
        .set("value", option.value)
        .set("unit", option.type)
        .set("timeError", false)
        .set("startingAt", repeat.get("startingAt") < option.value ? repeat.get("startingAt") : "")
    })
  }

  handleRepeatFromValueInputChange = evt => {
    const { value } = evt.target
    if (
      value === "" ||
      (_isInteger(parseInt(value)) && _toInteger(value) >= 0 && _toInteger(value) < 24)
    ) {
      if (value !== "00") {
        this.setState(prevState => ({
          repeat: prevState.repeat.set("from", value)
        }))
      }
    }
  }

  handleRepeatToValueInputChange = evt => {
    const { value } = evt.target
    if (
      value === "" ||
      (_isInteger(parseInt(value)) && _toInteger(value) >= 0 && _toInteger(value) < 24)
    ) {
      if (value !== "00") {
        this.setState(prevState => ({
          repeat: prevState.repeat.set("to", value)
        }))
      }
    }
  }

  handleStartingAtValueInputChange = option => {
    const { repeat } = this.state
    this.setState({
      repeat: repeat.set("startingAt", option ? option.value : null)
    })
  }

  handleTimeChange = evt => {
    const { value, name } = evt.target

    this.setState(prevState => ({
      once: prevState.once.set(
        "schedules",
        prevState.once.get("schedules").map((schedule, sidx) => {
          if (sidx === prevState.once.get("activeTab")) {
            return schedule.set(name, value).set("timeError", false)
          }
          return schedule
        })
      )
    }))
  }

  isScheduleTimeValid = schedule => {
    if (
      _isNaN(schedule.get("minutes")) ||
      _isNaN(schedule.get("hours")) ||
      !_inRange(schedule.get("hours"), 0, 24) ||
      !_inRange(schedule.get("minutes"), 0, 60)
    ) {
      return false
    }
    return true
  }

  handleOnceFormSubmit = () => {
    if (!this.state.loading) {
      const { once } = this.state

      let valid = true
      const schedulesCheck = once.get("schedules").map(schedule => {
        if (schedule.get("days").size === 0) {
          valid = false
          if (!this.isScheduleTimeValid(schedule)) {
            return schedule.set("dayError", "Please select at least one day").set("timeError", true)
          }
          return schedule.set("dayError", "Please select at least one day")
        } else {
          if (!this.isScheduleTimeValid(schedule)) {
            valid = false
            return schedule.set("timeError", true)
          }
        }
        return schedule
      })

      if (valid) {
        // submit
        this.setState({ loading: true })
        const schedulesUtc = once.get("schedules").map(schedule => ({
          minutes: _toInteger(schedule.get("minutes")),
          hours: moment.utc({ hour: _toInteger(schedule.get("hours")) }).hours(),
          days: schedule.get("days").toArray()
        }))

        this.props.onSubmit({ type: ONCE, schedules: schedulesUtc.toArray() }).catch(() => {
          this.setState({ loading: false })
        })
      } else {
        this.setState(prevState => ({
          once: prevState.once.set("schedules", schedulesCheck)
        }))
      }
    }
  }

  dayOfWeekActive = dayNumber => {
    if (this.state.checked === REPEAT) {
      return this.state.repeat.get("days").includes(dayNumber)
    } else {
      const { once } = this.state
      const schedule = once.getIn(["schedules", once.get("activeTab")])

      return schedule.get("days").includes(dayNumber)
    }
  }

  unsetDayOfWeek = dayNumber => {
    if (this.state.checked === REPEAT) {
      this.setState(prevState => ({
        repeat: prevState.repeat.set(
          "days",
          prevState.repeat.get("days").filterNot(day => day === dayNumber)
        )
      }))
    } else {
      this.setState(prevState => ({
        once: prevState.once.set(
          "schedules",
          prevState.once.get("schedules").map((schedule, sidx) => {
            if (sidx === prevState.once.get("activeTab")) {
              return schedule.set(
                "days",
                schedule.get("days").filterNot(day => day === dayNumber)
              )
            }
            return schedule
          })
        )
      }))
    }
  }

  setDayOfWeek = dayNumber => {
    if (this.state.checked === REPEAT) {
      this.setState(prevState => ({
        repeat: prevState.repeat
          .set("dayError", false)
          .set("days", prevState.repeat.get("days").push(dayNumber))
      }))
    } else {
      this.setState(prevState => ({
        once: prevState.once.set(
          "schedules",
          prevState.once.get("schedules").map((schedule, sidx) => {
            if (sidx === prevState.once.get("activeTab")) {
              return schedule
                .set("dayError", false)
                .set("days", schedule.get("days").push(dayNumber))
            }
            return schedule
          })
        )
      }))
    }
  }

  toggleDayOfWeek = dayNumber => {
    if (this.state.checked === REPEAT) {
      if (this.state.repeat.get("days").includes(dayNumber)) {
        this.unsetDayOfWeek(dayNumber)
      } else {
        this.setDayOfWeek(dayNumber)
      }
    } else {
      const schedule = this.state.once.getIn(["schedules", this.state.once.get("activeTab")])
      if (schedule.get("days").includes(dayNumber)) {
        this.unsetDayOfWeek(dayNumber)
      } else {
        this.setDayOfWeek(dayNumber)
      }
    }
  }

  toggleDayOfWeekFormType = evt => {
    this.setState({
      checked: evt.target.value
    })
  }

  render() {
    const {
      notificationEmails,
      handleEmailAdd,
      handleEmailDelete,
      handleFormTypeChange,
      handleFormClose,
      handleEntireSchedulesDelete
    } = this.props
    const { checked, repeat, once, loading } = this.state

    let startingAtDisabled = true
    let startingAtOptions = []
    if (checked === REPEAT) {
      const selectedValue = repeat.get("value") ? _toInteger(repeat.get("value").split(" ")[0]) : 0
      startingAtDisabled = selectedValue < 2
      const length = repeat.get("unit") === "Min" ? 29 : 11
      startingAtOptions = Array.from({ length: length }, (v, k) => ({
        value: k + 1,
        label: `${k + 1}`,
        type: repeat.get("unit")
      })).filter(obj => obj.value < selectedValue)
    }

    let fromToDisabled = false
    let startingAtDisabledDueToFromToFilled = false
    if (repeat.get("value").endsWith("Hour")) {
      if (repeat.get("startingAt")) {
        fromToDisabled = true
      } else if (repeat.get("from") || repeat.get("to")) {
        startingAtDisabled = true
        startingAtDisabledDueToFromToFilled = true
      }
    }
    return (
      <div>
        <CommonHeader
          handleClose={handleFormClose}
          formType={SCHEDULE_FORM_TYPE.DAY_OF_WEEK}
          handleSubmit={
            checked === REPEAT ? this.handleRepeatFormSubmit : this.handleOnceFormSubmit
          }
          handleFormTypeChange={handleFormTypeChange}
          loading={loading}
          handleEntireSchedulesDelete={handleEntireSchedulesDelete}
        />
        <Paper hasHeader={true} className="schedule-form">
          <div className="schedule-topbar">
            {checked === ONCE && (
              <div className="tabs">
                {once.get("schedules").map((schedule, idx) => {
                  let color = "grey"
                  if (once.get("activeTab") === idx) {
                    color = "primary"
                  }
                  if (schedule.get("dayError") || schedule.get("timeError")) {
                    color = "red"
                  }
                  return (
                    <Button
                      key={idx}
                      className="tab"
                      onClick={this.selectActiveSchedule(idx)}
                      color={color}
                      size="small"
                    >
                      Time {convertToRomanNum(idx + 1)}
                    </Button>
                  )
                })}
                {once.get("schedules").size < 8 && (
                  <Button
                    color="white"
                    className="tab"
                    onClick={this.addSchedule}
                    size="small"
                    type="button"
                  >
                    + ADD TIME
                  </Button>
                )}
              </div>
            )}
            <ToggleSwitch
              width="272px"
              leftValue={REPEAT}
              leftText="Interval of time"
              rightValue={ONCE}
              rightText="Fixed time"
              name="day_of_week_form_type"
              checked={checked}
              handleToggle={this.toggleDayOfWeekFormType}
              className="once-repeat-switch"
            />
          </div>
          <div className="schedule-middlebar">
            <div
              className={`days ${
                (checked === REPEAT && repeat.get("dayError")) ||
                (checked === ONCE && once.getIn(["schedules", once.get("activeTab"), "dayError"]))
                  ? "error"
                  : ""
              }`}
            >
              <p className="grey-label">Days</p>
              <Button
                onClick={() => this.toggleDayOfWeek(1)}
                color={this.dayOfWeekActive(1) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Mon
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(2)}
                color={this.dayOfWeekActive(2) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Tue
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(3)}
                color={this.dayOfWeekActive(3) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Wed
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(4)}
                color={this.dayOfWeekActive(4) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Thu
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(5)}
                color={this.dayOfWeekActive(5) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Fri
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(6)}
                color={this.dayOfWeekActive(6) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Sat
              </Button>
              <Button
                onClick={() => this.toggleDayOfWeek(7)}
                color={this.dayOfWeekActive(7) ? "primary inner-shadow border" : "white"}
                size="big"
              >
                Sun
              </Button>
              {checked === REPEAT && repeat.get("dayError") && (
                <p className="error-message">{repeat.get("dayError")}</p>
              )}
              {checked === ONCE && once.getIn(["schedules", once.get("activeTab"), "dayError"]) && (
                <p className="error-message">
                  {once.getIn(["schedules", once.get("activeTab"), "dayError"])}
                </p>
              )}
            </div>
            <div className="vertical-line" />
            {checked === REPEAT && (
              <div className="repeat-section">
                <p className="grey-label">Repeat every</p>
                <div className={`repeat-row text-field`}>
                  <span className={`${repeat.get("timeError") ? "error" : ""}`}>
                    <Select
                      value={
                        repeat.get("value")
                          ? {
                              value: repeat.get("value"),
                              label: _toString(
                                _get(repeat.get("value").split(" "), "[0]", repeat.get("value"))
                              ),
                              type: repeat.get("unit")
                            }
                          : null
                      }
                      onChange={this.handleRepeatValueInputChange}
                      options={repeatOptions}
                      styles={selectStyles()}
                      className="repeat-value"
                      placeholder=""
                      components={{
                        DropdownIndicator,
                        Option,
                        SingleValue
                      }}
                      classNamePrefix="repeat-field"
                    />
                  </span>
                  <div className={`from-time text-field`} data-tip data-for="disabled-from-tooltip">
                    <label className="grey-label">From</label>
                    <input
                      type="text"
                      name="from-time"
                      value={repeat.get("from") ? repeat.get("from") : ""}
                      onChange={this.handleRepeatFromValueInputChange}
                      maxLength={2}
                      disabled={fromToDisabled}
                    />
                  </div>
                  {fromToDisabled && (
                    <ReactTooltip id="disabled-from-tooltip" place="bottom">
                      For hours set either "From-To" or "Delayed by".
                    </ReactTooltip>
                  )}
                  <div className={`to-time text-field`} data-tip data-for="disabled-to-tooltip">
                    <label className="grey-label">To</label>
                    <input
                      type="text"
                      name="to-time"
                      value={repeat.get("to") ? repeat.get("to") : ""}
                      onChange={this.handleRepeatToValueInputChange}
                      maxLength={2}
                      disabled={fromToDisabled}
                    />
                  </div>
                  {fromToDisabled && (
                    <ReactTooltip id="disabled-to-tooltip" place="bottom">
                      For hours set either "From-To" or "Delayed by".
                    </ReactTooltip>
                  )}
                  <div
                    className={`starting-at ${startingAtDisabled ? "disabled" : ""}`}
                    data-tip
                    data-for="starting-at-disabled-tooltip"
                  >
                    <p className="grey-label">Delayed by</p>
                    <Select
                      value={
                        repeat.get("startingAt")
                          ? {
                              value: repeat.get("startingAt"),
                              label: _toString(repeat.get("startingAt")),
                              type: repeat.get("unit")
                            }
                          : null
                      }
                      onChange={this.handleStartingAtValueInputChange}
                      options={startingAtOptions}
                      styles={selectStyles()}
                      isDisabled={startingAtDisabled}
                      className="repeat-value starting-at"
                      placeholder=""
                      isClearable
                      components={{
                        DropdownIndicator,
                        Option,
                        SingleValue
                      }}
                      classNamePrefix="offset-field"
                    />
                  </div>
                  {startingAtDisabled && (
                    <ReactTooltip
                      id="starting-at-disabled-tooltip"
                      place="bottom"
                      className="tooltip"
                    >
                      {!repeat.get("unit") && (
                        <React.Fragment>Set "Repeat every" first.</React.Fragment>
                      )}
                      {repeat.get("unit") === "Min" && (
                        <React.Fragment>
                          You can not set "Delayed by" for "Repeat every" that equals 1 min.
                        </React.Fragment>
                      )}
                      {repeat.get("unit") === "Hour" && !startingAtDisabledDueToFromToFilled && (
                        <React.Fragment>
                          You can not set "Delayed by" for "Repeat every" that equals 1 hour.
                        </React.Fragment>
                      )}
                      {repeat.get("unit") === "Hour" && startingAtDisabledDueToFromToFilled && (
                        <React.Fragment>
                          For hours set either "From-To" or "Delayed by".
                        </React.Fragment>
                      )}
                    </ReactTooltip>
                  )}
                </div>
                {repeat.get("timeError") && (
                  <p className="error-message">{repeat.get("timeError")}</p>
                )}
              </div>
            )}
            {checked === ONCE && (
              <div className="once-section">
                <div className="time">
                  <p className="grey-label">Time (in UTC)</p>
                  <div
                    className={`time-row text-field ${
                      once.getIn(["schedules", once.get("activeTab"), "timeError"]) ? "error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      name="hours"
                      value={once.getIn(["schedules", once.get("activeTab"), "hours"])}
                      onChange={this.handleTimeChange}
                      maxLength="2"
                      placeholder="0"
                      className="time-input"
                    />
                    :
                    <input
                      type="text"
                      name="minutes"
                      value={once.getIn(["schedules", once.get("activeTab"), "minutes"])}
                      onChange={this.handleTimeChange}
                      maxLength="2"
                      placeholder="00"
                      className="time-input"
                    />
                  </div>
                  {once.getIn(["schedules", once.get("activeTab"), "timeError"]) && (
                    <p className="error-message">Please enter the correct time (0-23:00-59)</p>
                  )}
                </div>
              </div>
            )}
            {checked === ONCE && (
              <div className="delete-time-section">
                <Button
                  color="transparent-red"
                  type="button"
                  onClick={this.deleteSchedule}
                  size="small"
                  className="delete-time-button"
                >
                  <FontAwesomeIcon className="bin-icon" icon={["far", "trash-alt"]} /> Scheduled
                  Time
                </Button>
              </div>
            )}
          </div>
          <div className="schedule-bottombar">
            <AddInputbox
              values={notificationEmails}
              handleValueAdd={handleEmailAdd}
              handleValueDelete={handleEmailDelete}
              type={ADD_INPUTBOX_TYPE.EMAIL}
              className="notify-emails"
            />
          </div>
        </Paper>
      </div>
    )
  }
}

DayOfWeekForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  schedules: PropTypes.instanceOf(List).isRequired,
  notificationEmails: PropTypes.instanceOf(List).isRequired,
  handleEmailAdd: PropTypes.func.isRequired,
  handleEmailDelete: PropTypes.func.isRequired,
  handleFormTypeChange: PropTypes.func.isRequired,
  handleFormClose: PropTypes.func.isRequired,
  handleEntireSchedulesDelete: PropTypes.func
}

export default DayOfWeekForm
