import _get from "lodash/get"
import { api } from "api"
import { DAWG } from "sharedConstants"

export const runDawgJob = (dawgId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.JOB.ACTION.RUN,
    payload: {
      promise: (async () => await api().dawg.job.run(dawgId, loadFullStructure))()
    }
  })

export const listDawgJob = (dawgId, offset, limit, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.JOB.ACTION.LIST,
    payload: {
      promise: (async () => await api().dawg.job.list(dawgId, offset, limit, loadFullStructure))()
    }
  })

export const refreshLastDawgJob = (dawgId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.JOB.ACTION.REFRESH_LAST,
    payload: {
      dawg_job: _get(
        await api().dawg.job.list(dawgId, 0, 1, loadFullStructure),
        "dawg_jobs[0]",
        null
      )
    }
  })

export const clearCachedDawgJobs = () => dispatch =>
  dispatch({
    type: DAWG.JOB.ACTION.CLEAR_CACHE,
    payload: {}
  })

export const cancelDawgJob = (dawgId, jobId, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.JOB.ACTION.CANCEL,
    payload: {
      promise: (async () => await api().dawg.job.cancel(dawgId, jobId, loadFullStructure))()
    }
  })

export const retrieveDawgJob = (
  dawgId,
  jobId,
  loadFullStructure = 0,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: DAWG.JOB.ACTION.RETRIEVE,
      payload: {
        promise: (async () => await api().dawg.job.retrieve(dawgId, jobId, loadFullStructure))()
      }
    })
  } else {
    return dispatch({
      type: DAWG.JOB.ACTION.RETRIEVE,
      payload: await api().dawg.job.retrieve(dawgId, jobId, loadFullStructure)
    })
  }
}
