import { createSelector } from "reselect"
import { Map, List } from "immutable"
import _toInteger from "lodash/toInteger"

import { initFuseImmutableSearch } from "helpers/table.helper"

const getOAuthRepositories = state => state.oauthRepos
export const getOAuthRepositoryRecord = (state, id) => {
  if (state.oauthRepos) {
    return state.oauthRepos.find(repo => repo.id === _toInteger(id))
  } else {
    return null
  }
}

export const getOAuthRepositoriesData = createSelector([getOAuthRepositories], repos => {
  if (repos) {
    return Map({
      isFulfilled: true,
      data: repos
    })
  } else {
    return Map({
      isFulfilled: false,
      data: List()
    })
  }
})

export const getOAuthRepositoriesFuseData = createSelector([getOAuthRepositories], repos => {
  if (repos) {
    return Map({
      isFulfilled: true,
      data: initFuseImmutableSearch(repos, ["name"])
    })
  } else {
    return Map({
      isFulfilled: false,
      data: initFuseImmutableSearch(List(), ["name"])
    })
  }
})
