import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, FieldArray, getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Waypoint } from "react-waypoint"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import _trim from "lodash/trim"
import _toLower from "lodash/toLower"
import _isNumber from "lodash/isNumber"
import _forEach from "lodash/forEach"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import TextField from "components/UI/elements/TextField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import Avatar from "components/UI/elements/Avatar"
import CheckboxField from "components/UI/elements/CheckboxField"

// helpers
import { required, email, minLength12, passwordStrength } from "helpers/validators.helper"

// actions
import { inviteUser, createUser } from "actions/user.action"
import { showToast } from "actions/toast.action"
import {
  setDefaultPermissions,
  markAllWorkspaces,
  setPermisionsAndMarkWorkspaces,
  setActiveUser,
  toggleAllSwitch,
  toggleAllUserPermissions
} from "actions/inviteForm.action"

// models
import { WorkspaceSimple as WorkspaceModel } from "models/workspace.model"
import SelectionSettings from "models/selectionSettings.model"

import { getAllUsersMap } from "selectors/user.selector"

// constants, helpers
import { TOAST, PERMISSION, WORKSPACE } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

import "./CreateUserModal.css"

class CreateUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      workspaces: null,
      selectionSettings: null,
      hasWorkspacesMoreItems: false
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      const response = await api().workspace.list(0, WORKSPACE.LOADING_LIMIT, 0, "name", "ASC")
      this.setState({
        workspaces: List(response.workspaces.map(ws => new WorkspaceModel(ws))),
        selectionSettings: new SelectionSettings(response.selection_settings),
        hasWorkspacesMoreItems: WORKSPACE.LOADING_LIMIT === response.workspaces.length
      })
    }
  }

  onSubmit = values => {
    const { type, showToast } = this.props
    const { page, loading } = this.state

    if (type === "user" && page === 1) {
      this.changeNextPage(true)
    } else {
      if (!loading) {
        this.setState({ loading: true })
        this.usersCreated = 0
        this.createUsers(
          List(values["invite-users"]),
          type,
          values.send_invitation_emails ? "invite" : "create",
          isSuccess => {
            this.setState({ loading: false })
            if (isSuccess) {
              if (this.usersCreated > 0) {
                let toastMessage = ""
                if (values.send_invitation_emails) {
                  toastMessage = `${
                    this.usersCreated === 1 ? "Invite" : "Invites"
                  } to Meiro Integrations ${this.usersCreated === 1 ? "has" : "have"} been sent.`
                } else {
                  toastMessage = `${
                    this.usersCreated === 1 ? "User has" : "Users have"
                  } been created.`
                }

                showToast(toastMessage, TOAST.TYPE.SUCCESS)
              }
              this.cancel()
            }
          }
        )
      }
    }
  }

  createUsers = (users, type, apiCallType, cbFn) => {
    if (users.size === 0) {
      cbFn(true)
    } else {
      const { inviteUser, createUser } = this.props
      const user = users.last()
      const data = {
        name: _trim(user.name),
        email: _trim(user.email),
        role: type
      }
      if (apiCallType === "create") {
        data.password = user.password
      }
      if (type === "user") {
        const workspacesAcl = {}
        if (user["workspace-selected"]) {
          _forEach(user["workspace-selected"], (value, key) => {
            if (value) {
              workspacesAcl[Number(key.split("-")[1])] =
                user["workspace-permission"] && user["workspace-permission"][key]
                  ? user["workspace-permission"][key]
                  : PERMISSION.READ
            }
          })
        }
        data["workspaces_acl"] = workspacesAcl
      }

      const callerFunc = apiCallType === "invite" ? inviteUser : createUser
      callerFunc(data)
        .then(() => {
          this.usersCreated += 1
          this.createUsers(users.pop(), type, apiCallType, cbFn)
        })
        .catch(() => {
          this.createUsers(users.pop(), type, apiCallType, cbFn)
        })
    }
  }

  cancel = () => {
    const { handleClose, reset } = this.props
    handleClose()

    // reset after fade out
    setTimeout(() => {
      this.changeNextPage(false)
      reset()
    }, 500)
  }

  setPermissionsDefault = (workspaces = null) => {
    const { setDefaultPermissions } = this.props
    if (workspaces) {
      setDefaultPermissions(
        "CreateUserForm",
        workspaces.map(ws => ws.id)
      )
    } else if (List.isList(this.state.workspaces)) {
      setDefaultPermissions("CreateUserForm", this.state.workspaces.map(ws => ws.id).toArray())
    }
  }

  setWorkspaceDefaultPermissionsAndMarkThem = (workspaces, userIndex, markValue) => {
    this.props.setPermisionsAndMarkWorkspaces(
      "CreateUserForm",
      workspaces.map(sg => sg.id),
      userIndex,
      markValue
    )
  }

  emailUnique = value => {
    const { formValues, users } = this.props
    const otherFormUsers = _get(formValues, "invite-users")
    if (_isArray(otherFormUsers)) {
      let occurences = 0
      otherFormUsers.forEach(user => {
        if (_toLower(_trim(user.email)) === _toLower(_trim(value))) {
          occurences++
        }
      })
      if (occurences > 1) {
        return "Email has been used more than once"
      }
    }

    const existingUser = users.find(user => _toLower(_trim(user.email)) === _toLower(_trim(value)))
    if (existingUser && existingUser.deleted) {
      return "User is already in Trash"
    } else if (existingUser && !existingUser.deleted) {
      return "User already exists"
    }
    return undefined
  }

  onMarkAllChange = member => async evt => {
    const { allWorkspacesLoaded, hasWorkspacesMoreItems } = this.state
    const { markAllWorkspaces } = this.props
    const { value } = evt.currentTarget
    const index = Number(member.match(/\[(\d)\]/)[1])
    if (_isNumber(index)) {
      if (value === "true") {
        markAllWorkspaces("CreateUserForm", index, false)
      } else {
        if (allWorkspacesLoaded || !hasWorkspacesMoreItems) {
          markAllWorkspaces("CreateUserForm", index, true)
        } else {
          // we need to load all workspaces
          const caller = new AllResourceItemsFetcher()
          this.setState({ loadingAllWorkspaces: true })
          const data = await caller
            .setEndpointCall((offset, limit, loadFullStructure) =>
              api().workspace.list(offset, limit, 0, "name", "ASC")
            )
            .setDataPath("workspaces")
            .setLoadFullStructure(0)
            .run()

          this.setWorkspaceDefaultPermissionsAndMarkThem(data, index, true)
          this.setState({
            loadingAllWorkspaces: false,
            allWorkspacesLoaded: true,
            hasWorkspacesMoreItems: false,
            workspaces: List(data.map(ws => new WorkspaceModel(ws)))
          })
        }
      }
    }
  }

  onToggleAllChange = member => async evt => {
    const { toggleAllSwitch } = this.props
    const { value } = evt.currentTarget
    const index = Number(member.match(/\[(\d+)\]/)[1])
    if (_isNumber(index)) {
      toggleAllSwitch("CreateUserForm", index, value === "true")
    }
  }

  toggleAllSwitchesIfMarked = member => evt => {
    const { formValues, toggleAllUserPermissions } = this.props
    const index = Number(member.match(/\[(\d+)\]/)[1])
    if (_isNumber(index)) {
      const toggleAll = _get(formValues, `invite-users[${index}].toggle-all`)
      if (toggleAll) {
        toggleAllUserPermissions("CreateUserForm", index, evt.currentTarget.value)
      }
    }
  }

  renderWorkspaces = userIndex => {
    const { formValues } = this.props
    const { workspaces, loadingAllWorkspaces } = this.state

    if (!workspaces || workspaces.size === 0) {
      return null
    }

    const member = `invite-users[${userIndex}]`

    return (
      <div className="workspaces">
        <div className="select-all">
          <div className="checkbox-field">
            <label>
              <Field
                name={`${member}.select-all`}
                id={`${member}.select-all`}
                component="input"
                type="checkbox"
                className="checkbox"
                onChange={this.onMarkAllChange(member)}
              />
              <span className="checkmark">
                <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
              </span>
              <div className="horizontal-line" />
              <span className="bold-text">Mark all</span>
            </label>
          </div>
          <div className="checkbox-field permission">
            <label>
              <Field
                name={`${member}.toggle-all`}
                id={`${member}.toggle-all`}
                component="input"
                type="checkbox"
                className="checkbox"
                onChange={this.onToggleAllChange(member)}
              />
              <span className="checkmark">
                <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
              </span>
              <div className="horizontal-line" />
              <span className="bold-text">Toggle all</span>
            </label>
          </div>
        </div>
        <div>
          {List.isList(workspaces) &&
            workspaces.map((workspace, index) => {
              return (
                <div key={workspace.id} className="workspace">
                  <div className="checkbox-field">
                    <label className="">
                      <Field
                        name={`${member}.workspace-selected.id-${workspace.id}`}
                        id={`${member}.workspace-selected.id-${workspace.id}`}
                        component="input"
                        type="checkbox"
                        className="checkbox"
                      />
                      <span className="checkmark">
                        <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
                      </span>
                      <div className="horizontal-line" />
                      {workspace.name}
                    </label>
                  </div>
                  <div>
                    <ToggleSwitchField
                      name={`${member}.workspace-permission.id-${workspace.id}`}
                      leftLabel="View"
                      leftValue={PERMISSION.READ}
                      rightLabel="Edit"
                      rightValue={PERMISSION.WRITE}
                      width="7rem"
                      size="small"
                      label=""
                      disabled={
                        _get(formValues, `${member}.workspace-selected.id-${workspace.id}`) !== true
                      }
                      onChange={this.toggleAllSwitchesIfMarked(member)}
                    />
                  </div>
                </div>
              )
            })}
          {this.renderWaypoint()}
        </div>
        {loadingAllWorkspaces && <div className="loading-overlay" />}
      </div>
    )
  }

  renderWaypoint = () => {
    const { hasWorkspacesMoreItems } = this.state
    if (hasWorkspacesMoreItems) {
      return <Waypoint onEnter={this._loadMoreWorkspaces} bottomOffset={-250} />
    }
  }

  _loadMoreWorkspaces = async () => {
    const { selectionSettings } = this.state
    const response = await api().workspace.list(
      selectionSettings.offset + selectionSettings.limit,
      WORKSPACE.LOADING_LIMIT,
      0,
      "name",
      "ASC"
    )
    this.setState(prevState => ({
      workspaces: prevState.workspaces.concat(
        response.workspaces.map(ws => new WorkspaceModel(ws))
      ),
      selectionSettings: new SelectionSettings(response.selection_settings),
      hasWorkspacesMoreItems: WORKSPACE.LOADING_LIMIT === response.workspaces.length
    }))
    this.setPermissionsDefault(response.workspaces)
  }

  renderCreateUsers = ({ fields, showPasswordFields }) => {
    const defaultInviteUser = {
      name: "",
      email: ""
    }

    if (fields.length === 0) {
      fields.push(defaultInviteUser)
    }

    return (
      <React.Fragment>
        {fields.map((member, index) => {
          return (
            <div className="form-row" key={index}>
              <Field
                name={`${member}.email`}
                component={TextField}
                validate={[required, email, this.emailUnique]}
                fullWidth={true}
                placeholder="Email"
                className="user-field"
                autoFocus
                data-cy="user-email-field"
              />
              <Field
                name={`${member}.name`}
                component={TextField}
                placeholder="Full Name"
                className="user-field"
                validate={required}
                fullWidth={true}
                data-cy="user-name-field"
              />
              <IconButton
                color="red"
                type="button"
                onClick={() => {
                  fields.remove(index)
                  fields.forEach(field => {
                    // hack to reset validation
                    setTimeout(() => this.props.blur("CreateUserForm", `${field}.email`), 100)
                  })
                }}
                className="trash-icon"
                disabled={fields.length === 1}
              >
                <FontAwesomeIcon icon={["far", "trash-alt"]} />
              </IconButton>
              {showPasswordFields && (
                <Field
                  name={`${member}.password`}
                  component={TextField}
                  type="password"
                  placeholder="Password"
                  className="password-field"
                  validate={[required, minLength12, passwordStrength]}
                  fullWidth={true}
                />
              )}
            </div>
          )
        })}
        <Button
          color="link-primary"
          type="button"
          onClick={() => fields.push(defaultInviteUser)}
          className={`invite-more-btn ${fields.length > 4 ? "hidden" : ""}`}
          size="small"
        >
          <FontAwesomeIcon icon={["far", "plus-circle"]} className="icon" />
          add more people
        </Button>
      </React.Fragment>
    )
  }

  renderPermissions = ({ fields, activeUser }) => {
    return (
      <React.Fragment>
        <div className="form-row permissions">
          {fields.map((member, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`permissions-user ${index === activeUser ? "active" : ""}`}
                  onClick={() => this.props.setActiveUser("CreateUserForm", index)}
                >
                  <Avatar
                    email={fields.get(index)["email"]}
                    name={fields.get(index)["name"]}
                    gravatarSize={200}
                    className="img"
                    disabledLook={index !== activeUser}
                  />
                  <p className="permissions-name">{fields.get(index)["name"]}</p>
                </div>
                {index + 1 !== fields.length && <div className="vr-line" />}
              </React.Fragment>
            )
          })}
        </div>
        {this.renderWorkspaces(activeUser)}
      </React.Fragment>
    )
  }

  changeNextPage = isNext => {
    const { setActiveUser } = this.props
    if (isNext) {
      this.setPermissionsDefault()
      setActiveUser("CreateUserForm")
      this.setState({
        page: 2
      })
    } else {
      this.setState({
        page: 1
      })
    }
  }

  renderActionBtns = () => {
    const { type } = this.props
    const { page } = this.state

    return (
      <div className="action-buttons">
        {page === 1 && (
          <Button
            type="button"
            color="transparent-grey"
            size="big"
            onClick={this.cancel}
            className="cancel-button"
          >
            Cancel
          </Button>
        )}
        {page === 2 && (
          <Button
            type="button"
            color="primary"
            size="big"
            onClick={() => this.changeNextPage(false)}
          >
            Back
          </Button>
        )}

        {type === "user" && <span className="pagination-lbl">{page}/2</span>}

        {page === 1 && (
          <Button
            type="submit"
            color="primary"
            size="big"
            className={type === "user" && page === 1 ? "" : "hide"}
            data-cy="next-button"
          >
            Next
          </Button>
        )}

        {(page === 2 || type === "admin") && (
          <Button
            type="submit"
            color="primary"
            size="big"
            className={`${this.state.loading ? "loading" : ""}`}
            data-cy="invite-button"
          >
            {`Create ${type}`}
          </Button>
        )}
      </div>
    )
  }

  renderFieldArray = () => {
    const { type, formValues } = this.props
    const { page, workspaces } = this.state

    if (type === "admin" || page === 1) {
      return (
        <FieldArray
          name="invite-users"
          component={this.renderCreateUsers}
          rerenderOnEveryChange={true}
          props={{ showPasswordFields: !formValues.send_invitation_emails }}
        />
      )
    } else if (page === 2) {
      return (
        <FieldArray
          name="invite-users"
          component={this.renderPermissions}
          rerenderOnEveryChange={true}
          props={{ workspaces, activeUser: formValues["active-user"] }} // This is to force this FieldArray to rerender
        />
      )
    }
  }

  render() {
    const { open, handleSubmit, type } = this.props
    const { page } = this.state

    return (
      <Modal open={open} handleClose={this.cancel} title={`Create ${type}`} className="modal-list">
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} className="modal-list-form">
          <div className="create-user-list">{this.renderFieldArray()}</div>
          {page === 1 && (
            <div className="send-emails-checkbox">
              <Field
                name="send_invitation_emails"
                label="Send an invitation"
                component={CheckboxField}
              />
            </div>
          )}
          {this.renderActionBtns()}
        </form>
        <div className="form-row regulations-note-wrapper">
          <p className="regulations-note">
            Please ensure that you are compliant with Meiro's Data Privacy regulations
          </p>
        </div>
      </Modal>
    )
  }
}

CreateUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Map).isRequired
}

const mapStateToProps = state => {
  return {
    formValues: getFormValues("CreateUserForm")(state),
    users: getAllUsersMap(state)
  }
}

CreateUserModal = reduxForm({
  form: "CreateUserForm",
  touchOnBlur: false
})(
  connect(mapStateToProps, {
    inviteUser,
    showToast,
    setDefaultPermissions,
    markAllWorkspaces,
    setPermisionsAndMarkWorkspaces,
    createUser,
    setActiveUser,
    toggleAllSwitch,
    toggleAllUserPermissions
  })(CreateUserModal)
)

export default CreateUserModal
