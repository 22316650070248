import _get from "lodash/get"

export const goBackInHistory = (history, alternativePath) => () => {
  if (_get(history, "location.state.previous")) {
    history.goBack()
  } else {
    history.push(alternativePath)
  }
}

export const goBack = (history, path) => () => {
  history.push(path)
}
