import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { List, Map } from "immutable"

import PaperHeader from "components/UI/elements/PaperHeader"
import WorkspaceIcon from "components/UI/elements/WorkspaceIcon"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"
import StatusElement from "components/UI/elements/StatusElement"

import { capitalize } from "helpers/string.helper"
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"

import "./ProjectJobs.css"

class ProjectJobsCard extends PureComponent {
  render() {
    const { entityName, projectJobs } = this.props

    return (
      <section className="project-jobs">
        <PaperHeader size="small" className="project-jobs-header">
          <h3>{capitalize(entityName)} activity</h3>
          <Link
            to={getRoutePath(
              entityName === "configuration" ? "project.configurationJob" : "project.workspaceJob"
            )}
            className="button small primary"
          >
            View all
          </Link>
        </PaperHeader>
        {List.isList(projectJobs) &&
          projectJobs.map(projectJob => {
            const entity =
              entityName === "configuration" ? projectJob.configuration : projectJob.workspace
            const entityPathname =
              entityName === "configuration"
                ? getRoutePath("workspace.configuration.show", {
                    id: entity.getIn(["workspace", "id"]),
                    cid: entity.get("id")
                  })
                : getRoutePath("workspace.show", {
                    id: entity.get("id")
                  })
            const jobPathName =
              entityName === "configuration"
                ? getRoutePath("workspace.configuration.configurationJob.show", {
                    id: entity.getIn(["workspace", "id"]),
                    cid: entity.get("id"),
                    aid: projectJob.id
                  })
                : getRoutePath("workspace.workspaceJob.show", {
                    id: entity.get("id"),
                    aid: projectJob.id
                  })
            let iconComponent = null
            if (entityName === "configuration") {
              const icon = projectJob.configuration.getIn(["component", "icon"])
                ? projectJob.configuration.getIn(["component", "icon"])
                : "dummy.png"

              iconComponent = (
                <img src={getComponentIconSrc(icon)} alt={entity.getIn(["component", "name"])} />
              )
            } else {
              iconComponent = <WorkspaceIcon />
            }
            return (
              <div key={projectJob.id} className="project-job-item">
                <div className={`color-border ${projectJob.status}`}>
                  <div className="col-info">
                    {iconComponent}
                    <div className="info">
                      <Link
                        to={{
                          pathname: entityPathname,
                          state: {
                            previous: this.props.location.pathname
                          }
                        }}
                      >
                        <h3>{entity.get("name")}</h3>
                      </Link>
                      {entityName === "configuration" && (
                        <p className="workspace">{entity.getIn(["workspace", "name"])}</p>
                      )}
                      {entityName === "workspace" && entity.getIn(["dawg", "id"]) > 0 && (
                        <p className="workspace">{entity.getIn(["dawg", "name"])}</p>
                      )}
                      <div className="user-and-date">
                        {projectJob.user.get("name")},{" "}
                        <DateTimeWithTooltip
                          dateTime={projectJob.created}
                          uniqueTooltipId={`conf-job-${projectJob.id}-date-tooltip`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-status">
                    <Link
                      to={{
                        pathname: jobPathName,
                        state: {
                          previous: this.props.location.pathname
                        }
                      }}
                      className="status-link"
                    >
                      <StatusElement
                        align="center"
                        status={projectJob.status}
                        history={
                          projectJob.stats ? projectJob.stats.get("statuses_history") : Map()
                        }
                        created={
                          projectJob.origin_created ? projectJob.origin_created : projectJob.created
                        }
                        showActualRunningDuration
                      />
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
      </section>
    )
  }
}

ProjectJobsCard.propTypes = {
  entityName: PropTypes.string.isRequired,
  projectJobs: PropTypes.instanceOf(List)
}

export default withRouter(ProjectJobsCard)
