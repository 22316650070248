import React, { Component } from "react"
import { Field } from "redux-form"
import TextField from "components/UI/elements/TextField"
import zxcvbn from "zxcvbn"

import {
  required,
  minLength12,
  passwordStrength as pwdStrengthValidate
} from "helpers/validators.helper"

import "./PasswordField.css"

class PasswordField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordStrength: 0
    }
  }
  /*
   * Evaluates password strength.
   *
   * @param string  password  password to be evaluated
   * @return int    strength  0 - no password, 1 - weak, 2 - Good, 3 - Strong, 4 - Very strong
   */
  _evaluatePasswordStrength = password => {
    const strength = zxcvbn(password).score
    if (password.length < 12 && strength > 2) {
      return 2
    }
    return strength
  }

  setPasswordStrength = password => {
    const strength = this._evaluatePasswordStrength(password)
    this.setState({
      passwordStrength: strength
    })
  }

  renderPasswordStrengthMessage = strength => {
    switch (strength) {
      case 1:
        return "Bad"
      case 2:
        return "Weak"
      case 3:
        return "Good"
      case 4:
        return "Strong"
      default:
        return ""
    }
  }

  onChangePassword = evt => {
    const password = evt.currentTarget.value

    this.setPasswordStrength(password)
  }

  render() {
    const { passwordStrength } = this.state
    const { passwordFieldLabel, autoFocus } = this.props

    return (
      <div>
        <Field
          name="password"
          component={TextField}
          label={passwordFieldLabel}
          validate={[required, minLength12, pwdStrengthValidate]}
          fullWidth={true}
          placeholder="Password"
          type="password"
          onChange={this.onChangePassword}
          autoFocus={autoFocus ? true : false}
          data-cy="password-field"
        />
        <div className={`password-strength strength-${passwordStrength}`}>
          <div className="bar" />
          <p>{this.renderPasswordStrengthMessage(passwordStrength)}</p>
        </div>
      </div>
    )
  }
}

export default PasswordField
