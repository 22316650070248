import { createSelector } from "reselect"

const getTrashedItems = state => state.trash.get("data")
export const getSelectionSettings = state => state.trash.get("selectionSettings")

export const getHasMoreItems = state => state.trash.get("hasMoreItems")

export const getTrashData = createSelector(
  [getTrashedItems, getSelectionSettings],
  (trashedItems, selectionSettings) => {
    if (selectionSettings === null) {
      return null
    } else {
      return trashedItems
    }
  }
)
