import React from "react"
import PropTypes from "prop-types"
import _noop from "lodash/noop"

import { renderTooltip } from "helpers/disabledTooltip.helper"

import "./ToggleSwitch.css"

const ToggleSwitch = ({
  name,
  leftValue,
  rightValue,
  leftText,
  rightText,
  checked,
  handleToggle,
  width,
  disabled,
  className,
  tooltipId = "toggle-tooltip",
  currentlyEditing = "",
  ...attrs
}) => (
  <div
    data-testid="toggle-switch"
    className={`toggle-switch ${disabled || currentlyEditing.length > 0 ? "disabled" : ""} ${
      className ? className : ""
    }`}
    style={{ width: width }}
    {...attrs}
  >
    <p
      data-testid="fieldset"
      className="fieldset"
      data-tip={currentlyEditing.length > 0}
      data-for={currentlyEditing.length > 0 ? tooltipId : false}
    >
      <input
        type="radio"
        name={name}
        value={leftValue}
        id={`${leftValue}-${name}`}
        checked={checked === leftValue}
        onChange={disabled === true || currentlyEditing.length > 0 ? _noop : handleToggle}
        data-testid="left-input"
      />
      <label htmlFor={`${leftValue}-${name}`}>{leftText ? leftText : leftValue}</label>
      <input
        type="radio"
        name={name}
        value={rightValue}
        id={`${rightValue}-${name}`}
        checked={checked === rightValue}
        onChange={disabled === true || currentlyEditing.length > 0 ? _noop : handleToggle}
        data-testid="right-input"
      />
      <label htmlFor={`${rightValue}-${name}`}>{rightText ? rightText : rightValue}</label>
      <span className="switch" />
    </p>
    {renderTooltip(tooltipId, currentlyEditing)}
  </div>
)

ToggleSwitch.propTypes = {
  width: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  checked: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipId: PropTypes.string,
  currentlyEditing: PropTypes.string
}

export default ToggleSwitch
