import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Record } from "immutable"
import _isNil from "lodash/isNil"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _includes from "lodash/includes"
import _findIndex from "lodash/findIndex"
import _map from "lodash/map"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Map } from "immutable"
import moment from "moment"
import { Waypoint } from "react-waypoint"
import Linkify from "react-linkify"
import { Link, withRouter } from "react-router-dom"
import ReactTooltip from "react-tooltip"

// actions
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { showToast } from "actions/toast.action"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import Duration from "components/UI/elements/Duration"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Button from "components/UI/elements/Button"
import JobsGanttChart from "./JobsGanttChart"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// models
import SelectionSettingsModel from "models/selectionSettings.model"
import { ConfigurationJobFull as ConfigurationJobModel } from "models/configurationJob.model"
import { WorkspaceJobFull as WorkspaceJobModel } from "models/workspaceJob.model"

// helpers
import { getRoutePath } from "routes"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { api } from "api"
import { formatBytes } from "helpers/dataUnits.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBackInHistory } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"
import { capitalize } from "helpers/string.helper"

// constants
import { MOMENT, LOG, INTERVAL, TOAST, MODAL } from "sharedConstants"

import "./JobShow.css"

class JobShow extends PureComponent {
  timelineFetcher = null
  childJobsFetcher = null

  constructor(props) {
    super(props)
    this.state = {
      timeline: null,
      logs: Map({
        isLoading: false,
        orderDir: "DESC",
        orderDirButtonEnabled: false
      }),
      childJobs: null,
      cancelModal: Map({
        open: false,
        isLoading: false
      })
    }

    const {
      entityName,
      match: {
        params: { id, aid }
      }
    } = props
    this.timelineFetcher = new AllResourceItemsFetcher()
      .setEndpointCall((offset, limit, loadFullStructure) =>
        entityName === "workspace"
          ? api().workspace.job.history.list(
              id,
              aid,
              offset,
              limit,
              loadFullStructure,
              "id",
              "DESC"
            )
          : api().dawg.job.history.list(id, aid, offset, limit, loadFullStructure, "id", "DESC")
      )
      .setDataPath("history_list")
      .setLoadFullStructure(0)

    this.childJobsFetcher = new AllResourceItemsFetcher()
      .setEndpointCall((offset, limit, loadFullStructure) =>
        entityName === "workspace"
          ? api().workspace.job.configurationJob.list(
              id,
              aid,
              offset,
              limit,
              loadFullStructure,
              "id",
              "ASC",
              1
            )
          : api().dawg.job.workspaceJob.list(
              id,
              aid,
              offset,
              limit,
              loadFullStructure,
              "id",
              "ASC",
              1
            )
      )
      .setDataPath(entityName === "workspace" ? "configuration_jobs" : "workspace_jobs")
      .setLoadFullStructure(1)

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      entity,
      entityName,
      entityJob,
      retrieveEntity,
      retrieveEntityJob,
      match: {
        params: { id, aid }
      }
    } = this.props

    if (_isNil(entity)) {
      retrieveEntity(id).catch(_noop)
    }

    if (_isNil(entityJob)) {
      retrieveEntityJob(id, aid, 0, true)
        .then(response => {
          if (
            _includes(
              ["waiting", "running"],
              _get(
                response,
                entityName === "workspace" ? "value.workspace_job.status" : "value.dawg_job.status"
              )
            )
          ) {
            this.intervalId = setInterval(this.refreshPageData, INTERVAL.DAWG_WS_JOB_REFRESH)
          } else {
            this.setState(prevState => ({
              logs: prevState.logs.set("orderDirButtonEnabled", true)
            }))
          }
        })
        .catch(_noop)
    } else {
      if (_includes(["waiting", "running"], entityJob.status)) {
        this.intervalId = setInterval(this.refreshPageData, INTERVAL.DAWG_WS_JOB_REFRESH)
      } else {
        this.setState(prevState => ({
          logs: prevState.logs.set("orderDirButtonEnabled", true)
        }))
      }
    }

    const timelinePromise = this.pendingPromises.create(this.fetchWholeTimeline(true))
    timelinePromise.promise
      .then(response => {
        this.setState({
          timeline: List(response)
        })
        this.pendingPromises.remove(timelinePromise)
      })
      .catch(() => {
        this.pendingPromises.remove(timelinePromise)
      })

    const childJobsPromise = this.pendingPromises.create(this.fetchEntityJobChildJobs(true))
    childJobsPromise.promise
      .then(response => {
        this.setState({
          childJobs: List(
            _map(response, job =>
              entityName === "workspace"
                ? new ConfigurationJobModel(job)
                : new WorkspaceJobModel(job)
            )
          )
        })
        this.pendingPromises.remove(childJobsPromise)
      })
      .catch(() => {
        this.pendingPromises.remove(childJobsPromise)
      })

    this.setState(prevState => ({
      logs: prevState.logs.set("isLoading", true)
    }))
    const jobLogsPromise = this.pendingPromises.create(
      this.fetchEntityJobLogs(0, LOG.LOADING_LIMIT, true)
    )
    jobLogsPromise.promise
      .then(response => {
        const ret = entityName === "workspace" ? response.workspace_logs : response.dawg_logs
        this.setState(prevState => ({
          logs: prevState.logs
            .set("data", List(ret))
            .set("selectionSettings", new SelectionSettingsModel(response.selection_settings))
            .set("hasMoreItems", ret.length === LOG.LOADING_LIMIT)
            .set("isLoading", false)
        }))
        this.pendingPromises.remove(jobLogsPromise)
      })
      .catch(() => {
        this.pendingPromises.remove(jobLogsPromise)
      })
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
  }

  fetchWholeTimeline = async (showLoading = false) => {
    const { showLoadingBar, hideLoadingBar } = this.props
    if (showLoading) {
      showLoadingBar()
    }
    const data = await this.timelineFetcher.run()
    if (showLoading) {
      hideLoadingBar()
    }
    return data
  }

  fetchEntityJobChildJobs = async (showLoading = false) => {
    const { showLoadingBar, hideLoadingBar } = this.props
    if (showLoading) {
      showLoadingBar()
    }
    const data = await this.childJobsFetcher.run()
    if (showLoading) {
      hideLoadingBar()
    }
    return data
  }

  fetchEntityJobLogs = async (offset, limit, showLoading = false, orderDir = null) => {
    const {
      showLoadingBar,
      hideLoadingBar,
      entityName,
      match: {
        params: { id, aid }
      }
    } = this.props
    const { logs } = this.state
    if (showLoading) {
      showLoadingBar()
    }

    let response
    if (entityName === "workspace") {
      response = await api()
        .workspace.job.log.list(
          id,
          aid,
          offset,
          limit,
          0,
          "id",
          orderDir ? orderDir : logs.get("orderDir")
        )
        .catch(_noop)
    } else {
      response = await api()
        .dawg.job.log.list(
          id,
          aid,
          offset,
          limit,
          0,
          "id",
          orderDir ? orderDir : logs.get("orderDir")
        )
        .catch(_noop)
    }
    if (showLoading) {
      hideLoadingBar()
    }
    return response
  }

  refreshPageData = () => {
    const {
      retrieveEntityJob,
      entityName,
      match: {
        params: { id, aid }
      }
    } = this.props

    if (!this.state.logs.get("isLoading")) {
      retrieveEntityJob(id, aid, 0, false)
        .then(response => {
          const retJob =
            entityName === "workspace"
              ? _get(response, "payload.workspace_job")
              : _get(response, "payload.dawg_job")
          const timelinePromise = this.pendingPromises.create(this.fetchWholeTimeline(false))
          timelinePromise.promise
            .then(response => {
              this.setState({
                timeline: List(response)
              })
              this.pendingPromises.remove(timelinePromise)
            })
            .catch(() => {
              this.pendingPromises.remove(timelinePromise)
            })

          const childJobsPromise = this.pendingPromises.create(this.fetchEntityJobChildJobs(false))
          childJobsPromise.promise
            .then(response => {
              this.setState({
                childJobs: List(
                  _map(response, job =>
                    entityName === "workspace"
                      ? new ConfigurationJobModel(job)
                      : new WorkspaceJobModel(job)
                  )
                )
              })
              this.pendingPromises.remove(childJobsPromise)
            })
            .catch(() => {
              this.pendingPromises.remove(childJobsPromise)
            })

          this.refreshLogsData()

          if (!_includes(["waiting", "running"], _get(retJob, "status"))) {
            clearInterval(this.intervalId)
            this.setState(prevState => ({
              logs: prevState.logs.set("orderDirButtonEnabled", true)
            }))
            return
          }
        })
        .catch(_noop)
    }
  }

  refreshLogsData = () => {
    const { entityName } = this.props
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading")
      }))
    }

    const jobLogsPromise = this.pendingPromises.create(this.fetchEntityJobLogs(0, 10, false))
    jobLogsPromise.promise
      .then(response => {
        const retLogs = entityName === "workspace" ? response.workspace_logs : response.dawg_logs
        const lastLogItem = this.state.logs.getIn(["data", 0])
        if (lastLogItem) {
          const index = _findIndex(retLogs, {
            id: lastLogItem.id
          })
          if (index === -1) {
            this.setState(prevState => ({
              logs: prevState.logs
                .set("data", prevState.logs.get("data").unshift(...retLogs))
                .set(
                  "selectionSettings",
                  prevState.logs
                    .get("selectionSettings")
                    .set(
                      "offset",
                      prevState.logs.getIn(["selectionSettings", "offset"]) + retLogs.length
                    )
                )
                .set("isLoading", false)
            }))
          } else {
            this.setState(prevState => ({
              logs: prevState.logs
                .set("data", prevState.logs.get("data").unshift(...retLogs.slice(0, index)))
                .set(
                  "selectionSettings",
                  prevState.logs
                    .get("selectionSettings")
                    .set("offset", prevState.logs.getIn(["selectionSettings", "offset"]) + index)
                )
                .set("isLoading", false)
            }))
          }
        } else {
          this.setState(prevState => ({
            logs: prevState.logs
              .set("data", List(retLogs))
              .set(
                "selectionSettings",
                prevState.logs
                  .get("selectionSettings")
                  .set(
                    "offset",
                    prevState.logs.getIn(["selectionSettings", "offset"]) + retLogs.length
                  )
              )
              .set("isLoading", false)
          }))
        }
        this.pendingPromises.remove(jobLogsPromise)
      })
      .catch(err => {
        if (!_get(err, "isCanceled")) {
          clearInterval(this.intervalId)
          this.props.showToast(
            "Auto-refresh failed because of network connection, please refresh the page.",
            TOAST.TYPE.ERROR
          )
        }
        this.pendingPromises.remove(jobLogsPromise)
      })
  }

  loadMoreLogs = () => {
    const { entityName } = this.props
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading", true)
      }))

      const { logs } = this.state
      const jobLogsPromise = this.pendingPromises.create(
        this.fetchEntityJobLogs(
          logs.getIn(["selectionSettings", "offset"]) + logs.getIn(["selectionSettings", "limit"]),
          LOG.LOADING_LIMIT,
          true
        )
      )
      jobLogsPromise.promise
        .then(response => {
          const retLogs = entityName === "workspace" ? response.workspace_logs : response.dawg_logs
          const lastLogItem = logs.get("data").last()
          const index = _findIndex(retLogs, {
            id: lastLogItem.id
          })

          this.setState(prevState => ({
            logs: prevState.logs
              .set(
                "data",
                prevState.logs.get("data").concat(retLogs.slice(index + 1, retLogs.length))
              )
              .set(
                "selectionSettings",
                new SelectionSettingsModel({
                  ...response.selection_settings,
                  offset: response.selection_settings.offset + index + 1
                })
              )
              .set("hasMoreItems", retLogs.length === LOG.LOADING_LIMIT)
              .set("isLoading", false)
          }))
          this.pendingPromises.remove(jobLogsPromise)
        })
        .catch(() => {
          this.pendingPromises.remove(jobLogsPromise)
        })
    }
  }

  renderWaypoint = () => {
    const { logs } = this.state

    if (logs.get("hasMoreItems") && !logs.get("isLoading")) {
      return <Waypoint onEnter={this.loadMoreLogs} bottomOffset={-250} />
    }
  }

  toggleCancelModal = () => {
    this.setState(prevState => ({
      cancelModal: prevState.cancelModal
        .set("open", !prevState.cancelModal.get("open"))
        .set("isLoading", false)
    }))
  }

  confirmCancelModal = () => {
    if (!this.state.cancelModal.get("isLoading")) {
      const {
        cancelEntityJob,
        showToast,
        match: {
          params: { id, aid }
        }
      } = this.props

      this.setState(prevState => ({
        cancelModal: prevState.cancelModal.set("isLoading", true)
      }))

      const cancelPromise = this.pendingPromises.create(cancelEntityJob(id, aid))
      cancelPromise.promise
        .then(() => {
          this.setState(prevState => ({
            cancelModal: prevState.cancelModal.set("open", false).set("isLoading", false)
          }))
          showToast("Activity is being been cancelled.", TOAST.TYPE.SUCCESS)
          this.pendingPromises.remove(cancelPromise)
        })
        .catch(err => {
          if (!_get(err, "isCanceled")) {
            this.setState(prevState => ({
              cancelModal: prevState.cancelModal.set("isLoading", false)
            }))
          }
          this.pendingPromises.remove(cancelPromise)
        })
    }
  }

  reverseLogsOrder = () => {
    const { entityName } = this.props
    const { logs } = this.state
    this.setState({
      logs: logs.set("isLoading", true)
    })
    const jobLogsPromise = this.pendingPromises.create(
      this.fetchEntityJobLogs(
        0,
        LOG.LOADING_LIMIT,
        true,
        logs.get("orderDir") === "ASC" ? "DESC" : "ASC"
      )
    )
    jobLogsPromise.promise
      .then(response => {
        const retLogs = entityName === "workspace" ? response.workspace_logs : response.dawg_logs
        this.setState(prevState => ({
          logs: prevState.logs
            .set("data", List(retLogs))
            .set("selectionSettings", new SelectionSettingsModel(response.selection_settings))
            .set("isLoading", false)
            .set("hasMoreItems", retLogs.length === LOG.LOADING_LIMIT)
            .set("orderDir", prevState.logs.get("orderDir") === "ASC" ? "DESC" : "ASC")
        }))
        this.pendingPromises.remove(jobLogsPromise)
      })
      .catch(() => {
        this.pendingPromises.remove(jobLogsPromise)
      })
  }

  render() {
    const {
      entityJob,
      entityName,
      usersAcl,
      history,
      match: {
        params: { id, aid }
      }
    } = this.props
    const { timeline, logs, cancelModal, childJobs } = this.state

    const stats = entityJob ? entityJob.stats : null
    const isEditable = hasWritePermission(usersAcl)

    return (
      <section className="job-detail-page">
        <div className="job-timeline-stats">
          <div className="job-detail-timeline">
            <PaperHeader size="small" className="job-timeline-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBackInHistory(
                    history,
                    getRoutePath(
                      entityName === "workspace"
                        ? "workspace.workspaceJob.list"
                        : "dawg.dawgJob.list",
                      { id }
                    )
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Timeline</h3>
              </div>
              <Button
                size="small"
                color="red"
                onClick={this.toggleCancelModal}
                disabled={
                  !_includes(["waiting", "running"], _get(entityJob, "status")) || !isEditable
                }
              >
                <FontAwesomeIcon icon={["fas", "times"]} className="icon" /> Cancel
              </Button>
            </PaperHeader>
            {List.isList(timeline) && (
              <Paper hasHeader={true} className="job-detail-timeline-content">
                <div className="table table-content-clickable-row">
                  <div className="thead">
                    <div className="table-row">
                      <div className="table-head hover-overlap-helper">&nbsp;</div>
                      <div className="table-head status">Status</div>
                      <div className="table-head">Modified</div>
                      <div className="table-head align-right">User</div>
                      <div className="table-head hover-overlap-helper">&nbsp;</div>
                    </div>
                  </div>
                  <div className="tbody">
                    {timeline.map(historyItem => (
                      <Link
                        key={historyItem.id}
                        className="table-row"
                        to={{
                          pathname: getRoutePath(
                            entityName === "workspace"
                              ? "workspace.workspaceJob.show.history"
                              : "dawg.dawgJob.show.history",
                            {
                              id,
                              aid,
                              hid: historyItem.id
                            }
                          ),
                          state: {
                            historyItem,
                            previous: this.props.location.pathname
                          }
                        }}
                      >
                        <div className="table-cell hover-overlap-helper">&nbsp;</div>
                        <div className="table-cell">
                          <StatusElement
                            align="left"
                            status={_get(
                              historyItem,
                              entityName === "workspace"
                                ? "workspace_job.status"
                                : "dawg_job.status"
                            )}
                            showDuration={false}
                          />
                        </div>
                        <div className="table-cell">
                          <DateTimeWithTooltip
                            dateTime={_get(
                              historyItem,
                              entityName === "workspace"
                                ? "workspace_job.created"
                                : "dawg_job.created"
                            )}
                            uniqueTooltipId={`history-item-${historyItem.id}-tooltip`}
                          />
                        </div>
                        <div className="table-cell align-right">
                          <Username
                            userId={_get(
                              historyItem,
                              entityName === "workspace"
                                ? "workspace_job.user_id"
                                : "dawg_job.user_id",
                              ""
                            )}
                          />
                        </div>
                        <div className="table-cell hover-overlap-helper">&nbsp;</div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Paper>
            )}
          </div>
          <div className="job-detail-stats">
            <PaperHeader size="small" className="job-stats-header">
              <h3>Stats</h3>
            </PaperHeader>
            {entityJob && !_isNil(stats) && (
              <Paper className="job-stats-content">
                <table className="table stats-table">
                  <tbody>
                    <tr>
                      <td className="head-cell">Data volumes</td>
                      <td>
                        <span>In:</span> {formatBytes(stats.getIn(["data_volumes", "/in"]))}
                      </td>
                      <td>
                        <span>Out:</span> {formatBytes(stats.getIn(["data_volumes", "/out"]))}
                      </td>
                    </tr>
                    <tr>
                      <td className="head-cell">Files count</td>
                      <td>
                        <span>In:</span> {stats.getIn(["files_count", "/in"])}
                      </td>
                      <td>
                        <span>Out:</span> {stats.getIn(["files_count", "/out"])}
                      </td>
                    </tr>
                    <tr>
                      <td className="head-cell">Duration</td>
                      <td colSpan="2">
                        <Duration
                          status={entityJob.status}
                          history={
                            Map.isMap(stats.get("statuses_history"))
                              ? stats.get("statuses_history")
                              : Map()
                          }
                          created={
                            entityJob.origin_created ? entityJob.origin_created : entityJob.created
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            )}
          </div>
        </div>
        <PaperHeader className="configuration-jobs-header" size="small">
          <h3>{entityName === "workspace" ? "Configuration" : "Workspace"} Jobs</h3>
        </PaperHeader>
        {List.isList(childJobs) && childJobs.size > 0 && (
          <Paper hasHeader={true} className="configuration-jobs-content">
            <JobsGanttChart rootEntityName={entityName} jobs={childJobs} />
          </Paper>
        )}
        <PaperHeader
          className="job-logs-header"
          size="small"
          titleText="Logs"
          subTitleText={`${capitalize(entityName)} logs are archived after 30 days`}
        >
          <span data-tip data-for="disabled-reverse-button">
            <Button
              color="white"
              size="small"
              className={`reverse-button ${logs.get("orderDir")}`}
              disabled={!logs.get("orderDirButtonEnabled")}
              onClick={this.reverseLogsOrder}
            >
              <FontAwesomeIcon icon={["fas", "sort-alt"]} className="icon" /> Reverse order
            </Button>
            {!logs.get("orderDirButtonEnabled") && (
              <ReactTooltip id="disabled-reverse-button" place="left">
                Logs order can't be reversed while job is still running.
              </ReactTooltip>
            )}
          </span>
        </PaperHeader>
        {List.isList(logs.get("data")) && logs.get("data").size > 0 && (
          <Paper hasHeader={true} className="job-logs-content">
            <React.Fragment>
              <table className="table logs-table">
                <thead>
                  <tr>
                    <th className="level">Level</th>
                    <th className="time">Time</th>
                    <th className="text">Text</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.get("data").map(row => (
                    <tr key={row.id}>
                      <td className={`level ${row.level}`}>{row.level}</td>
                      <td className={`time ${row.level}`}>
                        {moment
                          .utc(row.created)
                          .local()
                          .format(MOMENT.DATE_TIME_WITH_SECONDS)}
                      </td>
                      <td className={`text ${row.level}`}>
                        <Linkify properties={{ target: "_blank" }}>{row.text}</Linkify>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {this.renderWaypoint()}
            </React.Fragment>
          </Paper>
        )}
        <ConfirmModal
          open={cancelModal.get("open")}
          type={MODAL.TYPE.CANCEL}
          handleClose={this.toggleCancelModal}
          handleConfirm={this.confirmCancelModal}
          title="Cancel activity"
          action="cancel"
          what="activity"
          isLoading={cancelModal.get("isLoading")}
        />
      </section>
    )
  }
}

JobShow.propTypes = {
  entityName: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Record),
  entityJob: PropTypes.instanceOf(Record),
  retrieveEntity: PropTypes.func.isRequired,
  retrieveEntityJob: PropTypes.func.isRequired,
  cancelEntityJob: PropTypes.func.isRequired,
  usersAcl: PropTypes.instanceOf(Record)
}

export default withRouter(connect(null, { showLoadingBar, hideLoadingBar, showToast })(JobShow))
