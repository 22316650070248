import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Record, Map } from "immutable"
import moment from "moment"
import _isNil from "lodash/isNil"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import StatusElement from "components/UI/elements/StatusElement"

// helpers
import { getRoutePath } from "routes"
import { formatBytes } from "helpers/dataUnits.helper"
import { goBack } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"
import { getComponentIconSrc } from "helpers/component.helper"

// constants
import { MOMENT } from "sharedConstants"

import "./EntityChildrenJobsList.css"
import { capitalize } from "helpers/string.helper"

const LOADING_LIMIT = 20

class EntityChildrenJobsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadingMore: false
    }
  }

  async componentDidMount() {
    const {
      selectionSettings,
      match: {
        params: { id: entityId }
      }
    } = this.props
    try {
      const limit =
        selectionSettings && selectionSettings.limit > 20 ? selectionSettings.limit : LOADING_LIMIT
      await this.props.listEntityChildrenJobs(entityId, 0, limit, 1, "status", "DESC")
      this.intervalId = setInterval(this.refreshEntityChildrenJobs, 8000)
    } catch (e) {}
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  refreshEntityChildrenJobs = async () => {
    const {
      listEntityChildrenJobs,
      selectionSettings,
      match: {
        params: { id: entityId }
      }
    } = this.props
    if (!this.state.loadingMore) {
      try {
        await listEntityChildrenJobs(
          entityId,
          selectionSettings.offset,
          selectionSettings.limit,
          1,
          selectionSettings.order_by,
          selectionSettings.order_dir,
          false
        )
      } catch (e) {}
    }
  }

  renderDataCell = (bytes, files) => {
    if (_isNil(bytes)) {
      return "–"
    }

    return (
      <span className="data-text">
        {formatBytes(bytes)}{" "}
        <span>
          in {files} {files === 1 ? "file" : "files"}
        </span>
      </span>
    )
  }

  renderLoadMoreButton = () => {
    const { selectionSettings, jobs } = this.props
    const { loadingMore } = this.state
    if (List.isList(jobs)) {
      if (
        selectionSettings.limit < 100 &&
        jobs.size < 100 &&
        jobs.size === selectionSettings.limit
      ) {
        return (
          <Button
            size="small"
            color="white"
            className={`load-more-button ${loadingMore ? "loading" : ""}`}
            onClick={this.loadMoreJobs}
          >
            Load more
          </Button>
        )
      }
    }
  }

  loadMoreJobs = async () => {
    const {
      selectionSettings,
      refreshEntityChildrenJobs,
      match: {
        params: { id: entityId }
      }
    } = this.props
    this.setState({
      loadingMore: true
    })
    try {
      await refreshEntityChildrenJobs(
        entityId,
        0,
        selectionSettings.limit + LOADING_LIMIT,
        1,
        "status",
        "DESC"
      )
    } catch (e) {
    } finally {
      this.setState({ loadingMore: false })
    }
  }

  render() {
    const {
      jobs,
      history,
      rootEntityName,
      match: {
        params: { id: entityId }
      }
    } = this.props

    return (
      <section className="job-list-page entity-children-jobs">
        {List.isList(jobs) && (
          <React.Fragment>
            <PaperHeader size="small" className="job-list-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(
                    history,
                    getRoutePath(rootEntityName === "workspace" ? "workspace.show" : "dawg.show", {
                      id: entityId
                    })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>
                  {capitalize(rootEntityName)}{" "}
                  {rootEntityName === "dawg" ? "workspace" : "configuration"} activity
                </h3>
              </div>
            </PaperHeader>
            <Paper hasHeader className="job-list-content">
              {jobs.size > 0 && (
                <React.Fragment>
                  <div className="table table-content-clickable-row">
                    <div className="thead">
                      <div className="table-row">
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                        <div className="table-head name">Name</div>
                        <div className="table-head status">Status</div>
                        <div className="table-head">Modified</div>
                        <div className="table-head">Data in</div>
                        <div className="table-head">Data out</div>
                        <div className="table-head align-right">User</div>
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {jobs.map(job => {
                        const pathname =
                          rootEntityName === "workspace"
                            ? "workspace.configuration.configurationJob.show"
                            : "workspace.workspaceJob.show"
                        const pathParams =
                          rootEntityName === "workspace"
                            ? {
                                id: job.getIn(["configuration", "workspace", "id"]),
                                cid: job.getIn(["configuration", "id"]),
                                aid: job.id
                              }
                            : {
                                id: job.getIn(["workspace", "id"]),
                                aid: job.id
                              }
                        return (
                          <Link
                            to={{
                              pathname: getRoutePath(pathname, pathParams),
                              state: {
                                previous: this.props.location.pathname
                              }
                            }}
                            className="table-row"
                            key={job.id}
                          >
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                            <div className="table-cell">
                              <div className="children-name">
                                <img
                                  src={getComponentIconSrc(
                                    rootEntityName === "workspace"
                                      ? job.getIn(["configuration", "component", "icon"])
                                      : "workspace.png"
                                  )}
                                  alt="component icon"
                                />
                                <div className="conf-ws-name-wrapper">
                                  <span className="conf-name">
                                    {rootEntityName === "workspace"
                                      ? job.getIn(["configuration", "name"])
                                      : job.getIn(["workspace", "name"])}
                                  </span>
                                  <span className="ws-name">
                                    {rootEntityName === "workspace"
                                      ? job.getIn(["configuration", "component", "name"])
                                      : "Workspace"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="table-cell">
                              <StatusElement
                                align="left"
                                status={job.status}
                                history={job.stats ? job.stats.get("statuses_history") : Map()}
                                created={job.origin_created ? job.origin_created : job.created}
                                showActualRunningDuration
                                durationOneLine
                              />
                            </div>
                            <div className="table-cell">
                              {moment
                                .utc(job.created)
                                .local()
                                .format(MOMENT.DATE_TIME_FORMAT)}
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/in"]),
                                job.getIn(["stats", "files_count", "/in"])
                              )}
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/out"]),
                                job.getIn(["stats", "files_count", "/out"])
                              )}
                            </div>
                            <div className="table-cell align-right">
                              <Username userId={job.user.get("id")} />
                            </div>
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </React.Fragment>
              )}
              {jobs.size === 0 && (
                <p className="no-jobs-message">
                  The {rootEntityName} doesn't have an activity history yet.
                </p>
              )}
            </Paper>
            {this.renderLoadMoreButton()}
          </React.Fragment>
        )}
      </section>
    )
  }
}

EntityChildrenJobsList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  listEntityChildrenJobs: PropTypes.func.isRequired
}

export default withRouter(EntityChildrenJobsList)
