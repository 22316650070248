import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2"
import { List } from "immutable"
import _includes from "lodash/includes"
import _round from "lodash/round"
import moment from "moment"

// constants, helpers
import { MOMENT } from "sharedConstants"
import { formatBytes } from "helpers/dataUnits.helper"

const epoch_to_hh_mm_ss = epoch => {
  console.log(epoch)
  return new Date(epoch * 1000).toISOString().substr(12, 7)
}

const jobsChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  hoverMode: "index",
  stacked: false,
  title: {
    display: false
  },
  legend: {
    position: "left",
    align: "center",
    labels: {
      usePointStyle: false,
      padding: 15,
      fontStyle: "bold",
      fontSize: 12,
      color: "#999999"
    }
  },
  layout: {
    padding: {
      bottom: 10
    }
  },
  scales: {
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        ticks: {
          userCallback: val => {
            const value = val < 0 ? 0 : _round(val)
            return epoch_to_hh_mm_ss(value)
          },
          fontSize: 10
        },
        gridLines: {
          color: "rgba(196,196,196,0.5)"
        }
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        ticks: {
          userCallback: val => {
            const value = val < 0 ? 0 : _round(val)
            return formatBytes(value)
          },
          fontSize: 10
        },
        // grid line settings
        gridLines: {
          drawOnChartArea: false,
          color: "rgba(196,196,196,0.5)"
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 10
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        switch (tooltipItem.datasetIndex) {
          case 0: {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${epoch_to_hh_mm_ss(
              tooltipItem.yLabel
            )}`
          }
          case 1: {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${formatBytes(
              tooltipItem.yLabel
            )}`
          }
          default: {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}`
          }
        }
      }
    }
  }
}

class JobsLineChart extends PureComponent {
  constructChartData = jobs => {
    if (List.isList(jobs) && jobs.size > 2) {
      let labels = []
      let durationData = []
      let volumeData = []
      let pointBackgroundColors = []
      for (let i = jobs.size - 1; i >= 0; i--) {
        const job = jobs.get(i)
        if (!_includes(["waiting", "running"], job.status)) {
          // x label
          labels.push(
            moment
              .utc(job.created)
              .local()
              .format(MOMENT.DATE_TIME_FORMAT)
          )

          // duration in seconds
          let duration = 0
          if (job.hasIn(["stats", "statuses_history"])) {
            const seconds = moment
              .duration(
                moment(job.getIn(["stats", "statuses_history", job.status])).diff(
                  moment(job.getIn(["stats", "statuses_history", "waiting"]))
                )
              )
              .asSeconds()
            duration = Math.round(seconds)
          }
          durationData.push(duration)

          // sum of volume
          let volume = 0
          if (
            job.hasIn(["stats", "data_volumes", "/in"]) &&
            job.hasIn(["stats", "data_volumes", "/out"])
          ) {
            volume =
              job.getIn(["stats", "data_volumes", "/in"]) +
              job.getIn(["stats", "data_volumes", "/out"])
          }
          volumeData.push(volume)

          // color of points
          switch (job.status) {
            case "finished": {
              pointBackgroundColors.push("#3aa545")
              break
            }
            case "error": {
              pointBackgroundColors.push("#ed382a")
              break
            }
            case "canceled": {
              pointBackgroundColors.push("#494a4a")
              break
            }
            case "waiting":
            case "warning": {
              pointBackgroundColors.push("#fabe53")
              break
            }
            case "running": {
              pointBackgroundColors.push("#229ace")
              break
            }
            default: {
              pointBackgroundColors.push("#999999")
              break
            }
          }
        }
      }
      return {
        labels: labels,
        datasets: [
          {
            label: "Duration",
            borderColor: "#8855DB",
            backgroundColor: "rgba(136,85,219, 0.5)",
            fill: true,
            data: durationData,
            pointBackgroundColor: pointBackgroundColors,
            yAxisID: "y-axis-1",
            pointRadius: 4,
            pointHoverRadius: 5
          },
          {
            label: "Data Volume",
            borderColor: "#5FC4BE",
            backgroundColor: "rgba(95,196,190, 0.5)",
            fill: true,
            data: volumeData,
            pointBackgroundColor: pointBackgroundColors,
            yAxisID: "y-axis-2",
            pointRadius: 4,
            pointHoverRadius: 5
          }
        ]
      }
    }
  }

  render() {
    const chartData = this.constructChartData(this.props.jobs)
    if (!chartData) {
      return null
    }
    return <Line data={chartData} options={jobsChartOptions} />
  }
}

JobsLineChart.propTypes = {
  jobs: PropTypes.instanceOf(List).isRequired
}

export default JobsLineChart
