import { DAWG } from "sharedConstants"
import DawgJobModel from "models/dawgJob.model"
import SelectionSettingsModel from "models/selectionSettings.model"
import { Map, List, fromJS } from "immutable"

/*
 * State need to be erased when user leaves the dawg -> visits dawg
 * list page.
 *
 * state example:
 * Map({
 *   data: List([ DawgJobModel ]),
 *   hasMoreItems: true/false,
 *   selectionSettings: SelectionSettingsModel(),
 *   isFetching: false
 * })
 */

const initialState = null

export default (state = initialState, { type, payload }) => {
  const addNewJobToExistingList = () => {
    const stateTemp = state
      .set("data", state.get("data").unshift(new DawgJobModel(fromJS(payload.dawg_job))))
      .set("isFetching", false)

    if (state.get("hasMoreItems")) {
      return stateTemp.setIn(
        ["selectionSettings", "offset"],
        stateTemp.getIn(["selectionSettings", "offset"]) + 1
      )
    }
    return stateTemp
  }

  switch (type) {
    case `${DAWG.JOB.ACTION.LIST}_PENDING`:
    case `${DAWG.JOB.ACTION.RUN}_PENDING`:
    case `${DAWG.JOB.ACTION.RETRIEVE}_PENDING`:
    case `${DAWG.JOB.ACTION.CANCEL}_PENDING`:
      if (Map.isMap(state)) {
        return state.set("isFetching", true)
      } else {
        return Map({ isFetching: true })
      }

    case `${DAWG.JOB.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        // initial load
        return Map({
          selectionSettings: new SelectionSettingsModel(payload.selection_settings),
          hasMoreItems: payload.dawg_jobs.length === payload.selection_settings.limit,
          data: List(payload.dawg_jobs.map(job => new DawgJobModel(fromJS(job)))),
          isFetching: false
        })
      } else {
        // concat
        return state
          .set("selectionSettings", new SelectionSettingsModel(payload.selection_settings))
          .set("hasMoreItems", payload.dawg_jobs.length === payload.selection_settings.limit)
          .set(
            "data",
            state.get("data").concat(payload.dawg_jobs.map(job => new DawgJobModel(fromJS(job))))
          )
          .set("isFetching", false)
      }

    case DAWG.JOB.ACTION.REFRESH_LAST:
      if (payload.dawg_job) {
        if (state === null) {
          return Map({
            selectionSettings: new SelectionSettingsModel({
              offset: 0,
              limit: 20
            }),
            hasMoreItems: false,
            data: List([new DawgJobModel(fromJS(payload.dawg_job))])
          })
        } else {
          const lastSavedJob = state.getIn(["data", 0])
          if (lastSavedJob) {
            /*
             * Now three situations can occurs:
             * a) jobs are completely same (same -> they have same id & status) => do nothing
             * b) jobs are same, but have different statuses => replace the job
             * c) payload job is completely new => add to redux state on first position
             */
            if (
              payload.dawg_job.id === lastSavedJob.id &&
              payload.dawg_job.status !== lastSavedJob.status
            ) {
              // case b)
              return state.setIn(["data", 0], new DawgJobModel(fromJS(payload.dawg_job)))
            }
            if (payload.dawg_job.id !== lastSavedJob.id) {
              // case c)
              return addNewJobToExistingList()
            }
            // case a)
            return state
          } else {
            return state.set("data", List([new DawgJobModel(fromJS(payload.dawg_job))]))
          }
        }
      }
      return state

    case `${DAWG.JOB.ACTION.RUN}_FULFILLED`:
      return addNewJobToExistingList()

    case DAWG.JOB.ACTION.CLEAR_CACHE:
      return initialState

    case `${DAWG.JOB.ACTION.RETRIEVE}_FULFILLED`:
    case DAWG.JOB.ACTION.RETRIEVE:
      if (state === null || !List.isList(state.get("data"))) {
        return Map({
          selectionSettings: new SelectionSettingsModel({
            offset: 0,
            limit: 20
          }),
          hasMoreItems: false,
          data: List([new DawgJobModel(fromJS(payload.dawg_job))]),
          isFetching: false
        })
      } else {
        const jobIndex = state.get("data").findIndex(job => job.id === payload.dawg_job.id)
        if (jobIndex !== -1) {
          return state
            .setIn(["data", jobIndex], new DawgJobModel(fromJS(payload.dawg_job)))
            .set("isFetching", false)
        }
        return addNewJobToExistingList()
      }

    case `${DAWG.JOB.ACTION.CANCEL}_FULFILLED`:
      const jobIndex = state.get("data").findIndex(job => job.id === payload.dawg_job.id)
      return state
        .setIn(["data", jobIndex], new DawgJobModel(fromJS(payload.dawg_job)))
        .set("isFetching", false)

    default:
      return state
  }
}
