import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Map } from "immutable"
import _noop from "lodash/noop"
import _isNil from "lodash/isNil"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import AceEditor from "react-ace"

import { Form, reduxForm, Field, FieldArray, formValueSelector } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import SelectField from "components/UI/elements/SelectField"

import "./VariablesInjection.css"
import { optionRequired } from "helpers/validators.helper"

class VariablesInjection extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isAuthorized: this._isAuthorized()
    }
  }

  _isAuthorized = () => {
    const { authLink, oAuthSettings } = this.props
    if (!authLink) {
      return true
    } else {
      if (Map.isMap(oAuthSettings) && !_isNil(oAuthSettings.get("token_request_response"))) {
        return true
      }
      return false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.oAuthSettings !== this.props.oAuthSettings) {
      this.setState({
        isAuthorized: this._isAuthorized()
      })
    }
    if (!prevProps.editMode && this.props.editMode && this.state.loading) {
      this.setState({
        loading: false
      })
    }
  }

  cancelVariablesEditing = () => {
    this.toggleEditMode()
    this.props.reset()
  }

  saveVariablesValues = async values => {
    if (!this.state.loading) {
      this.setState({ loading: true })

      const { settings, onInjectionSave } = this.props
      const variables = {}
      if (values.variables.length) {
        values.variables.forEach(item => {
          variables[item.value] = `{{${item.value}}}`
        })
      }

      let settingsToStore = null
      if (_isEmpty(variables)) {
        settingsToStore = settings.deleteIn(["parameters", "vars"])
      } else {
        settingsToStore = settings.setIn(["parameters", "vars"], Map(variables))
      }

      try {
        await onInjectionSave({ settings: settingsToStore.toJS() })
        this.toggleEditMode()
      } catch (err) {
        if (_get(err, "type") === "nothing_changed") {
          this.toggleEditMode()
        } else {
          this.setState({
            loading: false
          })
        }
      }
    }
  }

  toggleEditMode = () => {
    this.props.toggleEditMode()()
  }

  renderVariables = ({ fields, meta: { error } }) => {
    const { variables, selectedVars } = this.props
    const selectedVarsKeys = selectedVars.map(item => item.value)
    const fieldOptions = [
      ...variables.map((val, key) => ({ value: key, label: key })).values()
    ].filter(item => !selectedVarsKeys.includes(item.value))

    return (
      <React.Fragment>
        <p className="label">Workspace variables:</p>
        <div className="variables">
          {fields.map((member, index) => {
            return (
              <div key={index} className="row">
                <Field
                  name={member}
                  component={SelectField}
                  label="Workspace variable"
                  options={fieldOptions}
                  className="variable-select-field"
                  isSearchable={false}
                  validate={optionRequired}
                  maxDropdownHeight="180px"
                />
                <IconButton color="red" onClick={() => fields.remove(index)}>
                  <FontAwesomeIcon icon={["far", "trash-alt"]} />
                </IconButton>
              </div>
            )
          })}
        </div>
        <Button
          color="transparent-primary"
          size="small"
          type="button"
          onClick={() => fields.push({})}
          className="add-more"
        >
          + Add more
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const {
      editMode,
      isEditable,
      currentlyEditing,
      readyToEdit,
      settings,
      handleSubmit
    } = this.props
    const { loading, isAuthorized } = this.state

    if (!isAuthorized) {
      return null
    }

    return (
      <Form onSubmit={handleSubmit(this.saveVariablesValues)}>
        <PaperHeader
          titleText="Workspace variables injection"
          subTitleText="Variables will be injected into 'parameters.vars' object."
          size="small"
          className="variables-injection-header"
        >
          {editMode && (
            <div className="buttons">
              <Button
                className="cancel-button"
                color="link"
                onClick={this.cancelVariablesEditing}
                size="small"
                type="button"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                onClick={this.saveVariableValues}
                className={loading ? "loading" : ""}
                type="submit"
              >
                Save
              </Button>
            </div>
          )}
          {!editMode && (
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={readyToEdit ? this.toggleEditMode : _noop}
              disabled={!isEditable}
              currentlyEditing={currentlyEditing}
              tooltipId="edit-conf-variables-tooltip-id"
              className={!readyToEdit ? "cursor-wait" : ""}
            >
              Edit
            </Button>
          )}
        </PaperHeader>
        {!editMode &&
          Map.isMap(settings) &&
          Map.isMap(settings.getIn(["parameters", "vars"])) &&
          settings.getIn(["parameters", "vars"]).size > 0 && (
            <Paper className="variables-injection-list">
              <div className="ace-editor-wrapper">
                <AceEditor
                  mode="json"
                  theme="meiro"
                  name="variables-injection"
                  value={JSON.stringify(
                    { vars: settings.getIn(["parameters", "vars"]).toJS() },
                    null,
                    2
                  )}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{ maxLines: 50 }}
                  width="100%"
                  readOnly={true}
                  wrapEnabled={true}
                />
              </div>
            </Paper>
          )}
        {editMode && (
          <Paper className="variables-injection-body">
            <FieldArray name="variables" component={this.renderVariables} />
          </Paper>
        )}
      </Form>
    )
  }
}

VariablesInjection.defaultProps = {
  readyToEdit: true
}

VariablesInjection.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  settings: PropTypes.instanceOf(Map),
  onInjectionSave: PropTypes.func.isRequired,
  authLink: PropTypes.string,
  oAuthSettings: PropTypes.instanceOf(Map),
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool,
  variables: PropTypes.instanceOf(Map).isRequired,
  selectedVars: PropTypes.array
}

const selector = formValueSelector("VariablesInjectionForm")

VariablesInjection = connect(state => ({
  selectedVars: selector(state, "variables")
}))(VariablesInjection)

export default reduxForm({
  form: "VariablesInjectionForm",
  touchOnBlur: false,
  enableReinitialize: true
})(VariablesInjection)
