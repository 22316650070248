import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers
import { getRoutePath } from "routes"
import Username from "helpers/Username.helper"

import "./ConfigurationJobsCard.css"

class ConfigurationJobsCard extends PureComponent {
  render() {
    const {
      jobs,
      match: {
        params: { id: workspaceId, cid: configurationId }
      }
    } = this.props

    return (
      <React.Fragment>
        <PaperHeader size="small" className="configuration-jobs-card-header">
          <h3>Activity</h3>
          <Link
            className="button primary small"
            to={getRoutePath("workspace.configuration.configurationJob.list", {
              id: workspaceId,
              cid: configurationId
            })}
          >
            View all
          </Link>
        </PaperHeader>
        {List.isList(jobs) && jobs.size > 0 && (
          <Paper hasHeader={true} className="configuration-jobs-card-list">
            {jobs.map(job => (
              <Link
                className="job-item"
                key={job.id}
                to={{
                  pathname: getRoutePath("workspace.configuration.configurationJob.show", {
                    id: workspaceId,
                    cid: configurationId,
                    aid: job.id
                  }),
                  state: {
                    previous: this.props.location.pathname
                  }
                }}
              >
                <div className="content-border">
                  <div className="left-part">
                    <p className="user">
                      <Username userId={job.user_id} />
                    </p>
                    <div className="date-info">
                      <DateTimeWithTooltip
                        dateTime={job.created}
                        uniqueTooltipId={`configuration-job-${job.id}-tooltip`}
                      />
                    </div>
                  </div>
                  <StatusElement
                    align="right"
                    status={job.status}
                    history={job.stats ? job.stats.get("statuses_history") : Map()}
                    created={job.origin_created ? job.origin_created : job.created}
                    showActualRunningDuration
                    durationOneLine
                  />
                </div>
              </Link>
            ))}
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

ConfigurationJobsCard.propTypes = {
  jobs: PropTypes.instanceOf(List)
}

export default withRouter(ConfigurationJobsCard)
