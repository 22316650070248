import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Gantt from "5minute-frappe-gantt"
import { List } from "immutable"
import { Link, withRouter } from "react-router-dom"
import moment from "moment"
import _includes from "lodash/includes"
import _toString from "lodash/toString"
import _keys from "lodash/keys"
import _get from "lodash/get"
import _join from "lodash/join"
import _pick from "lodash/pick"
import humanizeDuration from "humanize-duration"

// helpers
import { shortenString } from "helpers/string.helper"
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"

import "./JobsGanttChart.css"

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms"
    }
  },
  delimiter: " ",
  spacer: ""
})

class JobsGanttChart extends PureComponent {
  gantt = null
  metaKey = false

  componentDidMount() {
    const { rootEntityName } = this.props
    const selector = rootEntityName === "dawg" ? "workspace" : "configuration"
    const tasks = this.getTasksFromJobs()
    this.gantt = new Gantt(this.containerDOM, tasks, {
      on_click: task => {
        const { deleted } = task.entity
        const {
          match: {
            params: { id }
          },
          history
        } = this.props
        if (!deleted) {
          let path
          if (rootEntityName === "workspace") {
            path = getRoutePath("workspace.configuration.configurationJob.show", {
              id,
              cid: task.entity.id,
              aid: task.job_id
            })
          } else {
            path = getRoutePath("workspace.workspaceJob.show", {
              id: task.entity.id,
              aid: task.job_id
            })
          }
          if (this.metaKey) {
            const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
            window.open(publicUrl.origin + path, "_blank")
          } else {
            history.push({
              pathname: path,
              state: {
                previous: this.props.location.pathname
              }
            })
          }
        }
      },
      view_mode: "Minute",
      language: "en",
      date_format: DATE_TIME_FORMAT,
      header_height: 20,
      bar_height: 28,
      column_width: 305,
      is_editable: false,
      popup_trigger: "mouseover",
      min_columns_count: 3,
      width: 918,
      responsive: true,
      custom_popup_html: function(task) {
        let icon = _get(task, "entity.component.icon", null)
        if (!icon) {
          if (rootEntityName === "dawg") {
            icon = "workspace.png"
          } else {
            icon = "dummy.png"
          }
        }

        let overalDuration, runningDuration
        if (task.runningStart) {
          overalDuration = moment.duration(moment(task.end).diff(moment(task.start))).asSeconds()
          runningDuration = moment
            .duration(moment(task.end).diff(moment.utc(task.runningStart)))
            .asSeconds()
        }

        let div
        if (task.entity.deleted) {
          div =
            '<div class="custom-popup">' +
            '<img class="component-icon" src="' +
            getComponentIconSrc(icon, true) +
            '" alt="icon" />' +
            '<p class="red">Deleted ' +
            selector +
            (task.runningStart
              ? '<br /><span class="duration">' +
                shortEnglishHumanizer(runningDuration * 1000) +
                " of " +
                shortEnglishHumanizer(overalDuration * 1000) +
                "</span>"
              : "") +
            "</p>" +
            "</div>"
        } else {
          div =
            '<div class="custom-popup">' +
            '<img class="component-icon" src="' +
            getComponentIconSrc(icon, true) +
            '" alt="icon" />' +
            "<p>" +
            _get(task, "entity.component.name", "Workspace") +
            (task.runningStart
              ? '<br /><span class="duration">' +
                shortEnglishHumanizer(runningDuration * 1000) +
                " of " +
                shortEnglishHumanizer(overalDuration * 1000) +
                "</span>"
              : "") +
            "</p>" +
            "</div>"
        }

        return div
      }
    })

    // command/ctrl key event handler
    window.addEventListener("keydown", this.handleKeyDown, false)
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    window.removeEventListener("keydown", this.handleKeyDown, false)
  }

  handleKeyUp = evt => {
    if (!evt.metaKey) {
      this.metaKey = false
    }
  }

  handleKeyDown = evt => {
    if (evt.metaKey) {
      this.metaKey = true
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      const tasks = this.getTasksFromJobs()
      this.gantt.refresh(tasks)
    }
  }

  getTasksFromJobs = () => {
    const { jobs, rootEntityName } = this.props
    const selector = rootEntityName === "dawg" ? "workspace" : "configuration"
    const tasks = jobs.map(job => {
      return {
        start: job.origin_created
          ? moment
              .utc(job.origin_created)
              .local()
              .format(DATE_TIME_FORMAT)
          : moment
              .utc(job.created)
              .local()
              .format(DATE_TIME_FORMAT),
        end: _includes(["running", "waiting"], job.status)
          ? moment()
              .local()
              .format(DATE_TIME_FORMAT)
          : moment
              .utc(job.created)
              .local()
              .format(DATE_TIME_FORMAT),
        name: job.status,
        id: _toString(job[selector].id),
        dependencies:
          rootEntityName === "dawg"
            ? _join(_get(job, "workspace.input_settings.inputs", []))
            : _join(_keys(_get(job, "configuration.input_settings.inputs", {}))),
        custom_class: `bar-${job.status}`,
        entity: _pick(job[selector], ["id", "component", "deleted"]),
        job_id: job.id,
        runningStart: _get(job.stats, "statuses_history.running", null)
      }
    })
    return tasks.toArray()
  }

  renderSvgBackground = () => {
    const { jobs } = this.props
    const height = 46 * jobs.size + 30
    return (
      <svg className="background" width="100%" height={height}>
        <g className="b-grid" width="100%">
          {jobs.map((job, index) => (
            <line
              key={index}
              x1="0%"
              x2="100%"
              y1={46 * index + 29}
              y2={46 * index + 29}
              className="b-grid-row"
            />
          ))}
        </g>
      </svg>
    )
  }

  render() {
    const { jobs, rootEntityName } = this.props

    const selector = rootEntityName === "dawg" ? "workspace" : "configuration"
    return (
      <div className="configuration-jobs-chart-wrapper">
        {this.renderSvgBackground()}
        <div className="configurations">
          <p className="label">
            Name <span>(minute)</span>
          </p>
          {jobs
            .map(job => {
              let path
              if (rootEntityName === "workspace") {
                path = getRoutePath("workspace.configuration.show", {
                  id: job.configuration.workspace.id,
                  cid: job.configuration.id
                })
              } else {
                path = getRoutePath("workspace.show", {
                  id: job.workspace.id
                })
              }
              return (
                <div key={job[selector].id} className="configuration-link-wrapper">
                  {job[selector].deleted === 1 && (
                    <span className="configuration-name">
                      <img
                        src={getComponentIconSrc(
                          _get(job, "configuration.component.icon", "workspace.png")
                        )}
                        alt="icon"
                        className="component-icon"
                      />
                      <span>{shortenString(job[selector].name, 25)}</span>
                    </span>
                  )}
                  {job[selector].deleted === 0 && (
                    <Link
                      to={{
                        pathname: path,
                        state: {
                          previous: this.props.location.pathname
                        }
                      }}
                      className="configuration-link"
                    >
                      <img
                        src={getComponentIconSrc(
                          _get(job, "configuration.component.icon", "workspace.png")
                        )}
                        alt="icon"
                        className="component-icon"
                      />
                      <span>{shortenString(job[selector].name, 25)}</span>
                    </Link>
                  )}
                </div>
              )
            })
            .toArray()}
        </div>
        <div ref={el => (this.containerDOM = el)} className="conf-jobs-gantt-chart" />
      </div>
    )
  }
}

JobsGanttChart.propTypes = {
  jobs: PropTypes.instanceOf(List).isRequired,
  rootEntityName: PropTypes.string.isRequired
}

export default withRouter(JobsGanttChart)
