import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"

class ObjectFieldTemplateCollapsable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  toggleExpanded = () => {
    const { uiSchema } = this.props
    const isRoot = _get(uiSchema, "root", false)
    if (!isRoot) {
      this.setState({
        expanded: !this.state.expanded
      })
    }
  }

  componentDidMount() {
    const { uiSchema } = this.props
    const isRoot = _get(uiSchema, "root", false)
    if (isRoot || _get(uiSchema, "expandAll", 0) === 1) {
      this.setState({
        expanded: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const prevExpandAll = _get(prevProps, "uiSchema.expandAll", 0)
    const prevCollapseAll = _get(prevProps, "uiSchema.collapseAll", 0)
    const expandAll = _get(this.props, "uiSchema.expandAll", 0)
    const collapseAll = _get(this.props, "uiSchema.collapseAll", 0)
    if (prevExpandAll !== expandAll) {
      if (!this.state.expanded) {
        this.setState({
          expanded: true
        })
      }
    }
    if (prevCollapseAll !== collapseAll) {
      if (this.state.expanded) {
        this.setState({
          expanded: false
        })
      }
    }
  }

  render() {
    const { properties, title, description } = this.props
    const { expanded } = this.state

    return (
      <div className="custom-object-field content-padding">
        {title && (
          <h4
            className={`form-part-title ${!this.state.expanded ? "title-collapsed" : ""}`}
            onClick={this.toggleExpanded}
          >
            {expanded && (
              <FontAwesomeIcon className="chevron chevron-up" icon={["far", "chevron-up"]} />
            )}
            {!expanded && (
              <FontAwesomeIcon className="chevron chevron-down" icon={["far", "chevron-down"]} />
            )}
            {title}
          </h4>
        )}
        <div className={`row ${!this.state.expanded ? "collapsed" : ""}`}>
          {properties.map(prop => (
            <div className="text-field" key={prop.content.key}>
              {prop.content}
            </div>
          ))}
        </div>
        {description}
      </div>
    )
  }
}

export default ObjectFieldTemplateCollapsable
