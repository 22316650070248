import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import { withRouter } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Route, Switch } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import _isNumber from "lodash/isNumber"
import _size from "lodash/size"
import _get from "lodash/get"
import moment from "moment"
import ReactTooltip from "react-tooltip"

import { logout } from "actions/authenticatedUser.action"
import { getRoutePath } from "routes"
import { isAdmin } from "helpers/authenticatedUser.helper"
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import DropdownMenu, {
  DropdownToggle,
  DropdownContent,
  DropdownMenuItem
} from "components/UI/elements/DropdownMenu"
import Avatar from "components/UI/elements/Avatar"
import IconButton from "components/UI/elements/IconButton"

// submenu components
import WorkspaceDetailSubmenu from "./SubmenuComponent/WorkspaceDetailSubmenu"
import WorkspaceConfigurationCreateSubmenu from "./SubmenuComponent/WorkspaceConfigurationCreateSubmenu"
import WorkspaceConfigurationJobsSubmenu from "./SubmenuComponent/WorkspaceConfigurationJobsSubmenu"
import WorkspaceConfigurationSubmenu from "./SubmenuComponent/WorkspaceConfigurationSubmenu"
import DataPreviewSubmenu from "./SubmenuComponent/DataPreviewSubmenu"
import DataPreviewListSubmenu from "./SubmenuComponent/DataPreviewListSubmenu"
import WorkspaceConfigurationConfigurationJobsSubmenu from "./SubmenuComponent/WorkspaceConfigurationConfigurationJobsSubmenu"
import WorkspaceConfigurationConfigurationJobSubmenu from "./SubmenuComponent/WorkspaceConfigurationConfigurationJobSubmenu"
import WorkspaceConfigurationConfigurationJobHistorySubmenu from "./SubmenuComponent/WorkspaceConfigurationConfigurationJobHistorySubmenu"
import WorkspaceJobsSubmenu from "./SubmenuComponent/WorkspaceJobsSubmenu"
import WorkspaceJobSubmenu from "./SubmenuComponent/WorkspaceJobSubmenu"
import WorkspaceJobHistorySubmenu from "./SubmenuComponent/WorkspaceJobHistorySubmenu"
import WorkspaceHistorySubmenu from "./SubmenuComponent/WorkspaceHistorySubmenu"
import WorkspaceHistoryDetailSubmenu from "./SubmenuComponent/WorkspaceHistoryDetailSubmenu"
import ConfigurationHistorySubmenu from "./SubmenuComponent/ConfigurationHistorySubmenu"
import ConfigurationHistoryDetailSubmenu from "./SubmenuComponent/ConfigurationHistoryDetailSubmenu"
import DawgDetailSubmenu from "./SubmenuComponent/DawgDetailSubmenu"
import DawgHistorySubmenu from "./SubmenuComponent/DawgHistorySubmenu"
import DawgHistoryDetailSubmenu from "./SubmenuComponent/DawgHistoryDetailSubmenu"
import DawgJobsSubmenu from "./SubmenuComponent/DawgJobsSubmenu"
import DawgJobSubmenu from "./SubmenuComponent/DawgJobSubmenu"
import DawgJobHistorySubmenu from "./SubmenuComponent/DawgJobHistorySubmenu"
import DawgWorkspaceJobsSubmenu from "./SubmenuComponent/DawgWorkspaceJobsSubmenu"

import logo from "images/meiro-symbol-white-transparent.svg"
import clientDummyLogo from "images/client-dummy.png"
import "./HeaderContainer.css"

const meiroClientLogoSrc = `${process.env.PUBLIC_URL}/img/client/logo.png`
let meiroLocation = ""
let meiroClient = ""
if (process.env.NODE_ENV === "production") {
  meiroLocation = "[[MEIRO_LOCATION]]"
  meiroClient = "[[MEIRO_CLIENT]]"
} else {
  meiroLocation = process.env.REACT_APP_MEIRO_LOCATION
  meiroClient = process.env.REACT_APP_CLIENT_NAME === '""' ? "" : process.env.REACT_APP_CLIENT_NAME
}

const AdminSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("administration.users")} activeClassName="is-active">
      Users
    </NavLink>
    <span className="middot">
      <span>&middot;</span>
    </span>
    <NavLink to={getRoutePath("administration.user-permissions")} activeClassName="is-active">
      User permissions
    </NavLink>
    <span className="middot">
      <span>&middot;</span>
    </span>
    <NavLink to={getRoutePath("administration.tags")} activeClassName="is-active">
      Tags
    </NavLink>
    <span className="middot">
      <span>&middot;</span>
    </span>
    <NavLink to={getRoutePath("administration.oauth")} activeClassName="is-active">
      OAuth
    </NavLink>
  </nav>
)

const WorkspacesListSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("workspace.list")} activeClassName="is-active">
      Workspaces
    </NavLink>
  </nav>
)

const DawgListSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("dawg.list")} activeClassName="is-active">
      DAWGs
    </NavLink>
  </nav>
)

const SearchSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("search")} activeClassName="is-active">
      Search
    </NavLink>
  </nav>
)

const ProjectConfigurationJobListSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("project.configurationJob")} activeClassName="is-active">
      All Configuration Activity
    </NavLink>
  </nav>
)

const ProjectWorkspaceJobListSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("project.workspaceJob")} activeClassName="is-active">
      All Workspace Activity
    </NavLink>
  </nav>
)

const TrashListSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("trash.list")} activeClassName="is-active">
      Trash
    </NavLink>
  </nav>
)

const DropDownSubmenu = () => (
  <nav className="secondary-nav">
    <NavLink to={getRoutePath("user-settings")} activeClassName="is-active">
      User Settings
    </NavLink>
  </nav>
)

class HeaderContainer extends Component {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.state = {
      userMenu: false,
      monitoring: {
        open: false,
        loading: false,
        data: null
      }
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this.setState(prevState => ({
      monitoring: {
        ...prevState.monitoring,
        loading: true
      }
    }))
    this.retrieveSystemInfo()
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
  }

  toggleUserMenu = () => {
    this.setState(prevState => ({
      userMenu: !prevState.userMenu
    }))
  }

  authenticatedUserLogout = () => {
    const { authenticatedUser, logout } = this.props
    if (!authenticatedUser.logoutDisabled) {
      logout(true)
    }
  }

  openLink = url => () => {
    window.open(url, "_blank")
  }

  retrieveSystemInfo = async () => {
    const systemInfoRequest = this.pendingPromises.create(api().systemInfo())
    try {
      const response = await systemInfoRequest.promise
      this.setState(prevState => ({
        monitoring: {
          ...prevState.monitoring,
          loading: false,
          data: response.system_info
        }
      }))
    } catch (err) {
      if (!_get(err, "isCanceled")) {
        this.setState(prevState => ({
          monitoring: {
            ...prevState.monitoring,
            loading: false
          }
        }))
      }
    } finally {
      this.pendingPromises.remove(systemInfoRequest)
    }
  }

  toggleMonitoringSection = () => {
    const { open } = this.state.monitoring
    this.setState(prevState => ({
      monitoring: {
        ...prevState.monitoring,
        open: !open,
        loading: !open ? true : false
      }
    }))
    if (!open) {
      // fetch and start refreshing interval
      this.retrieveSystemInfo()
      this.intervalId = setInterval(this.retrieveSystemInfo, 10000)
      document.body.classList.add("monitoring-visible")
    } else {
      clearInterval(this.intervalId)
      document.body.classList.remove("monitoring-visible")
    }
  }

  renderDiskUsageMessage = () => {
    const { data } = this.state.monitoring
    if (!data) return null
    const totalSpace = _isNumber(data.disk_space.size) ? data.disk_space.size * 1024 : 0
    const usedSpace = _isNumber(data.disk_space.used) ? data.disk_space.used * 1024 : 0
    if (0 === totalSpace) {
      return <span className="disk-usage-info">0B out of 0B</span>
    }
    const e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const c = 1024
    const f = Math.floor(Math.log(totalSpace) / Math.log(c))

    return (
      <span className="disk-usage-info">
        {parseFloat((usedSpace / Math.pow(c, f)).toFixed(2)) + e[f]} out of{" "}
        {parseFloat((totalSpace / Math.pow(c, f)).toFixed(2)) + e[f]}
      </span>
    )
  }

  getDiskUsagePercentage = () => {
    const { data } = this.state.monitoring
    if (!data) return null
    const totalSpace = data.disk_space.size
    const usedSpace = data.disk_space.used
    return Math.round(usedSpace / (totalSpace / 100))
  }

  drawPie = () => {
    const { data } = this.state.monitoring
    // round degrees
    if (data) {
      const usedDiskPercent = this.getDiskUsagePercentage()
      const degrees = Math.round(360 * (usedDiskPercent / 100))
      const radians = degrees * (Math.PI / 180)
      const x = 11 + 11 * Math.sin(radians)
      const y = 11 - 11 * Math.cos(radians)
      return (
        <svg className="pie">
          <circle cx="11" cy="11" r="11" />
          {degrees <= 180 && <path d={`M11,11 L11,0 A11,11 1 0,1 ${x},${y} z`} />}
          {degrees > 180 && <path d={`M11,11 L11,0 A11,11 1 0,1 11,22 A11,11 1 0,1 ${x},${y} z`} />}
        </svg>
      )
    }
    return null
  }

  goToLogs = () => {
    this.openLink(`${window.location.origin}/logs`)()
  }

  goToSqlClient = () => {
    this.openLink(`${window.location.origin}/sql`)()
  }

  render() {
    const { authenticatedUser } = this.props
    const { userMenu, monitoring } = this.state

    return (
      <div className="header-container">
        <div className="primary">
          <div className="wrapper" ref={this.wrapperRef}>
            <div className="logo-container">
              <Link to={getRoutePath("workspace.list")} className="logo">
                <img src={logo} alt="logo" />
                <div className="client-logo">
                  <img
                    onError={evt => {
                      evt.target.src = clientDummyLogo
                      evt.target.className = "dummy"
                    }}
                    src={meiroClientLogoSrc}
                    alt="client logo"
                  />
                </div>
                {_size(meiroClient) === 0 && (
                  <p className="product-name one-line">
                    <span className="strong">Integrations</span>
                  </p>
                )}
                {_size(meiroClient) > 0 && (
                  <p className="product-name two-line">
                    <span>Integrations for</span>
                    <span className="strong">{meiroClient}</span>
                  </p>
                )}
              </Link>
            </div>
            <nav className="primary-nav">
              <NavLink
                to={getRoutePath("dawg.list")}
                activeClassName="is-active"
                data-cy="nav-dawg"
              >
                <span className="inline-icon">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon className="search-icon" icon={["fas", "chart-network"]} />
                  </span>
                </span>
                DAWGS
              </NavLink>
              <NavLink
                to={getRoutePath("workspace.list")}
                activeClassName="is-active"
                data-cy="nav-workspaces"
              >
                <span className="inline-icon">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon className="code-branch" icon={["fas", "code-branch"]} />
                  </span>
                </span>
                Workspaces
              </NavLink>
              <NavLink to={getRoutePath("search")} activeClassName="is-active" data-cy="nav-search">
                <span className="inline-icon">
                  <span className="icon-wrapper">
                    <FontAwesomeIcon className="search-icon" icon={["fas", "search"]} />
                  </span>
                </span>
                Search
              </NavLink>
            </nav>
            <div className="user-menu-wrapper">
              <div className="icon-menu">
                <span
                  className={`monitoring-badge ${monitoring.open ? "is-active" : ""} ${
                    monitoring.loading ? "loading" : ""
                  }`}
                  onClick={this.toggleMonitoringSection}
                >
                  <FontAwesomeIcon className="icon" icon="tachometer-fastest" />
                </span>
                {isAdmin() && (
                  <NavLink to={getRoutePath("administration")} activeClassName="is-active">
                    <FontAwesomeIcon className="icon" icon="wrench" />
                  </NavLink>
                )}
                <NavLink
                  to={getRoutePath("trash.list")}
                  activeClassName="is-active"
                  data-cy="trash-navigation"
                >
                  <FontAwesomeIcon className="icon" icon={["fas", "trash-alt"]} />
                </NavLink>
              </div>
              <DropdownMenu open={userMenu} toggle={this.toggleUserMenu} align="right">
                <DropdownToggle className="user-menu-toggle-button">
                  <Avatar
                    email={authenticatedUser.data.email}
                    name={authenticatedUser.data.name}
                    gravatarSize={80}
                    className="gravatar-image"
                  />
                  {userMenu && <FontAwesomeIcon icon={["fas", "caret-up"]} />}
                  {!userMenu && <FontAwesomeIcon icon={["fas", "caret-down"]} />}
                </DropdownToggle>
                <DropdownContent className="user-menu">
                  <DropdownMenuItem link={getRoutePath("user-settings")}>
                    <FontAwesomeIcon className="icon" icon={["fas", "user"]} /> User settings
                  </DropdownMenuItem>
                  {isAdmin() && (
                    <DropdownMenuItem action={this.goToLogs}>
                      <FontAwesomeIcon className="icon" icon={["fas", "terminal"]} /> Logs
                    </DropdownMenuItem>
                  )}
                  {isAdmin() && (
                    <DropdownMenuItem action={this.goToSqlClient}>
                      <FontAwesomeIcon className="icon" icon={["fas", "database"]} /> SQL Client
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem
                    action={this.openLink("https://docs.meiro.io/books/meiro-integrations")}
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "life-ring"]} /> Help
                  </DropdownMenuItem>
                  <DropdownMenuItem action={this.openLink("https://meiro.io/general-terms/")}>
                    <FontAwesomeIcon className="icon" icon={["fas", "file-contract"]} /> Terms &
                    Conditions
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    action={this.authenticatedUserLogout}
                    className={authenticatedUser.logoutDisabled ? "is-disabled logout" : "logout"}
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "sign-out"]} /> Logout
                  </DropdownMenuItem>
                  <div className="instance-info">
                    <div className="location-info">
                      <span className="location-label">
                        <FontAwesomeIcon icon={["fas", "map-marker-alt"]} /> Location:
                      </span>
                      <span className="location-value">{meiroLocation}</span>
                    </div>
                    {monitoring.data?.version && (
                    <div className="version-info">
                      <span className="version-label">
                        <FontAwesomeIcon icon={["fas", "binary"]} /> Version:
                      </span>{" "}
                      <span className="version-value">{monitoring.data.version}</span>
                    </div>
                    )}
                    {monitoring.data?.build_date && (
                      <div className="build-date-info">
                        <span className="build-date-label">
                          <FontAwesomeIcon icon={["fas", "gear-code"]} /> Build date:
                        </span>{" "}
                        <span className="build-date-value">
                          {moment(new Date(`${monitoring.data.build_date}T00:00`)).format(
                            "D MMM YYYY"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </DropdownContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="secondary">
          <div className="wrapper">
            <Switch>
              <Route path={getRoutePath("administration")} component={AdminSubmenu} />

              <Route exact path={getRoutePath("dawg.list")} component={DawgListSubmenu} />

              <Route
                exact
                path={getRoutePath("project.workspaceJob")}
                component={ProjectWorkspaceJobListSubmenu}
              />

              <Route
                exact
                path={getRoutePath("workspace.list")}
                component={WorkspacesListSubmenu}
              />
              <Route exact path={getRoutePath("search")} component={SearchSubmenu} />
              <Route
                exact
                path={getRoutePath("project.configurationJob")}
                component={ProjectConfigurationJobListSubmenu}
              />

              <Route
                path={getRoutePath("workspace.configuration.data.preview")}
                component={DataPreviewSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.data.preview.list")}
                component={DataPreviewListSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.configurationJob.show.history")}
                component={WorkspaceConfigurationConfigurationJobHistorySubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.configurationJob.show")}
                component={WorkspaceConfigurationConfigurationJobSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.configurationJob.list")}
                component={WorkspaceConfigurationConfigurationJobsSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.configurationHistory.show")}
                component={ConfigurationHistoryDetailSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.configurationHistory.list")}
                component={ConfigurationHistorySubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.create")}
                component={WorkspaceConfigurationCreateSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configurationJob.list")}
                component={WorkspaceConfigurationJobsSubmenu}
              />
              <Route
                path={getRoutePath("workspace.configuration.show")}
                component={WorkspaceConfigurationSubmenu}
              />
              <Route
                path={getRoutePath("workspace.workspaceJob.show.history")}
                component={WorkspaceJobHistorySubmenu}
              />
              <Route
                path={getRoutePath("workspace.workspaceJob.show")}
                component={WorkspaceJobSubmenu}
              />
              <Route
                path={getRoutePath("workspace.workspaceJob.list")}
                component={WorkspaceJobsSubmenu}
              />
              <Route
                path={getRoutePath("workspace.workspaceHistory.show")}
                component={WorkspaceHistoryDetailSubmenu}
              />
              <Route
                path={getRoutePath("workspace.workspaceHistory.list")}
                component={WorkspaceHistorySubmenu}
              />
              <Route path={getRoutePath("workspace.show")} component={WorkspaceDetailSubmenu} />
              <Route path={getRoutePath("trash.list")} component={TrashListSubmenu} />

              <Route
                path={getRoutePath("dawg.workspaceJob.list")}
                component={DawgWorkspaceJobsSubmenu}
              />
              <Route
                path={getRoutePath("dawg.dawgJob.show.history")}
                component={DawgJobHistorySubmenu}
              />
              <Route path={getRoutePath("dawg.dawgJob.show")} component={DawgJobSubmenu} />
              <Route path={getRoutePath("dawg.dawgJob.list")} component={DawgJobsSubmenu} />
              <Route
                path={getRoutePath("dawg.dawgHistory.show")}
                component={DawgHistoryDetailSubmenu}
              />
              <Route path={getRoutePath("dawg.dawgHistory.list")} component={DawgHistorySubmenu} />
              <Route path={getRoutePath("dawg.show")} component={DawgDetailSubmenu} />

              <Route path={getRoutePath("user-settings")} component={DropDownSubmenu} />
            </Switch>
          </div>
          <CSSTransition
            in={monitoring.open && !monitoring.loading}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <div className="system-info">
              {monitoring.data && (
                <React.Fragment>
                  <div className="disc-info">
                    <span className="label">space used:</span>
                    {this.drawPie()}
                    <div className="details">
                      <span className="percentage-usage">{this.getDiskUsagePercentage()}%</span>
                      {this.renderDiskUsageMessage()}
                    </div>
                  </div>
                  <div className="cores-info">
                    <div className="cores-icon" data-tip data-for="cores-tooltip">
                      <FontAwesomeIcon className="microchip" icon={["fas", "microchip"]} />
                      <span className="info-tooltip">
                        <FontAwesomeIcon className="circle-info" icon={["fas", "info-circle"]} />
                      </span>
                    </div>
                    <ReactTooltip
                      id="cores-tooltip"
                      className="cores-tooltip"
                      place="bottom"
                      delayHide={500}
                      effect="solid"
                    >
                      The load shows how occupied the server is. If it is higher than the number of
                      cores, it means the server is over-utilized. More info{" "}
                      <a
                        href="https://en.wikipedia.org/wiki/Load_(computing)"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </ReactTooltip>
                    <div className="details">
                      <span className="system-load">{monitoring.data.system_one_minute_load}</span>
                      <span className="cores-number">
                        out of {monitoring.data.cpu_cores_count}{" "}
                        {monitoring.data.cpu_cores_count === 1 ? "core" : "cores"}
                      </span>
                    </div>
                  </div>
                  <div className="workers-info">
                    <FontAwesomeIcon className="workers-icon" icon={["fas", "cogs"]} />
                    <div className="details">
                      <span className="workers-count">{monitoring.data.mi_workers_count}</span>
                      <span className="desc">
                        worker{monitoring.data.mi_workers_count > 1 ? "s" : ""}
                      </span>
                    </div>
                  </div>
                  <IconButton
                    color="black"
                    className="times-icon"
                    onClick={this.toggleMonitoringSection}
                  >
                    <FontAwesomeIcon icon={["fas", "times"]} />
                  </IconButton>
                </React.Fragment>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

HeaderContainer.propTypes = {
  authenticatedUser: PropTypes.object.isRequired
}

function mapStateToProps({ authenticatedUser }) {
  return { authenticatedUser }
}

export default withRouter(connect(mapStateToProps, { logout })(HeaderContainer))
