import { COMPONENT } from "sharedConstants"
import ComponentModel from "models/component.model"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import { Map } from "immutable"

/*
 * null if not fetched yet, otherwise
 * Map({
 *   'id': { component record }
 * })
 */

export default (state = null, { type, payload }) => {
  switch (type) {
    case `${COMPONENT.ACTION.LIST}_FULFILLED`:
      return Map(
        _mapKeys(
          _map(payload, component => new ComponentModel(component)),
          "id"
        )
      )
    default:
      return state
  }
}
