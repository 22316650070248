import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import _isString from "lodash/isString"

import { MOMENT } from "sharedConstants"

import "./DateTimeWithTooltip.css"

const DateTimeWithTooltip = ({ dateTime, uniqueTooltipId, momentDateTime }) => (
  <span className="date-time-with-tooltip">
    <span className="date-time" data-tip data-for={uniqueTooltipId}>
      {dateTime &&
        moment
          .utc(dateTime)
          .local()
          .format(MOMENT.DATE_TIME_FORMAT)}
      {moment.isMoment(momentDateTime) && momentDateTime.local().format(MOMENT.DATE_TIME_FORMAT)}
    </span>
    <ReactTooltip id={uniqueTooltipId} place="bottom" className="utc-tooltip" delayShow={300}>
      UTC: {dateTime && moment.utc(dateTime).format(MOMENT.DATE_TIME_FORMAT)}
      {moment.isMoment(momentDateTime) && momentDateTime.utc().format(MOMENT.DATE_TIME_FORMAT)}
    </ReactTooltip>
  </span>
)

/* istanbul ignore next */
const requiredDatePropsCheck = (props, propName, componentName) => {
  if (!props.dateTime && !props.momentDateTime) {
    return new Error(
      `One of 'dateTime' or 'momentDateTime' is required by '${componentName}' component.`
    )
  }
  if (props.dateTime && !_isString(props.dateTime)) {
    return new Error(`Prop 'dateTime' has to be a valid string in '${componentName}' component.`)
  }
  if (props.momentDateTime && !moment.isMoment(props.momentDateTime)) {
    return new Error(
      `Prop 'momentDateTime' has to be a valid moment object instance in '${componentName}' component.`
    )
  }
  return null
}

DateTimeWithTooltip.propTypes = {
  dateTime: requiredDatePropsCheck,
  uniqueTooltipId: PropTypes.string.isRequired,
  momentDateTime: requiredDatePropsCheck
}

export default DateTimeWithTooltip
