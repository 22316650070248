import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _isPlainObject from "lodash/isPlainObject"
import _isEmpty from "lodash/isEmpty"
import _noop from "lodash/noop"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Map, fromJS } from "immutable"

import ComponentModel from "models/component.model"

import Modal from "components/UI/elements/Modal"
import DescriptionCard from "components/UI/components/DescriptionCard"
import SettingsFormCard from "components/Configuration/pages/cards/SettingsForm"
import CodeEditorCard from "components/Configuration/pages/cards/CodeEditor"

import { getComponentIconSrc } from "helpers/component.helper"

import "./ConfigurationModal.css"

class ConfigurationModal extends PureComponent {
  renderComponentBox = () => {
    const { configuration } = this.props
    const component = configuration.component

    let icon = component.icon
    if (!icon) {
      icon = "dummy.png"
    }

    return (
      <div>
        <p className="label-like">Component</p>
        <div className={`component ${component.hidden ? "deprecated" : ""}`}>
          <div className="icon">
            <img src={getComponentIconSrc(icon)} alt="component icon" />
          </div>
          <div className="desc">
            <h4>
              {component.name}{" "}
              {component.hidden === 1 && (
                <React.Fragment>
                  <FontAwesomeIcon
                    data-tip
                    data-for="deprecated-component"
                    icon={["fas", "info-circle"]}
                  />
                  <ReactTooltip className="tooltip-bubble" id="deprecated-component" place="bottom">
                    The component is deprecated, please consider a replacement.
                  </ReactTooltip>
                </React.Fragment>
              )}
            </h4>
            <h5>{component.type}</h5>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { open, handleClose, configuration } = this.props
    if (!_isPlainObject(configuration)) {
      return null
    }

    const component = new ComponentModel(configuration.component)
    const settings = Map(fromJS(configuration.settings))
    const oauthSettings = Map(fromJS(configuration.oauth_settings))

    return (
      <Modal
        open={open}
        handleClose={handleClose}
        className="configuration-show-modal"
        title="Preview: configuration"
      >
        <div className="content">
          <div className="scrollable">
            <div className="header">
              <div className="name-wrapper">
                <p className="label-like">Name</p>
                <div className="name">{configuration.name}</div>
              </div>
              {this.renderComponentBox()}
            </div>
            {!_isEmpty(configuration.description) && (
              <DescriptionCard
                initialValues={{ description: configuration.description }}
                isEditable={false}
                onSubmit={_noop}
                form="configuration-preview"
                toggleEditMode={_noop}
                editMode={false}
                viewOnly={true}
              />
            )}
            <SettingsFormCard
              configurationId={configuration.id}
              component={component}
              isEditable={false}
              authLink={configuration.auth_link}
              oauthSettings={oauthSettings}
              handleOauthReset={_noop}
              settings={settings}
              onFormError={_noop}
              onFormSubmit={_noop}
              onJsonInvalid={_noop}
              copyMessageFunc={_noop}
              toggleEditMode={_noop}
              editMode={false}
              viewOnly={true}
            />
            <CodeEditorCard
              component={component}
              isEditable={false}
              settings={settings}
              onCodeSave={_noop}
              authLink={configuration.auth_link}
              oauthSettings={oauthSettings}
              toggleEditMode={_noop}
              editMode={false}
              viewOnly={true}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

ConfigurationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  configuration: PropTypes.object
}

export default ConfigurationModal
