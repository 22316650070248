import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Record } from "immutable"
import ReactTooltip from "react-tooltip"
import { NavLink } from "react-router-dom"
import _noop from "lodash/noop"

// helpers
import { shortenString } from "helpers/string.helper"
import { getRoutePath } from "routes"

// actions
import { retrieveDawg } from "actions/dawg.action"

// selectors
import { getDawgRecord } from "selectors/dawg.selector"

class DawgJobHistory extends PureComponent {
  componentDidMount() {
    const {
      dawg,
      retrieveDawg,
      match: {
        params: { id }
      }
    } = this.props

    if (!dawg) {
      retrieveDawg(id).catch(_noop)
    }
  }

  render() {
    const {
      dawg,
      match: {
        params: { aid }
      }
    } = this.props

    if (!dawg) {
      return null
    }

    const hasDawgTooltip = dawg.name.length >= 25
    const dawgTooltipProps = hasDawgTooltip
      ? { "data-tip": dawg.name, "data-for": "navigation-tooltip" }
      : {}

    return (
      <React.Fragment>
        <nav className="secondary-nav">
          <NavLink to={getRoutePath("dawg.list")}>DAWGS</NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink
            className="has-dawg-icon"
            to={getRoutePath("dawg.show", { id: dawg.id })}
            {...dawgTooltipProps}
          >
            <FontAwesomeIcon className="prefix-icon network" icon={["fas", "chart-network"]} />
            {shortenString(dawg.name, 25)}
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink to={getRoutePath("dawg.dawgJob.list", { id: dawg.id })}>Activity</NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink to={getRoutePath("dawg.dawgJob.show", { id: dawg.id, aid })}>
            Activity Detail
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <span className="link-like is-active">History</span>
        </nav>
        <ReactTooltip id="navigation-tooltip" getContent={dataTip => dataTip} place="bottom" />
      </React.Fragment>
    )
  }
}

DawgJobHistory.propTypes = {
  dawg: PropTypes.instanceOf(Record)
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id }
    }
  } = ownProps
  return {
    dawg: getDawgRecord(state, id)
  }
}

export default connect(mapStateToProps, { retrieveDawg })(DawgJobHistory)
