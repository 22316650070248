import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import { listDawgHistory, revertDawgTo } from "actions/dawgHistory.action"
import { showToast } from "actions/toast.action"

// selectors
import {
  getDawgHistoryList,
  getDawgHistorySelectionSettings,
  hasDawgHistoryMoreItems,
  isDawgHistoryFulfilled
} from "selectors/dawgHistory.selector"
import { getUsersDawgAcl } from "selectors/usersAcl.selector"

import EntityHistoryList from "components/UI/DawgWsShared/EntityHistoryList"

// helpers
import { getDawgChangedKeys } from "helpers/objectChanges.helper"

class DawgHistoryList extends PureComponent {
  render() {
    const {
      historyList,
      selectionSettings,
      hasMoreHistoryItems,
      usersAcl,
      isHistoryListFulfilled,
      listDawgHistory,
      revertDawgTo,
      showToast
    } = this.props

    return (
      <EntityHistoryList
        historyList={historyList}
        isHistoryListFulfilled={isHistoryListFulfilled}
        selectionSettings={selectionSettings}
        hasMoreHistoryItems={hasMoreHistoryItems}
        usersAcl={usersAcl}
        listEntityHistory={listDawgHistory}
        revertEntityTo={revertDawgTo}
        showToast={showToast}
        getEntityChangedKeys={getDawgChangedKeys}
        entityName="dawg"
      />
    )
  }
}

DawgHistoryList.propTypes = {
  historyList: PropTypes.instanceOf(List).isRequired,
  isHistoryListFulfilled: PropTypes.bool.isRequired,
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  hasMoreHistoryItems: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  listDawgHistory: PropTypes.func.isRequired,
  revertDawgTo: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    historyList: getDawgHistoryList(state),
    selectionSettings: getDawgHistorySelectionSettings(state),
    hasMoreHistoryItems: hasDawgHistoryMoreItems(state),
    usersAcl: getUsersDawgAcl(state, ownProps.match.params.id),
    isHistoryListFulfilled: isDawgHistoryFulfilled(state)
  }
}

export default connect(mapStateToProps, { listDawgHistory, revertDawgTo, showToast })(
  DawgHistoryList
)
