import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import _noop from "lodash/noop"
import _toString from "lodash/toString"
import _get from "lodash/get"
import _isNumber from "lodash/isNumber"
import _isNil from "lodash/isNil"
import { Map, List, Record } from "immutable"
import { Waypoint } from "react-waypoint"

// helpers
import { canCreateWorkspaces } from "helpers/authenticatedUser.helper"

// selectors
import {
  getWorkspaces,
  getLastWorkspacesJobs,
  getWorkspacesSelectionSettings,
  getWorkspacesHasMoreItems
} from "selectors/workspace.selector"
import { getDashboardConfJobs } from "selectors/projectConfigurationJob.selector"
import { getTagsMap, areTagsAlreadyFetched } from "selectors/tag.selector"

// actions
import {
  fetchWorkspaceList,
  retrieveWorkspace,
  fetchLastWorkspaceJobs,
  createWorkspace
} from "actions/workspace.action"
import { listProjectConfigurationJob } from "actions/configurationJob.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import {
  setSortingOptions,
  setFilterByTags,
  setFilterAndSorting,
  setFilterBy
} from "actions/table.action"
import { clearCachedConfigurationJobs } from "actions/configurationJob.action"
import { clearCachedWorkspaceJobs } from "actions/workspaceJob.action"
import { clearCachedWorkspaceHistory } from "actions/workspaceHistory.action"
import { clearCachedConfigurationHistory } from "actions/configurationHistory.action"
import { setUiAttribute } from "actions/authenticatedUser.action"
import { fetchUsersAcl } from "actions/acl.action"

// UI components
import WorkspacesActivityDrawer from "./cards/WorkspacesActivityDrawer"
import Header from "components/UI/DawgWsShared/Header"
import SubHeader from "components/UI/DawgWsShared/SubHeader"
import CreateActionModal from "components/UI/DawgWsShared/CreateActionModal"
import ProjectJobsCard from "components/UI/DawgWsShared/ProjectJobsCard"
import WorkspaceListItem from "../WorkspaceListItem"

import { INTERVAL, WORKSPACE } from "sharedConstants"

import "./WorkspaceList.css"

class WorkspaceList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      createWorkspaceModalOpen: false,
      expandedSchedules: Map(),
      // workspaceId: compactWorkspaceBoxHeight
      compactWorkspaceBoxesHeights: Map()
    }

    this.compactWorkspaceBoxesRefs = {}
    this.compactWorkspaceBoxesHeightsTmp = {}
  }

  componentDidMount() {
    this.props.clearCachedConfigurationJobs()
    this.props.clearCachedWorkspaceJobs()
    this.props.clearCachedWorkspaceHistory()
    this.props.clearCachedConfigurationHistory()

    const { filterValues } = this.props

    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const searchText = _get(filterValues, "search", "")
    const filterBy = _get(filterValues, "filterBy", "all")

    this.props
      .fetchWorkspaceList(
        0,
        WORKSPACE.LOADING_LIMIT,
        1,
        orderBy,
        orderDir,
        searchText,
        selectedTags,
        this._determineShowEnabled(filterBy),
        this._determineShowDisabled(filterBy)
      )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        this.props.showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(this.props.hideLoadingBar)
          .catch(this.props.hideLoadingBar)
      })
      .catch(_noop)

    this.props.setFilterAndSorting("WorkspaceFilter", orderBy, orderDir, selectedTags)

    this.props.showLoadingBar()
    this.props
      .listProjectConfigurationJob(0, 10, 1, "status", "DESC", false)
      .then(this.props.hideLoadingBar)
      .catch(this.props.hideLoadingBar)

    this.intervalId = setInterval(() => {
      this.props.listProjectConfigurationJob(0, 10, 1, "status", "DESC", false).catch(_noop)
      if (this.props.workspaces) {
        const workspaceIds = this.props.workspaces.map(ws => ws.id).toArray()
        this.props.fetchLastWorkspaceJobs(workspaceIds, 0).catch(_noop)
      }
    }, INTERVAL.WORKSPACE_LIST)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  toggleCreateWorkspaceModalOpen = () => {
    this.setState(prevState => ({
      createWorkspaceModalOpen: !prevState.createWorkspaceModalOpen
    }))
  }

  _loadMoreWorkspaces = () => {
    const { selectionSettings, fetchWorkspaceList, hideLoadingBar, showLoadingBar } = this.props

    fetchWorkspaceList(
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      1,
      selectionSettings.order_by,
      selectionSettings.order_dir,
      selectionSettings.name_filter,
      selectionSettings.tag_ids,
      selectionSettings.show_enabled,
      selectionSettings.show_disabled
    )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(hideLoadingBar)
          .catch(hideLoadingBar)
      })
      .catch(_noop)
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    //return <div>Waypoint</div>
    const { hasWorkspacesMoreItems } = this.props
    if (hasWorkspacesMoreItems) {
      return <Waypoint onEnter={this._loadMoreWorkspaces} bottomOffset={-250} />
    }
  }

  expandWorkspaceSchedules = workspaceId => () => {
    this.setState(prevState => ({
      expandedSchedules: prevState.expandedSchedules.set(workspaceId, true)
    }))
  }

  _determineSortOptions = values => ({
    column: values.sortBy.value,
    order: values.order ? "ASC" : "DESC"
  })

  onSortSubmit = values => {
    const { setSortingOptions, filterValues, fetchWorkspaceList } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    const sortOpt = this._determineSortOptions(values)
    const searchText = _get(filterValues, "search", "")
    const filterBy = _get(filterValues, "filterBy", "all")

    setSortingOptions("WorkspaceFilter", sortOpt.column, sortOpt.order)
    fetchWorkspaceList(
      0,
      WORKSPACE.LOADING_LIMIT,
      1,
      sortOpt.column,
      sortOpt.order,
      searchText,
      selectedTags,
      this._determineShowEnabled(filterBy),
      this._determineShowDisabled(filterBy)
    )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        this.props.showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(this.props.hideLoadingBar)
          .catch(this.props.hideLoadingBar)
      })
      .catch(_noop)
  }

  _determineShowEnabled = filterBy => {
    return filterBy === "all" || filterBy === "enabled" ? 1 : 0
  }

  _determineShowDisabled = filterBy => {
    return filterBy === "all" || filterBy === "disabled" ? 1 : 0
  }

  onFilterBySubmit = values => {
    const { setFilterBy, filterValues, fetchWorkspaceList } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const searchText = _get(filterValues, "search", "")
    const filterBy = _get(values, "filterBy.value", "all")

    setFilterBy("WorkspaceFilter", filterBy)
    fetchWorkspaceList(
      0,
      WORKSPACE.LOADING_LIMIT,
      1,
      orderBy,
      orderDir,
      searchText,
      selectedTags,
      this._determineShowEnabled(filterBy),
      this._determineShowDisabled(filterBy)
    )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        this.props.showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(this.props.hideLoadingBar)
          .catch(this.props.hideLoadingBar)
      })
      .catch(_noop)
  }

  onSearchSubmit = filterName => {
    const { filterValues, fetchWorkspaceList } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const filterBy = _get(filterValues, "filterBy", "all")

    fetchWorkspaceList(
      0,
      WORKSPACE.LOADING_LIMIT,
      1,
      orderBy,
      orderDir,
      filterName,
      selectedTags,
      this._determineShowEnabled(filterBy),
      this._determineShowDisabled(filterBy)
    )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        this.props.showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(this.props.hideLoadingBar)
          .catch(this.props.hideLoadingBar)
      })
      .catch(_noop)
  }

  selectFilterTag = tagId => () => {
    const { filterValues, fetchWorkspaceList, setFilterByTags } = this.props

    const searchText = _get(filterValues, "search", "")
    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const filterBy = _get(filterValues, "filterBy", "all")

    if (!selectedTags.includes(tagId)) {
      const newTags = [...selectedTags, tagId]
      fetchWorkspaceList(
        0,
        WORKSPACE.LOADING_LIMIT,
        1,
        orderBy,
        orderDir,
        searchText,
        newTags,
        this._determineShowEnabled(filterBy),
        this._determineShowDisabled(filterBy)
      )
        .then(response => {
          const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
          this.props.showLoadingBar()
          this.props
            .fetchLastWorkspaceJobs(workspaceIds, 0)
            .then(this.props.hideLoadingBar)
            .catch(this.props.hideLoadingBar)
        })
        .catch(_noop)

      setFilterByTags("WorkspaceFilter", newTags)
    }
  }

  deselectFilterTag = tagId => () => {
    const { filterValues, fetchWorkspaceList, setFilterByTags } = this.props

    const searchText = _get(filterValues, "search", "")
    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const filterBy = _get(filterValues, "filterBy", "all")

    let newTags = [...selectedTags]
    newTags = newTags.filter(tag => tag !== tagId)
    fetchWorkspaceList(
      0,
      WORKSPACE.LOADING_LIMIT,
      1,
      orderBy,
      orderDir,
      searchText,
      newTags,
      this._determineShowEnabled(filterBy),
      this._determineShowDisabled(filterBy)
    )
      .then(response => {
        const workspaceIds = response.value.workspaces.map(workspace => workspace.id)
        this.props.showLoadingBar()
        this.props
          .fetchLastWorkspaceJobs(workspaceIds, 0)
          .then(this.props.hideLoadingBar)
          .catch(this.props.hideLoadingBar)
      })
      .catch(_noop)

    setFilterByTags("WorkspaceFilter", newTags)
  }

  setLayout = type => () => {
    const { setUiAttribute, authenticatedUser } = this.props
    const setLayout = _get(authenticatedUser, "ui.workspacesListLayout", "one-col")
    if (type !== setLayout) {
      setUiAttribute("workspacesListLayout", type)
    }
  }

  renderWorkspaces = () => {
    const { workspaces, lastWorkspacesJobs, areTagsAlreadyFetched, authenticatedUser } = this.props
    const { expandedSchedules, compactWorkspaceBoxesHeights } = this.state

    if (!areTagsAlreadyFetched || !List.isList(workspaces)) {
      return null
    }

    if (workspaces.size === 0) {
      return <div className="no-workspaces-found">No workspaces found.</div>
    }

    const renderWorkspace = (workspace, isLast = false) => {
      const workspaceJob =
        lastWorkspacesJobs !== null ? lastWorkspacesJobs.get(_toString(workspace.id)) : null

      return (
        <WorkspaceListItem
          key={workspace.id}
          authenticatedUser={authenticatedUser}
          job={workspaceJob}
          workspace={workspace}
          selectFilterTag={this.selectFilterTag}
          expandedSchedules={expandedSchedules}
          expandWorkspaceSchedules={this.expandWorkspaceSchedules}
          invisible={_isNil(compactWorkspaceBoxesHeights.get(_toString(workspace.id)))}
          ref={el => {
            this.compactWorkspaceBoxesRefs[workspace.id] = el
          }}
          onInit={() => {
            if (!_isNumber(this.compactWorkspaceBoxesHeightsTmp[workspace.id])) {
              this.compactWorkspaceBoxesHeightsTmp[workspace.id] = this.compactWorkspaceBoxesRefs[
                workspace.id
              ].getBoundingClientRect().height
            }
            if (
              isLast &&
              !this.state.compactWorkspaceBoxesHeights.equals(
                Map(this.compactWorkspaceBoxesHeightsTmp)
              )
            ) {
              this.setState({
                compactWorkspaceBoxesHeights: Map(this.compactWorkspaceBoxesHeightsTmp)
              })
            }
          }}
        />
      )
    }

    const compactView = _get(authenticatedUser, "ui.workspacesListLayout", "one-col") === "two-cols"
    const invisible = compactWorkspaceBoxesHeights.size < workspaces.size
    if (compactView) {
      let leftColHeight = 0,
        rightColHeight = 0
      const left = [],
        right = []
      workspaces.forEach(workspace => {
        if (leftColHeight <= rightColHeight) {
          left.push(workspace)
          leftColHeight += compactWorkspaceBoxesHeights.get(_toString(workspace.id))
        } else {
          right.push(workspace)
          rightColHeight += compactWorkspaceBoxesHeights.get(_toString(workspace.id))
        }
      })
      const lastWorkspace = workspaces.last()
      if (!lastWorkspace) {
        return null
      }
      return (
        <React.Fragment>
          <div className="two-cols">
            <div className="left-col">
              {left.map(workspace => {
                return renderWorkspace(workspace, lastWorkspace.id === workspace.id)
              })}
            </div>
            <div className="right-col">
              {right.map(workspace => {
                return renderWorkspace(workspace, lastWorkspace.id === workspace.id)
              })}
            </div>
          </div>
          {!invisible && this.renderWaypoint()}
        </React.Fragment>
      )
    } else {
      return (
        <div style={{ position: "relative" }}>
          {workspaces.map((workspace, index) => {
            return renderWorkspace(workspace, index + 1 === workspaces.size)
          })}
          {!invisible && this.renderWaypoint()}
        </div>
      )
    }
  }

  render() {
    const { projectConfJobs, authenticatedUser, createWorkspace, fetchUsersAcl } = this.props
    const { createWorkspaceModalOpen } = this.state
    const isAbleToCreateWorkspaces = canCreateWorkspaces()
    const currentLayout = _get(authenticatedUser, "ui.workspacesListLayout", "one-col")

    return (
      <section className="workspaces-dashboard">
        <div className="left-column">
          <Header
            className="ws-list-header"
            title="Workspaces"
            onSearchSubmit={this.onSearchSubmit}
            searchFormName="WorkspaceFilter"
            toggleCreateActionModal={this.toggleCreateWorkspaceModalOpen}
            isAbleToCreateEntity={isAbleToCreateWorkspaces}
            entityName="workspace"
            isViewPickerEnabled={true}
            currentViewLayout={currentLayout}
            setViewLayout={this.setLayout}
          />
          <SubHeader
            globalFormName="WorkspaceFilter"
            sortingFormName="WorkspaceSortingForm"
            filterFormName="WorkspaceFilterForm"
            onSortSubmit={this.onSortSubmit}
            onFilterBySubmit={this.onFilterBySubmit}
            selectFilterTag={this.selectFilterTag}
            deselectFilterTag={this.deselectFilterTag}
            entityName="workspace"
          />
          {this.renderWorkspaces()}
        </div>
        <div className="right-column">
          <ProjectJobsCard entityName="configuration" projectJobs={projectConfJobs} />
        </div>
        <WorkspacesActivityDrawer />
        <CreateActionModal
          open={createWorkspaceModalOpen}
          handleClose={this.toggleCreateWorkspaceModalOpen}
          createAction={createWorkspace}
          fetchUsersAcl={fetchUsersAcl}
          entityName="Workspace"
        />
      </section>
    )
  }
}

WorkspaceList.propTypes = {
  workspaces: PropTypes.instanceOf(List),
  tags: PropTypes.instanceOf(Map).isRequired,
  setFilterByTags: PropTypes.func.isRequired,
  setSortingOptions: PropTypes.func.isRequired,
  setFilterAndSorting: PropTypes.func.isRequired,
  projectConfJobs: PropTypes.instanceOf(List),
  lastWorkspacesJobs: PropTypes.instanceOf(Map),
  fetchWorkspaceList: PropTypes.func.isRequired,
  retrieveWorkspace: PropTypes.func.isRequired,
  listProjectConfigurationJob: PropTypes.func.isRequired,
  fetchLastWorkspaceJobs: PropTypes.func.isRequired,
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  hasWorkspacesMoreItems: PropTypes.bool.isRequired,
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  clearCachedConfigurationJobs: PropTypes.func.isRequired,
  clearCachedConfigurationHistory: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.object,
  setUiAttribute: PropTypes.func.isRequired,
  areTagsAlreadyFetched: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    workspaces: getWorkspaces(state),
    tags: getTagsMap(state),
    filterValues: getFormValues("WorkspaceFilter")(state),
    projectConfJobs: getDashboardConfJobs(state),
    lastWorkspacesJobs: getLastWorkspacesJobs(state),
    selectionSettings: getWorkspacesSelectionSettings(state),
    hasWorkspacesMoreItems: getWorkspacesHasMoreItems(state),
    authenticatedUser: state.authenticatedUser,
    areTagsAlreadyFetched: areTagsAlreadyFetched(state)
  }
}

export default connect(mapStateToProps, {
  fetchWorkspaceList,
  retrieveWorkspace,
  listProjectConfigurationJob,
  fetchLastWorkspaceJobs,
  setFilterBy,
  setFilterByTags,
  setSortingOptions,
  setFilterAndSorting,
  showLoadingBar,
  hideLoadingBar,
  clearCachedConfigurationJobs,
  clearCachedWorkspaceJobs,
  clearCachedWorkspaceHistory,
  clearCachedConfigurationHistory,
  setUiAttribute,
  createWorkspace,
  fetchUsersAcl
})(WorkspaceList)
