import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { List, Map } from "immutable"
import _includes from "lodash/includes"
import _toInteger from "lodash/toInteger"
import _map from "lodash/map"
import _each from "lodash/each"
import _isNaN from "lodash/isNaN"
import _inRange from "lodash/inRange"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// helpers
import { convertToRomanNum } from "helpers/romanNumeral.helper"

// ui components
import Button from "components/UI/elements/Button"
import AddInputbox from "components/UI/components/AddInputbox/AddInputbox"
import Paper from "components/UI/elements/Paper"
import CommonHeader from "./CommonHeader"

// constants
import { ADD_INPUTBOX_TYPE, SCHEDULE_FORM_TYPE } from "sharedConstants"

class DayOfMonthForm extends PureComponent {
  constructor(props) {
    super(props)

    const { schedules } = this.props
    if (schedules.size === 0) {
      this.state = {
        activeTab: 0,
        schedules: List([Map({ minutes: "", hours: "", days: List() })]),
        loading: false
      }
    } else {
      const schedule = schedules.first()

      if (_includes(schedule.get("day"), "*")) {
        this.state = {
          activeTab: 0,
          schedules: List([Map({ minutes: "", hours: "", days: List() })]),
          loading: false
        }
      } else {
        const schedulesTransformed = schedules.map(schedule => {
          const minutes = moment({
            minute: _toInteger(schedule.get("minute"))
          }).format("mm")
          const hours = moment.utc({ hour: _toInteger(schedule.get("hour")) }).hours()
          const days = List(_map(schedule.get("day").split(","), day => _toInteger(day)))

          return Map({ minutes, hours, days })
        })
        this.state = {
          activeTab: 0,
          schedules: schedulesTransformed,
          loading: false
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleCalendarButtonsResize)
    this.handleCalendarButtonsResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalendarButtonsResize)
  }

  handleCalendarButtonsResize = () => {
    const elements = document.getElementsByClassName("day-of-month-button")
    _each(elements, el => {
      el.style.height = `${el.offsetWidth}px`
    })
  }

  addSchedule = () => {
    this.setState(prevState => ({
      activeTab: prevState.schedules.size,
      schedules: prevState.schedules.push(Map({ minutes: "", hours: "", days: List() }))
    }))
  }

  deleteSchedule = () => {
    const { activeTab, schedules } = this.state
    if (schedules.size === 1) {
      // erase last schedule, don't delete tab
      this.setState({
        schedules: List([Map({ minutes: "", hours: "", days: List() })])
      })
    } else {
      this.setState(prevState => ({
        activeTab: 0,
        schedules: prevState.schedules.filterNot((schedule, sidx) => activeTab === sidx)
      }))
    }
  }

  selectActiveSchedule = idx => () => {
    this.setState({
      activeTab: idx
    })
  }

  isScheduleTimeValid = schedule => {
    if (
      _isNaN(schedule.get("minutes")) ||
      _isNaN(schedule.get("hours")) ||
      !_inRange(schedule.get("hours"), 0, 24) ||
      !_inRange(schedule.get("minutes"), 0, 60)
    ) {
      return false
    }
    return true
  }

  handleSubmit = () => {
    if (!this.state.loading) {
      const { schedules } = this.state

      let valid = true
      const schedulesCheck = schedules.map(schedule => {
        if (schedule.get("days").size === 0) {
          valid = false
          if (!this.isScheduleTimeValid(schedule)) {
            return schedule.set("dayError", true).set("timeError", true)
          }
          return schedule.set("dayError", true)
        } else {
          if (!this.isScheduleTimeValid(schedule)) {
            valid = false
            return schedule.set("timeError", true)
          }
        }
        return schedule
      })

      if (valid) {
        // submit
        this.setState({ loading: true })
        const schedulesUtc = schedules.map(schedule => {
          return {
            minutes: _toInteger(schedule.get("minutes")),
            hours: moment.utc({ hour: _toInteger(schedule.get("hours")) }).hours(),
            days: schedule.get("days").toArray()
          }
        })
        this.props.onSubmit({ schedules: schedulesUtc.toArray() }).catch(() => {
          this.setState({ loading: false })
        })
      } else {
        this.setState({
          schedules: schedulesCheck
        })
      }
    }
  }

  handleTimeChange = evt => {
    const { activeTab, schedules } = this.state
    const { value, name } = evt.target

    this.setState({
      schedules: schedules.map((schedule, sidx) => {
        if (sidx === activeTab) {
          return schedule.set(name, value).set("timeError", false)
        }
        return schedule
      })
    })
  }

  isDayOfMonthSelected = dayNumber => {
    const { activeTab } = this.state
    const schedule = this.state.schedules.get(activeTab)

    return schedule.get("days").includes(dayNumber)
  }

  toggleDayOfMonth = dayNumber => {
    const { activeTab } = this.state

    if (this.isDayOfMonthSelected(dayNumber)) {
      this.setState(prevState => ({
        schedules: prevState.schedules.map((schedule, sidx) => {
          if (sidx === activeTab) {
            return schedule.set(
              "days",
              schedule.get("days").filterNot(day => day === dayNumber)
            )
          }
          return schedule
        })
      }))
    } else {
      this.setState(prevState => ({
        schedules: prevState.schedules.map((schedule, sidx) => {
          if (sidx === activeTab) {
            return schedule.set("days", schedule.get("days").push(dayNumber)).set("dayError", false)
          }
          return schedule
        })
      }))
    }
  }

  renderCalendarButtons = () => {
    let buttons = []
    for (let i = 1; i <= 28; i++) {
      const active = this.isDayOfMonthSelected(i) ? "active" : ""
      buttons.push(
        <button
          type="button"
          key={i}
          onClick={() => this.toggleDayOfMonth(i)}
          className={`calendar-button day-of-month-button ${active}`}
        >
          {i}
        </button>
      )
    }
    return buttons
  }

  render() {
    const { schedules, activeTab, loading } = this.state
    const {
      notificationEmails,
      handleEmailDelete,
      handleEmailAdd,
      handleFormTypeChange,
      handleFormClose,
      handleEntireSchedulesDelete
    } = this.props

    return (
      <div>
        <CommonHeader
          handleClose={handleFormClose}
          formType={SCHEDULE_FORM_TYPE.DAY_OF_MONTH}
          handleSubmit={this.handleSubmit}
          handleFormTypeChange={handleFormTypeChange}
          loading={loading}
          handleEntireSchedulesDelete={handleEntireSchedulesDelete}
        />
        <Paper hasHeader={true} className="schedule-form">
          <div className="schedule-topbar">
            <div className="tabs">
              {schedules.map((schedule, idx) => {
                let color = "grey"
                if (activeTab === idx) {
                  color = "primary"
                }
                if (schedule.get("dayError") || schedule.get("timeError")) {
                  color = "red"
                }
                return (
                  <Button
                    key={idx}
                    className="tab"
                    onClick={this.selectActiveSchedule(idx)}
                    color={color}
                    size="small"
                  >
                    Time {convertToRomanNum(idx + 1)}
                  </Button>
                )
              })}
              {schedules.size < 8 && (
                <Button
                  type="button"
                  color="white"
                  className="tab"
                  onClick={this.addSchedule}
                  size="small"
                >
                  + ADD TIME
                </Button>
              )}
            </div>
          </div>
          <div className="schedule-middlebar">
            <div className="calendar">
              <p className="grey-label">Calendar</p>
              {this.renderCalendarButtons()}
              {schedules.getIn([activeTab, "dayError"]) && (
                <p className="error-message">Please select at least one day</p>
              )}
            </div>
            <div className="vertical-line-long" />
            <div className="once-section">
              <div className="time">
                <p className="grey-label">Time (in UTC)</p>
                <div
                  className={`time-row text-field ${
                    schedules.getIn([activeTab, "timeError"]) ? "error" : ""
                  }`}
                >
                  <input
                    type="text"
                    name="hours"
                    value={schedules.getIn([activeTab, "hours"])}
                    onChange={this.handleTimeChange}
                    maxLength="2"
                    className="time-input"
                    placeholder="0"
                  />
                  :
                  <input
                    type="text"
                    name="minutes"
                    value={schedules.getIn([activeTab, "minutes"])}
                    onChange={this.handleTimeChange}
                    maxLength="2"
                    className="time-input"
                    placeholder="00"
                  />
                </div>
                {schedules.getIn([activeTab, "timeError"]) && (
                  <p className="error-message">Please enter the correct time (0-23:0-59)</p>
                )}
              </div>
            </div>
            <div className="delete-time-section">
              <Button
                color="transparent-red"
                type="button"
                onClick={this.deleteSchedule}
                size="small"
                className="delete-time-button"
              >
                <FontAwesomeIcon className="bin-icon" icon={["far", "trash-alt"]} /> Scheduled Time
              </Button>
            </div>
          </div>
          <div className="schedule-bottombar">
            <AddInputbox
              values={notificationEmails}
              handleValueAdd={handleEmailAdd}
              handleValueDelete={handleEmailDelete}
              type={ADD_INPUTBOX_TYPE.EMAIL}
              className="notify-emails"
            />
          </div>
        </Paper>
      </div>
    )
  }
}

DayOfMonthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  schedules: PropTypes.instanceOf(List).isRequired,
  notificationEmails: PropTypes.instanceOf(List).isRequired,
  handleEmailAdd: PropTypes.func.isRequired,
  handleEmailDelete: PropTypes.func.isRequired,
  handleFormTypeChange: PropTypes.func.isRequired,
  handleFormClose: PropTypes.func.isRequired,
  handleEntireSchedulesDelete: PropTypes.func
}

export default DayOfMonthForm
