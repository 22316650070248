import React, { PureComponent } from "react"
import hljs from "highlight.js"
import PropTypes from "prop-types"

import "highlight.js/styles/github.css"

class CodeBlock extends PureComponent {
  constructor(props) {
    super(props)
    this.codeEl = React.createRef()
  }

  componentDidMount() {
    this.highlightCode()
  }

  componentDidUpdate() {
    this.highlightCode()
  }

  highlightCode = () => {
    hljs.highlightBlock(this.codeEl.current)
  }

  render() {
    return (
      <pre>
        <code ref={this.codeEl} className={this.props.language ? this.props.language : ""}>
          {this.props.value}
        </code>
      </pre>
    )
  }
}

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string
}

export default CodeBlock
