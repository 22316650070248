import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import _isNull from "lodash/isNull"
import _isString from "lodash/isString"
import _isFunction from "lodash/isFunction"
import { CSSTransition } from "react-transition-group"

import "./DropdownMenu.css"

const DropdownContext = React.createContext()

/*
 * Dropdown toggle button item
 */
export class DropdownToggle extends Component {
  render() {
    const { children, className } = this.props
    return (
      <DropdownContext.Consumer>
        {({ toggle }) => (
          <button
            type="button"
            onClick={toggle}
            className={`toggle-button ${className ? className : ""}`}
            data-testid="toggle-button"
          >
            {children}
          </button>
        )}
      </DropdownContext.Consumer>
    )
  }
}

DropdownToggle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

/*
 * Dropdown content area
 */
export class DropdownContent extends Component {
  render() {
    const { children, className } = this.props
    return (
      <DropdownContext.Consumer>
        {({ open, align = "left" }) => (
          <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
            <div className={`dropdown-content ${align}`}>
              <ul className={`dropdown-list ${className ? className : ""}`}>{children}</ul>
            </div>
          </CSSTransition>
        )}
      </DropdownContext.Consumer>
    )
  }
}

DropdownContent.propTypes = {
  className: PropTypes.string
}

/*
 * Dropdown menu item, if you want to use it as menu
 */
export class DropdownMenuItem extends Component {
  anchorOnclick = (toggle, action) => () => {
    const { hideMenuOnClick = true } = this.props
    action()
    if (hideMenuOnClick) {
      toggle()
    }
  }

  render() {
    const { children, className, active, link, action, hidden = false } = this.props

    if (hidden) {
      return null
    }

    return (
      <DropdownContext.Consumer>
        {({ toggle }) => (
          <li>
            {link && (
              <Link
                className={`${className ? className : ""} ${active ? "active" : ""}`}
                to={link}
                onClick={toggle}
              >
                {children}
              </Link>
            )}
            {action && (
              <span
                className={`link-like ${className ? className : ""} ${active ? "active" : ""}`}
                onClick={this.anchorOnclick(toggle, action)}
                data-testid="anchor-menu-item"
              >
                {children}
              </span>
            )}
          </li>
        )}
      </DropdownContext.Consumer>
    )
  }
}

/* istanbul ignore next */
DropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  hidden: PropTypes.bool,
  hideMenuOnClick: PropTypes.bool,
  link: (props, propName, componentName) => {
    if (!props.link && !props.action) {
      return new Error(`One of props 'link' or 'action' was not specified in '${componentName}'.`)
    }
    if (props.link && !_isString(props.link)) {
      return new Error(`'link' property of component '${componentName}' has to be valid string.`)
    }
  },
  action: (props, propName, componentName) => {
    if (!props.link && !props.action) {
      return new Error(`One of props 'action' or 'link' was not specified in '${componentName}'.`)
    }
    if (props.action && !_isFunction(props.action)) {
      return new Error(`'action' property of component '${componentName}' has to be function.`)
    }
  }
}

/*
 * Dropdown wrapper element
 */

class DropdownMenu extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      // dropdown opened
      document.addEventListener("click", this._handleOutsideClick, false)
    } else if (!this.props.open && prevProps.open) {
      // dropdown closed
      document.removeEventListener("click", this._handleOutsideClick, false)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleOutsideClick, false)
  }

  _handleOutsideClick = evt => {
    if (!_isNull(this.dropdown)) {
      if (!this.dropdown.contains(evt.target)) {
        this.props.toggle()
      }
    }
  }

  render() {
    const { className, open, children, align, toggle } = this.props

    return (
      <DropdownContext.Provider value={{ open, align, toggle }}>
        <div
          className={`dropdown ${className ? className : ""}`}
          ref={node => (this.dropdown = node)}
        >
          {children}
        </div>
      </DropdownContext.Provider>
    )
  }
}

DropdownMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default DropdownMenu
