import React from "react"

const ObjectFieldTemplate = ({ title, properties, description }) => {
  return (
    <div className="custom-object-field content-padding">
      {title && <h4 className="form-part-title">{title}</h4>}
      <div className="row">
        {properties.map(prop => (
          <div className="text-field" key={prop.content.key}>
            {prop.content}
          </div>
        ))}
      </div>
      {description}
    </div>
  )
}

export default ObjectFieldTemplate
