import React from "react"
import { List, Map } from "immutable"
import _includes from "lodash/includes"
import _last from "lodash/last"
import _first from "lodash/first"
import _map from "lodash/map"
import _toInteger from "lodash/toInteger"
import moment from "moment"
import parser from "cron-parser"

const dayMappings = {
  "0": "Sun",
  "1": "Mon",
  "2": "Tue",
  "3": "Wed",
  "4": "Thu",
  "5": "Fri",
  "6": "Sat",
  "7": "Sun"
}

export const generateSchedulesReadableObject = schedules => {
  let schedulesReadable = List()
  if (List.isList(schedules) && schedules.size > 0) {
    const schedule = schedules.first()
    if (schedule.get("day") !== "*") {
      // day of month
      schedulesReadable = schedules.map(schedule =>
        Map({
          days: schedule
            .get("day")
            .split(",")
            .join(", "),
          hours: schedule.get("hour"),
          minutes: schedule.get("minute")
        })
      )
    } else {
      // day of weak
      let repeat = ""
      if (_includes(schedule.get("minute"), "/")) {
        const parts = schedule.get("minute").split("/")
        repeat = `${_last(parts)} mins`
        const startingAt = _first(_first(parts).split("-"))
        if (startingAt !== "*") {
          repeat = `${repeat} delayed by ${startingAt} min`
        }
        if (_includes(schedule.get("hour"), "-")) {
          const parts = schedule.get("hour").split("-")
          const from = parts[0]
          let to
          if (schedules.size > 1) {
            // 'to' value will be in the second schedule because it crossed the day
            const endSchedule = schedules.get(1)
            const parts = endSchedule.get("hour").split("-")
            to = parts[1]
          } else {
            to = parts[1]
          }
          repeat = `${repeat} from ${from} hour to ${to} hour`
        }
      } else if (_includes(schedule.get("hour"), "/")) {
        const parts = schedule.get("hour").split("/")
        const repeatHoursNumber = _toInteger(_last(parts))
        repeat = `${_last(parts)} hours`
        let startingAt, from, to
        const toParse = _first(parts)

        if (_first(toParse.split("-")) !== "*") {
          const hourParts = toParse.split("-")
          if (schedules.size === 1) {
            if (_toInteger(hourParts[0] < repeatHoursNumber) && hourParts[1] === "23") {
              // delayed by
              startingAt = hourParts[0]
            } else {
              // from / to
              from = hourParts[0]
              to = hourParts[1]
            }
          } else {
            // from/to crossing the day
            from = hourParts[0]
            const endSchedule = schedules.get(1)
            const parts = endSchedule.get("hour").split("/")
            to = _first(parts).split("-")[1]
          }
        }
        if (startingAt) {
          repeat = `${repeat} delayed by ${startingAt} hour`
        } else if (from && to) {
          repeat = `${repeat} from ${from} hour to ${to} hour`
        }
      }

      if (repeat !== "") {
        // repeat
        const daysArray = schedule.get("day_of_week").split(",")
        schedulesReadable = List([
          Map({
            days:
              daysArray.length === 7
                ? "every day"
                : _map(daysArray, day => dayMappings[day]).join(", "),
            repeat
          })
        ])
      } else {
        // once
        schedulesReadable = schedules.map(schedule => {
          const daysArray = schedule.get("day_of_week").split(",")
          return Map({
            days:
              daysArray.length === 7
                ? "every day"
                : _map(daysArray, day => dayMappings[day]).join(", "),
            minutes: schedule.get("minute"),
            hours: schedule.get("hour")
          })
        })
      }
    }
  }
  return schedulesReadable
}

export const getSetSchedulesDescription = schedulesReadable => {
  const schedule = schedulesReadable.first()

  if (schedule.get("repeat")) {
    const days = schedule.get("days")
    if (days === "every day") {
      return (
        <React.Fragment>
          <strong>{days}</strong> every <strong>{schedule.get("repeat")}</strong>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          on <strong>{days}</strong> every <strong>{schedule.get("repeat")}</strong>
        </React.Fragment>
      )
    }
  } else {
    const schedules = schedulesReadable.map((schedule, idx) => {
      return (
        <React.Fragment key={idx}>
          {idx > 0 && " / on "}
          <strong>{schedule.get("days")}</strong> at{" "}
          <strong>
            {moment
              .utc({
                hour: _toInteger(schedule.get("hours")),
                minute: _toInteger(schedule.get("minutes"))
              })
              .format("HH:mm")}
          </strong>
        </React.Fragment>
      )
    })
    return <React.Fragment>on {schedules}</React.Fragment>
  }
}

export const getSetSchedulesDescriptionArray = schedulesReadable => {
  const schedule = schedulesReadable.first()
  if (schedule.get("repeat")) {
    // only one schedule exists
    const days = schedule.get("days")
    if (days === "every day") {
      return List([
        <React.Fragment>
          <strong>{days}</strong> every <strong>{schedule.get("repeat")}</strong>
        </React.Fragment>
      ])
    } else {
      return List([
        <React.Fragment>
          on <strong>{days}</strong> every <strong>{schedule.get("repeat")}</strong>
        </React.Fragment>
      ])
    }
  } else {
    return schedulesReadable.map((schedule, idx) => {
      return (
        <React.Fragment key={idx}>
          on <strong>{schedule.get("days")}</strong> at{" "}
          <strong>
            {moment
              .utc({
                hour: _toInteger(schedule.get("hours")),
                minute: _toInteger(schedule.get("minutes"))
              })
              .format("HH:mm")}
          </strong>
        </React.Fragment>
      )
    })
  }
}

export const computeNextSchedulesDates = schedules => {
  if (List.isList(schedules)) {
    const schedulesCronStrings = schedules.map(
      schedule =>
        `${schedule.get("minute")} ${schedule.get("hour")} ${schedule.get("day")} ${schedule.get(
          "month"
        )} ${schedule.get("day_of_week")}`
    )
    let nextSchedulesDates = List()
    try {
      const intervals = schedulesCronStrings.map(cronString =>
        parser.parseExpression(cronString, { utc: true })
      )
      nextSchedulesDates = intervals.map(interval => {
        let dates = List()
        for (let i = 0; i < 3; i++) {
          dates = dates.set(i, moment.utc(interval.next().toISOString()))
        }
        return dates
      })
    } catch (err) {
      console.log(err)
    }
    return nextSchedulesDates
      .flatten()
      .sort()
      .slice(0, 3)
  }
  return null
}
