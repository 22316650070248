import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import { Link, withRouter } from "react-router-dom"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers
import { getRoutePath } from "routes"
import Username from "helpers/Username.helper"

import "./EntityJobsCard.css"
import { capitalize } from "helpers/string.helper"

class EntityJobsCard extends PureComponent {
  render() {
    const {
      entityName,
      jobs,
      className,
      hideViewAll,
      match: {
        params: { id: entityId }
      }
    } = this.props

    let allPathname
    if (entityName === "dawg") {
      allPathname = "dawg.dawgJob.list"
    } else {
      allPathname = "workspace.workspaceJob.list"
    }
    return (
      <React.Fragment>
        <PaperHeader
          size="small"
          className={`entity-jobs-card-header ${className ? className : ""}`}
        >
          <h3>{capitalize(entityName)} activity</h3>
          {!hideViewAll && (
            <Link
              className="button primary small"
              to={{
                pathname: getRoutePath(allPathname, {
                  id: entityId
                }),
                state: {
                  previous: this.props.location
                }
              }}
            >
              View all
            </Link>
          )}
        </PaperHeader>
        {List.isList(jobs) && jobs.size > 0 && (
          <Paper hasHeader={true} className="entity-jobs-card-list">
            {jobs.map(job => {
              let pathname, params
              if (entityName === "dawg") {
                pathname = "dawg.dawgJob.show"
                params = { id: entityId, aid: job.id }
              } else {
                pathname = "workspace.workspaceJob.show"
                params = { id: entityId, aid: job.id }
              }
              return (
                <Link
                  to={{
                    pathname: getRoutePath(pathname, params),
                    state: {
                      previous: this.props.location
                    }
                  }}
                  className="job-item"
                  key={job.id}
                >
                  <div className="content-border">
                    <div className="left-part">
                      <p className="user">
                        <Username userId={job.user_id} />
                      </p>
                      <div className="date-info">
                        <DateTimeWithTooltip
                          dateTime={job.created}
                          uniqueTooltipId={`entity-job-date-tooltip-${job.id}`}
                        />
                      </div>
                    </div>
                    <StatusElement
                      align="right"
                      status={job.status}
                      history={job.stats ? job.stats.get("statuses_history") : Map()}
                      created={job.origin_created ? job.origin_created : job.created}
                      showDuration={true}
                    />
                  </div>
                </Link>
              )
            })}
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

EntityJobsCard.propTypes = {
  entityName: PropTypes.string.isRequired,
  jobs: PropTypes.instanceOf(List),
  className: PropTypes.string,
  hideViewAll: PropTypes.bool
}

export default withRouter(EntityJobsCard)
