import { DAWG } from "sharedConstants"

import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import { WorkspaceJobFull } from "models/workspaceJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map()

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case DAWG.WORKSPACE.JOB.ACTION.LIST:
    case `${DAWG.WORKSPACE.JOB.ACTION.LIST}_FULFILLED`:
      return Map({
        [meta.dawgId]: Map({
          data: List(_map(payload.workspace_jobs, wsJob => new WorkspaceJobFull(fromJS(wsJob)))),
          selectionSettings: new SelectionSettingModel(payload.selection_settings)
        })
      })
    default:
      return state
  }
}
