import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./OrderField.css"

const OrderField = ({ input }) => (
  <div className="order-field-wrapper">
    <input {...input} id="order-checkbox" type="checkbox" />
    <label
      htmlFor="order-checkbox"
      className={`order-button ${input.value ? "checked" : "unchecked"}`}
    >
      {input.value ? (
        <FontAwesomeIcon icon={["far", "sort-alpha-down"]} />
      ) : (
        <FontAwesomeIcon icon={["far", "sort-alpha-up"]} />
      )}
    </label>
  </div>
)

OrderField.propTypes = {
  input: PropTypes.object.isRequired
}

export default OrderField
