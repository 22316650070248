import React from "react"
import Button from "components/UI/elements/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ArrayFieldTemplate = props => {
  return (
    <div className={`${props.className} custom-array-field`}>
      {props.items &&
        props.items.map(element => (
          <div key={element.index} className="custom-array-field-item">
            <div>{element.children}</div>
            <Button
              onClick={element.onDropIndexClick(element.index)}
              className="delete-button"
              size="small"
              color="transparent-red"
              type="button"
            >
              <FontAwesomeIcon className="icon" icon={["far", "trash-alt"]} /> Delete
            </Button>
          </div>
        ))}

      {props.canAdd && (
        <div className="row">
          <p className="array-item-add">
            <Button
              onClick={props.onAddClick}
              className="add-item-button"
              type="button"
              size="small"
              color="primary"
            >
              + Add {props.title}
            </Button>
          </p>
        </div>
      )}
    </div>
  )
}

export default ArrayFieldTemplate
