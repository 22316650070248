import { createSelector } from "reselect"
import _toString from "lodash/toString"
import _toInteger from "lodash/toInteger"
import { List } from "immutable"

export const getConfigurationJobsData = (state, configurationId) =>
  state.configurationJobs.getIn([_toString(configurationId), "data"])

export const getConfigurationJobsSelectionSettings = (state, configurationId) =>
  state.configurationJobs.getIn([_toString(configurationId), "selectionSettings"])

export const haveConfigurationJobsMoreItems = (state, configurationId) =>
  state.configurationJobs.getIn([_toString(configurationId), "hasMoreItems"]) === true

export const getLastConfigurationJobsData = createSelector(getConfigurationJobsData, data => {
  if (List.isList(data)) {
    return data.slice(0, 3)
  }
  return null
})

export const isConfigurationJobsFetching = (state, configurationId) =>
  state.configurationJobs.getIn([_toString(configurationId), "isFetching"]) === true

export const getConfigurationJobData = (state, configurationId, jobId) => {
  const data = getConfigurationJobsData(state, configurationId)
  if (List.isList(data)) {
    const jobIdInt = _toInteger(jobId)
    return data.find(job => job.id === jobIdInt)
  }
  return null
}
