import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _toString from "lodash/toString"
import _map from "lodash/map"
import _isArray from "lodash/isArray"
import _isNil from "lodash/isNil"
import _isEmpty from "lodash/isEmpty"
import Papa from "papaparse"
import { Link } from "react-router-dom"

// helpers
import { api, BASE_API_URL } from "api"
import { isCsv } from "helpers/filename.helper"
import { getRoutePath } from "routes"
import { formatBytes } from "helpers/dataUnits.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { goBackInHistory } from "helpers/backButton.helper"
import { getComponentIconSrc } from "helpers/component.helper"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// selectors
import { getComponentsData } from "selectors/component.selector"

import "./DataPreviewDetail.css"

const TABLE_VIEW = "table"
const CSV_VIEW = "csv"

class DataPreviewDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      file: _get(this.props, "location.state.file", null),
      sourceConfiguration: _get(this.props, "location.state.sourceConfiguration", null),
      viewType: TABLE_VIEW
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      location,
      history,
      match: {
        params: { id, cid, type, did }
      }
    } = this.props

    if (!this.state.file) {
      if (type === "in") {
        const dataPromise = this.pendingPromises.create(
          api().configuration.dataPreview.in.retrieve(id, cid, did)
        )
        dataPromise.promise
          .then(response => {
            const configurationPromise = this.pendingPromises.create(
              api().configuration.retrieve(id, response.input_file_preview.parent_configuration_id)
            )
            configurationPromise.promise
              .then(response => {
                this.setState({
                  sourceConfiguration: response.configuration
                })
                this.pendingPromises.remove(configurationPromise)
              })
              .catch(() => {
                this.pendingPromises.remove(configurationPromise)
              })

            this.setState({
              file: response.input_file_preview
            })
            this.pendingPromises.remove(dataPromise)
          })
          .catch(() => {
            this.pendingPromises.remove(dataPromise)
          })
      } else if (type === "out") {
        const dataPromise = this.pendingPromises.create(
          api().configuration.dataPreview.out.retrieve(id, cid, did)
        )
        dataPromise.promise
          .then(response => {
            this.setState({
              file: response.output_file_preview
            })
            this.pendingPromises.remove(dataPromise)
          })
          .catch(() => {
            this.pendingPromises.remove(dataPromise)
          })
      }
    } else {
      // erase state, otherwise page reload will not re-fetch previewed file
      history.replace({
        pathname: location.path,
        state: {
          ...location.state,
          sourceConfiguration: null,
          file: null
        }
      })
    }
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
  }

  renderComponentsIcon = componentId => {
    const { components } = this.props

    if (components !== null) {
      const component = components.get(_toString(componentId))
      return <img src={getComponentIconSrc(component.icon)} alt={component.name} />
    }
  }

  _parseCsvHead = line => {
    const csvOutput = Papa.parse(line)
    if (_isArray(csvOutput.data) && csvOutput.data.length > 0) {
      return csvOutput.data[0].map((columnName, key) => <th key={key}>{columnName}</th>)
    }
    return null
  }

  _parseCsvContent = content => {
    if (!_isEmpty(content)) {
      const contentString = content.join("\r\n")
      const csvOutput = Papa.parse(contentString)
      if (_isArray(csvOutput.data) && csvOutput.data.length > 0) {
        return csvOutput.data.map((row, key) => (
          <tr key={key}>
            {row.map((value, valueKey) => (
              <td key={valueKey}>{value}</td>
            ))}
          </tr>
        ))
      }
    }
    return null
  }

  renderContent = () => {
    const { file, viewType } = this.state
    if (isCsv(file.file_name) && viewType === TABLE_VIEW && file.first_lines.length > 0) {
      const [head, ...content] = file.first_lines
      return (
        <div className="csv-table">
          <table>
            <thead>
              <tr>{this._parseCsvHead(head)}</tr>
            </thead>
            <tbody>{this._parseCsvContent(content)}</tbody>
          </table>
        </div>
      )
    } else {
      return (
        <div className="first-lines">
          {_map(file.first_lines, (line, index) => {
            return (
              <p key={index} className="line">
                <span className="line-number">{index + 1}</span>
                {line}
              </p>
            )
          })}
        </div>
      )
    }
  }

  toggleViewType = () => {
    this.setState(prevState => ({
      viewType: prevState.viewType === TABLE_VIEW ? CSV_VIEW : TABLE_VIEW
    }))
  }

  render() {
    const {
      history,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    const { file, sourceConfiguration, viewType } = this.state

    return (
      <section className="data-preview">
        {file && (
          <React.Fragment>
            <PaperHeader size="small" className="data-preview-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBackInHistory(
                    history,
                    getRoutePath("workspace.configuration.data.preview.list", {
                      id,
                      cid,
                      type
                    })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>{file.file_name}</h3>
              </div>
              <div className="buttons-block">
                {isCsv(file.file_name) && file.first_lines.length > 0 && (
                  <div className="table-csv-switch">
                    <label>Display: </label>
                    <ToggleSwitch
                      width="112px"
                      name="view-type"
                      leftValue={TABLE_VIEW}
                      rightValue={CSV_VIEW}
                      checked={viewType}
                      handleToggle={this.toggleViewType}
                      className="view-type-toggle"
                    />
                  </div>
                )}
                <Button
                  className="data-download"
                  color="primary"
                  size="big"
                  href={`${BASE_API_URL}/configuration_file?file_download_token=${file.file_download_token}`}
                  download
                  target="_blank"
                >
                  <FontAwesomeIcon className="icon" icon={["far", "download"]} /> Download
                </Button>
              </div>
            </PaperHeader>
            <Paper hasHeader={true} className="data-preview-content">
              <div className="file-info">
                <div className="stats">
                  <span>
                    Size: <strong>{formatBytes(file.file_size)}</strong>
                  </span>
                  {file.file_rows_count !== null && (
                    <span>
                      Rows: <strong>{file.file_rows_count}</strong>
                    </span>
                  )}
                  <span>
                    Last modified:{" "}
                    <strong>
                      <DateTimeWithTooltip
                        dateTime={file.last_modified_date}
                        uniqueTooltipId={`data-preview-${file.id}-tooltip`}
                      />
                    </strong>
                  </span>
                </div>
                {type === "in" && !_isNil(sourceConfiguration) && (
                  <div className="source-conf">
                    <span className="label">Source configuration:</span>
                    <Link
                      to={{
                        pathname: getRoutePath("workspace.configuration.show", {
                          id,
                          cid: sourceConfiguration.id
                        }),
                        state: {
                          previous: this.props.location.pathname
                        }
                      }}
                      className="configuration-badge"
                    >
                      {this.renderComponentsIcon(sourceConfiguration.component_id)}
                      <div className="right-part">
                        <h4>{sourceConfiguration.name}</h4>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              {this.renderContent()}
            </Paper>
          </React.Fragment>
        )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  components: getComponentsData(state)
})

export default connect(mapStateToProps)(DataPreviewDetail)
