import React from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import AsyncSelect from "react-select/async"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { selectStyles } from "helpers/customSelectStyle.helper"
import Avatar from "../Avatar"

import "./SelectField.css"

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={["fas", "caret-down"]} className="caret-down" />
    </components.DropdownIndicator>
  )
}

const SingleValue = props => {
  const { data } = props

  return (
    <components.SingleValue {...props}>
      <div className="select-single-value">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.label}
      </div>
    </components.SingleValue>
  )
}

const Option = props => {
  const { data } = props

  return (
    <components.Option {...props}>
      <div className="select-option">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.label}
      </div>
    </components.Option>
  )
}

const SelectField = ({
  input,
  label,
  meta: { touched, error },
  options,
  isSearchable = true,
  className = "",
  size = "medium",
  async = false,
  loadOptions = () => {},
  onChange = () => {},
  placeholder = "Search...",
  maxDropdownHeight = "300px",
  disabled = false
}) => {
  const onInputChange = input => value => {
    input.onChange(value)
    onChange()
  }

  const errorMessage = touched ? error : ""
  if (async) {
    return (
      <div className={`select-field ${className} ${errorMessage ? "error" : ""}`}>
        <label>{label}</label>
        <AsyncSelect
          styles={selectStyles()}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          placeholder={placeholder}
          onChange={onInputChange(input)}
          value={input.value}
          inputId="async-react-select-2-input"
          isDisabled={disabled}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    )
  }
  return (
    <div
      className={`select-field ${className} ${errorMessage ? "error" : ""}`}
      data-testid="select-field-wrapper"
    >
      <label data-testid="label">{label}</label>
      <Select
        value={input.value}
        onChange={onInputChange(input)}
        options={options}
        styles={selectStyles(size, "all", maxDropdownHeight)}
        isSearchable={isSearchable}
        components={{
          SingleValue: SingleValue,
          Option: Option,
          DropdownIndicator: DropdownIndicator
        }}
        classNamePrefix="select-field"
        inputId="react-select-2-input"
        isDisabled={disabled}
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  )
}

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  placeholder: PropTypes.string,
  maxDropdownHeight: PropTypes.string
}

export default SelectField
