import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import _pick from "lodash/pick"
import _toLower from "lodash/toLower"
import _trim from "lodash/trim"
import _toInteger from "lodash/toInteger"
import _get from "lodash/get"
import { Map } from "immutable"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import TextField from "components/UI/elements/TextField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"

// helpers
import { required, email } from "helpers/validators.helper"

// selectors
import { getAllUsersMap } from "selectors/user.selector"

// actions
import { modifyUser } from "actions/user.action"
import { showToast } from "actions/toast.action"
import { TOAST, USER } from "sharedConstants"

import "./ModifyUserModal.css"

class ModifyUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  onSubmit = values => {
    if (!this.state.loading) {
      this.setState({ loading: true })
      const { modifyUser, showToast, handleClose, initialValues } = this.props
      const user = _pick(values, ["name", "email", "password"])
      user.can_create_workspaces = _toInteger(values.can_create_workspaces)
      user.can_create_dawgs = _toInteger(values.can_create_dawgs)
      const initialUser = _pick(initialValues, [
        "name",
        "email",
        "can_create_workspaces",
        "can_create_dawgs"
      ])
      modifyUser(values.id, user, initialUser)
        .then(() => {
          handleClose()
          this.setState({ loading: false })
          showToast("User has been modified.", TOAST.TYPE.SUCCESS)
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  emailUnique = value => {
    const { users, initialValues } = this.props

    const filtered = users.filter(user => _toLower(_trim(user.email)) === _toLower(_trim(value)))
    if (filtered.size > 1) {
      return "User already exists"
    } else {
      let exists = false
      filtered.forEach(user => {
        if (user.id !== initialValues.id) {
          exists = true
        }
      })
      if (exists) {
        return "User already exists"
      }
    }
  }

  render() {
    const { open, handleSubmit, handleClose, initialValues } = this.props

    return (
      <Modal open={open} handleClose={handleClose} title="Modify user">
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-row">
            <Field
              name="name"
              component={TextField}
              label="Name *"
              placeholder="Name"
              validate={required}
              fullWidth={true}
              data-cy="user-name-field"
              autoFocus
            />
          </div>
          <div className="form-row">
            <Field
              name="email"
              component={TextField}
              label="Email *"
              validate={[required, email, this.emailUnique]}
              fullWidth={true}
              placeholder="Email"
              data-cy="user-email-field"
            />
          </div>
          <div className="form-row">
            <Field
              name="password"
              component={TextField}
              label="New password"
              fullWidth={true}
              placeholder="Fill in if you want to change"
              type="password"
              data-cy="user-password-field"
            />
          </div>
          {_get(initialValues, "role") === USER.ROLE.USER && (
            <div className="form-row toggles">
              <div className="can-create-workspaces">
                <ToggleSwitchField
                  name="can_create_workspaces"
                  leftValue="1"
                  leftLabel="on"
                  rightValue="0"
                  rightLabel="off"
                  width="100px"
                  label="Can create workspaces"
                />
              </div>
              <div className="can-create-dawgs">
                <ToggleSwitchField
                  name="can_create_dawgs"
                  leftValue="1"
                  leftLabel="on"
                  rightValue="0"
                  rightLabel="off"
                  width="100px"
                  label="Can create DAWGs"
                />
              </div>
            </div>
          )}

          <Button
            type="submit"
            color="primary"
            size="big"
            fullWidth={true}
            className={this.state.loading ? "loading" : ""}
            data-cy="user-submit-button"
          >
            Modify
          </Button>
        </form>
      </Modal>
    )
  }
}

ModifyUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modifyUser: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  users: PropTypes.instanceOf(Map).isRequired
}

const mapStateToProps = state => {
  return {
    users: getAllUsersMap(state)
  }
}

ModifyUserModal = reduxForm({
  form: "UserForm",
  touchOnBlur: false,
  enableReinitialize: true
})(connect(mapStateToProps, { modifyUser, showToast })(ModifyUserModal))

export default ModifyUserModal
