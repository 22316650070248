import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { List, Record } from "immutable"
import { connect } from "react-redux"

// actions
import { listDawgWorkspaceJob } from "actions/workspaceJob.action"

// selectors
import {
  getDawgWorkspaceJobs,
  getDawgWorkspaceJobsSelectionSettings
} from "selectors/dawgWorkspaceJob.selector"

import EntityChildrenJobsList from "components/UI/DawgWsShared/EntityChildrenJobsList"

class DawgWorkspaceJobList extends PureComponent {
  render() {
    const { jobs, selectionSettings, listDawgWorkspaceJob } = this.props
    return (
      <EntityChildrenJobsList
        jobs={jobs}
        selectionSettings={selectionSettings}
        listEntityChildrenJobs={listDawgWorkspaceJob}
        rootEntityName="dawg"
      />
    )
  }
}

DawgWorkspaceJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  listDawgWorkspaceJob: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  jobs: getDawgWorkspaceJobs(state, ownProps.match.params.id),
  selectionSettings: getDawgWorkspaceJobsSelectionSettings(state, ownProps.match.params.id)
})

export default connect(mapStateToProps, { listDawgWorkspaceJob })(DawgWorkspaceJobList)
