import { SYSTEM } from "sharedConstants"
import { Map } from "immutable"

export default (state = Map({ count: undefined }), { type, payload }) => {
  switch (type) {
    case `${SYSTEM.ACTION.GET_WORKERS_COUNT}_FULFILLED`:
      return state.set("count", payload)
    default:
      return state
  }
}
