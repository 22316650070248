import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Form from "react-jsonschema-form"
import { Map, Record } from "immutable"
import _isEmpty from "lodash/isEmpty"
import _forEach from "lodash/filter"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _unset from "lodash/unset"
import _isNil from "lodash/isNil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import * as flat from "flat"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import Paper from "components/UI/elements/Paper"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import ConfirmModal from "components/UI/components/ConfirmModal"
import OauthNoteModal from "./OauthNoteModal"

// json form custom templates
import ArrayFieldReadOnlyTemplate from "components/UI/components/JsonFormFieldTemplate/ArrayFieldReadOnlyTemplate"
import ArrayFieldTemplate from "components/UI/components/JsonFormFieldTemplate/ArrayFieldTemplate"
import ObjectFieldTemplate from "components/UI/components/JsonFormFieldTemplate/ObjectFieldTemplate"
import ObjectFieldTemplateCollapsable from "components/UI/components/JsonFormFieldTemplate/ObjectFieldTemplateCollapsable"
import FieldTemplate from "components/UI/components/JsonFormFieldTemplate/FieldTemplate"

// helpers
import { generateJsonFormUiSchema } from "helpers/jsonForm.helper"
import { isJSONString } from "helpers/validators.helper"
import ErrorBoundary from "helpers/ErrorBoundary.helper"

// constants
import { MODAL } from "sharedConstants"

// settings processors
import SettingsProcessor from "./processors/Processor"

// ace editor
import AceEditor from "react-ace"
import "brace/mode/json"
import "components/UI/elements/CodeEditorTheme/meiro"

import "./SettingsFormCard.css"

const FORM_TYPE = "form"
const JSON_TYPE = "json"

class SettingsFormCard extends PureComponent {
  constructor(props) {
    super(props)
    this.submitButtonRef = React.createRef()
    this.settingsProcessor = new SettingsProcessor(
      _get(props.component, "settings_template.processors", [])
    )

    // expand form by default, collapse only if code editors are below
    let expandAllVar = 1
    const codeParameters = _get(props.component, "settings_template.code_parameters", [])
    if (!_isEmpty(codeParameters)) {
      expandAllVar = 0
    }

    const viewUiSchema = generateJsonFormUiSchema(
      _get(props.component, "settings_template.dataschema", {}),
      _get(props.component, "settings_template.uischema", {}),
      true,
      true,
      expandAllVar
    )
    this.state = {
      jsonSettingsString: this._preProcessSettings(props.settings),
      isJsonInvalid: false,
      viewUiSchema: viewUiSchema.uiSchema,
      hasCollapsableElements: viewUiSchema.hasCollapsableElements,
      editUiSchema: generateJsonFormUiSchema(
        _get(props.component, "settings_template.dataschema", {}),
        _get(props.component, "settings_template.uischema", {}),
        false,
        false
      ),
      isAuthorized: this._isAuthorized(),
      type: FORM_TYPE,
      loading: false,
      resetConfirmModal: Map({
        open: false,
        isLoading: false
      }),
      expandAllVar,
      collapseAllVar: 0,
      authAccountModalOpen: false
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.configurationId &&
      this.props.configurationId &&
      prevProps.configurationId !== this.props.configurationId
    ) {
      // configuration changed
      this.settingsProcessor = new SettingsProcessor(
        _get(this.props, "component.settings_template.processors", [])
      )
      const viewUiSchema = generateJsonFormUiSchema(
        _get(this.props.component, "settings_template.dataschema", {}),
        _get(this.props.component, "settings_template.uischema", {}),
        true
      )
      this.setState({
        jsonSettingsString: this._preProcessSettings(this.props.settings),
        viewUiSchema: viewUiSchema.uiSchema,
        hasCollapsableElements: viewUiSchema.hasCollapsableElements,
        editUiSchema: generateJsonFormUiSchema(
          _get(this.props.component, "settings_template.dataschema", {}),
          _get(this.props.component, "settings_template.uischema", {}),
          false,
          false
        ),
        isAuthorized: this._isAuthorized(),
        type: FORM_TYPE,
        loading: false,
        resetConfirmModal: Map({
          open: false,
          isLoading: false
        }),
        expandAllVar: 0,
        collapseAllVar: 0,
        isJsonInvalid: false
      })
    } else {
      if (prevProps.settings !== this.props.settings) {
        this.setState({
          jsonSettingsString: this._preProcessSettings(this.props.settings),
          isJsonInvalid: false
        })
      }
      if (prevProps.oauthSettings !== this.props.oauthSettings) {
        this.setState({
          isAuthorized: this._isAuthorized()
        })
      }
    }
  }

  _isAuthorized = () => {
    const { authLink, oauthSettings } = this.props
    if (!authLink) {
      return true
    } else {
      if (Map.isMap(oauthSettings) && !_isNil(oauthSettings.get("token_request_response"))) {
        return true
      }
      return false
    }
  }

  /*
   * @param settings 	of type Map()
   *
   * @return (JSON) string
   */
  _preProcessSettings = settings => {
    if (Map.isMap(settings)) {
      return JSON.stringify(this.settingsProcessor.preProcessSettings(settings.toJS()), null, 2)
    } else {
      return JSON.stringify(this.settingsProcessor.preProcessSettings(settings), null, 2)
    }
  }

  /*
   * @param settings 	of type string (JSON)
   *
   * @return object
   */
  _postProcessSettings = jsonSettingsString => {
    return this.settingsProcessor.postProcessSettings(JSON.parse(jsonSettingsString))
  }

  toggleEditMode = () => {
    this.props.toggleEditMode()()
    this.setState({ loading: false, isJsonInvalid: false })
  }

  cancelEditing = () => {
    this.setState(
      {
        jsonSettingsString: this._preProcessSettings(this.props.settings)
      },
      this.toggleEditMode
    )
  }

  externallySubmitForm = () => {
    this.submitButtonRef.current.click()
  }

  onJsonSettingsChange = ({ formData }) => {
    this.setState({
      jsonSettingsString: JSON.stringify(formData, null, 2),
      isJsonInvalid: false
    })
  }

  toggleFormType = () => {
    const { type, jsonSettingsString } = this.state

    if (type === JSON_TYPE) {
      // toggle to FORM_TYPE
      if (!isJSONString(jsonSettingsString) || _isEmpty(jsonSettingsString)) {
        this.setState({
          isJsonInvalid: true
        })
        return
      }

      this.setState({
        type: FORM_TYPE,
        jsonSettingsString: this._preProcessSettings(JSON.parse(jsonSettingsString))
      })
    } else {
      // toggle to JSON_TYPE
      this.setState({
        type: JSON_TYPE,
        jsonSettingsString: JSON.stringify(this._postProcessSettings(jsonSettingsString), null, 2)
      })
    }
  }

  submitSettings = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true })
      const { jsonSettingsString } = this.state
      if (!isJSONString(jsonSettingsString) || _isEmpty(jsonSettingsString)) {
        this.setState({ loading: false, isJsonInvalid: true })
        return
      }

      const settings = this._postProcessSettings(jsonSettingsString)
      // CDP connector unset fields from different engine, do it better when
      // json form library supports ommiting unused attributes
      if (this.props.component.id === 86) {
        const engine = _get(settings, "parameters.cdp_exports_db.engine")
        if (engine === "postgresql") {
          _unset(settings, "parameters.cdp_exports_db.credentials.project")
          _unset(settings, "parameters.cdp_exports_db.credentials.dataset")
          _unset(settings, "parameters.cdp_exports_db.credentials.location")
          _unset(settings, "parameters.cdp_exports_db.credentials.#credentials")
        } else if (engine === "bigquery") {
          _unset(settings, "parameters.cdp_exports_db.credentials.host")
          _unset(settings, "parameters.cdp_exports_db.credentials.port")
          _unset(settings, "parameters.cdp_exports_db.credentials.user")
          _unset(settings, "parameters.cdp_exports_db.credentials.#password")
          _unset(settings, "parameters.cdp_exports_db.credentials.database")
          _unset(settings, "parameters.cdp_exports_db.credentials.schema")
        }
      }
      try {
        await this.props.onFormSubmit({ settings })
        this.toggleEditMode()
      } catch (err) {
        if (_get(err, "type") === "nothing_changed") {
          this.toggleEditMode()
        }
        this.setState({ loading: false })
      }
    }
  }

  /*
   * @param actionType can be 'toggle' or 'confirm'
   */
  toggleResetConfirmModal = actionType => async () => {
    if (actionType === "toggle") {
      this.setState(prevState => ({
        resetConfirmModal: prevState.resetConfirmModal.set(
          "open",
          !prevState.resetConfirmModal.get("open")
        )
      }))
    } else if (actionType === "confirm") {
      this.setState(prevState => ({
        resetConfirmModal: prevState.resetConfirmModal.set("isLoading", true)
      }))

      try {
        await this.props.handleOauthReset()
      } catch (err) {
      } finally {
        this.setState(prevState => ({
          resetConfirmModal: prevState.resetConfirmModal.set("isLoading", false).set("open", false)
        }))
      }
    }
  }

  customValidate = (formData, errors) => {
    const dataSchema = _get(this.props, "component.settings_template.dataschema", {})
    const flattenDataSchema = flat.flatten(dataSchema)
    let castTypes = []
    _forEach(flattenDataSchema, (value, key) => {
      if (key.includes("cast_type")) {
        castTypes.push({
          path: key,
          cast_type: value
        })
      }
    })
    if (castTypes.length > 0) {
      _forEach(castTypes, castType => {
        const parts = castType.path.split(".")
        let ended = false
        let dataPath = ""
        _forEach(parts, part => {
          if (!ended) {
            const tmpPath = dataPath.length ? `${dataPath}.${part}` : part
            if (_get(formData, tmpPath, undefined) !== undefined) {
              dataPath = tmpPath
            } else if (!["properties", "cast_type"].includes(part)) {
              ended = true
            }
          }
        })
        if (!ended) {
          const value = _get(formData, dataPath)
          let regex = null
          if (castType.cast_type === "integer") {
            regex = RegExp("^[1-9]\\d*$|.*{{.+}}.*|^0$")
          } else if (castType.cast_type === "float") {
            regex = RegExp("^(([1-9][0-9]*)|(0))([.][0-9]+)?$|.*{{.+}}.*")
          }
          if (regex !== null && !regex.test(value)) {
            const errorObj = _get(errors, dataPath)
            if (errorObj && typeof errorObj === "object" && errorObj.hasOwnProperty("addError")) {
              errorObj.addError(`should be valid ${castType.cast_type} or variable placeholder`)
            }
          }
        }
      })
    }
    return errors
  }

  copyOAuthLink = () => {
    const { authLink, copyMessageFunc } = this.props
    var dummy = document.createElement("input")
    document.body.appendChild(dummy)
    dummy.setAttribute("value", authLink)
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
    copyMessageFunc()
  }

  transformErrors = errors => {
    return errors.map(err => {
      if (_get(err, "params.limit") === 1) {
        err.message = "is a required property"
      }
      return err
    })
  }

  incrementExpandAllVar = () => {
    const { expandAllVar } = this.state

    const viewUiSchema = generateJsonFormUiSchema(
      _get(this.props.component, "settings_template.dataschema", {}),
      _get(this.props.component, "settings_template.uischema", {}),
      true,
      true,
      expandAllVar + 1
    )

    this.setState({
      viewUiSchema: viewUiSchema.uiSchema,
      expandAllVar: expandAllVar + 1
    })
  }

  incrementCollapseAllVar = () => {
    const { collapseAllVar, expandAllVar } = this.state

    const viewUiSchema = generateJsonFormUiSchema(
      _get(this.props.component, "settings_template.dataschema", {}),
      _get(this.props.component, "settings_template.uischema", {}),
      true,
      true,
      expandAllVar,
      collapseAllVar + 1
    )

    this.setState({
      viewUiSchema: viewUiSchema.uiSchema,
      collapseAllVar: collapseAllVar + 1
    })
  }

  toggleAuthAccountModal = () => {
    this.setState(prevState => ({
      authAccountModalOpen: !prevState.authAccountModalOpen
    }))
  }

  render() {
    const {
      authLink,
      component,
      onFormError,
      isEditable,
      editMode,
      readyToEdit,
      viewOnly,
      oauthSettings,
      currentlyEditing = ""
    } = this.props
    const {
      isAuthorized,
      viewUiSchema,
      editUiSchema,
      jsonSettingsString,
      type,
      loading,
      resetConfirmModal,
      hasCollapsableElements,
      authAccountModalOpen,
      isJsonInvalid
    } = this.state
    const dataSchema = _get(component, "settings_template.dataschema", null)

    return (
      <React.Fragment>
        <PaperHeader size="small" className="settings-form-card-header">
          <h3>
            {component.name}
            {component.id === 30 && (
              <React.Fragment>
                {" "}
                <FontAwesomeIcon
                  icon={["fas", "info-circle"]}
                  data-tip
                  data-for="sql-on-csv-tooltip"
                />
                <ReactTooltip id="sql-on-csv-tooltip" className="component-tooltip" place="top">
                  This component uses SQLite library.
                </ReactTooltip>
              </React.Fragment>
            )}
          </h3>
          {!viewOnly && (
            <React.Fragment>
              {isAuthorized && !editMode && (
                <div className="buttons">
                  {authLink && (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={["far", "user-circle"]}
                        data-tip
                        data-for="oauth-account-note"
                        className="info-icon"
                      />
                      <ReactTooltip
                        id="oauth-account-note"
                        className="oauth-note-tooltip"
                        place="top"
                      >
                        OAuth account:{" "}
                        {!_isNil(oauthSettings.get("note"))
                          ? oauthSettings.get("note")
                          : "not filled"}
                      </ReactTooltip>
                      <Button
                        color="white"
                        size="small"
                        className={`reset-auth-button ${!readyToEdit ? "cursor-wait" : ""}`}
                        onClick={readyToEdit ? this.toggleResetConfirmModal("toggle") : _noop}
                        disabled={!isEditable}
                        currentlyEditing={currentlyEditing}
                        tooltipId="reset-conf-auth-tooltip-id"
                      >
                        Reset authorization
                      </Button>
                    </React.Fragment>
                  )}
                  {dataSchema && (
                    <Button
                      color="primary"
                      size="small"
                      onClick={readyToEdit ? this.toggleEditMode : _noop}
                      disabled={!isEditable}
                      className={`edit-button ${!readyToEdit ? "cursor-wait" : ""}`}
                      currentlyEditing={currentlyEditing}
                      tooltipId="edit-conf-tooltip-id"
                    >
                      Edit
                    </Button>
                  )}
                </div>
              )}
              {isAuthorized && editMode && (
                <div className="buttons">
                  <Button
                    className="cancel-button"
                    color="link"
                    onClick={this.cancelEditing}
                    size="small"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={this.externallySubmitForm}
                    className={loading ? "loading" : ""}
                  >
                    Save
                  </Button>
                </div>
              )}
              {!isAuthorized && isEditable && (
                <div className="auth-start-buttons">
                  <Button color="green" size="small" onClick={this.toggleAuthAccountModal}>
                    Authorize
                  </Button>
                  <Button
                    color="white"
                    size="small"
                    onClick={this.copyOAuthLink}
                    className="copy-button"
                  >
                    <FontAwesomeIcon icon={["fal", "copy"]} />
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
        </PaperHeader>
        {isAuthorized && (
          <Paper hasHeader={true} className="json-form-wrapper">
            {!editMode && (
              <React.Fragment>
                {dataSchema && (
                  <div className="json-form disabled">
                    <ErrorBoundary fallbackText="Something went wrong while showing configuration form. Please check the configuration in JSON view and make sure it is correct.">
                      {hasCollapsableElements && (
                        <div className="expand-collapse-all-wrapper">
                          <span onClick={this.incrementExpandAllVar}>Expand all</span> /{" "}
                          <span onClick={this.incrementCollapseAllVar}>Collapse all</span>
                        </div>
                      )}
                      <Form
                        schema={dataSchema}
                        uiSchema={viewUiSchema}
                        formData={JSON.parse(jsonSettingsString)}
                        ArrayFieldTemplate={ArrayFieldReadOnlyTemplate}
                        ObjectFieldTemplate={ObjectFieldTemplateCollapsable}
                        FieldTemplate={FieldTemplate}
                        className="settings-form"
                      >
                        <span />
                      </Form>
                    </ErrorBoundary>
                  </div>
                )}
                {!dataSchema && <p>This component does not have any form. Carry on!</p>}
              </React.Fragment>
            )}
            {editMode && (
              <React.Fragment>
                <div className="toggle-form-json">
                  <h4 className="form-part-title">Choose</h4>
                  <ToggleSwitch
                    width="112px"
                    name="settings-form-type"
                    leftValue={FORM_TYPE}
                    rightValue={JSON_TYPE}
                    checked={type}
                    handleToggle={this.toggleFormType}
                    className="settings-form-type-toggle"
                  />
                </div>
                {type === FORM_TYPE && (
                  <div className="json-form">
                    <ErrorBoundary fallbackText="Something went wrong while showing configuration form. Please check the configuration in JSON view and make sure it is correct.">
                      <Form
                        schema={dataSchema}
                        uiSchema={editUiSchema}
                        formData={JSON.parse(jsonSettingsString)}
                        ArrayFieldTemplate={ArrayFieldTemplate}
                        ObjectFieldTemplate={ObjectFieldTemplate}
                        FieldTemplate={FieldTemplate}
                        className="settings-form"
                        onSubmit={this.submitSettings}
                        onError={onFormError}
                        onChange={this.onJsonSettingsChange}
                        showErrorList={false}
                        noHtml5Validate={true}
                        autocomplete="off"
                        validate={this.customValidate}
                        transformErrors={this.transformErrors}
                      >
                        <span className="control-buttons">
                          <Button
                            type="button"
                            color="white"
                            size="small"
                            onClick={this.cancelEditing}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            size="small"
                            className={`submit-button ${loading ? "loading" : ""}`}
                            ref={this.submitButtonRef}
                          >
                            Save
                          </Button>
                        </span>
                      </Form>
                    </ErrorBoundary>
                  </div>
                )}
                {type === JSON_TYPE && (
                  <div className="ace-editor-settings">
                    <h4 className="form-part-title">Parameters</h4>
                    <div
                      className={`ace-editor-wrapper ${editMode ? "edit" : ""} ${
                        isJsonInvalid ? "error" : ""
                      }`}
                    >
                      <AceEditor
                        mode="json"
                        theme="meiro"
                        onChange={newValue => {
                          this.setState({ jsonSettingsString: newValue, isJsonInvalid: false })
                        }}
                        name="json_settings"
                        value={jsonSettingsString}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{ maxLines: 50 }}
                        width="100%"
                        wrapEnabled={true}
                      />
                    </div>
                    {isJsonInvalid && (
                      <p className="code-error">Entered code is not valid JSON object.</p>
                    )}
                    <span className="control-buttons mb-0">
                      <Button type="button" color="white" size="small" onClick={this.cancelEditing}>
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        color="primary"
                        size="small"
                        className="submit-button"
                        onClick={this.submitSettings}
                        ref={this.submitButtonRef}
                      >
                        Save
                      </Button>
                    </span>
                  </div>
                )}
              </React.Fragment>
            )}
            <ConfirmModal
              open={resetConfirmModal.get("open")}
              type={MODAL.TYPE.SUCCESS}
              handleClose={this.toggleResetConfirmModal("toggle")}
              handleConfirm={this.toggleResetConfirmModal("confirm")}
              title="Reset OAuth"
              action="reset"
              what="authorization"
              isLoading={resetConfirmModal.get("isLoading")}
            />
          </Paper>
        )}
        <OauthNoteModal
          open={authAccountModalOpen}
          handleClose={this.toggleAuthAccountModal}
          authLink={authLink}
        />
      </React.Fragment>
    )
  }
}

SettingsFormCard.defaultProps = {
  readyToEdit: true,
  viewOnly: false
}

SettingsFormCard.propTypes = {
  configurationId: PropTypes.number,
  component: PropTypes.instanceOf(Record).isRequired,
  isEditable: PropTypes.bool.isRequired,
  authLink: PropTypes.string,
  oauthSettings: PropTypes.instanceOf(Map),
  handleOauthReset: PropTypes.func.isRequired,
  settings: PropTypes.instanceOf(Map),
  onFormError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  copyMessageFunc: PropTypes.func.isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool,
  viewOnly: PropTypes.bool
}

export default SettingsFormCard
