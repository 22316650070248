import React, { Component } from "react"
import PropTypes from "prop-types"
import { List } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// helpers
import { isEmailValid } from "helpers/validators.helper"

// constats
import { ADD_INPUTBOX_TYPE } from "sharedConstants"

import "./AddInputbox.css"

class AddInputbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: ""
    }
  }

  handleValueChange = evt => {
    this.setState({
      error: "",
      inputValue: evt.target.value
    })
  }

  handleKeyPress = evt => {
    if (evt.key === "Enter") {
      evt.preventDefault()
      this.addValue()
    }
  }

  addValue = () => {
    const { type, handleValueAdd } = this.props
    const { inputValue } = this.state
    if (type === ADD_INPUTBOX_TYPE.EMAIL) {
      if (!isEmailValid(inputValue)) {
        this.setState({
          error: "Email is not valid"
        })
        return
      }
    }

    const response = handleValueAdd(inputValue)
    if (response.error) {
      this.setState({ error: response.error })
    } else if (response.value) {
      this.setState({
        error: "",
        inputValue: ""
      })
    }
  }

  removeValue = value => () => {
    const { handleValueDelete } = this.props
    handleValueDelete(value)
  }

  render() {
    const { values, className, type } = this.props
    const { error } = this.state

    return (
      <div className={`add-inputbox-wrapper ${className ? className : ""}`}>
        <p className="grey-label">Failure notifications send to</p>
        <div className={`add-inputbox text-field ${error ? "error" : ""}`}>
          <input
            type="text"
            name="add-value"
            id="add-value"
            onChange={this.handleValueChange}
            onKeyPress={this.handleKeyPress}
            value={this.state.inputValue}
            placeholder={type === ADD_INPUTBOX_TYPE.EMAIL ? "Email" : ""}
          />
          <span className="link" onClick={this.addValue}>
            + ADD
          </span>
          {error && <p className="error-message">{error}</p>}
        </div>
        <div className="values">
          {values.map(value => (
            <span className="added-value" key={value}>
              {value}{" "}
              <span onClick={this.removeValue(value)} className="close-icon">
                <FontAwesomeIcon icon={["far", "times"]} />
              </span>
            </span>
          ))}
        </div>
      </div>
    )
  }
}

AddInputbox.propTypes = {
  values: PropTypes.instanceOf(List).isRequired,
  handleValueAdd: PropTypes.func.isRequired,
  handleValueDelete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default AddInputbox
