import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Prompt } from "react-router-dom"
import _noop from "lodash/noop"
import _get from "lodash/get"
import { Record } from "immutable"
import { Map, List } from "immutable"

// ui components
import Header from "components/UI/DawgWsShared/DetailHeader"
import SetScheduleCard from "components/UI/DawgWsShared/SetSchedule"
import VariablesCard from "./cards/Variables"
import DescriptionCard from "components/UI/components/DescriptionCard"
import ConfirmModal from "components/UI/components/ConfirmModal"
import EntityJobsCard from "components/UI/DawgWsShared/EntityJobsCard"
import ConfigurationJobsCard from "components/UI/DawgWsShared/EntityChildrenJobsCard"
import DataFlowComponent from "components/UI/DawgWsShared/DataFlow"

// selectors
import { getWorkspaceRecord } from "selectors/workspace.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"
import { getWorkspaceAclsData } from "selectors/acl.selector"
import {
  isConfigurationsFulfilled,
  getWorkspaceConfigurationsFuseData,
  getIsConfigurationsFetching,
  getWorkspaceConfigurationsData,
  getIsWorkspaceConfigurationsModifying
} from "selectors/configuration.selector"
import { getComponentsData } from "selectors/component.selector"
import { getLastWorkspaceJobsData } from "selectors/workspaceJob.selector"
import { getWorkspaceDashboardConfJobs } from "selectors/workspaceConfigurationJob.selector"

// helpers
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { getRoutePath } from "routes"
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"

// actions
import {
  retrieveWorkspace,
  modifyWorkspace,
  deleteWorkspace,
  cloneWorkspace,
  assignTagToWorkspace,
  unassignTagFromWorkspace
} from "actions/workspace.action"
import { fetchWorkspaceAcl } from "actions/acl.action"
import { showToast } from "actions/toast.action"
import {
  fetchConfigurationList,
  modifyConfiguration,
  deleteConfiguration,
  modifyConfigurationsBulk
} from "actions/configuration.action"
import {
  clearCachedConfigurationJobs,
  listWorkspaceConfigurationJob
} from "actions/configurationJob.action"
import { clearCachedConfigurationHistory } from "actions/configurationHistory.action"
import {
  listWorkspaceJob,
  runWorkspaceJob,
  cancelWorkspaceJob,
  refreshLastWorkspaceJob,
  clearCachedWorkspaceJobs
} from "actions/workspaceJob.action"
import { runConfigurationJob, cancelConfigurationJob } from "actions/configurationJob.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { enableLogout, disableLogout } from "actions/authenticatedUser.action"
import { clearCachedWorkspaceHistory } from "actions/workspaceHistory.action"
import { createAcl, fetchUsersAcl, modifyAcl, deleteAcl } from "actions/acl.action"

// constants
import { MODAL, INTERVAL, WORKSPACE, TOAST } from "sharedConstants"

import "./WorkspaceShow.css"

const initialState = {
  confirmModalVariables: Map({
    open: false,
    type: "",
    handleClose: () => {},
    handleConfirm: () => {},
    title: "",
    action: "",
    what: "",
    text: "",
    isLoading: false
  }),
  editModes: Map({
    workspaceName: false,
    description: false,
    variables: false,
    schedule: false
  }),
  configurationStatuses: Map(),
  isWorkspaceReloading: true
}

class WorkspaceShow extends PureComponent {
  runningCalls = {
    lastWorkspaceJobs: false,
    lastConfigurationJobs: false,
    workspaceConfigurationsLastJobs: false
  }

  constructor(props) {
    super(props)
    this.state = initialState
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this._init()
  }

  _init(clearWorkspaceCache = false) {
    const { id } = this.props.match.params
    const {
      retrieveWorkspace,
      fetchWorkspaceAcl,
      fetchConfigurationList,
      clearCachedConfigurationJobs,
      clearCachedConfigurationHistory,
      listWorkspaceJob,
      clearCachedWorkspaceJobs,
      clearCachedWorkspaceHistory
    } = this.props

    clearCachedConfigurationJobs()
    clearCachedConfigurationHistory()
    if (clearWorkspaceCache) {
      clearCachedWorkspaceJobs()
      clearCachedWorkspaceHistory()
    }

    retrieveWorkspace(id)
      .then(() => {
        this.setState({ isWorkspaceReloading: false })
      })
      .catch(() => {
        this.setState({ isWorkspaceReloading: false })
      })
    fetchWorkspaceAcl(id).catch(_noop)
    fetchConfigurationList(id).catch(_noop)

    this.runningCalls.lastWorkspaceJobs = true
    listWorkspaceJob(id, 0, WORKSPACE.JOB.LOADING_LIMIT, 0)
      .then(() => {
        this.runningCalls.lastWorkspaceJobs = false
      })
      .catch(() => {
        this.runningCalls.lastWorkspaceJobs = false
      })

    this.fetchLastConfigurationJobs()

    this.fetchWorkspaceConfigurationsLastJobs()

    this.intervalId = setInterval(() => {
      this.refreshLastWorkspaceJob()
      this.fetchLastConfigurationJobs(false)
      this.fetchWorkspaceConfigurationsLastJobs()
    }, INTERVAL.WORKSPACE_SHOW)
  }

  _cleanup = (clearState = false) => {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
    this.runningCalls = {
      lastWorkspaceJobs: false,
      lastConfigurationJobs: false,
      workspaceConfigurationsLastJobs: false
    }

    if (clearState) {
      this.setState(initialState)
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params
    const prevId = prevProps.match.params.id
    if (id && prevId && id !== prevId) {
      this._cleanup()
      this._init(true)
    }
  }

  componentWillUnmount() {
    this._cleanup()
  }

  modifyWorkspace = async (
    parameters,
    previousParameters = {},
    message = "",
    toastType = TOAST.TYPE.SUCCESS
  ) => {
    const { modifyWorkspace, showToast, workspace } = this.props
    await modifyWorkspace(workspace.id, parameters, previousParameters)
    showToast(message ? message : "Workspace has been modified.", toastType)
  }

  deleteWorkspace = () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { deleteWorkspace, showToast, history } = this.props
      const { id: workspaceId } = this.props.match.params
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      deleteWorkspace(workspaceId)
        .then(() => {
          showToast("Workspace has been deleted.", TOAST.TYPE.SUCCESS)
          history.push(getRoutePath("workspace.list"))
        })
        .catch(() => {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  toggleDeleteModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
      this.intervalId = setInterval(() => {
        this.refreshLastWorkspaceJob()
        this.fetchLastConfigurationJobs(false)
        this.fetchWorkspaceConfigurationsLastJobs()
      }, INTERVAL.WORKSPACE_SHOW)
    } else {
      clearInterval(this.intervalId)
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.DELETE)
          .set("handleClose", this.toggleDeleteModal)
          .set("handleConfirm", this.deleteWorkspace)
          .set("title", "Delete workspace")
          .set("action", "delete")
          .set("what", "workspace")
          .set("text", "")
      }))
    }
  }

  toggleRunModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.SUCCESS)
          .set("handleClose", this.toggleRunModal)
          .set("handleConfirm", this.runWorkspace)
          .set("title", "Run workspace")
          .set("action", "run")
          .set("what", "workspace")
          .set("text", "")
      }))
    }
  }

  toggleWorkspaceCancelModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.CANCEL)
          .set("handleClose", this.toggleWorkspaceCancelModal)
          .set("handleConfirm", this.cancelWorkspaceJob)
          .set("title", "Cancel workspace")
          .set("action", "cancel")
          .set("what", "workspace job")
          .set("text", "")
      }))
    }
  }

  toggleDataEraseModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.ERASE)
          .set("handleClose", this.toggleDataEraseModal)
          .set("handleConfirm", this.eraseWorkspaceData)
          .set("title", "Erase workspace data")
          .set("text", "Do you really want to erase all workspace data?")
      }))
    }
  }

  toggleCloneModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.SUCCESS)
          .set("handleClose", this.toggleCloneModal)
          .set("handleConfirm", this.cloneWorkspace)
          .set("title", "Clone workspace")
          .set("action", "clone")
          .set("what", "workspace")
          .set("text", "")
      }))
    }
  }

  eraseWorkspaceData = async () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const {
        showToast,
        match: {
          params: { id }
        }
      } = this.props
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      const eraseWorkspaceDataRequest = this.pendingPromises.create(api().workspace.data.delete(id))
      try {
        await eraseWorkspaceDataRequest.promise
        showToast("Workspace data has been erased.", TOAST.TYPE.SUCCESS)
        this.toggleDataEraseModal()
      } catch (err) {
        if (!_get(err, "isCanceled")) {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        }
      } finally {
        this.pendingPromises.remove(eraseWorkspaceDataRequest)
      }
    }
  }

  cancelWorkspaceJob = async () => {
    const {
      lastWorkspaceJobs,
      cancelWorkspaceJob,
      showToast,
      match: {
        params: { id }
      }
    } = this.props
    if (List.isList(lastWorkspaceJobs) && lastWorkspaceJobs.size > 0) {
      const job = lastWorkspaceJobs.get(0)
      this.runningCalls.lastWorkspaceJobs = true
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      try {
        await cancelWorkspaceJob(id, job.id)
        showToast("Workspace job is being cancelled.", TOAST.TYPE.SUCCESS)
        this.runningCalls.lastWorkspaceJobs = false
        this.toggleWorkspaceCancelModal()
      } catch (err) {
        this.runningCalls.lastWorkspaceJobs = false
        this.setState(prevState => ({
          confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
        }))
      }
    } else {
      this.toggleWorkspaceCancelModal()
    }
  }

  runWorkspace = async () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { showToast, runWorkspaceJob } = this.props
      const { id: workspaceId } = this.props.match.params
      this.runningCalls.lastWorkspaceJobs = true

      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      runWorkspaceJob(workspaceId, 0)
        .then(response => {
          showToast(
            "Workspace job has started.",
            TOAST.TYPE.SUCCESS,
            getRoutePath("workspace.workspaceJob.show", {
              id: response.value.workspace_job.workspace_id,
              aid: response.value.workspace_job.id
            })
          )
          this.runningCalls.lastWorkspaceJobs = false
          this.toggleRunModal()
        })
        .catch(() => {
          this.runningCalls.lastWorkspaceJobs = false
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  cloneWorkspace = () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { cloneWorkspace, showToast } = this.props
      const { id: workspaceId } = this.props.match.params

      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true),
        highlightedConfigurations: Map({
          active: false,
          ids: []
        })
      }))
      cloneWorkspace(workspaceId)
        .then(response => {
          showToast(
            "Workspace has been cloned.",
            TOAST.TYPE.SUCCESS,
            getRoutePath("workspace.show", { id: response.value.id })
          )
          this.toggleCloneModal()
        })
        .catch(() => {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
          }))
        })
    }
  }

  refreshLastWorkspaceJob = () => {
    if (!this.runningCalls.lastWorkspaceJobs) {
      this.runningCalls.lastWorkspaceJobs = true
      const {
        refreshLastWorkspaceJob,
        match: {
          params: { id }
        }
      } = this.props
      refreshLastWorkspaceJob(id, 0)
        .then(() => {
          this.runningCalls.lastWorkspaceJobs = false
        })
        .catch(() => {
          this.runningCalls.lastWorkspaceJobs = false
        })
    }
  }

  fetchLastConfigurationJobs = async (promise = true) => {
    if (!this.runningCalls.lastConfigurationJobs) {
      this.runningCalls.lastConfigurationJobs = true
      const {
        listWorkspaceConfigurationJob,
        match: {
          params: { id }
        }
      } = this.props

      try {
        await listWorkspaceConfigurationJob(id, 0, 6, 1, "status", "DESC", promise)
      } catch (err) {
      } finally {
        this.runningCalls.lastConfigurationJobs = false
      }
    }
  }

  fetchWorkspaceConfigurationsLastJobs = async () => {
    if (!this.runningCalls.workspaceConfigurationsLastJobs) {
      this.runningCalls.workspaceConfigurationsLastJobs = true
      const { id } = this.props.match.params
      const { configurationStatuses } = this.state
      const lastConfJobsPromise = this.pendingPromises.create(
        api().workspace.configuration.listLastJobs(id)
      )

      try {
        const response = await lastConfJobsPromise.promise
        if (response.last_configuration_jobs.length > 0) {
          const currentStatuses = Map(
            response.last_configuration_jobs.reduce((returnObj, job) => {
              returnObj[job.configuration_id] = Map({
                id: _get(job, "last_job.id"),
                status: _get(job, "last_job.status")
              })
              return returnObj
            }, {})
          )

          if (!configurationStatuses.equals(currentStatuses)) {
            this.setState({
              configurationStatuses: currentStatuses
            })
          }
        }
      } catch (err) {
      } finally {
        this.runningCalls.workspaceConfigurationsLastJobs = false
        this.pendingPromises.remove(lastConfJobsPromise)
      }
    }
  }

  toggleEditMode = editMode => (cb = _noop) => () => {
    this.setState(
      prevState => ({
        editModes: prevState.editModes.set(editMode, !prevState.editModes.get(editMode))
      }),
      () => {
        let isEditing = false
        this.state.editModes.forEach(mode => {
          if (mode) {
            isEditing = true
          }
        })

        if (isEditing) {
          this.props.disableLogout()
        } else {
          this.props.enableLogout()
        }
        cb()
      }
    )
  }

  toggleDeleteDataCheckbox = evt => {
    const { workspace } = this.props
    const value = evt.target.checked

    this.modifyWorkspace(
      {
        settings: workspace.settings.set("delete_data_after_success", value).toJS()
      },
      {},
      value
        ? "Workspace data will be deleted after execution."
        : "Workspace data won't be deleted after execution.",
      TOAST.TYPE.SUCCESS
    )
  }

  transferOwnership = async userId => {
    const { modifyWorkspace, showToast, workspace } = this.props
    await modifyWorkspace(workspace.id, { owner_id: userId })
    showToast("Workspace owner has been changed.", TOAST.TYPE.SUCCESS)
  }

  render() {
    const {
      createAcl,
      fetchUsersAcl,
      modifyAcl,
      deleteAcl,
      workspace,
      usersAcl,
      workspaceUsers,
      isConfigurationsFulfilled,
      lastWorkspaceJobs,
      components,
      configurationJobs,
      assignTagToWorkspace,
      unassignTagFromWorkspace,
      configurationsFuseSearchInstance,
      isConfigurationsFetching,
      isConfigurationsModifying,
      configurations,
      modifyConfiguration,
      deleteConfiguration,
      runConfigurationJob,
      cancelConfigurationJob,
      modifyConfigurationsBulk
    } = this.props
    const {
      confirmModalVariables,
      editModes,
      configurationStatuses,
      isWorkspaceReloading
    } = this.state

    const isEditable = hasWritePermission(usersAcl)
    let currentlyEditing = ""
    editModes.forEach((mode, key) => {
      if (mode) {
        switch (key) {
          case "workspaceName": {
            currentlyEditing = "workspace name"
            break
          }
          case "description": {
            currentlyEditing = "workspace description"
            break
          }
          case "variables": {
            currentlyEditing = "workspace variables"
            break
          }
          case "schedule": {
            currentlyEditing = "workspace schedule"
            break
          }
          default:
            currentlyEditing = ""
        }
      }
    })

    return (
      <section className="workspace workspace-detail">
        {workspace && (
          <React.Fragment>
            <Header
              entity={workspace}
              createAcl={createAcl}
              entityUsers={workspaceUsers}
              entityName="workspace"
              fetchUsersAcl={fetchUsersAcl}
              modifyAcl={modifyAcl}
              deleteAcl={deleteAcl}
              handleEntityClone={this.toggleCloneModal}
              handleEntityDelete={this.toggleDeleteModal}
              handleEntityModify={this.modifyWorkspace}
              handleEntityRun={this.toggleRunModal}
              handleEntityCancel={this.toggleWorkspaceCancelModal}
              assignTagToEntity={assignTagToWorkspace}
              unassignTagFromEntity={unassignTagFromWorkspace}
              handleDataErase={this.toggleDataEraseModal}
              handleDataDeleteCheckboxToggle={this.toggleDeleteDataCheckbox}
              isEditable={isEditable}
              currentlyEditing={currentlyEditing}
              toggleNameFormEditMode={this.toggleEditMode("workspaceName")}
              nameFormEditMode={editModes.get("workspaceName")}
              entityStatus={
                List.isList(lastWorkspaceJobs) ? lastWorkspaceJobs.getIn([0, "status"]) : null
              }
              isEntityReloading={isWorkspaceReloading}
              transferOwnership={this.transferOwnership}
            />
            <DataFlowComponent
              entitiesFuseSearchInstance={configurationsFuseSearchInstance}
              entityName="workspace"
              entity={workspace}
              isEntitiesFulfilled={isConfigurationsFulfilled}
              components={components}
              isEditable={isEditable}
              entityStatuses={configurationStatuses}
              isChildrenFetching={isConfigurationsFetching}
              isChildrenModifying={isConfigurationsModifying}
              childEntities={configurations}
              modifyEntity={modifyConfiguration}
              deleteEntity={deleteConfiguration}
              runEntityJob={runConfigurationJob}
              cancelEntityJob={cancelConfigurationJob}
              modifyEntitiesBulk={modifyConfigurationsBulk}
            />
            <div className="two-column-layout">
              <div className="left-column">
                <DescriptionCard
                  form="WorkspaceDescriptionForm"
                  initialValues={{ description: workspace.description }}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  onSubmit={this.modifyWorkspace}
                  toggleEditMode={this.toggleEditMode("description")}
                  editMode={editModes.get("description")}
                  readyToEdit={!isWorkspaceReloading}
                />
                <VariablesCard
                  workspace={workspace}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  onSubmit={this.modifyWorkspace}
                  toggleEditMode={this.toggleEditMode("variables")}
                  editMode={editModes.get("variables")}
                  showToastMessage={(message, toastType) => {
                    this.props.showToast(message, toastType)
                  }}
                  readyToEdit={!isWorkspaceReloading}
                />
                <SetScheduleCard
                  entity={workspace}
                  showToast={this.props.showToast}
                  modifyEntity={this.props.modifyWorkspace}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  toggleEditMode={this.toggleEditMode("schedule")}
                  editMode={editModes.get("schedule")}
                  readyToEdit={!isWorkspaceReloading}
                />
              </div>
              <div className="right-column">
                <EntityJobsCard entityName="workspace" jobs={lastWorkspaceJobs} />
                {List.isList(configurationJobs) && (
                  <ConfigurationJobsCard jobs={configurationJobs} rootEntityName="workspace" />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        <ConfirmModal
          open={confirmModalVariables.get("open")}
          type={confirmModalVariables.get("type")}
          handleClose={confirmModalVariables.get("handleClose")}
          handleConfirm={confirmModalVariables.get("handleConfirm")}
          title={confirmModalVariables.get("title")}
          action={confirmModalVariables.get("action")}
          what={confirmModalVariables.get("what")}
          isLoading={confirmModalVariables.get("isLoading")}
          text={confirmModalVariables.get("text")}
        />
        <Prompt
          when={currentlyEditing.length > 0}
          message={`Do you really want to leave current page and lose unsaved ${currentlyEditing}?`}
        />
      </section>
    )
  }
}

WorkspaceShow.propTypes = {
  acls: PropTypes.instanceOf(List),
  workspace: PropTypes.instanceOf(Record),
  usersAcl: PropTypes.instanceOf(Record),
  isConfigurationsFulfilled: PropTypes.bool.isRequired,
  configurationsFuseSearchInstance: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map),
  lastWorkspaceJobs: PropTypes.instanceOf(List),
  retrieveWorkspace: PropTypes.func.isRequired,
  modifyWorkspace: PropTypes.func.isRequired,
  fetchWorkspaceAcl: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  clearCachedConfigurationJobs: PropTypes.func.isRequired,
  listWorkspaceJob: PropTypes.func.isRequired,
  runWorkspaceJob: PropTypes.func.isRequired,
  refreshLastWorkspaceJob: PropTypes.func.isRequired,
  enableLogout: PropTypes.func.isRequired,
  disableLogout: PropTypes.func.isRequired,
  clearCachedConfigurationHistory: PropTypes.func.isRequired,
  listWorkspaceConfigurationJob: PropTypes.func.isRequired,
  configurationJobs: PropTypes.instanceOf(List),
  workspaceUsers: PropTypes.instanceOf(List),
  createAcl: PropTypes.func.isRequired,
  fetchUsersAcl: PropTypes.func.isRequired,
  modifyAcl: PropTypes.func.isRequired,
  deleteAcl: PropTypes.func.isRequired,
  assignTagToWorkspace: PropTypes.func.isRequired,
  unassignTagFromWorkspace: PropTypes.func.isRequired,
  isConfigurationsFetching: PropTypes.bool.isRequired,
  isConfigurationsModifying: PropTypes.bool.isRequired,
  configurations: PropTypes.instanceOf(Map),
  modifyConfiguration: PropTypes.func.isRequired,
  deleteConfiguration: PropTypes.func.isRequired,
  modifyConfigurationsBulk: PropTypes.func.isRequired,
  runConfigurationJob: PropTypes.func.isRequired,
  cancelConfigurationJob: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  workspace: getWorkspaceRecord(state, ownProps.match.params.id),
  usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id),
  workspaceUsers: getWorkspaceAclsData(state, ownProps.match.params.id),
  isConfigurationsFulfilled: isConfigurationsFulfilled(state, ownProps.match.params.id),
  lastWorkspaceJobs: getLastWorkspaceJobsData(state),
  components: getComponentsData(state),
  configurationsFuseSearchInstance: getWorkspaceConfigurationsFuseData(
    state,
    ownProps.match.params.id
  ),
  configurationJobs: getWorkspaceDashboardConfJobs(state, ownProps.match.params.id),
  isConfigurationsFetching: getIsConfigurationsFetching(state),
  configurations: getWorkspaceConfigurationsData(state, ownProps.match.params.id),
  isConfigurationsModifying: getIsWorkspaceConfigurationsModifying(state, ownProps.match.params.id)
})

export default connect(mapStateToProps, {
  retrieveWorkspace,
  modifyWorkspace,
  deleteWorkspace,
  fetchWorkspaceAcl,
  showToast,
  fetchConfigurationList,
  cloneWorkspace,
  showLoadingBar,
  hideLoadingBar,
  clearCachedConfigurationJobs,
  clearCachedWorkspaceJobs,
  clearCachedWorkspaceHistory,
  clearCachedConfigurationHistory,
  listWorkspaceJob,
  runWorkspaceJob,
  cancelWorkspaceJob,
  refreshLastWorkspaceJob,
  enableLogout,
  disableLogout,
  listWorkspaceConfigurationJob,
  createAcl,
  fetchUsersAcl,
  modifyAcl,
  deleteAcl,
  assignTagToWorkspace,
  unassignTagFromWorkspace,
  modifyConfiguration,
  deleteConfiguration,
  modifyConfigurationsBulk,
  runConfigurationJob,
  cancelConfigurationJob
})(WorkspaceShow)
