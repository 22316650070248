import React, { PureComponent } from "react"
import ReactTooltip from "react-tooltip"
import PropTypes from "prop-types"

import PaperHeader from "../../elements/PaperHeader"
import { SearchFormUndestroyable } from "../../components/SearchForm"
import Button from "../../elements/Button"
import IconButton from "../../elements/IconButton"
import { ReactComponent as Bars } from "images/bars.svg"
import { ReactComponent as Cols } from "images/cols.svg"

import "./Header.css"

class Header extends PureComponent {
  render() {
    const {
      className,
      title,
      onSearchSubmit,
      searchFormName,
      toggleCreateActionModal,
      isAbleToCreateEntity,
      entityName,
      isViewPickerEnabled,
      currentViewLayout,
      setViewLayout
    } = this.props

    return (
      <PaperHeader
        size="small"
        title={title}
        className={`dawg-ws-header ${className ? className : ""}`}
      >
        <div className="left-part">
          <div className="titles">
            <h3 className="title">{title}</h3>
            {entityName === "dawg" && (
              <h4 className="subtitle">Directed Acyclic Workspaces' Graphs</h4>
            )}
          </div>

          <SearchFormUndestroyable
            placeholder="Search for name"
            className="dawg-ws-search"
            initialValues={{ search: "" }}
            onSubmit={onSearchSubmit}
            form={searchFormName}
          />
        </div>
        <div className="right-part">
          <span data-tip data-for="can-create-tooltip">
            <Button
              type="button"
              size="big"
              color="primary"
              onClick={toggleCreateActionModal}
              disabled={!isAbleToCreateEntity}
              data-cy="create-action-button"
            >
              {`+ Create ${entityName}`}
            </Button>
          </span>
          {!isAbleToCreateEntity && (
            <ReactTooltip id="can-create-tooltip" place="bottom">
              {`You haven't got permissions to create ${entityName}s.`}
            </ReactTooltip>
          )}
          {isViewPickerEnabled && (
            <div className="view-picker">
              <IconButton
                className={`${currentViewLayout === "one-col" ? "active" : ""}`}
                color="grey"
                onClick={setViewLayout("one-col")}
              >
                <Bars />
              </IconButton>
              <IconButton
                className={`${currentViewLayout === "two-cols" ? "active" : ""}`}
                color="grey"
                onClick={setViewLayout("two-cols")}
              >
                <Cols />
              </IconButton>
            </div>
          )}
        </div>
      </PaperHeader>
    )
  }
}

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  searchFormName: PropTypes.string.isRequired,
  toggleCreateActionModal: PropTypes.func.isRequired,
  isAbleToCreateEntity: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  isViewPickerEnabled: PropTypes.bool,
  currentViewLayout: PropTypes.string,
  setViewLayout: PropTypes.func
}

export default Header
