import _isEmpty from "lodash/isEmpty"
import _isArray from "lodash/isArray"
import _map from "lodash/map"
import _keyBy from "lodash/keyBy"

class ObjectKeysToArraySettingsProcessor {
  constructor(objectPath = []) {
    this.objectPath = objectPath
  }

  _preProcessRecursion(part, objectPathIndex) {
    if (_isArray(part) && objectPathIndex < this.objectPath.length - 2) {
      part.forEach(item => {
        this._preProcessRecursion(item, objectPathIndex + 1)
      })
    } else if (objectPathIndex === this.objectPath.length - 2) {
      const attribute = this.objectPath[objectPathIndex + 1]
      if (_isEmpty(part[attribute])) {
        part[attribute] = []
        return
      } else {
        part[attribute] = _map(part[attribute], id => id)
      }
    } else {
      this._preProcessRecursion(part[this.objectPath[objectPathIndex + 1]], objectPathIndex + 1)
    }
  }

  _postProcessRecursion(part, objectPathIndex) {
    if (_isArray(part) && objectPathIndex < this.objectPath.length - 2) {
      part.forEach(item => {
        this._postProcessRecursion(item, objectPathIndex + 1)
      })
    } else if (objectPathIndex === this.objectPath.length - 2) {
      const attribute = this.objectPath[objectPathIndex + 1]
      if (_isEmpty(part[attribute])) {
        part[attribute] = {}
      } else {
        part[attribute] = _keyBy(part[attribute], id => id)
      }
    } else {
      this._postProcessRecursion(part[this.objectPath[objectPathIndex + 1]], objectPathIndex + 1)
    }
  }

  preProcessSettings(settings) {
    if (!_isEmpty(this.objectPath)) {
      try {
        this._preProcessRecursion(settings[this.objectPath[0]], 0)
      } catch (err) {
        console.log(err)
        return settings
      }
    }
    return settings
  }

  postProcessSettings(settings) {
    if (!_isEmpty(this.objectPath)) {
      try {
        this._postProcessRecursion(settings[this.objectPath[0]], 0)
      } catch (err) {
        console.log(err)
        return settings
      }
    }
    return settings
  }
}

export default ObjectKeysToArraySettingsProcessor
