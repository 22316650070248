import { Record } from "immutable"

const SelectionSetting = Record({
  limit: 0,
  offset: null,
  order_dir: "ASC",
  order_by: "",
  item_type: "",
  searched_text: "",
  name_filter: "",
  show_enabled: 1,
  show_disabled: 1
})

export default SelectionSetting
