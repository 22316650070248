import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map, List, Record } from "immutable"
import { connect } from "react-redux"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _toString from "lodash/toString"
import _isNil from "lodash/isNil"
import { Waypoint } from "react-waypoint"

import { fetchDawgList, createDawg, fetchLastDawgsJobs } from "actions/dawg.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { fetchDawgUsersAcl } from "actions/acl.action"
import {
  setSortingOptions,
  setFilterByTags,
  setFilterAndSorting,
  setFilterBy
} from "actions/table.action"
import { listProjectWorkspaceJob, clearCachedWorkspaceJobs } from "actions/workspaceJob.action"
import { clearCachedConfigurationJobs } from "actions/configurationJob.action"
import { clearCachedWorkspaceHistory } from "actions/workspaceHistory.action"
import { clearCachedConfigurationHistory } from "actions/configurationHistory.action"
import { clearCachedDawgJobs } from "actions/dawgJob.action"
import { clearCachedDawgHistory } from "actions/dawgHistory.action"
import {
  getDawgs,
  getDawgsSelectionSettings,
  getDawgsHasMoreItem,
  getLastDawgsJobs
} from "selectors/dawg.selector"
import { getDashboardWsJobs } from "selectors/projectWorkspaceJob.selector"
import { areTagsAlreadyFetched } from "selectors/tag.selector"
import { canCreateDawgs } from "helpers/authenticatedUser.helper"
import { DAWG, INTERVAL } from "sharedConstants"

import Header from "components/UI/DawgWsShared/Header"
import SubHeader from "components/UI/DawgWsShared/SubHeader"
import CreateActionModal from "components/UI/DawgWsShared/CreateActionModal"
import ProjectJobsCard from "components/UI/DawgWsShared/ProjectJobsCard"
import DawgListItem from "./DawgListItem"

import "./DawgList.css"

class DawgList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      createDawgModalOpen: false,
      expandedSchedules: Map()
    }
  }

  componentDidMount() {
    const {
      fetchDawgList,
      dawgs,
      fetchLastDawgsJobs,
      listProjectWorkspaceJob,
      clearCachedConfigurationJobs,
      clearCachedWorkspaceJobs,
      clearCachedWorkspaceHistory,
      clearCachedConfigurationHistory,
      clearCachedDawgJobs,
      clearCachedDawgHistory
    } = this.props

    // cache clear
    clearCachedConfigurationJobs()
    clearCachedConfigurationHistory()
    clearCachedWorkspaceJobs()
    clearCachedWorkspaceHistory()
    clearCachedDawgJobs()
    clearCachedDawgHistory()

    const filterVars = this._getDawgsFilterOptions()
    fetchDawgList(
      0,
      DAWG.LOADING_LIMIT,
      1,
      filterVars.orderBy,
      filterVars.orderDir,
      filterVars.searchText,
      filterVars.selectedTags,
      this._determineShowEnabled(filterVars.filterBy),
      this._determineShowDisabled(filterVars.filterBy)
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)

    listProjectWorkspaceJob(0, 10, 1, "status", "DESC", true)

    this.intervalId = setInterval(() => {
      listProjectWorkspaceJob(0, 10, 1, "status", "DESC", false).catch(_noop)
      if (dawgs) {
        const dawgIds = dawgs.map(dawg => dawg.id).toArray()
        fetchLastDawgsJobs(dawgIds, 0).catch(_noop)
      }
    }, INTERVAL.DAWG_LIST)
  }

  _getDawgsFilterOptions = () => {
    const { filterValues } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])
    const orderBy = _get(filterValues, "orderBy", "last_run")
    const orderDir = _get(filterValues, "orderDir", "DESC")
    const searchText = _get(filterValues, "search", "")
    const filterBy = _get(filterValues, "filterBy", "all")
    return {
      selectedTags,
      orderBy,
      orderDir,
      searchText,
      filterBy
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  fetchLastDawgsJobs = response => {
    const { showLoadingBar, hideLoadingBar, fetchLastDawgsJobs } = this.props
    const dawgIds = response.value.dawgs.map(dawg => dawg.id)
    showLoadingBar()
    fetchLastDawgsJobs(dawgIds, 0)
      .then(hideLoadingBar)
      .catch(hideLoadingBar)
  }

  toggleCreateDawgModalOpen = () => {
    this.setState(prevState => ({
      createDawgModalOpen: !prevState.createDawgModalOpen
    }))
  }

  _loadMoreDawgs = () => {
    const { selectionSettings, fetchDawgList } = this.props
    fetchDawgList(
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      1,
      selectionSettings.order_by,
      selectionSettings.order_dir,
      selectionSettings.name_filter,
      selectionSettings.tag_ids,
      selectionSettings.show_enabled,
      selectionSettings.show_disabled
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)
  }

  onSearchSubmit = filterName => {
    const { fetchDawgList } = this.props
    const filterVars = this._getDawgsFilterOptions()

    fetchDawgList(
      0,
      DAWG.LOADING_LIMIT,
      1,
      filterVars.orderBy,
      filterVars.orderDir,
      filterName,
      filterVars.selectedTags,
      this._determineShowEnabled(filterVars.filterBy),
      this._determineShowDisabled(filterVars.filterBy)
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)
  }

  onSortSubmit = values => {
    const { setSortingOptions, fetchDawgList } = this.props
    const filterVars = this._getDawgsFilterOptions()
    const sortOpt = this._determineSortOptions(values)

    setSortingOptions("DawgFilter", sortOpt.column, sortOpt.order)
    fetchDawgList(
      0,
      DAWG.LOADING_LIMIT,
      1,
      sortOpt.column,
      sortOpt.order,
      filterVars.searchText,
      filterVars.selectedTags,
      this._determineShowEnabled(filterVars.filterBy),
      this._determineShowDisabled(filterVars.filterBy)
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)
  }

  onFilterBySubmit = values => {
    const { setFilterBy, fetchDawgList } = this.props
    const filterVars = this._getDawgsFilterOptions()
    const filterBy = _get(values, "filterBy.value", "all")
    setFilterBy("DawgFilter", filterBy)
    fetchDawgList(
      0,
      DAWG.LOADING_LIMIT,
      1,
      filterVars.orderBy,
      filterVars.orderDir,
      filterVars.searchText,
      filterVars.selectedTags,
      this._determineShowEnabled(filterBy),
      this._determineShowDisabled(filterBy)
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)
  }

  selectFilterTag = tagId => () => {
    const { fetchDawgList, setFilterByTags } = this.props
    const filterVars = this._getDawgsFilterOptions()

    if (!filterVars.selectedTags.includes(tagId)) {
      const newTags = [...filterVars.selectedTags, tagId]
      fetchDawgList(
        0,
        DAWG.LOADING_LIMIT,
        1,
        filterVars.orderBy,
        filterVars.orderDir,
        filterVars.searchText,
        newTags,
        this._determineShowEnabled(filterVars.filterBy),
        this._determineShowDisabled(filterVars.filterBy)
      )
        .then(response => {
          this.fetchLastDawgsJobs(response)
        })
        .catch(_noop)

      setFilterByTags("DawgFilter", newTags)
    }
  }

  deselectFilterTag = tagId => () => {
    const { fetchDawgList, setFilterByTags } = this.props
    const filterVars = this._getDawgsFilterOptions()

    let newTags = [...filterVars.selectedTags]
    newTags = newTags.filter(tag => tag !== tagId)
    fetchDawgList(
      0,
      DAWG.LOADING_LIMIT,
      1,
      filterVars.orderBy,
      filterVars.orderDir,
      filterVars.searchText,
      newTags,
      this._determineShowEnabled(filterVars.filterBy),
      this._determineShowDisabled(filterVars.filterBy)
    )
      .then(response => {
        this.fetchLastDawgsJobs(response)
      })
      .catch(_noop)

    setFilterByTags("DawgFilter", newTags)
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { hasDawgsMoreItems } = this.props
    if (hasDawgsMoreItems) {
      return <Waypoint onEnter={this._loadMoreDawgs} bottomOffset={-250} />
    }
  }

  _determineShowEnabled = filterBy => {
    return filterBy === "all" || filterBy === "enabled" ? 1 : 0
  }

  _determineShowDisabled = filterBy => {
    return filterBy === "all" || filterBy === "disabled" ? 1 : 0
  }

  _determineSortOptions = values => ({
    column: values.sortBy.value,
    order: values.order ? "ASC" : "DESC"
  })

  expandDawgSchedules = dawgId => () => {
    this.setState(prevState => ({
      expandedSchedules: prevState.expandedSchedules.set(dawgId, true)
    }))
  }

  renderDawgs = () => {
    const { dawgs, lastDawgsJobs, areTagsAlreadyFetched } = this.props
    const { expandedSchedules } = this.state

    if (!areTagsAlreadyFetched || !List.isList(dawgs)) {
      return null
    }

    if (dawgs.size === 0) {
      return <div className="no-dawgs-found">No DAWGs found.</div>
    }

    const renderDawg = dawg => {
      const dawgJob = !_isNil(lastDawgsJobs) ? lastDawgsJobs.get(_toString(dawg.id)) : null

      return (
        <DawgListItem
          job={dawgJob}
          dawg={dawg}
          selectFilterTag={this.selectFilterTag}
          expandedSchedules={expandedSchedules}
          expandDawgSchedules={this.expandDawgSchedules}
          key={dawg.id}
        />
      )
    }

    return (
      <div style={{ position: "relative" }}>
        {dawgs.map((dawg, index) => {
          return renderDawg(dawg)
        })}
        {this.renderWaypoint()}
      </div>
    )
  }

  render() {
    const { createDawgModalOpen } = this.state
    const { createDawg, fetchDawgUsersAcl, projectWsJobs } = this.props
    return (
      <section className="dawg-list">
        <div className="left-column">
          <Header
            className="dawg-list-header"
            title="DAWGs"
            onSearchSubmit={this.onSearchSubmit}
            searchFormName="DawgFilter"
            toggleCreateActionModal={this.toggleCreateDawgModalOpen}
            isAbleToCreateEntity={canCreateDawgs()}
            entityName="dawg"
          />
          <SubHeader
            globalFormName="DawgFilter"
            sortingFormName="DawgSortingForm"
            filterFormName="DawgFilterForm"
            onSortSubmit={this.onSortSubmit}
            onFilterBySubmit={this.onFilterBySubmit}
            selectFilterTag={this.selectFilterTag}
            deselectFilterTag={this.deselectFilterTag}
          />
          {this.renderDawgs()}
        </div>
        <div className="right-column">
          <ProjectJobsCard entityName="workspace" projectJobs={projectWsJobs} />
        </div>
        <CreateActionModal
          open={createDawgModalOpen}
          handleClose={this.toggleCreateDawgModalOpen}
          createAction={createDawg}
          fetchUsersAcl={fetchDawgUsersAcl}
          entityName="Dawg"
        />
      </section>
    )
  }
}

DawgList.propTypes = {
  areTagsAlreadyFetched: PropTypes.bool.isRequired,
  dawgs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record).isRequired,
  hasDawgsMoreItems: PropTypes.bool.isRequired,
  fetchDawgList: PropTypes.func.isRequired,
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  createDawg: PropTypes.func.isRequired,
  fetchDawgUsersAcl: PropTypes.func.isRequired,
  fetchLastDawgsJobs: PropTypes.func.isRequired,
  lastDawgsJobs: PropTypes.instanceOf(Map),
  listProjectWorkspaceJob: PropTypes.func.isRequired,
  projectWsJobs: PropTypes.instanceOf(List),
  clearCachedConfigurationJobs: PropTypes.func.isRequired,
  clearCachedWorkspaceJobs: PropTypes.func.isRequired,
  clearCachedWorkspaceHistory: PropTypes.func.isRequired,
  clearCachedConfigurationHistory: PropTypes.func.isRequired,
  clearCachedDawgJobs: PropTypes.func.isRequired,
  clearCachedDawgHistory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  dawgs: getDawgs(state),
  selectionSettings: getDawgsSelectionSettings(state),
  hasDawgsMoreItems: getDawgsHasMoreItem(state),
  areTagsAlreadyFetched: areTagsAlreadyFetched(state),
  lastDawgsJobs: getLastDawgsJobs(state),
  projectWsJobs: getDashboardWsJobs(state)
})

export default connect(mapStateToProps, {
  fetchDawgList,
  showLoadingBar,
  hideLoadingBar,
  createDawg,
  fetchDawgUsersAcl,
  setSortingOptions,
  setFilterByTags,
  setFilterAndSorting,
  setFilterBy,
  fetchLastDawgsJobs,
  listProjectWorkspaceJob,
  clearCachedConfigurationJobs,
  clearCachedWorkspaceJobs,
  clearCachedWorkspaceHistory,
  clearCachedConfigurationHistory,
  clearCachedDawgJobs,
  clearCachedDawgHistory
})(DawgList)
