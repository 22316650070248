import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, Map } from "immutable"
import _get from "lodash/get"
import _map from "lodash/map"
import _each from "lodash/each"
import _set from "lodash/set"
import _isEmpty from "lodash/isEmpty"
import _isNil from "lodash/isNil"
import _noop from "lodash/noop"
import ReactTooltip from "react-tooltip"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button"

// helpers
import { isJSONObject } from "helpers/validators.helper"

// ace editor
import AceEditor from "react-ace"

import "./CodeEditor.css"

class CodeEditor extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      codeEditors: this.initCodeEditors(),
      isAuthorized: this._isAuthorized(),
      loading: false,
      hideTooltip: false
    }
  }

  initCodeEditors = () => {
    const { component, settings } = this.props
    const codeEditorsTmp = _get(component, "settings_template.code_parameters", [])
    const codeEditors = _map(codeEditorsTmp, _code => {
      if (_code.language === "json") {
        if (_code.settings_path === "") {
          return {
            ..._code,
            value: JSON.stringify(settings.toJS(), null, 2)
          }
        } else {
          return {
            ..._code,
            value: JSON.stringify(_get(settings.toJS(), _code.settings_path, ""), null, 2)
          }
        }
      } else {
        if (_code.settings_path === "json") {
          return {
            ..._code,
            value: settings.toJS()
          }
        } else {
          return {
            ..._code,
            value: _get(settings.toJS(), _code.settings_path, "")
          }
        }
      }
    })

    return codeEditors
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  _isAuthorized = () => {
    const { authLink, oauthSettings } = this.props
    if (!authLink) {
      return true
    } else {
      if (Map.isMap(oauthSettings) && !_isNil(oauthSettings.get("token_request_response"))) {
        return true
      }
      return false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      this.setState({
        codeEditors: this.initCodeEditors()
      })
    }
    if (prevProps.oauthSettings !== this.props.oauthSettings) {
      this.setState({
        isAuthorized: this._isAuthorized()
      })
    }
  }

  toggleEditMode = () => {
    this.props.toggleEditMode()()
    this.setState({ loading: false, hideTooltip: false })
  }

  saveCodeEditorsValues = async () => {
    if (!this.state.loading) {
      this.setState({ loading: true })
      const { codeEditors } = this.state
      let settings = this.props.settings.toJS()
      let valid = true
      _each(codeEditors, (code, idx) => {
        if (code.language === "json") {
          if (isJSONObject(code.value)) {
            if (code.settings_path === "") {
              settings = JSON.parse(code.value)
            } else {
              _set(settings, code.settings_path, JSON.parse(code.value))
            }
          } else {
            valid = false
            this.setState({
              codeEditors: _map(codeEditors, (_code, _idx) =>
                _idx === idx ? { ..._code, error: "Entered code is not valid JSON object" } : _code
              )
            })
          }
        } else {
          if (code.settings_path === "") {
            settings = code.value
          } else {
            _set(settings, code.settings_path, code.value)
          }
        }
      })

      if (valid) {
        try {
          await this.props.onCodeSave({ settings })
          this.toggleEditMode()
        } catch (err) {
          if (_get(err, "type") === "nothing_changed") {
            this.toggleEditMode()
          } else {
            this.setState({
              loading: false
            })
          }
        }
      } else {
        this.setState({ loading: false })
      }
    }
  }

  cancelCodeEditorsValues = () => {
    this.setState(
      {
        codeEditors: this.initCodeEditors()
      },
      this.toggleEditMode()
    )
  }

  onCodeChange = idx => newValue => {
    this.setState(prevState => ({
      codeEditors: _map(prevState.codeEditors, (_code, _idx) =>
        _idx === idx ? { ..._code, value: newValue, error: "" } : _code
      )
    }))
  }

  autoHideTooltip = () => {
    const { editMode, hideTooltip } = this.state
    if (!editMode && !hideTooltip) {
      this.timer = setTimeout(() => {
        this.setState({
          hideTooltip: true
        })
      }, 2000)
    }
  }

  render() {
    const { editMode, readyToEdit, viewOnly, currentlyEditing = "" } = this.props
    const { codeEditors, isAuthorized, loading, hideTooltip } = this.state

    if (_isEmpty(codeEditors) || !isAuthorized) {
      return null
    }

    return (
      <React.Fragment>
        <PaperHeader size="small" className="code-editor-card-header">
          <h3>Code Editor</h3>
          {!viewOnly && (
            <React.Fragment>
              {editMode && (
                <div className="buttons">
                  <Button
                    className="cancel-button"
                    color="link"
                    onClick={this.cancelCodeEditorsValues}
                    size="small"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={this.saveCodeEditorsValues}
                    className={loading ? "loading" : ""}
                  >
                    Save
                  </Button>
                </div>
              )}
              {!editMode && (
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={readyToEdit ? this.toggleEditMode : _noop}
                  disabled={!this.props.isEditable}
                  currentlyEditing={currentlyEditing}
                  tooltipId="edit-conf-code-tooltip-id"
                  className={!readyToEdit ? "cursor-wait" : ""}
                >
                  Edit
                </Button>
              )}
            </React.Fragment>
          )}
        </PaperHeader>
        <Paper hasHeader={true} className="code-editor-card-content">
          {_map(codeEditors, (editor, idx) => (
            <React.Fragment key={idx}>
              <h4>{editor.title}</h4>
              <div
                data-tip
                data-for="code-editor-tooltip"
                className={`ace-editor-wrapper ${editMode ? "edit" : "view"} ${
                  editor.error ? "error" : ""
                }`}
                onMouseEnter={this.autoHideTooltip}
              >
                <AceEditor
                  mode={editor.language}
                  theme="meiro"
                  onChange={this.onCodeChange(idx)}
                  name={`code_${idx}`}
                  value={editor.value}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{ maxLines: 50 }}
                  width="100%"
                  readOnly={!editMode}
                  wrapEnabled={true}
                />
              </div>
              {!editMode && !hideTooltip && !viewOnly && (
                <ReactTooltip id="code-editor-tooltip" place="bottom">
                  Tap edit for change
                </ReactTooltip>
              )}
              {editor.error && <p className="code-error">{editor.error}</p>}
            </React.Fragment>
          ))}
        </Paper>
      </React.Fragment>
    )
  }
}

CodeEditor.defaultProps = {
  readyToEdit: true,
  viewOnly: false
}

CodeEditor.propTypes = {
  component: PropTypes.instanceOf(Record).isRequired,
  settings: PropTypes.instanceOf(Map),
  onCodeSave: PropTypes.func.isRequired,
  authLink: PropTypes.string,
  oauthSettings: PropTypes.instanceOf(Map),
  isEditable: PropTypes.bool.isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  readyToEdit: PropTypes.bool,
  viewOnly: PropTypes.bool
}

export default CodeEditor
