import { ACL } from "sharedConstants"
import { Map, fromJS } from "immutable"
import _map from "lodash/map"
import AclModel, { DawgAclModel } from "models/acl.model"
import _toString from "lodash/toString"

export default (state = Map({ workspaces: null, dawgs: null }), { type, payload }) => {
  switch (type) {
    case `${ACL.ACTION.LIST}_FULFILLED`:
      if (state.get("workspaces")) {
        return state.set(
          "workspaces",
          state.get("workspaces").merge({
            [payload.workspaceId]: fromJS(_map(payload.data, acl => new AclModel(acl)))
          })
        )
      } else {
        return state.set(
          "workspaces",
          Map({
            [payload.workspaceId]: fromJS(_map(payload.data, acl => new AclModel(acl)))
          })
        )
      }
    case `${ACL.DAWG.ACTION.LIST}_FULFILLED`:
      if (state.get("dawgs")) {
        return state.set(
          "dawgs",
          state.get("dawgs").merge({
            [payload.dawgId]: fromJS(_map(payload.data, acl => new DawgAclModel(acl)))
          })
        )
      } else {
        return state.set(
          "dawgs",
          Map({
            [payload.dawgId]: fromJS(_map(payload.data, acl => new DawgAclModel(acl)))
          })
        )
      }
    case `${ACL.ACTION.CREATE}_FULFILLED`:
      return state.setIn(
        ["workspaces", _toString(payload.workspace_id)],
        state
          .getIn(["workspaces", _toString(payload.workspace_id)])
          .insert(0, new AclModel(payload))
      )
    case `${ACL.DAWG.ACTION.CREATE}_FULFILLED`:
      return state.setIn(
        ["dawgs", _toString(payload.dawg_id)],
        state.getIn(["dawgs", _toString(payload.dawg_id)]).insert(0, new DawgAclModel(payload))
      )
    case `${ACL.ACTION.MODIFY}_FULFILLED`:
      return state.setIn(
        ["workspaces", _toString(payload.workspace_id)],
        state
          .getIn(["workspaces", _toString(payload.workspace_id)])
          .map(acl => (acl.id === payload.id ? new AclModel(payload) : acl))
      )
    case `${ACL.DAWG.ACTION.MODIFY}_FULFILLED`:
      return state.setIn(
        ["dawgs", _toString(payload.dawg_id)],
        state
          .getIn(["dawgs", _toString(payload.dawg_id)])
          .map(acl => (acl.id === payload.id ? new DawgAclModel(payload) : acl))
      )
    case `${ACL.ACTION.DELETE}_FULFILLED`:
      return state.setIn(
        ["workspaces", _toString(payload.workspaceId)],
        state
          .getIn(["workspaces", _toString(payload.workspaceId)])
          .filterNot(acl => acl.id === payload.aclId)
      )
    case `${ACL.DAWG.ACTION.DELETE}_FULFILLED`:
      return state.setIn(
        ["dawgs", _toString(payload.dawgId)],
        state.getIn(["dawgs", _toString(payload.dawgId)]).filterNot(acl => acl.id === payload.aclId)
      )
    default:
      return state
  }
}
