import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _toString from "lodash/toString"
import _get from "lodash/get"
import { Map, List } from "immutable"
import { getFormValues } from "redux-form"

import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import FilterForm from "../FilterForm"
import SortingForm from "../SortingForm"
import { getTagsMap, getTagsSortedByName } from "selectors/tag.selector"

import "./SubHeader.css"

const dawgSelectOptions = [
  { value: "name", label: "Name" },
  { value: "last_run", label: "Activity" },
  { value: "last_run_duration", label: "Duration" },
  { value: "author_name", label: "Author" }
]

const wsSelectOptions = [
  { value: "name", label: "Name" },
  { value: "last_run", label: "Workspace activity" },
  { value: "last_activity", label: "Configuration activity" },
  { value: "last_run_duration", label: "Duration" },
  { value: "author_name", label: "Author" },
  { value: "disk_usage", label: "Disk used" }
]

const filterBySelectOptions = [
  { value: "all", label: "all" },
  { value: "enabled", label: "enabled" },
  { value: "disabled", label: "disabled" }
]

class SubHeader extends PureComponent {
  render() {
    const {
      filterValues,
      tags,
      tagsList,
      sortingFormName,
      filterFormName,
      onSortSubmit,
      onFilterBySubmit,
      selectFilterTag,
      deselectFilterTag,
      entityName
    } = this.props
    const selectedTags = _get(filterValues, "selectedTags", [])

    const sortingFormSelectOptions =
      entityName === "workspace" ? wsSelectOptions : dawgSelectOptions

    return (
      <Paper hasHeader={true} className="tags-and-ordering">
        <div className="tag-filter">
          <div className="label-tags">
            <span className="selected-tags">
              {selectedTags.size === 0 ? "Select tag:" : "Selected tags:"}
            </span>
            <div className="tags-wrapper">
              {selectedTags.map(tagId => {
                const stringTagId = _toString(tagId)
                let color = tags.getIn([stringTagId, "color"])
                if (!color) {
                  color = "primary"
                }
                return (
                  <Tag
                    key={tagId}
                    clickable={true}
                    color={color}
                    onClick={deselectFilterTag(tagId)}
                    className="selected-tag"
                  >
                    {tags.getIn([stringTagId, "name"], "Deleted tag")}
                  </Tag>
                )
              })}
              <TagPicker
                selectedTagIds={selectedTags}
                allTags={tagsList}
                onTagSelect={tagId => selectFilterTag(tagId)()}
                className="selected-tags-picker"
              />
            </div>
          </div>
        </div>
        <div className="selectboxes">
          <SortingForm
            form={sortingFormName}
            options={sortingFormSelectOptions}
            initialValues={{ sortBy: sortingFormSelectOptions[1], orderDir: "DESC" }}
            onSubmit={onSortSubmit}
          />
          <div className="vertical-delimiter" />
          <FilterForm
            form={filterFormName}
            options={filterBySelectOptions}
            initialValues={{ filterBy: filterBySelectOptions[0] }}
            onSubmit={onFilterBySubmit}
          />
        </div>
      </Paper>
    )
  }
}

SubHeader.propTypes = {
  filterValues: PropTypes.object.isRequired,
  globalFormName: PropTypes.string.isRequired,
  tags: PropTypes.instanceOf(Map).isRequired,
  tagsList: PropTypes.instanceOf(List).isRequired,
  sortingFormName: PropTypes.string.isRequired,
  filterFormName: PropTypes.string.isRequired,
  onSortSubmit: PropTypes.func.isRequired,
  onFilterBySubmit: PropTypes.func.isRequired,
  selectFilterTag: PropTypes.func.isRequired,
  deselectFilterTag: PropTypes.func.isRequired,
  entityName: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  return {
    filterValues: getFormValues(ownProps.globalFormName)(state),
    tags: getTagsMap(state),
    tagsList: getTagsSortedByName(state)
  }
}

export default connect(mapStateToProps)(SubHeader)
