import { Record } from "immutable"

const Configuration = Record({
  id: 0,
  user_id: 0,
  workspace_id: 0,
  component_id: 0,
  name: "",
  description: "",
  input_settings: null,
  settings: null,
  oauth_settings: null,
  frontend_settings: null,
  continue_on_failure: 0,
  disabled: 0,
  deleted: 0,
  auth_link: "",
  created: ""
})

export const ConfigurationFullStructure = Record({
  id: 0,
  user: null,
  workspace: null,
  component: null,
  name: "",
  description: "",
  input_settings: null,
  settings: null,
  oauth_settings: null,
  frontend_settings: null,
  continue_on_failure: 0,
  disabled: 0,
  deleted: 0,
  auth_link: "",
  created: ""
})

export default Configuration
