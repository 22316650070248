import { TRASH } from "sharedConstants"
import TrashedItemModel from "models/trashedItem.model"
import SelectionSettingModel from "models/selectionSettings.model"
import _map from "lodash/map"
import { Map, fromJS, List } from "immutable"

const initialState = Map(
  fromJS({
    data: [],
    selectionSettings: null,
    hasMoreItems: false
  })
)

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${TRASH.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        return state
          .set("data", List(_map(payload.trashed_items, item => new TrashedItemModel(item))))
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.trashed_items.length === TRASH.LOADING_LIMIT)
      } else {
        return state
          .set(
            "data",
            state
              .get("data")
              .concat(_map(payload.trashed_items, item => new TrashedItemModel(item)))
          )
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.trashed_items.length === TRASH.LOADING_LIMIT)
      }
    case `${TRASH.ACTION.RESTORE_USER}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").filterNot(item => item.type === "user" && item.entity.id === payload.id)
      )
    case `${TRASH.ACTION.RESTORE_CONFIGURATION}_FULFILLED`:
      return state.set(
        "data",
        state
          .get("data")
          .filterNot(item => item.type === "configuration" && item.entity.id === payload.id)
      )
    case `${TRASH.ACTION.RESTORE_WORKSPACE}_FULFILLED`:
      return state.set(
        "data",
        state
          .get("data")
          .filterNot(item => item.type === "workspace" && item.entity.id === payload.id)
      )
    default:
      return state
  }
}
