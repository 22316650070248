import React, { Component } from "react"
import PropTypes from "prop-types"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h3>{this.props.fallbackText}</h3>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  fallbackText: PropTypes.string.isRequired
}

export default ErrorBoundary
