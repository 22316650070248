import React from "react"
import PropTypes from "prop-types"

import "./Paper.css"

const Paper = ({ className, hasHeader, children }) => (
  <div className={`paper ${className ? className : ""} ${hasHeader ? "has-header" : ""}`}>
    {children}
  </div>
)

Paper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hasHeader: PropTypes.bool
}

export default Paper
