/*
 * These actions are used for showing/hiding top loading bar when we are calling
 * api directly from react component (we don't dispatch redux action).
 */

import { showLoading, hideLoading } from "react-redux-loading-bar"

export const showLoadingBar = () => dispatch => dispatch(showLoading())

export const hideLoadingBar = () => dispatch => dispatch(hideLoading())
