import { api } from "api"
import { TRASH } from "sharedConstants"
import _get from "lodash/get"

export const fetchTrashItems = (
  offset,
  limit,
  searched_text = null,
  item_type = null
) => async dispatch => {
  const load_full_structure = 1

  return dispatch({
    type: TRASH.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().trash.list(offset, limit, load_full_structure, searched_text, item_type)
      })()
    }
  })
}

export const restoreItem = item => async dispatch => {
  switch (item.type) {
    case "user":
      return dispatch({
        type: TRASH.ACTION.RESTORE_USER,
        payload: {
          promise: (async () => {
            const response = await api().user.restore(item.entity.id)
            return _get(response, "user", {})
          })()
        }
      })
    case "configuration":
      return dispatch({
        type: TRASH.ACTION.RESTORE_CONFIGURATION,
        payload: {
          promise: (async () => {
            const response = await api().configuration.restore(
              item.entity.workspace.id,
              item.entity.id
            )
            return _get(response, "configuration", {})
          })()
        }
      })
    case "workspace":
      return dispatch({
        type: TRASH.ACTION.RESTORE_WORKSPACE,
        payload: {
          promise: (async () => {
            const response = await api().workspace.restore(item.entity.id)
            return _get(response, "workspace", {})
          })()
        }
      })
    default:
      break
  }
}
