import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import TextField from "components/UI/elements/TextField"
import InfoTooltip from "components/UI/elements/InfoTooltip"

import { modifyConfiguration } from "actions/configuration.action"

import "./OauthNoteModal.css"

class OauthNoteModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = values => {
    if (!this.state.isLoading) {
      const {
        modifyConfiguration,
        authLink,
        match: {
          params: { id, cid }
        }
      } = this.props
      this.setState({ loading: true })
      modifyConfiguration(id, cid, { oauth_settings: { note: values.name } })
        .then(response => {
          window.location.href = authLink
        })
        .catch(() => {
          this.setState({
            loading: false
          })
        })
    }
  }

  render() {
    const { open, handleClose, handleSubmit } = this.props

    return (
      <Modal open={open} handleClose={handleClose} title="OAuth">
        <form
          className="oauth-note-modal"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="form-row name-wrapper">
            <InfoTooltip tooltipId="oauth-info-tooltip" className="name-tooltip">
              Name will be displayed in the component.
            </InfoTooltip>
            <Field
              name="name"
              component={TextField}
              label="Account name (optional)"
              placeholder="Name for this authorization"
              fullWidth={true}
              autoFocus
            />
          </div>
          <Button
            type="submit"
            color="primary"
            size="big"
            fullWidth={true}
            className={this.state.loading ? "loading" : ""}
          >
            Next
          </Button>
        </form>
      </Modal>
    )
  }
}

OauthNoteModal.propTypes = {
  modifyConfiguration: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  authLink: PropTypes.string
}

OauthNoteModal = reduxForm({
  form: "OauthNoteForm",
  touchOnBlur: false
})(connect(null, { modifyConfiguration })(OauthNoteModal))

export default withRouter(OauthNoteModal)
