import _isEmpty from "lodash/isEmpty"
import _cloneDeep from "lodash/cloneDeep"

import DefaultSettingsProcessor from "./DefaultSettingsProcessor"
import AutoIncrementSettingsProcessor from "./AutoIncrementSettingsProcessor"
import ObjectKeysToArraySettingsProcessor from "./ObjectKeysToArraySettingsProcessor"
import ObjectToArraySettingsProcessor from "./ObjectToArraySettingsProcessor"

class Processor {
  constructor(processors = []) {
    this.processors = processors.map(processor => {
      if (processor.type === "autoincrement") {
        return new AutoIncrementSettingsProcessor(
          processor.settings.path,
          processor.settings.start,
          processor.settings.data_type
        )
      } else if (processor.type === "object_keys_to_array") {
        return new ObjectKeysToArraySettingsProcessor(processor.settings.object_path)
      } else if (processor.type === "object_to_array") {
        return new ObjectToArraySettingsProcessor(
          processor.settings.object_path,
          processor.settings.key_property
        )
      } else {
        return new DefaultSettingsProcessor()
      }
    })
  }

  preProcessSettings(settings) {
    if (_isEmpty(this.processors)) {
      return settings
    }

    let settingsTransformation = _cloneDeep(settings)
    this.processors.forEach(processorInstance => {
      processorInstance.preProcessSettings(settingsTransformation)
    })
    return settingsTransformation
  }

  postProcessSettings(settings) {
    if (_isEmpty(this.processors)) {
      return settings
    }

    let settingsTransformation = _cloneDeep(settings)
    this.processors.forEach(processorInstance => {
      processorInstance.postProcessSettings(settingsTransformation)
    })

    return settingsTransformation
  }
}

export default Processor
