import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import PropTypes from "prop-types"
import { Record, List } from "immutable"
import moment from "moment"
import { Link } from "react-router-dom"
import _findKey from "lodash/findKey"
import _toLower from "lodash/toLower"
import _size from "lodash/size"
import _trimStart from "lodash/trimStart"
import { Waypoint } from "react-waypoint"

import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import LoadingCircle from "components/UI/elements/LoadingCircle"
import { SearchFormUndestroyable } from "components/UI/components/SearchForm"
import { fulltextSearch, clearFulltextSearch } from "actions/fulltextSearch.action"
import {
  getFulltextSearchData,
  getSelectionSettings,
  hasMoreItems
} from "selectors/fulltextSearch.selector"
import { getComponentIconSrc } from "helpers/component.helper"
import { SEARCH, MOMENT } from "sharedConstants"
import { getRoutePath } from "routes"

import "./Search.css"

class Search extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadingMore: false
    }
  }

  onSearchSubmit = searchTerm => {
    const { fulltextSearch, clearFulltextSearch } = this.props
    if (searchTerm !== null && searchTerm.length > 0) {
      fulltextSearch(searchTerm, 0, SEARCH.LOADING_LIMIT, 1)
    } else {
      clearFulltextSearch()
    }
  }

  _lodaMoreResults = () => {
    if (!this.state.loadingMore) {
      const { fulltextSearch, selectionSettings, searchTerm } = this.props
      this.setState({
        loadingMore: true
      })
      fulltextSearch(
        searchTerm,
        selectionSettings.offset + SEARCH.LOADING_LIMIT,
        SEARCH.LOADING_LIMIT,
        1
      )
        .then(() => {
          this.setState({
            loadingMore: false
          })
        })
        .catch(() => {
          this.setState({
            loadingMore: false
          })
        })
    }
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { hasMoreResults } = this.props
    const { loadingMore } = this.state

    if (loadingMore) {
      return <LoadingCircle className="loading-indicator" />
    }
    if (hasMoreResults) {
      return <Waypoint onEnter={this._lodaMoreResults} bottomOffset={-250} />
    }
  }

  renderComponentBox = component => {
    let icon = component.icon
    if (!icon) {
      icon = "dummy.png"
    }

    return (
      <div className={`component-icon ${component.hidden ? "deprecated" : ""}`}>
        <div className="icon">
          <img src={getComponentIconSrc(icon)} alt="component icon" />
        </div>
        <div className="desc">
          <h4>{component.name}</h4>
          <h5>{component.type}</h5>
        </div>
      </div>
    )
  }

  renderSearchResults = () => {
    const { searchResults, searchTerm } = this.props
    return searchResults.map(result => {
      // search in settings
      let arrayOfLines = JSON.stringify(result.settings, null, 2).split("\n")
      let searchTermLineIndex = arrayOfLines.findIndex(line =>
        _toLower(line).includes(_toLower(searchTerm))
      )
      // try to find variable value
      if (searchTermLineIndex === -1) {
        const vKey = _findKey(result.workspace.variables, variable => {
          if (_size(variable) > 0) {
            if (_toLower(variable).includes(_toLower(searchTerm))) {
              return true
            }
          }
        })
        if (vKey !== undefined) {
          searchTermLineIndex = arrayOfLines.findIndex(line =>
            _toLower(line).includes(_toLower(`{{${vKey}}}`))
          )
        }
      }
      // search in conf name
      if (searchTermLineIndex === -1) {
        arrayOfLines = JSON.stringify({ name: result.name }, null, 2).split("\n")
        searchTermLineIndex = arrayOfLines.findIndex(line =>
          _toLower(line).includes(_toLower(searchTerm))
        )
      }
      // search in conf description
      if (searchTermLineIndex === -1) {
        arrayOfLines = JSON.stringify({ description: result.description }, null, 2).split("\n")
        searchTermLineIndex = arrayOfLines.findIndex(line =>
          _toLower(line).includes(_toLower(searchTerm))
        )
      }
      // search in conf workspace name
      if (searchTermLineIndex === -1) {
        arrayOfLines = JSON.stringify(
          { workspace: { name: result.workspace.name } },
          null,
          2
        ).split("\n")
        searchTermLineIndex = arrayOfLines.findIndex((line, idx) => {
          if (idx !== 1) {
            return _toLower(line).includes(_toLower(searchTerm))
          }
          return false
        })
      }
      // search in conf workspace description
      if (searchTermLineIndex === -1) {
        arrayOfLines = JSON.stringify(
          { workspace: { description: result.workspace.description } },
          null,
          2
        ).split("\n")
        searchTermLineIndex = arrayOfLines.findIndex((line, idx) => {
          if (idx !== 1) {
            return _toLower(line).includes(_toLower(searchTerm))
          }
          return false
        })
      }
      let startIndex, endIndex
      switch (searchTermLineIndex) {
        case 0:
          startIndex = searchTermLineIndex
          endIndex = startIndex + 5
          break
        case 1:
          startIndex = searchTermLineIndex - 1
          endIndex = searchTermLineIndex + 4
          break
        case 2:
          startIndex = searchTermLineIndex - 2
          endIndex = searchTermLineIndex + 3
          break
        case arrayOfLines.length - 1:
          startIndex = searchTermLineIndex - 4 < 0 ? 0 : searchTermLineIndex - 4
          endIndex = searchTermLineIndex + 1
          break
        case arrayOfLines.length - 2:
          startIndex = searchTermLineIndex - 3 < 0 ? 0 : searchTermLineIndex - 3
          endIndex = searchTermLineIndex + 2
          break
        default:
          startIndex = searchTermLineIndex - 2
          endIndex = searchTermLineIndex + 3
      }
      let gapSize = 2147483647
      let resultToRender = arrayOfLines.slice(startIndex, endIndex)
      if (_size(resultToRender) > 0) {
        resultToRender.forEach(line => {
          const startSize = _size(line)
          const endSize = _size(_trimStart(line))
          const gap = startSize - endSize
          if (gap < gapSize) {
            gapSize = gap
          }
        })
        if (gapSize) {
          resultToRender = resultToRender.map(line => {
            return line.substring(gapSize)
          })
        }
      }
      const maxIndexLength = endIndex.toString().length

      return (
        <Paper key={result.id} className="configuration-card">
          <div className="col-1">
            <div className="row">
              <div className="configuration">
                <span className="label">Configuration</span>
              </div>
              <div className="component">
                <span className="label">Component</span>
              </div>
              <div className="author">
                <span className="label">Author</span>
              </div>
              <div className="modified">
                <span className="label">Modified</span>
              </div>
            </div>
            <div className="row values">
              <div className="configuration value">
                <Link
                  className="conf-link"
                  to={{
                    pathname: getRoutePath("workspace.configuration.show", {
                      id: result.workspace.id,
                      cid: result.id
                    }),
                    state: {
                      previous: this.props.location.pathname
                    }
                  }}
                >
                  <h3>{result.name}</h3>
                </Link>
              </div>
              <div className="component value">{this.renderComponentBox(result.component)}</div>
              <div className="author value">
                <p className="text">{result.user.name}</p>
              </div>
              <div className="modified value">
                <p className="text">
                  {moment
                    .utc(result.created)
                    .local()
                    .format(MOMENT.DATE_TIME_FORMAT)}
                </p>
              </div>
            </div>
            <div className="row bottom">
              <div className="workspace">
                <span className="label">Workspace</span>
                <p className="text">{result.workspace.name}</p>
              </div>
            </div>
          </div>
          <div className="col-2">
            <span className="label">Result</span>
            <div className="result-code">
              {searchTermLineIndex === -1 && <p className="na">Unable to create preview.</p>}
              {searchTermLineIndex !== -1 &&
                resultToRender.map(line => {
                  startIndex += 1
                  return (
                    <p
                      key={startIndex}
                      className={startIndex - 1 === searchTermLineIndex ? "marked" : ""}
                    >
                      <span className={`index l-${maxIndexLength}`}>{startIndex}</span>
                      {line}
                    </p>
                  )
                })}
            </div>
          </div>
        </Paper>
      )
    })
  }

  render() {
    const { searchResults } = this.props
    return (
      <section className="search-page">
        <PaperHeader
          size="small"
          titleText="Full-text search"
          subTitleText="Search through configurations’ name / description / parameters / code / workspace name and description."
          className="search-page-header"
        >
          <SearchFormUndestroyable
            placeholder="Search in configuration"
            className="search-page-search-form"
            initialValues={{ search: "" }}
            onSubmit={this.onSearchSubmit}
            form="SearchPageSearchForm"
          />
        </PaperHeader>
        {searchResults !== null && searchResults.size === 0 && (
          <p className="no-results-found">No results found.</p>
        )}
        {searchResults !== null && searchResults.size > 0 && this.renderSearchResults()}
        {this.renderWaypoint()}
      </section>
    )
  }
}

Search.propTypes = {
  selectionSettings: PropTypes.instanceOf(Record),
  searchResults: PropTypes.instanceOf(List),
  fulltextSearch: PropTypes.func.isRequired,
  hasMoreResults: PropTypes.bool.isRequired
}

const selector = formValueSelector("SearchPageSearchForm")

const mapStateToProps = state => {
  return {
    searchResults: getFulltextSearchData(state),
    selectionSettings: getSelectionSettings(state),
    searchTerm: selector(state, "search"),
    hasMoreResults: hasMoreItems(state)
  }
}

export default connect(mapStateToProps, { fulltextSearch, clearFulltextSearch })(Search)
