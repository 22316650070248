import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import "./WorkspaceLink.css"

const WorkspaceLink = ({ name, route }) => (
  <Link
    className="workspace-link"
    to={{
      pathname: route,
      state: {
        previous: true
      }
    }}
    data-testid="workspace-link"
  >
    {name}
  </Link>
)

WorkspaceLink.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
}

export default WorkspaceLink
