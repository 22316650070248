import deepForEach from "deep-for-each"
import _ from "lodash"

export const generateJsonFormUiSchema = (
  dataSchema,
  uiSchema,
  readOnly = false,
  collapsable = true,
  expandAll = 0,
  collapseAll = 0
) => {
  let uiSchemaComposed = {}
  let hasCollapsableElements = false
  deepForEach(dataSchema, (value, key, subject, path) => {
    if (_.isPlainObject(value)) {
      if (_.has(value, "type")) {
        const pathUiSchema = _.replace(path, new RegExp("properties.", "g"), "")
        if (value.type === "object") {
          if (_.includes(pathUiSchema, ".")) {
            if (collapsable) {
              _.set(uiSchemaComposed, `${pathUiSchema}.classNames`, "collapsable")
              _.set(uiSchemaComposed, `${pathUiSchema}.expandAll`, expandAll)
              _.set(uiSchemaComposed, `${pathUiSchema}.collapseAll`, collapseAll)
              hasCollapsableElements = true
            } else {
              _.set(uiSchemaComposed, `${pathUiSchema}.classNames`, "not-collapsable")
            }
          }
        }
        if (!_.includes(pathUiSchema, ".")) {
          // root
          if (readOnly) {
            _.set(uiSchemaComposed, `${pathUiSchema}.ui:readonly`, true)
          }
          _.set(uiSchemaComposed, `${pathUiSchema}.root`, true)
        }
      }
    }
  })
  if (collapsable) {
    return {
      uiSchema: _.merge(uiSchemaComposed, uiSchema),
      hasCollapsableElements
    }
  } else {
    return _.merge(uiSchemaComposed, uiSchema)
  }
}
