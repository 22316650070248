import React from "react"

// ui components
import Paper from "components/UI/elements/Paper"

import notFoundImageUrl from "images/404-graph.png"
import "./NotFoundPage.css"

const NotFoundPage = () => (
  <Paper className="not-found">
    <img src={notFoundImageUrl} alt="404 Not Found" className="left-side" />
    <div className="right-side">
      <h1>Did I just end up on 404?</h1>
      <p className="yes">Yes</p>
      <p className="yes-black">Yes in black</p>
    </div>
  </Paper>
)

export default NotFoundPage
