import _get from "lodash/get"
import { api } from "api"
import { OAUTH } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchOAuthList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: OAUTH.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().oauth.list(offset, limit, loadFullStructure)
          )
          .setDataPath("oauth_repositories")
          .setLoadFullStructure(0)
          .run()
        return data
      })()
    }
  })
}

export const modifyOAuth = (id, data, previousData) => async dispatch =>
  dispatch({
    type: OAUTH.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().oauth.modify(id, data, previousData)
        return _get(response, "oauth_repository", {})
      })()
    }
  })
