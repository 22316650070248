import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Form } from "redux-form"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _noop from "lodash/noop"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import Avatar from "components/UI/elements/Avatar"

// actions
import { showToast } from "actions/toast.action"

// constants, helpers
import { TOAST, PERMISSION, USER } from "sharedConstants"
import { shortenString } from "helpers/string.helper"

import "./EditUserAclModal.css"

class EditUserAclModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      changingOwnPermissionToView: false
    }
  }

  closeAndReset = () => {
    const { handleClose, reset } = this.props
    if (this.state.changingOwnPermissionToView) {
      this.setState({
        changingOwnPermissionToView: false
      })
    }
    reset()
    handleClose()
  }

  onSubmit = values => {
    if (!this.state.loading) {
      const { modifyAcl, handleClose, showToast, acl, fetchUsersAcl, entityName } = this.props
      const { changingOwnPermissionToView } = this.state
      this.setState({ loading: true })
      const entityId = entityName === "workspace" ? acl.workspace_id : acl.dawg_id
      modifyAcl(entityId, acl.user_id, { ...values })
        .then(() => {
          if (changingOwnPermissionToView) {
            fetchUsersAcl(acl.user_id).catch(_noop)
          }
          handleClose()
          this.setState({ loading: false, changingOwnPermissionToView: false })
          showToast("User's permission has been modified.", TOAST.TYPE.SUCCESS)
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  deleteEntityUser = () => {
    if (!this.state.loading) {
      const { deleteAcl, handleClose, acl, showToast } = this.props
      this.setState({ loading: true })
      deleteAcl(acl)
        .then(() => {
          handleClose()
          this.setState({ loading: false })
          showToast("User's permission has been deleted.", TOAST.TYPE.SUCCESS)
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  checkChangingOwnPermissionToView = evt => {
    const { user, authenticatedUser } = this.props
    const value = _get(evt, "target.value")
    if (value) {
      if (_get(user, "id") === _get(authenticatedUser, "data.id") && value === PERMISSION.READ) {
        this.setState({ changingOwnPermissionToView: true })
      } else {
        if (this.state.changingOwnPermissionToView) {
          this.setState({ changingOwnPermissionToView: false })
        }
      }
    }
  }

  render() {
    const { open, handleSubmit, user, entityName } = this.props
    const { changingOwnPermissionToView } = this.state

    return (
      <Modal open={open} handleClose={this.closeAndReset} title="Modify user's permission">
        <Form
          className="edit-entity-user-modal"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="form-row">
            {user && (
              <div className="user">
                <Avatar
                  name={user.name}
                  email={user.email}
                  gravatarSize={110}
                  className="user-avatar"
                />
                {shortenString(user.name, 20)}
              </div>
            )}
            <ToggleSwitchField
              name="permission"
              leftValue={PERMISSION.READ}
              leftLabel="View"
              rightValue={PERMISSION.WRITE}
              rightLabel="Edit"
              width="100px"
              label="Permission"
              disabled={_get(user, "role") === USER.ROLE.ADMIN}
              onChange={this.checkChangingOwnPermissionToView}
            />
            <IconButton
              color="red"
              type="button"
              onClick={this.deleteEntityUser}
              data-cy="remove-user-from-entity"
            >
              <FontAwesomeIcon icon={["far", "trash-alt"]} />
            </IconButton>
            {_get(user, "role") === USER.ROLE.ADMIN && (
              <p className="admin-permission-message">Admin's permissions can't be modified.</p>
            )}
            {changingOwnPermissionToView && (
              <p className="admin-permission-message">
                {`You won't be able to edit this ${entityName} anymore.`}
              </p>
            )}
          </div>
          <div className="action-buttons">
            <Button
              type="button"
              color="transparent-grey"
              size="big"
              onClick={this.closeAndReset}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              size="big"
              className={this.state.loading ? "loading" : ""}
              disabled={_get(user, "role") === USER.ROLE.ADMIN}
            >
              Modify
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }
}

EditUserAclModal.propTypes = {
  open: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  user: PropTypes.object,
  acl: PropTypes.object,
  modifyAcl: PropTypes.func.isRequired,
  fetchUsersAcl: PropTypes.func.isRequired,
  deleteAcl: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  authenticatedUser: state.authenticatedUser
})

EditUserAclModal = reduxForm({
  form: "EditUserAcl",
  enableReinitialize: true
})(connect(mapStateToProps, { showToast })(EditUserAclModal))

export default EditUserAclModal
