import _isPlainObject from "lodash/isPlainObject"
import _isEmpty from "lodash/isEmpty"
import _isString from "lodash/isString"
import _trim from "lodash/trim"
import deepForEach from "deep-for-each"
import { Map } from "immutable"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import zxcvbn from "zxcvbn"

export const isEmailValid = email => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(_trim(email)) ? false : true
}

// validation mesages
export const email = value => (value && !isEmailValid(value) ? "Invalid email address" : undefined)
export const required = value => (value ? undefined : "Please fill in the field")
export const minLength6 = value => (value.length < 6 ? "At least 6 characters required" : undefined)
export const minLength12 = value =>
  value.length < 12 ? "At least 12 characters required" : undefined
export const requiredJSON = value =>
  value.length > 1 ? undefined : "Enter the valid JSON, at least brackets '{}'"
export const isValidJSON = value =>
  value && !isJSONString(value) ? "Entered string is not valid JSON" : undefined
export const optionRequired = value => (!_isEmpty(value) ? undefined : "Please select one option")

// JSON validators
export const isJSONString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isJSONObject = str => {
  try {
    const obj = JSON.parse(str)
    if (_isPlainObject(obj)) {
      return true
    }
  } catch (e) {
    return false
  }
  return false
}

export const getUndefinedWorkspaceVariables = (settings, variables) => {
  const undefinedVariables = []
  const variablesKeys = []
  if (Map.isMap(variables)) {
    variables.forEach((value, key) => {
      variablesKeys.push(`{{${_trim(key)}}}`)
    })
  }
  if (settings) {
    deepForEach(settings, value => {
      if (_isString(value)) {
        const trimmed = _trim(value)
        if (/.*\{\{.+\}\}.*/.test(_trim(trimmed))) {
          console.log(trimmed)
          if (!variablesKeys.some(key => trimmed.includes(key))) {
            undefinedVariables.push(trimmed)
          }
        }
      }
    })
  }
  return undefinedVariables
}

export const passwordStrength = value => {
  const strResults = zxcvbn(value)
  if (strResults.score >= 3) {
    return undefined
  } else {
    if (_isArray(_get(strResults, "feedback.suggestions"))) {
      return strResults.feedback.suggestions[strResults.feedback.suggestions.length - 1]
    }
    return "Password is weak."
  }
}
