import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, formValueSelector } from "redux-form"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// ui components
import TagTextField from "components/UI/elements/TextField/TagTextField"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"

// constants helpers
import { required } from "helpers/validators.helper"

import "./TagForm.css"

const colors = [
  "wasabi",
  "medium-aquamarine",
  "persian-blue",
  "medium-purple",
  "contessa",
  "fire-engine-red",
  "cabaret",
  "macaroni-and-cheese",
  "broom",
  "fuego",
  "gossamer",
  "silver-tree",
  "nepal",
  "gumbo"
]

class TagForm extends PureComponent {
  onDeleteButtonClick = () => {
    this.props.handleTagDelete()
  }

  onCloseButtonClick = () => {
    this.props.handleClose()
  }

  render() {
    const { handleSubmit, onSubmit, type, selectedColor, name } = this.props
    const isCreate = type === "CREATE"

    return (
      <Form className="tag-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="custom-text-field">
          <Field
            placeholder="Tag name"
            name="name"
            component={TagTextField}
            label={isCreate ? "Create tag" : "Modify tag"}
            validate={required}
            className={selectedColor}
            autoFocus
          />
        </div>
        <div className="colors-buttons">
          <div className="colors-box">
            <p className="label-like">Pick color</p>
            <Field name="color" colors={colors} component={ColorRadioGroup} validate={required} />
          </div>
          <div className="buttons">
            <Button type="submit" size="small" color="primary" disabled={!selectedColor || !name}>
              {isCreate ? "Add tag" : "Modify tag"}
            </Button>
            <IconButton
              color="red"
              onClick={this.onDeleteButtonClick}
              disabled={isCreate}
              data-cy="delete-tag-button"
            >
              <FontAwesomeIcon icon={["far", "trash-alt"]} />
            </IconButton>
            <IconButton color="grey" onClick={this.onCloseButtonClick}>
              <FontAwesomeIcon icon={["far", "times"]} />
            </IconButton>
          </div>
        </div>
      </Form>
    )
  }
}

TagForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleTagDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  type: PropTypes.string.isRequired
}

const selector = formValueSelector("TagForm")
TagForm = connect(state => ({
  selectedColor: selector(state, "color"),
  name: selector(state, "name")
}))(TagForm)

TagForm = reduxForm({
  form: "TagForm",
  touchOnBlur: false,
  enableReinitialize: true
})(TagForm)

export default TagForm
