import store from "store"
import { PERMISSION } from "sharedConstants"
import _get from "lodash/get"

const { getState } = store

export const isFetching = () => getState().authenticatedUser.isFetching

export const token = () => getState().authenticatedUser.token

export const isLoggedIn = () => token() !== ""

export const isAdmin = () => {
  const userRole = _get(getState().authenticatedUser, "data.role", "")
  return userRole === "admin"
}

export const hasWritePermission = acl => {
  const loggedUser = getState().authenticatedUser.data
  return loggedUser.role === "admin" || (acl ? acl.permission === PERMISSION.WRITE : false)
}

export const canCreateWorkspaces = () => {
  const loggedUser = getState().authenticatedUser.data
  return loggedUser.role === "admin" || loggedUser.can_create_workspaces === 1
}

export const canCreateDawgs = () => {
  const loggedUser = getState().authenticatedUser.data
  return loggedUser.role === "admin" || loggedUser.can_create_dawgs === 1
}
