import { List } from "immutable"

export const getDawgDashboardWorkspaceJobs = (state, dawgId) => {
  const data = state.dawgWorkspaceJobs.getIn([dawgId, "data"])
  if (List.isList(data)) {
    return data.take(6)
  }
  return null
}

export const getDawgWorkspaceJobs = (state, dawgId) =>
  state.dawgWorkspaceJobs.getIn([dawgId, "data"])

export const getDawgWorkspaceJobsSelectionSettings = (state, dawgId) =>
  state.dawgWorkspaceJobs.getIn([dawgId, "selectionSettings"])
