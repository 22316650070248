import { createSelector } from "reselect"

const getSearchedItems = state => state.fulltextSearch.get("data")
export const getSelectionSettings = state => state.fulltextSearch.get("selectionSettings")

export const hasMoreItems = state => state.fulltextSearch.get("hasMoreItems")

export const getFulltextSearchData = createSelector(
  [getSearchedItems, getSelectionSettings],
  (searchedItems, selectionSettings) => {
    if (selectionSettings === null) {
      return null
    } else {
      return searchedItems
    }
  }
)
