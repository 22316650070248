import { createSelector } from "reselect"
import _toLower from "lodash/toLower"

const getTags = state => state.tags.get("data")

export const getTagsMap = createSelector([getTags], tags => tags)

export const areTagsAlreadyFetched = state => state.tags.get("isFetched")

export const getTagsArrayForSelect = createSelector(getTags, tags =>
  tags
    .map(tag => ({ value: tag.id, label: tag.name }))
    .toList()
    .toJS()
)

export const getTagsSortedByName = createSelector(getTags, tags => {
  return tags.toList().sortBy(val => _toLower(val.name))
})
