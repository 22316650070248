import React from "react"
import PropTypes from "prop-types"
import { Map, List } from "immutable"
import _includes from "lodash/includes"
import _pick from "lodash/pick"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import moment from "moment"
import { Link } from "react-router-dom"

// ui components
import WorkspaceGanttChart from "./WorkspaceGanttChart"

// constants, helpers
import { GANTT_DATETIME_FORMAT } from "sharedConstants"
import { shortenString } from "helpers/string.helper"
import { getRoutePath } from "routes"
import { getComponentIconSrc } from "helpers/component.helper"

import "./IntegrationsActivity.css"

const IntegrationsActivity = ({ data, startDate, endDate }) => {
  const processedData = data.map((workspace, id) =>
    Map({
      name: workspace.get("name"),
      id: parseInt(id),
      tasks: List.isList(workspace.get("jobs"))
        ? workspace
            .get("jobs")
            .filter(job => {
              const jobEnd = _includes(["running", "waiting"], job.status)
                ? moment().local()
                : moment.utc(job.created).local()
              return jobEnd.isBetween(startDate, endDate)
            })
            .map(job => {
              return {
                start: job.origin_created
                  ? moment
                      .utc(job.origin_created)
                      .local()
                      .format(GANTT_DATETIME_FORMAT)
                  : moment
                      .utc(job.created)
                      .local()
                      .format(GANTT_DATETIME_FORMAT),
                end: _includes(["running", "waiting"], job.status)
                  ? moment()
                      .local()
                      .format(GANTT_DATETIME_FORMAT)
                  : moment
                      .utc(job.created)
                      .local()
                      .format(GANTT_DATETIME_FORMAT),
                name: job.status,
                id: job.configuration.id.toString(),
                dependencies: "",
                custom_class: `bar-${job.status}`,
                configuration: _pick(job.configuration, ["id", "name", "component", "deleted"]),
                job_id: job.id
              }
            })
            .toArray()
        : []
    })
  )

  let count = 0
  let charts = processedData
    .map(workspaceChartData => {
      if (!_isEmpty(workspaceChartData.get("tasks"))) {
        count++
        return (
          <div className={`workspace-gantt gantt-${count}`} key={workspaceChartData.get("id")}>
            <div>
              <Link
                to={getRoutePath("workspace.show", {
                  id: workspaceChartData.get("id")
                })}
                className="workspace-gantt-workspace-name"
              >
                <p>{shortenString(workspaceChartData.get("name"), 25)}</p>
              </Link>
              <div className="configurations-list">
                {workspaceChartData.get("tasks").map((task, index) => {
                  let icon = _get(task, "configuration.component.icon", null)
                  if (!icon) {
                    icon = "dummy.png"
                  }
                  let link
                  if (task.configuration.deleted) {
                    link = (
                      <div key={index} className="configuration-item deleted">
                        <img
                          className="component-icon"
                          src={getComponentIconSrc(icon)}
                          alt="icon"
                        />
                        <span className="red">Deleted configuration</span>
                      </div>
                    )
                  } else {
                    link = (
                      <Link
                        key={index}
                        className="configuration-item link"
                        to={getRoutePath("workspace.configuration.show", {
                          id: workspaceChartData.get("id"),
                          cid: task.configuration.id
                        })}
                      >
                        <img
                          className="component-icon"
                          src={getComponentIconSrc(icon)}
                          alt="icon"
                        />
                        <span>{shortenString(task.configuration.name, 23)}</span>
                      </Link>
                    )
                  }
                  return link
                })}
              </div>
            </div>
            <WorkspaceGanttChart
              workspaceName={workspaceChartData.get("name")}
              workspaceId={workspaceChartData.get("id")}
              tasks={workspaceChartData.get("tasks")}
              startDate={startDate
                .clone()
                .subtract(30, "m")
                .format(GANTT_DATETIME_FORMAT)}
              endDate={endDate.format(GANTT_DATETIME_FORMAT)}
            />
          </div>
        )
      }
      return null
    })
    .toArray()

  if (count) {
    return charts
  } else {
    return <p className="no-activity">No activity occurrence in the selected time range.</p>
  }
}

IntegrationsActivity.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired
}

export default IntegrationsActivity
