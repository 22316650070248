import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

import "./ToggleSwitch.css"

const ToggleSwitchField = ({
  name,
  leftValue,
  rightValue,
  leftLabel,
  rightLabel,
  width,
  size,
  label,
  disabled = false,
  onChange = () => {}
}) => (
  <div className="toggle-switch-wrapper" data-testid="toggle-switch-wrapper">
    {label && (
      <label className="form-label" data-testid="whole-field-label">
        {label}
      </label>
    )}
    <div
      className={`toggle-switch ${disabled ? "disabled" : ""}`}
      style={{ width: width }}
      data-testid="toggle-switch"
    >
      <p className={`fieldset ${size}`} data-testid="fieldset">
        <Field
          type="radio"
          name={name}
          value={leftValue}
          id={`${leftValue}-${name}`}
          component="input"
          disabled={disabled}
          onChange={onChange}
          data-testid="left-field"
        />
        <label htmlFor={`${leftValue}-${name}`} className={`${size}`} data-testid="left-label">
          {leftLabel ? leftLabel : leftValue}
        </label>
        <Field
          type="radio"
          name={name}
          value={rightValue}
          id={`${rightValue}-${name}`}
          component="input"
          disabled={disabled}
          onChange={onChange}
          data-testid="right-field"
        />
        <label htmlFor={`${rightValue}-${name}`} className={`${size}`} data-testid="right-label">
          {rightLabel ? rightLabel : rightValue}
        </label>
        <span className={`switch ${size}`} data-testid="switch" />
      </p>
    </div>
  </div>
)

ToggleSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  rightValue: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  width: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleSwitchField
