import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { oktaLogin } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import { getRoutePath } from "routes"

import { TOAST } from "sharedConstants"

import WholePageLoading from "components/UI/elements/WholePageLoading"

class OktaCallback extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }

  componentDidMount() {
    const { location, oktaLogin, history, showToast } = this.props

    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get("code")

    try {
      if (
        window.localStorage.getItem("redirectAfterOktaLoginTo") !== "null" &&
        window.localStorage.getItem("redirectAfterOktaLoginTo") !== ""
      ) {
        history.replace({
          search: `${location.search}&${new URLSearchParams({
            redirect: window.localStorage.getItem("redirectAfterOktaLoginTo")
          })}`
        })
      }
    } catch (err) {}
    if (code) {
      oktaLogin({ code })
        .then(() => {
          try {
            if (window.localStorage.getItem("miGeneralTermsAccepted") !== "true") {
              window.localStorage.setItem("miGeneralTermsAccepted", "true")
            }
          } catch (err) {}
        })
        .catch(error => {
          this.setState({
            error: true
          })
        })
    } else {
      const error_description = urlParams.get("error_description")
      this.setState({
        error: true
      })
      showToast(error_description, TOAST.TYPE.ERROR)
    }
  }

  render() {
    return (
      <WholePageLoading
        customMessage="Logging in..."
        loadingError={this.state.error}
        customErrorMessage="Error while logging in."
        fullHeight
      >
        {this.state.error && (
          <Link to={getRoutePath("login")} className="back-to-login">
            Back to login
          </Link>
        )}
      </WholePageLoading>
    )
  }
}

export default connect(null, { oktaLogin, showToast })(OktaCallback)
