import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSTransition } from "react-transition-group"

import IconButton from "../IconButton"

import "./Modal.css"

class Modal extends Component {
  componentDidMount() {
    if (this.props.open) {
      window.addEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      window.addEventListener("keyup", this.handleKeyUp, false)
    } else if (!this.props.open && prevProps.open) {
      window.removeEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleKeyUp = evt => {
    const { handleClose } = this.props
    const keys = {
      27: () => {
        evt.preventDefault()
        handleClose()
      }
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  render() {
    const { handleClose, children, className, hideCloseButton, title, open, size } = this.props
    return (
      <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
        <div className="modal-wrapper">
          <div
            className={`modal ${className ? className : ""} ${size ? size : ""}`}
            data-testid="modal"
          >
            <header>
              <h3>{title}</h3>
              {!hideCloseButton && (
                <IconButton
                  className="close-button"
                  color="grey"
                  onClick={handleClose}
                  data-testid="close-button"
                >
                  <FontAwesomeIcon icon={["far", "times"]} />
                </IconButton>
              )}
            </header>
            <section className="modal-body" data-testid="modal-content">
              {children}
            </section>
          </div>
          <div
            className="modal-overlay"
            data-testid="modal-overlay"
            onClick={() => {
              if (open) {
                handleClose()
              }
            }}
          />
        </div>
      </CSSTransition>
    )
  }
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default Modal
