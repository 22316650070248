import { SYSTEM } from "sharedConstants"
import { api } from "api"
import _get from "lodash/get"

export const fetchWorkersCount = () => async dispatch =>
  dispatch({
    type: SYSTEM.ACTION.GET_WORKERS_COUNT,
    payload: {
      promise: (async () => {
        const response = await api().systemInfo()
        return _get(response, "system_info.mi_workers_count")
      })()
    }
  })
