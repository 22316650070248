import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./Tag.css"

const Tag = ({
  children,
  color,
  clickable = false,
  size = "small",
  className = "",
  ...tagAttrs
}) => (
  <span
    {...tagAttrs}
    className={`${color} tag ${size} ${clickable ? "clickable" : ""} ${className}`}
    data-testid="tag"
  >
    <span data-testid="content">{children}</span>
    <FontAwesomeIcon icon={["far", "times"]} className="close" />
  </span>
)

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  size: PropTypes.string
}

export default Tag
