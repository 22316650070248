import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Record, Map } from "immutable"
import _isNil from "lodash/isNil"
import _includes from "lodash/includes"
import _noop from "lodash/noop"
import _get from "lodash/get"
import { Waypoint } from "react-waypoint"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import StatusElement from "components/UI/elements/StatusElement"
import IconButton from "components/UI/elements/IconButton"
import Duration from "components/UI/elements/Duration"
import ConfirmModal from "components/UI/components/ConfirmModal"
import JobsLineChart from "components/UI/elements/JobsLineChart"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers
import { getRoutePath } from "routes"
import { formatBytes } from "helpers/dataUnits.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBack } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"
import { capitalize } from "helpers/string.helper"

// constants
import { INTERVAL, MODAL, TOAST } from "sharedConstants"

const JOB_LOADING_LIMIT = 20

class JobList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cancelModal: Map({
        open: false,
        jobId: null,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      listEntityJob,
      match: {
        params: { id, wid }
      }
    } = this.props

    if (wid) {
      listEntityJob(id, wid, 0, JOB_LOADING_LIMIT, 0).catch(_noop)
    } else {
      listEntityJob(id, 0, JOB_LOADING_LIMIT, 0).catch(_noop)
    }
    this.intervalId = setInterval(this.refreshLastEntityJob, INTERVAL.DAWG_WS_JOBLIST_REFRESH)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
  }

  refreshLastEntityJob = () => {
    const {
      refreshLastEntityJob,
      isLoading,
      match: {
        params: { id, wid }
      }
    } = this.props
    if (!isLoading) {
      if (wid) {
        refreshLastEntityJob(id, wid, 0).catch(_noop)
      } else {
        refreshLastEntityJob(id, 0).catch(_noop)
      }
    }
  }

  renderDataCell = (bytes, files) => {
    if (_isNil(bytes)) {
      return "–"
    }

    return (
      <span className="data-text">
        {formatBytes(bytes)}{" "}
        <span>
          in {files} {files === 1 ? "file" : "files"}
        </span>
      </span>
    )
  }

  loadMoreJobs = () => {
    const {
      listEntityJob,
      selectionSettings,
      match: {
        params: { id, wid }
      }
    } = this.props

    if (wid) {
      listEntityJob(
        id,
        wid,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        0
      ).catch(_noop)
    } else {
      listEntityJob(
        id,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        0
      ).catch(_noop)
    }
  }

  renderWaypoint = () => {
    const { hasMoreJobs, isLoading } = this.props
    if (hasMoreJobs && !isLoading) {
      return <Waypoint onEnter={this.loadMoreJobs} bottomOffset={-250} />
    }
  }

  confirmCancelModal = async () => {
    if (!this.state.cancelModal.get("isLoading")) {
      const {
        cancelEntityJob,
        showToast,
        match: {
          params: { id, wid }
        }
      } = this.props
      const { cancelModal } = this.state

      this.setState(prevState => ({
        cancelModal: prevState.cancelModal.set("isLoading", true)
      }))

      const entityId = wid ? wid : id
      const cancelPromise = this.pendingPromises.create(
        cancelEntityJob(entityId, cancelModal.get("jobId"))
      )
      try {
        await cancelPromise.promise
        this.setState(prevState => ({
          cancelModal: prevState.cancelModal.set("open", false)
        }))
        showToast("Activity is being cancelled.", TOAST.TYPE.SUCCESS)
      } catch (err) {
        if (!_get(err, "isCanceled")) {
          this.setState(prevState => ({
            cancelModal: prevState.cancelModal.set("isLoading", false)
          }))
        }
      } finally {
        this.pendingPromises.remove(cancelPromise)
      }
    }
  }

  toggleCancelModal = (jobId = null) => evt => {
    evt.preventDefault()
    this.setState(prevState => ({
      cancelModal: prevState.cancelModal
        .set("jobId", jobId)
        .set("open", !prevState.cancelModal.get("open"))
        .set("isLoading", false)
    }))
  }

  render() {
    const {
      jobs,
      usersAcl,
      history,
      entityName,
      match: {
        params: { id, wid }
      }
    } = this.props
    const { cancelModal } = this.state
    const isEditable = hasWritePermission(usersAcl)

    let backPath = entityName === "workspace" && !wid ? "workspace.show" : "dawg.show"
    return (
      <section className="job-list-page">
        {List.isList(jobs) && (
          <React.Fragment>
            <PaperHeader size="small" className="job-list-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(history, getRoutePath(backPath, { id }))}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>{capitalize(entityName)} activity</h3>
              </div>
            </PaperHeader>
            {jobs.size > 2 && (
              <Paper className="jobs-chart">
                <JobsLineChart jobs={jobs} />
              </Paper>
            )}
            <Paper className="job-list-content" hasHeader={jobs.size < 3}>
              {jobs.size > 0 && (
                <React.Fragment>
                  <div className="table table-content-clickable-row">
                    <div className="thead">
                      <div className="table-row">
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                        <div className="status table-head">Status</div>
                        <div className="table-head">Duration</div>
                        <div className="table-head">Modified</div>
                        <div className="table-head">Data in</div>
                        <div className="table-head">Data out</div>
                        <div className="table-head align-right">User</div>
                        <div className="table-head action-column">&nbsp;</div>
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {jobs.map(job => {
                        return (
                          <Link
                            key={job.id}
                            className="table-row"
                            to={{
                              pathname: getRoutePath(
                                entityName === "workspace"
                                  ? "workspace.workspaceJob.show"
                                  : "dawg.dawgJob.show",
                                {
                                  id: wid ? wid : id,
                                  aid: job.id
                                }
                              ),
                              state: {
                                previous: this.props.location.pathname
                              }
                            }}
                          >
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                            <div className="table-cell">
                              <StatusElement
                                align="left"
                                status={job.status}
                                showDuration={false}
                              />
                            </div>
                            <div className="table-cell">
                              <Duration
                                status={job.status}
                                history={job.stats ? job.stats.get("statuses_history") : Map()}
                                created={job.origin_created ? job.origin_created : job.created}
                              />
                            </div>
                            <div className="table-cell">
                              <DateTimeWithTooltip
                                dateTime={job.created}
                                uniqueTooltipId={`entity-job-${job.id}-tooltip`}
                              />
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/in"]),
                                job.getIn(["stats", "files_count", "/in"])
                              )}
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/out"]),
                                job.getIn(["stats", "files_count", "/out"])
                              )}
                            </div>
                            <div className="table-cell align-right">
                              <Username userId={job.user_id} />
                            </div>
                            <div className="table-cell action-column">
                              <IconButton
                                color="red"
                                onClick={this.toggleCancelModal(job.id)}
                                disabled={
                                  !_includes(["waiting", "running"], job.status) || !isEditable
                                }
                              >
                                <FontAwesomeIcon icon={["fas", "times"]} />
                              </IconButton>
                            </div>
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                  {this.renderWaypoint()}
                </React.Fragment>
              )}
              {jobs.size === 0 && (
                <p className="no-jobs-message">
                  The {entityName} doesn't have an activity history yet. Run the {entityName} at
                  least once.
                </p>
              )}
            </Paper>
            <ConfirmModal
              open={cancelModal.get("open")}
              type={MODAL.TYPE.CANCEL}
              handleClose={this.toggleCancelModal()}
              handleConfirm={this.confirmCancelModal}
              title="Cancel activity"
              action="cancel"
              what="activity"
              isLoading={cancelModal.get("isLoading")}
            />
          </React.Fragment>
        )}
      </section>
    )
  }
}

JobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  hasMoreJobs: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  isLoading: PropTypes.bool.isRequired,
  listEntityJob: PropTypes.func.isRequired,
  refreshLastEntityJob: PropTypes.func.isRequired,
  cancelEntityJob: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired
}

export default withRouter(JobList)
