import _toInteger from "lodash/toInteger"
import { createSelector } from "reselect"
import { List } from "immutable"

export const getDawgJobsData = state => {
  if (state.dawgJobs !== null) {
    return state.dawgJobs.get("data")
  }
  return null
}

export const getDawgJobsSelectionSettings = state => {
  if (state.dawgJobs !== null) {
    return state.dawgJobs.get("selectionSettings")
  }
  return null
}

export const haveDawgJobsMoreItems = state => {
  if (state.dawgJobs !== null) {
    return state.dawgJobs.get("hasMoreItems") === true
  }
  return false
}

export const getDawgJobData = (state, jobId) => {
  const data = getDawgJobsData(state)
  if (List.isList(data)) {
    const jobIdInt = _toInteger(jobId)
    return data.find(job => job.id === jobIdInt)
  }
  return null
}

export const isDawgJobsFetching = state => {
  if (state.dawgJobs !== null) {
    return state.dawgJobs.get("isFetching") === true
  }
  return true
}

export const getLastDawgJobsData = createSelector(getDawgJobsData, data => {
  if (List.isList(data)) {
    return data.slice(0, 3)
  }
  return null
})
