import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field } from "redux-form"
import PropTypes from "prop-types"
import { Record } from "immutable"
import _map from "lodash/map"
import _get from "lodash/get"
import _trim from "lodash/trim"

// ui elements
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import SelectField from "components/UI/elements/SelectField"

// actions
import { showToast } from "actions/toast.action"
import { cloneConfiguration } from "actions/configuration.action"

// helpers
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { optionRequired } from "helpers/validators.helper"
import { getRoutePath } from "routes"

import "./CloneConfigurationModal.css"
import { TOAST } from "sharedConstants"

class CloneConfigurationModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      current: false
    }
  }

  onSubmit = values => {
    if (!this.state.loading) {
      const { cloneConfiguration, showToast, configuration, reset } = this.props
      this.setState({ loading: true })
      let destinationWorkspaceId = null
      if (this.state.current) {
        destinationWorkspaceId = configuration.workspace_id
      } else {
        destinationWorkspaceId = _get(values, "workspace_id.value", null)
      }
      if (destinationWorkspaceId) {
        cloneConfiguration(
          configuration.workspace_id,
          configuration.id,
          { workspace_id: destinationWorkspaceId },
          0
        )
          .then(response => {
            this.closeModal()
            showToast(
              "Configuration has been cloned.",
              TOAST.TYPE.SUCCESS,
              getRoutePath("workspace.configuration.show", {
                id: response.value.workspace_id,
                cid: response.value.id
              })
            )
            reset()
          })
          .catch(() => {
            this.setState({ loading: false })
          })
      }
    }
  }

  fetchWorkspacesListForSelect = async (filterText = "") => {
    if (_trim(filterText).length > 0) {
      // fetch all
      const caller = new AllResourceItemsFetcher()

      const data = await caller
        .setEndpointCall((offset, limit, loadFullStructure) =>
          api().workspace.list(offset, limit, 0, "created", "DESC", filterText)
        )
        .setDataPath("workspaces")
        .run()

      return _map(data, item => ({ label: item.name, value: item.id }))
    } else {
      // fetch initial
      const offset = 0,
        limit = 50
      const data = await api().workspace.list(offset, limit, 0, "created", "DESC")

      return _map(_get(data, "workspaces", []), item => ({
        label: item.name,
        value: item.id
      }))
    }
  }

  toggleCurrent = () => {
    this.props.change("workspace_id", null)
    this.setState(prevState => ({
      current: !prevState.current
    }))
  }

  closeModal = () => {
    this.props.handleClose()
    setTimeout(() => {
      this.props.reset()
      this.setState({
        loading: false,
        current: false
      })
    })
  }

  onSelectFieldChange = () => {
    // reset current button when change occurs
    this.setState({
      current: false
    })
  }

  render() {
    const { open, handleSubmit } = this.props
    return (
      <Modal open={open} handleClose={this.closeModal} title="Clone configuration">
        <Form
          className="clone-configuration-modal"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="form-row">
            <Field
              name="workspace_id"
              component={SelectField}
              label="Choose workspace"
              className="workspace-field"
              isSearchable={true}
              async={true}
              loadOptions={this.fetchWorkspacesListForSelect}
              placeholder="Search workspace..."
              validate={!this.state.current ? optionRequired : undefined}
              onChange={this.onSelectFieldChange}
            />
            <div className="current-wrapper">
              <label>Or select</label>
              <Button
                size="big"
                color={this.state.current ? "primary" : "white"}
                onClick={this.toggleCurrent}
                className="current"
              >
                Current
              </Button>
            </div>
          </div>
          <div className="action-buttons">
            <Button
              type="button"
              color="transparent-grey"
              size="big"
              onClick={this.closeModal}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              size="big"
              className={this.state.loading ? "loading" : ""}
            >
              Clone
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }
}

CloneConfigurationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  configuration: PropTypes.instanceOf(Record).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

CloneConfigurationModal = reduxForm({
  form: "CloneConfigurationForm",
  touchOnBlur: false
})(connect(null, { showToast, cloneConfiguration })(CloneConfigurationModal))

export default CloneConfigurationModal
