import { WORKSPACE } from "sharedConstants"
import WorkspaceHistoryModel from "models/workspaceHistory.model"
import SelectionSettingsModel from "models/selectionSettings.model"
import { Map, List, fromJS } from "immutable"

/*
 * State need to be erased when user leaves the workspaces -> visits workspace
 * list page.
 *
 * state example:
 * Map({
 *   data: List([ WorkspaceHistoryModel ]),
 *   hasMoreItems: true/false,
 *   selectionSettings: SelectionSettingsModel(),
 *   isFulfilled: false
 * })
 */

const initialState = Map({
  isFulfilled: false,
  data: List(),
  hasMoreItems: false,
  selectionSettings: SelectionSettingsModel()
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${WORKSPACE.HISTORY.ACTION.LIST}_FULFILLED`: {
      if (payload.selection_settings.offset === 0) {
        // initial load
        return Map({
          isFulfilled: true,
          data: List(
            payload.history_list.map(historyItem => new WorkspaceHistoryModel(fromJS(historyItem)))
          ),
          hasMoreItems: payload.history_list.length === payload.selection_settings.limit,
          selectionSettings: new SelectionSettingsModel(payload.selection_settings)
        })
      } else {
        // concat
        return state
          .set("selectionSettings", new SelectionSettingsModel(payload.selection_settings))
          .set("hasMoreItems", payload.history_list.length === payload.selection_settings.limit)
          .set("isFulfilled", true)
          .set(
            "data",
            state
              .get("data")
              .concat(
                payload.history_list.map(
                  historyItem => new WorkspaceHistoryModel(fromJS(historyItem))
                )
              )
          )
      }
    }
    case `${WORKSPACE.HISTORY.ACTION.RETRIEVE}_FULFILLED`: {
      const historyItemIndex = state
        .get("data")
        .findIndex(historyItem => historyItem.id === payload.history.id)
      if (historyItemIndex !== -1) {
        return state.setIn(
          ["data", historyItemIndex],
          new WorkspaceHistoryModel(fromJS(payload.history))
        )
      } else {
        return state.set(
          "data",
          state.get("data").push(new WorkspaceHistoryModel(fromJS(payload.history)))
        )
      }
    }
    case `${WORKSPACE.HISTORY.ACTION.GET_LAST_TWO}_FULFILLED`: {
      return state
        .set(
          "data",
          state
            .get("data")
            .splice(
              0,
              1,
              new WorkspaceHistoryModel(fromJS(payload.history_list[0])),
              new WorkspaceHistoryModel(fromJS(payload.history_list[1]))
            )
        )
        .setIn(["selectionSettings", "offset"], state.getIn(["selectionSettings", "offset"]) + 1)
    }
    case WORKSPACE.HISTORY.ACTION.CLEAR_CACHE: {
      return initialState
    }
    default:
      return state
  }
}
