export const shortenString = (string, limit, end = "bracket") => {
  if (string.length < limit) {
    return string
  } else {
    const stringStart = string.slice(0, limit - 3)
    if (end === "bracket") {
      return stringStart + "[...]"
    } else {
      return stringStart + "..."
    }
  }
}

export const capitalize = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const copyStringToClipboard = s => {
  const dummy = document.createElement("input")
  document.body.appendChild(dummy)
  dummy.value = s
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}
