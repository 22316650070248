import { Record } from "immutable"

export default Record({
  file_download_token: "",
  file_name: "",
  file_rows_count: null,
  file_size: null,
  first_lines: [],
  id: 0,
  last_modified_date: "",
  parent_configuration_id: null
})
