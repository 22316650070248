import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Map } from "immutable"
import _toString from "lodash/toString"

import { getAllUsersMap } from "selectors/user.selector"

class Username extends PureComponent {
  render() {
    const { users, userId } = this.props
    return <span>{users.getIn([_toString(userId), "name"], "")}</span>
  }
}

Username.propTypes = {
  userId: PropTypes.number.isRequired,
  users: PropTypes.instanceOf(Map).isRequired
}

const mapStateToProps = state => ({
  users: getAllUsersMap(state)
})

export default connect(mapStateToProps)(Username)
