import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _get from "lodash/get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Map, Record } from "immutable"
import { getFormValues } from "redux-form"

// actions
import { createConfiguration } from "actions/configuration.action"
import { showToast } from "actions/toast.action"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import SearchForm from "components/UI/components/SearchForm"
import CreateConfigurationForm from "../CreateConfigurationForm"

// helpers
import { getRoutePath } from "routes"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBack } from "helpers/backButton.helper"
import { getComponentIconSrc } from "helpers/component.helper"

// selectors
import { getComponentsFuseData } from "selectors/component.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

import "./ConfigurationCreate.css"
import { TOAST } from "sharedConstants"

class ConfigurationCreate extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filterType: null,
      // expanded component id
      expanded: null,
      createLoading: false
    }
  }

  onSubmit = data => {
    if (!this.state.createLoading) {
      this.setState({
        createLoading: true
      })
      const { id } = this.props.match.params
      const { history, createConfiguration, showToast } = this.props
      const requestData = {
        ...data,
        component_id: this.state.expanded
      }

      createConfiguration(id, requestData)
        .then(response => {
          showToast(
            "Configuration has been created.",
            TOAST.TYPE.SUCCESS,
            getRoutePath("workspace.configuration.show", {
              id,
              cid: response.value.id
            })
          )
          history.push(getRoutePath("workspace.show", { id }))
        })
        .catch(error => {
          this.setState({
            createLoading: false
          })
        })
    }
  }

  toggleFilterType = type => () => {
    this.setState(prevState => ({
      filterType: prevState.filterType === type ? null : type
    }))
  }

  toggleComponent = component => () => {
    if (!component.in_progress) {
      this.setState(prevState => ({
        expanded: prevState.expanded === component.id ? null : component.id
      }))
    }
  }

  _renderComponent = component => {
    const { usersAcl } = this.props
    const { expanded, createLoading } = this.state
    const isEditable = hasWritePermission(usersAcl)

    return (
      <div
        className={`component-item ${component.in_progress ? "in-progress" : ""}`}
        key={component.id}
      >
        <header className="component-item-header" onClick={this.toggleComponent(component)}>
          <img src={getComponentIconSrc(component.icon)} alt={component.name} loading="lazy" />
          <div className="info">
            <h3>{component.name}</h3>
            <p>{component.type}</p>
          </div>
          {component.in_progress === 1 && (
            <div className="development">
              <FontAwesomeIcon icon={["fas", "traffic-cone"]} /> Under development
            </div>
          )}
        </header>
        {component.id === expanded && (
          <div className="create-configuration-form">
            {component.description.length !== 0 && <p>{component.description}</p>}
            <CreateConfigurationForm
              onSubmit={this.onSubmit}
              loading={createLoading}
              isEditable={isEditable}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { id } = this.props.match.params
    const { filterType } = this.state
    const { components, searchValues, history } = this.props
    const filterTerm = _get(searchValues, "search", "")

    let componentsList = filterTerm
      ? components
          .get("data")
          .search(filterTerm)
          .toArray()
      : components.get("data").list.toArray()

    if (filterType) {
      componentsList = componentsList.filter(
        component => component.type === filterType && component.hidden === 0
      )
    } else {
      componentsList = componentsList.filter(component => component.hidden === 0)
    }

    return (
      <React.Fragment>
        <section className="configuration-create">
          <PaperHeader size="small" className="configuration-create-header">
            <div className="navigation-block">
              <Button
                className="back-link"
                onClick={goBack(history, getRoutePath("workspace.show", { id }))}
                size="small"
                color="none"
              >
                <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
              </Button>
              <h3>Create new configuration</h3>
            </div>
            <SearchForm
              placeholder="Search name"
              className="configuration-search"
              initialValues={{ search: "" }}
            />
          </PaperHeader>

          <div className="filter-picker">
            <Button
              color={filterType === "connector" ? "primary" : "white"}
              type="button"
              size="big"
              onClick={this.toggleFilterType("connector")}
            >
              <FontAwesomeIcon icon={["far", "level-up"]} /> Connector
            </Button>
            <Button
              color={filterType === "processor" ? "primary" : "white"}
              type="button"
              size="big"
              onClick={this.toggleFilterType("processor")}
            >
              <FontAwesomeIcon icon={["far", "filter"]} /> Processor
            </Button>
            <Button
              color={filterType === "loader" ? "primary" : "white"}
              type="button"
              size="big"
              onClick={this.toggleFilterType("loader")}
            >
              <FontAwesomeIcon icon={["far", "stream"]} /> Loader
            </Button>
          </div>

          {components.get("isFulfilled") && (
            <div className="components-container">
              <div className="col col-1">
                {componentsList.map((component, index) => {
                  if (index % 3 === 0) {
                    return this._renderComponent(component)
                  }
                  return null
                })}
              </div>
              <div className="col col-2">
                {componentsList.map((component, index) => {
                  if (index % 3 === 1) {
                    return this._renderComponent(component)
                  }
                  return null
                })}
              </div>
              <div className="col col-3">
                {componentsList.map((component, index) => {
                  if (index % 3 === 2) {
                    return this._renderComponent(component)
                  }
                  return null
                })}
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    )
  }
}

ConfigurationCreate.propTypes = {
  createConfiguration: PropTypes.func.isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  showToast: PropTypes.func.isRequired,
  usersAcl: PropTypes.instanceOf(Record)
}

const mapStateToProps = (state, ownProps) => ({
  components: getComponentsFuseData(state),
  searchValues: getFormValues("SearchForm")(state),
  usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id)
})

export default connect(mapStateToProps, { createConfiguration, showToast })(ConfigurationCreate)
