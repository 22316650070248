import React, { Component } from "react"
import PropTypes from "prop-types"
import _isEmpty from "lodash/isEmpty"
import { Form, reduxForm, Field } from "redux-form"

import SelectField from "components/UI/elements/SelectField"
import OrderField from "./OrderField"

import "./SortingForm.css"

class SortingForm extends Component {
  onSubmit = values => {
    const { onSubmit } = this.props
    if (onSubmit) {
      // order: false => DESC, order: true => ASC
      onSubmit(values)
    }
  }

  render() {
    const { options, handleSubmit, form } = this.props
    return (
      <Form name={form} className="sorting-form" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="sortBy"
          component={SelectField}
          label="Sort by:"
          options={options}
          className="sort"
          isSearchable={false}
        />
        <Field name="order" type="checkbox" component={OrderField} />
      </Form>
    )
  }
}

SortingForm.propTypes = {
  form: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
}

export default reduxForm({
  onChange: (values, dispatch, props, previousValues) => {
    if (!_isEmpty(previousValues)) {
      // it's not initialValues change, so do props.submit
      props.submit()
    }
  },
  destroyOnUnmount: false
})(SortingForm)
