import { api } from "api"
import _get from "lodash/get"
import { DAWG } from "sharedConstants"

export const listDawgHistory = (dawgId, offset, limit, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.HISTORY.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().dawg.history.list(dawgId, offset, limit, loadFullStructure)
      })()
    }
  })

export const revertDawgTo = (
  dawgId,
  historyId,
  loadFullStructure = 0,
  updateRedux = false
) => async dispatch =>
  dispatch({
    type: DAWG.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().dawg.history.revertTo(dawgId, historyId, loadFullStructure)
        if (updateRedux) {
          dispatch({
            type: DAWG.HISTORY.ACTION.GET_LAST_TWO,
            payload: {
              promise: async () => {
                return await api().dawg.history.list(dawgId, 0, 2, loadFullStructure)
              }
            }
          })
        }
        return _get(response, "dawg", {})
      })()
    }
  })

export const retrieveDawgHistoryItem = (
  dawgId,
  historyId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: DAWG.HISTORY.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        return await api().dawg.history.retrieve(dawgId, historyId, loadFullStructure)
      })()
    }
  })

export const clearCachedDawgHistory = () => dispatch =>
  dispatch({
    type: DAWG.HISTORY.ACTION.CLEAR_CACHE,
    payload: {}
  })
