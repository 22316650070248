import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import { Map } from "immutable"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Waypoint } from "react-waypoint"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button"

// helpers
import { shortenFilename } from "helpers/filename.helper"
import { BASE_API_URL } from "api"
import { getRoutePath } from "routes"
import { copyStringToClipboard } from "helpers/string.helper"
import { TOAST } from "sharedConstants"

import "./DataOutCard.css"

class DataOutCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { dataPreviews, loadMoreFiles } = this.props
    const { filesLoading } = this.state
    if (dataPreviews.get("hasMoreItems") && !filesLoading) {
      return (
        <Waypoint
          onEnter={() => {
            this.setState({
              filesLoading: true
            })

            loadMoreFiles(
              dataPreviews.get("selectionSettings").offset +
                dataPreviews.get("selectionSettings").limit
            )
              .then(() => {
                this.setState({
                  filesLoading: false
                })
              })
              .catch(err => {})
          }}
          bottomOffset={-40}
        />
      )
    }
  }

  render() {
    const {
      dataPreviews,
      location,
      showToast,
      match: {
        params: { id, cid }
      }
    } = this.props
    const { filesLoading } = this.state
    if (!dataPreviews) {
      return null
    }

    const files = dataPreviews.get("data")
    const filesTotalCount = dataPreviews.get("filesTotalCount")
    return (
      <React.Fragment>
        <PaperHeader size="small" className="data-out-card-header">
          <h3>Data out</h3>
          {filesTotalCount !== undefined && (
            <h3>{`${filesTotalCount} ${filesTotalCount === 1 ? "file" : "files"} total`}</h3>
          )}
        </PaperHeader>
        {filesTotalCount > 0 && (
          <Paper hasHeader={true} className="data-out-card-content">
            <div className="files">
              <div className={`files-content ${filesTotalCount > 4 ? "scrollable" : ""}`}>
                {files
                  .map((file, idx) => (
                    <div className="row" key={idx}>
                      <Link
                        to={{
                          pathname: getRoutePath("workspace.configuration.data.preview", {
                            id,
                            cid,
                            type: "out",
                            did: file.id
                          }),
                          state: {
                            file,
                            previous: location.pathname
                          }
                        }}
                      >
                        <div
                          className="out-file-item"
                          data-tip={file.file_name}
                          data-for="out-file-tooltip"
                        >
                          {shortenFilename(file.file_name, 45)}
                          <span
                            className="copy-button"
                            onClick={evt => {
                              evt.preventDefault()
                              copyStringToClipboard(file.file_name)
                              showToast(
                                "Filename has been copied to clipboard.",
                                TOAST.TYPE.SUCCESS
                              )
                            }}
                          >
                            <FontAwesomeIcon icon={["fas", "copy"]} />
                          </span>
                        </div>
                      </Link>
                      <IconButton
                        color="grey"
                        type="anchor"
                        href={`${BASE_API_URL}/configuration_file?file_download_token=${file.file_download_token}`}
                        download
                        target="_blank"
                        className="download-button"
                      >
                        <FontAwesomeIcon icon={["far", "download"]} />
                      </IconButton>
                    </div>
                  ))
                  .toArray()}
                {filesLoading && <div className="row loading-row" />}
                {this.renderWaypoint()}
              </div>
            </div>
            <div className="download-all">
              <Link
                to={getRoutePath("workspace.configuration.data.preview.list", {
                  id,
                  cid,
                  type: "out"
                })}
                className="button transparent-grey small shrinked"
              >
                <FontAwesomeIcon className="icon" icon={["fas", "search"]} /> View all files
              </Link>
              <Button
                color="transparent-grey"
                size="small"
                href={`${BASE_API_URL}/configuration_output_files?download_token=${dataPreviews.get(
                  "downloadToken"
                )}`}
                download
                target="_blank"
              >
                <FontAwesomeIcon className="icon" icon={["far", "download"]} /> Download all files
              </Button>
            </div>
            <ReactTooltip place="bottom" id="out-file-tooltip" getContent={dataTip => dataTip} />
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

DataOutCard.propTypes = {
  dataPreviews: PropTypes.instanceOf(Map),
  loadMoreFiles: PropTypes.func.isRequired
}

export default withRouter(DataOutCard)
