import _get from "lodash/get"
import { api } from "api"
import { CONFIGURATION, WORKSPACE } from "sharedConstants"

export const runConfigurationJob = (
  workspaceId,
  configurationId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.JOB.ACTION.RUN,
    payload: {
      promise: (async () => ({
        job: _get(
          await api().configuration.job.run(workspaceId, configurationId, loadFullStructure),
          "configuration_job",
          null
        )
      }))()
    },
    meta: {
      configurationId
    }
  })

export const listConfigurationJob = (
  workspaceId,
  configurationId,
  offset,
  limit,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.JOB.ACTION.LIST,
    payload: {
      promise: (async () => {
        const response = await api().configuration.job.list(
          workspaceId,
          configurationId,
          offset,
          limit,
          loadFullStructure
        )
        return {
          jobs: response
        }
      })()
    },
    meta: {
      configurationId
    }
  })

export const refreshLastConfigurationJob = (
  workspaceId,
  configurationId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.JOB.ACTION.REFRESH_LAST,
    payload: {
      job: _get(
        await api().configuration.job.list(workspaceId, configurationId, 0, 1, loadFullStructure),
        "configuration_jobs[0]",
        null
      )
    },
    meta: {
      configurationId
    }
  })

export const clearCachedConfigurationJobs = () => dispatch =>
  dispatch({
    type: CONFIGURATION.JOB.ACTION.CLEAR_CACHE,
    payload: {}
  })

export const cancelConfigurationJob = (
  workspaceId,
  configurationId,
  jobId,
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: CONFIGURATION.JOB.ACTION.CANCEL,
    payload: {
      promise: (async () => ({
        job: _get(
          await api().configuration.job.cancel(
            workspaceId,
            configurationId,
            jobId,
            loadFullStructure
          ),
          "configuration_job",
          null
        )
      }))()
    },
    meta: {
      configurationId
    }
  })

export const retrieveConfigurationJob = (
  workspaceId,
  configurationId,
  jobId,
  loadFullStructure = 0,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: CONFIGURATION.JOB.ACTION.RETRIEVE,
      payload: {
        promise: (async () => ({
          job: _get(
            await api().configuration.job.retrieve(
              workspaceId,
              configurationId,
              jobId,
              loadFullStructure
            ),
            "configuration_job",
            null
          )
        }))()
      },
      meta: {
        configurationId
      }
    })
  } else {
    return dispatch({
      type: CONFIGURATION.JOB.ACTION.RETRIEVE,
      payload: {
        job: _get(
          await api().configuration.job.retrieve(
            workspaceId,
            configurationId,
            jobId,
            loadFullStructure
          ),
          "configuration_job",
          null
        )
      },
      meta: {
        configurationId
      }
    })
  }
}

// without promise => doesn't display top loading bar (refreshing every few seconds)
export const listProjectConfigurationJob = (
  offset,
  limit,
  loadFullStructure,
  orderBy,
  orderDir,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: CONFIGURATION.JOB.ACTION.LIST_PROJECT,
      payload: {
        promise: (async () => {
          return await api().project.configuration.job.list(
            offset,
            limit,
            loadFullStructure,
            orderBy,
            orderDir,
            0
          )
        })()
      }
    })
  } else {
    return dispatch({
      type: CONFIGURATION.JOB.ACTION.LIST_PROJECT,
      payload: await api().project.configuration.job.list(
        offset,
        limit,
        loadFullStructure,
        orderBy,
        orderDir,
        0
      )
    })
  }
}

export const listWorkspaceConfigurationJob = (
  workspaceId,
  offset,
  limit,
  loadFullStructure,
  orderBy,
  orderDir,
  promise = true
) => async dispatch => {
  if (promise) {
    return dispatch({
      type: WORKSPACE.CONFIGURATION.JOB.ACTION.LIST,
      meta: {
        workspaceId
      },
      payload: {
        promise: (async () => {
          return await api().workspace.configurationJob.list(
            workspaceId,
            offset,
            limit,
            loadFullStructure,
            orderBy,
            orderDir,
            0
          )
        })()
      }
    })
  } else {
    return dispatch({
      type: WORKSPACE.CONFIGURATION.JOB.ACTION.LIST,
      meta: {
        workspaceId
      },
      payload: await api().workspace.configurationJob.list(
        workspaceId,
        offset,
        limit,
        loadFullStructure,
        orderBy,
        orderDir,
        0
      )
    })
  }
}
