import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Record } from "immutable"

// selectors
import { getDawgHistoryItem } from "selectors/dawgHistory.selector"
import { getUsersDawgAcl } from "selectors/usersAcl.selector"

// actions
import { retrieveDawgHistoryItem, revertDawgTo } from "actions/dawgHistory.action"
import { showToast } from "actions/toast.action"

import EntityHistoryDetail from "components/UI/DawgWsShared/EntityHistoryDetail"
import { getDawgChangedKeys } from "helpers/objectChanges.helper.js"

class DawgHistoryDetail extends PureComponent {
  render() {
    const { historyItem, usersAcl, retrieveDawgHistoryItem, revertDawgTo, showToast } = this.props

    return (
      <EntityHistoryDetail
        usersAcl={usersAcl}
        historyItem={historyItem}
        revertEntityTo={revertDawgTo}
        retrieveEntityHistoryItem={retrieveDawgHistoryItem}
        showToast={showToast}
        getEntityChangedKeys={getDawgChangedKeys}
        entityName="dawg"
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  historyItem: getDawgHistoryItem(state, ownProps.match.params.hid),
  usersAcl: getUsersDawgAcl(state, ownProps.match.params.id)
})

DawgHistoryDetail.propTypes = {
  usersAcl: PropTypes.instanceOf(Record),
  historyItem: PropTypes.instanceOf(Record),
  revertDawgTo: PropTypes.func.isRequired,
  retrieveDawgHistoryItem: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  revertDawgTo,
  retrieveDawgHistoryItem,
  showToast
})(DawgHistoryDetail)
