import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import { listDawgJob, refreshLastDawgJob, cancelDawgJob } from "actions/dawgJob.action"
import { showToast } from "actions/toast.action"

// selectors
import {
  getDawgJobsData,
  getDawgJobsSelectionSettings,
  haveDawgJobsMoreItems,
  isDawgJobsFetching
} from "selectors/dawgJob.selector"
import { getUsersDawgAcl } from "selectors/usersAcl.selector"

import JobList from "components/UI/DawgWsShared/JobList"

class DawgJobList extends PureComponent {
  render() {
    const {
      jobs,
      usersAcl,
      listDawgJob,
      refreshLastDawgJob,
      cancelDawgJob,
      selectionSettings,
      hasMoreJobs,
      isLoading,
      showToast
    } = this.props

    return (
      <JobList
        jobs={jobs}
        selectionSettings={selectionSettings}
        hasMoreJobs={hasMoreJobs}
        usersAcl={usersAcl}
        isLoading={isLoading}
        listEntityJob={listDawgJob}
        refreshLastEntityJob={refreshLastDawgJob}
        cancelEntityJob={cancelDawgJob}
        showToast={showToast}
        entityName="dawg"
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    jobs: getDawgJobsData(state),
    selectionSettings: getDawgJobsSelectionSettings(state),
    hasMoreJobs: haveDawgJobsMoreItems(state),
    isLoading: isDawgJobsFetching(state),
    usersAcl: getUsersDawgAcl(state, ownProps.match.params.id)
  }
}

DawgJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  hasMoreJobs: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  isLoading: PropTypes.bool.isRequired,
  listDawgJob: PropTypes.func.isRequired,
  refreshLastDawgJob: PropTypes.func.isRequired,
  cancelDawgJob: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  listDawgJob,
  refreshLastDawgJob,
  cancelDawgJob,
  showToast
})(DawgJobList)
