import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { withRouter } from "react-router"

import { getRoutePath } from "routes"
import AdminProtectedRoute from "./AdminProtectedRoute"

// pages
import ConfigurationShowPage from "components/Configuration/pages/ConfigurationShow"
import ConfigurationCreatePage from "components/Configuration/pages/ConfigurationCreate"
import SearchPage from "components/Search/pages/Search"

import WorkspaceListPage from "components/Workspace/pages/WorkspaceList"
import WorkspaceShowPage from "components/Workspace/pages/WorkspaceShow"
import WorkspaceJobListPage from "components/WorkspaceJob/WorkspaceJobList"
import WorkspaceJobShowPage from "components/WorkspaceJob/WorkspaceJobShow"
import WorkspaceJobHistoryPage from "components/WorkspaceJobHistory"
import WorkspaceHistoryListPage from "components/WorkspaceHistory/WorkspaceHistoryList"
import WorkspaceHistoryDetailPage from "components/WorkspaceHistory/WorkspaceHistoryDetail"

import DawgListPage from "components/Dawg/DawgList"
import DawgDetailPage from "components/Dawg/DawgDetail"
import DawgHistoryListPage from "components/DawgHistory/DawgHistoryList"
import DawgHistoryDetailPage from "components/DawgHistory/DawgHistoryDetail"
import DawgJobListPage from "components/DawgJob/DawgJobList"
import DawgJobDetailPage from "components/DawgJob/DawgJobShow"
import DawgWorkspaceAddPage from "components/Dawg/DawgWorkspaceAdd"
import DawgJobHistoryDetail from "components/DawgJobHistory"
import DawgWorkspaceJobList from "components/DawgWorkspaceJob/DawgWorkspaceJobList"

import UserListPage from "components/User/pages/UserList"
import TagListPage from "components/Tag/pages/TagList"
import OAuthListPage from "components/Oauth/pages/OAuthList"
import TrashList from "components/Trash/pages/TrashList"
import DataPreviewDetailPage from "components/DataPreview/DataPreviewDetail"
import DataPreviewListPage from "components/DataPreview/DataPreviewList"
import ConfigurationJobListPage from "components/ConfigurationJob/ConfigurationJobList"
import ConfigurationJobShowPage from "components/ConfigurationJob/ConfigurationJobShow"
import ConfigurationJobShowHistoryPage from "components/ConfigurationJobHistory"
import ConfigurationHistoryListPage from "components/ConfigurationHistory/ConfigurationHistoryList"
import ConfigurationHistoryDetailPage from "components/ConfigurationHistory/ConfigurationHistoryDetail"
import UserSettings from "components/DropDown/pages/UserSettings"
import NotAuthorizedPage from "components/Page/NotAuthorizedPage"
import NotFoundPage from "components/Page/NotFoundPage"
import ProjectConfigurationJobListPage from "components/Project/ConfigurationJob"
import ProjectWorkspaceJobListPage from "components/Project/WorkspaceJob"
import WorkspaceConfigurationJobListPage from "components/WorkspaceConfigurationJob/WorkspaceConfigurationJobList"
import UserPermissionsListPage from "components/UserPermissions/pages/UserPermissionsList"

class AuthenticatedRoutesSwitch extends Component {
  redirectToWorkspaces = () => {
    return <Redirect to={getRoutePath("workspace.list")} />
  }

  redirectToUsersAdministration = () => {
    return <Redirect to={getRoutePath("administration.users")} />
  }

  render() {
    return (
      <Switch>
        <Route exact path={getRoutePath("dashboard")} render={this.redirectToWorkspaces} />

        <Route path={getRoutePath("dawg.dawgJob.show.history")} component={DawgJobHistoryDetail} />
        <Route path={getRoutePath("dawg.dawgJob.show")} component={DawgJobDetailPage} />
        <Route path={getRoutePath("dawg.dawgJob.list")} component={DawgJobListPage} />
        <Route path={getRoutePath("dawg.dawgHistory.show")} component={DawgHistoryDetailPage} />
        <Route path={getRoutePath("dawg.dawgHistory.list")} component={DawgHistoryListPage} />
        <Route path={getRoutePath("dawg.workspaceJob.list")} component={DawgWorkspaceJobList} />
        <Route path={getRoutePath("dawg.workspace.add")} component={DawgWorkspaceAddPage} />
        <Route path={getRoutePath("dawg.show")} component={DawgDetailPage} />
        <Route path={getRoutePath("dawg.list")} component={DawgListPage} />

        <Route
          path={getRoutePath("workspace.configuration.data.preview")}
          component={DataPreviewDetailPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.data.preview.list")}
          component={DataPreviewListPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.configurationJob.show.history")}
          component={ConfigurationJobShowHistoryPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.configurationJob.show")}
          component={ConfigurationJobShowPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.configurationJob.list")}
          component={ConfigurationJobListPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.configurationHistory.show")}
          component={ConfigurationHistoryDetailPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.configurationHistory.list")}
          component={ConfigurationHistoryListPage}
        />
        <Route
          path={getRoutePath("workspace.workspaceJob.show.history")}
          component={WorkspaceJobHistoryPage}
        />
        <Route
          path={getRoutePath("workspace.workspaceJob.show")}
          component={WorkspaceJobShowPage}
        />
        <Route
          path={getRoutePath("workspace.workspaceJob.list")}
          component={WorkspaceJobListPage}
        />
        <Route
          path={getRoutePath("workspace.workspaceHistory.show")}
          component={WorkspaceHistoryDetailPage}
        />
        <Route
          path={getRoutePath("workspace.workspaceHistory.list")}
          component={WorkspaceHistoryListPage}
        />
        <Route
          path={getRoutePath("workspace.configurationJob.list")}
          component={WorkspaceConfigurationJobListPage}
        />
        <Route
          path={getRoutePath("workspace.configuration.create")}
          component={ConfigurationCreatePage}
        />
        <Route
          path={getRoutePath("workspace.configuration.show")}
          component={ConfigurationShowPage}
        />
        <Route path={getRoutePath("workspace.show")} component={WorkspaceShowPage} />
        <Route path={getRoutePath("workspace.list")} component={WorkspaceListPage} />
        <Route path={getRoutePath("search")} component={SearchPage} />
        <Route
          path={getRoutePath("project.workspaceJob")}
          component={ProjectWorkspaceJobListPage}
        />
        <Route
          path={getRoutePath("project.configurationJob")}
          component={ProjectConfigurationJobListPage}
        />
        <Route path={getRoutePath("not-authorized")} component={NotAuthorizedPage} />
        <Route path={getRoutePath("not-found")} component={NotFoundPage} />
        <Route
          exact
          path={getRoutePath("administration")}
          render={this.redirectToUsersAdministration}
        />
        <AdminProtectedRoute path={getRoutePath("administration.users")} component={UserListPage} />
        <AdminProtectedRoute
          path={getRoutePath("administration.user-permissions")}
          component={UserPermissionsListPage}
        />
        <AdminProtectedRoute path={getRoutePath("administration.tags")} component={TagListPage} />
        <AdminProtectedRoute
          path={getRoutePath("administration.oauth")}
          component={OAuthListPage}
        />

        <Route path={getRoutePath("trash.list")} component={TrashList} />

        <Route path={getRoutePath("user-settings")} component={UserSettings} />

        <Route component={NotFoundPage} />
      </Switch>
    )
  }
}

export default withRouter(AuthenticatedRoutesSwitch)
