import { Record } from "immutable"

const User = Record({
  id: 0,
  name: "",
  email: "",
  role: "",
  created: "",
  disabled: 0,
  deleted: 0,
  can_create_workspaces: 0,
  can_create_dawgs: 0,
  last_login: ""
})

export default User
