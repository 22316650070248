import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import _noop from "lodash/noop"

// components
import HeaderContainer from "./components/HeaderContainer"
import FooterContainer from "./components/FooterContainer"
import AuthenticatedRoutesSwitch from "./AuthenticatedRoutesSwitch"

// actions
import { fetchComponentList } from "actions/component.action"
import { fetchUsersAcl, fetchDawgUsersAcl } from "actions/acl.action"
import { verifyUser } from "actions/authenticatedUser.action"
import { fetchTagsList } from "actions/tag.action"
import { fetchUserList, fetchTrashedUserList } from "actions/user.action"
import { refreshToken } from "actions/authenticatedUser.action"

import "./AuthenticatedLayout.css"

// 5 hours
const REFRESH_TOKEN_INTERVAL = 18000000

class AuthenticatedLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aclsFetched: false,
      dawgAclsFetched: false
    }
  }

  fetchAppData = () => {
    const {
      authenticatedUser,
      fetchUsersAcl,
      fetchDawgUsersAcl,
      fetchComponentList,
      fetchUserList,
      fetchTagsList,
      fetchTrashedUserList
    } = this.props

    fetchUsersAcl(authenticatedUser.data.id)
      .then(() => {
        this.setState({
          aclsFetched: true
        })
      })
      .catch(_noop)
    fetchDawgUsersAcl(authenticatedUser.data.id)
      .then(() => {
        this.setState({
          dawgAclsFetched: true
        })
      })
      .catch(_noop)

    fetchComponentList().catch(_noop)
    fetchUserList().catch(_noop)
    fetchTagsList().catch(_noop)
    fetchTrashedUserList().catch(_noop)
  }

  refreshTokenAndData = () => {
    this.props
      .refreshToken()
      .then(this.fetchAppData)
      .catch(_noop)
  }

  componentDidMount() {
    const { authenticatedUser, verifyUser } = this.props

    verifyUser(authenticatedUser.token)
      .then(() => {
        const timestamp = Date.now()
        const diff = timestamp - authenticatedUser.loginTimestamp
        if (diff < REFRESH_TOKEN_INTERVAL) {
          this.fetchAppData()
        } else {
          this.refreshTokenAndData()
        }
        // check token every hour
        this.tokenRefreshInterval = setInterval(this.refreshTokenAndData, 3600000)
      })
      .catch(_noop)
  }

  componentWillUnmount() {
    clearInterval(this.tokenRefreshInterval)
  }

  render() {
    const { authenticatedUser } = this.props
    const { aclsFetched, dawgAclsFetched } = this.state

    return (
      <div className="authenticated-layout">
        {authenticatedUser.isFetching === false &&
          aclsFetched &&
          dawgAclsFetched &&
          authenticatedUser.data !== null && (
            <React.Fragment>
              <div className="content-wrap">
                <HeaderContainer />
                <div className="wrapper">
                  <AuthenticatedRoutesSwitch />
                </div>
              </div>
              <FooterContainer />
            </React.Fragment>
          )}
      </div>
    )
  }
}

AuthenticatedLayout.propTypes = {
  fetchComponentList: PropTypes.func.isRequired,
  fetchUsersAcl: PropTypes.func.isRequired,
  fetchDawgUsersAcl: PropTypes.func.isRequired,
  fetchUserList: PropTypes.func.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
  fetchTrashedUserList: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    authenticatedUser: state.authenticatedUser
  }
}

export default connect(mapStateToProps, {
  fetchComponentList,
  fetchUsersAcl,
  fetchDawgUsersAcl,
  verifyUser,
  fetchUserList,
  fetchTagsList,
  fetchTrashedUserList,
  refreshToken
})(AuthenticatedLayout)
