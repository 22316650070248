import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import _noop from "lodash/noop"
import { withRouter } from "react-router-dom"

// actions
import { showToast } from "actions/toast.action"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import TextField from "components/UI/elements/TextField"

// helpers, constants
import { required } from "helpers/validators.helper"
import { TOAST } from "sharedConstants"
import { getRoutePath } from "routes"

import "./CreateActionModal.css"

class CreateActionModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = values => {
    if (!this.state.isLoading) {
      const {
        createAction,
        showToast,
        fetchUsersAcl,
        history,
        entityName,
        authenticatedUser: {
          data: { id }
        }
      } = this.props
      this.setState({ loading: true })
      createAction({ ...values })
        .then(response => {
          fetchUsersAcl(id)
            .then(() => {
              history.push({
                pathname: getRoutePath(
                  entityName === "Workspace" ? "workspace.show" : "dawg.show",
                  { id: response.value.id }
                ),
                state: {
                  previous: true
                }
              })
              showToast(`${entityName} has been created.`, TOAST.TYPE.SUCCESS)
            })
            .catch(_noop)
        })
        .catch(() => {
          this.setState({
            loading: false
          })
        })
    }
  }

  render() {
    const { open, handleClose, handleSubmit, entityName } = this.props

    return (
      <Modal open={open} handleClose={handleClose} title={`Create new ${entityName.toLowerCase()}`}>
        <form
          className="create-action-modal"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="form-row">
            <Field
              name="name"
              component={TextField}
              label="Name"
              placeholder="Name"
              validate={required}
              fullWidth={true}
              autoFocus
            />
          </div>
          <Button
            type="submit"
            color="primary"
            size="big"
            fullWidth={true}
            className={this.state.loading ? "loading" : ""}
          >
            {`Create ${entityName.toLowerCase()}`}
          </Button>
        </form>
      </Modal>
    )
  }
}

CreateActionModal.propTypes = {
  createAction: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  fetchUsersAcl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

const mapStateToProps = ({ authenticatedUser }) => ({ authenticatedUser })

CreateActionModal = reduxForm({
  form: "CreateWsDawgActionForm",
  touchOnBlur: false
})(connect(mapStateToProps, { showToast })(CreateActionModal))

export default withRouter(CreateActionModal)
