/*
 * Shortens filename based on limit value
 *
 * @param   {string}    filename    filename to be shorten
 * @param   {int}       limit       maximum size of filename, otherwise it will be shortened
 *
 * @return  {string}    Shortened filename
 */
export const shortenFilename = (filename, limit) => {
  if (filename.length <= limit) {
    return filename
  } else {
    const filenameEnd = filename.slice(-(limit - 7))
    const filenameStart = filename.slice(0, 3)
    return filenameStart + "..." + filenameEnd
  }
}

export const isCsv = filename => filename.slice(-4) === ".csv"
