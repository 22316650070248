import "react-app-polyfill/ie11"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"

import "index.css"
import App from "components/App"
import store, { persistor } from "store"
import { unregister } from "registerServiceWorker"

// gantt chart css
import "5minute-frappe-gantt/dist/frappe-gantt.css"

// import brace modes
import "brace/mode/python"
import "brace/mode/sql"
import "brace/mode/r"
import "brace/mode/sh"
// import brace searchbox extension
import "brace/ext/searchbox"
// import code editor custom theme
import "components/UI/elements/CodeEditorTheme/meiro"

const updateSW = registration => () => {
  if (registration && registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" })
  }
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App onLoadNewServiceWorkerAccept={updateSW} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)

unregister()
