import _get from "lodash/get"
import { api } from "api"
import { WORKSPACE, TAG } from "sharedConstants"

export const fetchWorkspaceList = (
  offset,
  limit,
  loadFullStructure,
  orderBy,
  orderDir,
  nameFilter = "",
  tagIds = [],
  showEnabled = 1,
  showDisabled = 1
) => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().workspace.list(
          offset,
          limit,
          loadFullStructure,
          orderBy,
          orderDir,
          nameFilter,
          tagIds,
          showEnabled,
          showDisabled
        )
      })()
    }
  })

export const fetchLastWorkspaceJobs = (workspaceIds, loadFullStructure) => async dispatch => {
  const response = await api().workspace.listLastJobs(workspaceIds, loadFullStructure)
  return dispatch({
    type: WORKSPACE.ACTION.LIST_LAST_JOBS,
    payload: _get(response, "last_workspace_jobs", [])
  })
}

export const createWorkspace = data => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api().workspace.create(data)
        return _get(response, "workspace", {})
      })()
    }
  })

export const modifyWorkspace = (id, data, originData = {}) => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().workspace.modifyWorkspace(id, data, originData)
        return _get(response, "workspace", {})
      })()
    }
  })

export const deleteWorkspace = id => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api().workspace.delete(id)
        return id
      })()
    }
  })

export const retrieveWorkspace = id => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        const response = await api().workspace.retrieve(id)
        return _get(response, "workspace", {})
      })()
    }
  })

export const cloneWorkspace = id => async dispatch =>
  dispatch({
    type: WORKSPACE.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api().workspace.clone(id)
        return _get(response, "workspace", {})
      })()
    }
  })

export const assignTagToWorkspace = (workspaceId, data) => async dispatch =>
  dispatch({
    type: TAG.ACTION.ASSIGN_TAG_TO_WORKSPACE,
    payload: {
      promise: (async () => {
        const response = await api().workspace.tag.assign(workspaceId, data)
        return _get(response, "workspace_tag", {})
      })()
    }
  })

export const unassignTagFromWorkspace = (workspaceId, tagId) => async dispatch =>
  dispatch({
    type: TAG.ACTION.DELETE_TAG_FROM_WORKSPACE,
    payload: {
      promise: (async () => {
        await api().workspace.tag.delete(workspaceId, tagId)
        return {
          workspace_id: workspaceId,
          tag_id: tagId
        }
      })()
    }
  })
