import _toInteger from "lodash/toInteger"

export const getDawgHistoryList = state => state.dawgHistory.get("data")

export const getDawgHistorySelectionSettings = state => state.dawgHistory.get("selectionSettings")

export const hasDawgHistoryMoreItems = state => state.dawgHistory.get("hasMoreItems")

export const getDawgHistoryItem = (state, historyItemId) => {
  if (historyItemId === "0") {
    return state.dawgHistory.get("data").find(historyItem => historyItem.id === null)
  } else {
    return state.dawgHistory
      .get("data")
      .find(historyItem => historyItem.id === _toInteger(historyItemId))
  }
}

export const isDawgHistoryFulfilled = state => state.dawgHistory.get("isFulfilled")
