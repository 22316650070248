import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Record } from "immutable"
import { NavLink } from "react-router-dom"
import _noop from "lodash/noop"
import ReactTooltip from "react-tooltip"

// actions
import { retrieveWorkspace } from "actions/workspace.action"
import { retrieveConfiguration } from "actions/configuration.action"

// helpers
import { shortenString } from "helpers/string.helper"
import { getRoutePath } from "routes"

// selectors
import { getWorkspaceRecord } from "selectors/workspace.selector"
import { getWorkspaceConfigurationData } from "selectors/configuration.selector"

class WorkspaceConfigurationConfigurationJobHistorySubmenu extends PureComponent {
  componentDidMount() {
    const {
      workspace,
      configuration,
      retrieveWorkspace,
      retrieveConfiguration,
      match: {
        params: { id, cid }
      }
    } = this.props

    if (!workspace) {
      retrieveWorkspace(id).catch(_noop)
    }

    if (!configuration) {
      retrieveConfiguration(id, cid).catch(_noop)
    }
  }

  render() {
    const {
      match: {
        params: { aid }
      }
    } = this.props
    const { workspace, configuration } = this.props

    if (!workspace || !configuration) {
      return null
    }

    const hasWorkspaceTooltip = workspace.name.length >= 25
    const workspaceTooltipProps = hasWorkspaceTooltip
      ? { "data-tip": workspace.name, "data-for": "navigation-tooltip" }
      : {}

    const hasConfigurationTooltip = configuration.name.length >= 25
    const configurationTooltipProps = hasConfigurationTooltip
      ? { "data-tip": configuration.name, "data-for": "navigation-tooltip" }
      : {}

    return (
      <React.Fragment>
        <nav className="secondary-nav">
          <NavLink to={getRoutePath("workspace.list")}>Workspaces</NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink
            className="has-ws-icon"
            to={getRoutePath("workspace.show", { id: workspace.id })}
            {...workspaceTooltipProps}
          >
            <FontAwesomeIcon className="prefix-icon" icon={["fas", "code-branch"]} />
            {shortenString(workspace.name, 25)}
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink
            to={getRoutePath("workspace.configuration.show", {
              id: workspace.id,
              cid: configuration.id
            })}
            className="has-conf-icon"
            {...configurationTooltipProps}
          >
            <FontAwesomeIcon className="prefix-icon" icon={["far", "cogs"]} />
            {shortenString(configuration.name, 25)}
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink
            to={getRoutePath("workspace.configuration.configurationJob.list", {
              id: workspace.id,
              cid: configuration.id
            })}
          >
            Activity
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <NavLink
            to={getRoutePath("workspace.configuration.configurationJob.show", {
              id: workspace.id,
              cid: configuration.id,
              aid
            })}
          >
            Activity Detail
          </NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <span className="link-like is-active">History</span>
        </nav>
        <ReactTooltip id="navigation-tooltip" getContent={dataTip => dataTip} place="bottom" />
      </React.Fragment>
    )
  }
}

WorkspaceConfigurationConfigurationJobHistorySubmenu.propTypes = {
  workspace: PropTypes.instanceOf(Record),
  configuration: PropTypes.instanceOf(Record)
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id, cid }
    }
  } = ownProps
  return {
    workspace: getWorkspaceRecord(state, id),
    configuration: getWorkspaceConfigurationData(state, id, cid)
  }
}

export default connect(mapStateToProps, { retrieveWorkspace, retrieveConfiguration })(
  WorkspaceConfigurationConfigurationJobHistorySubmenu
)
