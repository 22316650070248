import { List } from "immutable"

export const getDashboardConfJobs = state => {
  const data = state.projectConfigurationJobs.get("data")
  if (List.isList(data)) {
    return data.take(10)
  }
  return null
}

export const getProjectConfJobs = state => state.projectConfigurationJobs.get("data")

export const getProjectConfJobsSelectionSettings = state =>
  state.projectConfigurationJobs.get("selectionSettings")
