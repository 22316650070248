import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import { listProjectWorkspaceJob } from "actions/workspaceJob.action"

import {
  getProjectWsJobs,
  getProjectWsJobsSelectionSettings
} from "selectors/projectWorkspaceJob.selector"

import PageComponent from "./PageComponent"

class ProjectWorkspaceJobList extends PureComponent {
  render() {
    const { jobs, selectionSettings, listProjectWorkspaceJob } = this.props

    return (
      <PageComponent
        jobs={jobs}
        selectionSettings={selectionSettings}
        entityName="workspace"
        listProjectJob={listProjectWorkspaceJob}
      />
    )
  }
}

const mapStateToProps = state => ({
  jobs: getProjectWsJobs(state),
  selectionSettings: getProjectWsJobsSelectionSettings(state)
})

ProjectWorkspaceJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  listProjectWorkspaceJob: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  listProjectWorkspaceJob
})(ProjectWorkspaceJobList)
