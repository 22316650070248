import { WORKSPACE } from "sharedConstants"

import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import { ConfigurationJobFull } from "models/configurationJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map()

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case WORKSPACE.CONFIGURATION.JOB.ACTION.LIST:
    case `${WORKSPACE.CONFIGURATION.JOB.ACTION.LIST}_FULFILLED`:
      return Map({
        [meta.workspaceId]: Map({
          data: List(
            _map(payload.configuration_jobs, confJob => new ConfigurationJobFull(fromJS(confJob)))
          ),
          selectionSettings: new SelectionSettingModel(payload.selection_settings)
        })
      })
    default:
      return state
  }
}
