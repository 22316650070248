import React, { Component } from "react"
import PropTypes from "prop-types"
import _isEmpty from "lodash/isEmpty"
import { Form, reduxForm, Field } from "redux-form"

import SelectField from "components/UI/elements/SelectField"

import "./FilterForm.css"

class FilterForm extends Component {
  onSubmit = values => {
    const { onSubmit } = this.props
    if (onSubmit) {
      onSubmit(values)
    }
  }

  render() {
    const { options, handleSubmit, form } = this.props
    return (
      <Form name={form} className="dawg-ws-filter-form" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="filterBy"
          component={SelectField}
          label="Show:"
          options={options}
          className="filter"
          isSearchable={false}
        />
      </Form>
    )
  }
}

FilterForm.propTypes = {
  options: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  form: PropTypes.string.isRequired
}

export default reduxForm({
  onChange: (values, dispatch, props, previousValues) => {
    if (!_isEmpty(previousValues)) {
      // it's not initialValues change, so do props.submit
      props.submit()
    }
  },
  destroyOnUnmount: false
})(FilterForm)
