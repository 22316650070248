import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import IconButton from "../IconButton"

const SearchField = ({
  input,
  placeholder,
  handleSubmit,
  handleClear,
  handleChange,
  autoFocus = false,
  className
}) => {
  const onInputChange = input => evt => {
    input.onChange(evt.target.value)
    handleChange?.()
  }

  return (
    <div className={`text-field search-field ${className ?? ""}`} data-testid="wrapper">
      <input
        {...input}
        onChange={onInputChange(input)}
        placeholder={placeholder}
        autoFocus={autoFocus}
        data-testid="input-field"
      />
      {input.value && (
        <IconButton
          type="button"
          color="black"
          className="search-button"
          onClick={handleClear}
          data-testid="clear-button"
        >
          <FontAwesomeIcon icon={["far", "times"]} />
        </IconButton>
      )}
      {!input.value && (
        <IconButton
          color="black"
          className="search-button"
          onClick={handleSubmit}
          data-testid="search-button"
        >
          <FontAwesomeIcon icon="search" />
        </IconButton>
      )}
    </div>
  )
}

SearchField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool
}

export default SearchField
