import { CONFIGURATION } from "sharedConstants"

import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import { ConfigurationJobFull } from "models/configurationJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map({
  data: null,
  selectionSettings: null
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIGURATION.JOB.ACTION.LIST_PROJECT:
    case `${CONFIGURATION.JOB.ACTION.LIST_PROJECT}_FULFILLED`:
      return state
        .set(
          "data",
          List(
            _map(payload.configuration_jobs, confJob => new ConfigurationJobFull(fromJS(confJob)))
          )
        )
        .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
    default:
      return state
  }
}
