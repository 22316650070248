import React, { PureComponent } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _noop from "lodash/noop"
import moment from "moment"
import { Record, Map } from "immutable"
import TimeAgo from "react-timeago"
import ReactDiffViewer from "react-diff-viewer"

// helpers, constants
import { getRoutePath } from "routes"
import { goBack } from "helpers/backButton.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { MODAL, TOAST } from "sharedConstants"
import { capitalize } from "helpers/string.helper"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import Paper from "components/UI/elements/Paper"
import ConfirmModal from "components/UI/components/ConfirmModal"

// helpers
import Username from "helpers/Username.helper"

class EntityHistoryDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirmModal: Map({
        open: false,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      historyItem,
      retrieveEntityHistoryItem,
      match: {
        params: { id, hid }
      }
    } = this.props

    if (!historyItem) {
      retrieveEntityHistoryItem(id, hid).catch(_noop)
    }
  }

  confirmEntityRevert = () => {
    if (!this.state.confirmModal.get("isLoading")) {
      const {
        revertEntityTo,
        showToast,
        entityName,
        match: {
          params: { id, hid }
        }
      } = this.props

      this.setState(prevState => ({
        confirmModal: prevState.confirmModal.set("isLoading", true)
      }))

      const revertToPromise = this.pendingPromises.create(revertEntityTo(id, hid))
      revertToPromise.promise
        .then(() => {
          this.setState(prevState => ({
            confirmModal: prevState.confirmModal.set("open", false)
          }))
          showToast(
            `${entityName === "dawg" ? "DAWG" : capitalize(entityName)} has been reverted.`,
            TOAST.TYPE.SUCCESS
          )
          this.pendingPromises.remove(revertToPromise)
        })
        .catch(() => {
          this.pendingPromises.remove(revertToPromise)
        })
    }
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: prevState.confirmModal
        .set("open", !prevState.confirmModal.get("open"))
        .set("isLoading", false)
    }))
  }

  render() {
    const {
      history,
      historyItem,
      usersAcl,
      getEntityChangedKeys,
      entityName,
      match: {
        params: { id }
      }
    } = this.props
    const { confirmModal } = this.state
    const isEditable = hasWritePermission(usersAcl)
    const pathname =
      entityName === "workspace" ? "workspace.workspaceHistory.list" : "dawg.dawgHistory.list"

    return (
      <section className="history-item-page">
        {historyItem && (
          <React.Fragment>
            <PaperHeader size="small" className="history-item-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(history, getRoutePath(pathname, { id }))}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>{capitalize(entityName)} Version History Detail</h3>
              </div>
              <Button
                className="revert-button"
                color="green"
                size="small"
                disabled={!isEditable || !historyItem.id}
                onClick={this.toggleConfirmModal}
              >
                <FontAwesomeIcon className="icon" icon={["far", "undo"]} /> Revert
              </Button>
            </PaperHeader>
            <Paper hasHeader={true} className="history-item-content">
              <table className="table history-item-summary">
                <thead>
                  <tr>
                    <th>Changes</th>
                    <th className="align-right">User</th>
                    <th className="align-right modified">Modified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {getEntityChangedKeys(
                        historyItem.get(entityName),
                        historyItem.getIn(["previous_history", entityName], Map())
                      ).join(", ")}
                    </td>
                    <td className="align-right">
                      <Username userId={historyItem.getIn([entityName, "user_id"])} />
                    </td>
                    <td className="align-right">
                      <TimeAgo
                        date={moment
                          .utc(historyItem.getIn([entityName, "created"]))
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="diff-view">
                <ReactDiffViewer
                  splitView={true}
                  oldValue={JSON.stringify(
                    historyItem.getIn(["previous_history", entityName], Map()),
                    null,
                    2
                  )}
                  newValue={JSON.stringify(historyItem.get(entityName).toJS(), null, 2)}
                />
              </div>
            </Paper>
            <ConfirmModal
              open={confirmModal.get("open")}
              type={MODAL.TYPE.SUCCESS}
              handleClose={this.toggleConfirmModal}
              handleConfirm={this.confirmEntityRevert}
              title={`Revert ${entityName}`}
              text={`Do you really want to revert ${entityName} to viewed history item?`}
              isLoading={confirmModal.get("isLoading")}
            />
          </React.Fragment>
        )}
      </section>
    )
  }
}

EntityHistoryDetail.propTypes = {
  usersAcl: PropTypes.instanceOf(Record),
  historyItem: PropTypes.instanceOf(Record),
  revertEntityTo: PropTypes.func.isRequired,
  retrieveEntityHistoryItem: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getEntityChangedKeys: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired
}

export default withRouter(EntityHistoryDetail)
