import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, reduxForm, Field } from "redux-form"
import _get from "lodash/get"

import SearchField from "components/UI/elements/SearchField"

import "./SearchForm.css"

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typingTimeout: 0
    }
  }

  onSubmit = values => {
    const { onSubmit } = this.props
    if (onSubmit) {
      // submit field only when onSubmit is passed to props
      const filterTerm = _get(values, "search", "")
      onSubmit(filterTerm)
    }
  }

  onSearchFieldChange = values => {
    // submit field only when onSubmit is passed to props
    const { onSubmit } = this.props
    if (onSubmit) {
      const { typingTimeout } = this.state
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      this.setState({
        typingTimeout: setTimeout(this.props.handleSubmit(this.onSubmit), 250)
      })
    }
  }

  handleClear = () => {
    const { handleSubmit, change } = this.props
    const submitter = handleSubmit(() => this.onSubmit({ search: "" }))
    change("search", "")
    submitter()
  }

  render() {
    const { placeholder, className, handleSubmit } = this.props
    return (
      <Form
        name="search-form"
        className={`search-form ${className ? className : ""}`}
        autoComplete="off"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Field
          name="search"
          component={SearchField}
          placeholder={placeholder}
          handleSubmit={handleSubmit(this.onSubmit)}
          handleClear={this.handleClear}
          handleChange={this.onSearchFieldChange}
          autoFocus
        />
      </Form>
    )
  }
}

SearchForm.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  initialValues: PropTypes.object
}

export default reduxForm({
  form: "SearchForm",
  destroyOnUnmount: true
})(SearchForm)

export const SearchFormUndestroyable = reduxForm({
  destroyOnUnmount: false
})(SearchForm)
