import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ToggleButton.css"

const ToggleButton = ({ value, handleToggle, className }) => (
  <button
    className={`bool-toggle-button ${value ? "on" : "off"} ${className ? className : ""}`}
    onClick={handleToggle}
    data-testid="button"
  >
    <span className="switch">
      <FontAwesomeIcon data-testid="power-icon" icon={["fal", "power-off"]} />
    </span>
  </button>
)

ToggleButton.propTypes = {
  value: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default ToggleButton
