import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Record } from "immutable"
import ReactTooltip from "react-tooltip"
import { NavLink } from "react-router-dom"

// helpers
import { shortenString } from "helpers/string.helper"
import { getRoutePath } from "routes"

// selectors
import { getDawgRecord } from "selectors/dawg.selector"

class DawgDetailSubmenu extends PureComponent {
  render() {
    const { dawg } = this.props

    if (!dawg) {
      return null
    }

    const hasDawgTooltip = dawg.name.length >= 70
    const dawgTooltipProps = hasDawgTooltip
      ? { "data-tip": dawg.name, "data-for": "navigation-tooltip" }
      : {}

    return (
      <React.Fragment>
        <nav className="secondary-nav">
          <NavLink to={getRoutePath("dawg.list")}>DAWGS</NavLink>
          <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
          <a className="is-active has-dawg-icon" {...dawgTooltipProps}>
            <FontAwesomeIcon className="prefix-icon network" icon={["fas", "chart-network"]} />
            {shortenString(dawg.name, 70)}
          </a>
        </nav>
        <ReactTooltip id="navigation-tooltip" getContent={dataTip => dataTip} place="bottom" />
      </React.Fragment>
    )
  }
}

DawgDetailSubmenu.propTypes = {
  dawg: PropTypes.instanceOf(Record)
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id }
    }
  } = ownProps
  return {
    dawg: getDawgRecord(state, id)
  }
}

export default connect(mapStateToProps)(DawgDetailSubmenu)
