import React from "react"
import { Route, Redirect } from "react-router-dom"
import { isAdmin } from "helpers/authenticatedUser.helper"
import { getRoutePath } from "routes"

const AdminProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdmin() ? <Component {...props} /> : <Redirect to={getRoutePath("workspace.list")} />
    }
  />
)

export default AdminProtectedRoute
