import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _noop from "lodash/noop"
import moment from "moment"
import { Record, Map } from "immutable"
import TimeAgo from "react-timeago"
import ReactDiffViewer from "react-diff-viewer"

// helpers, constants
import { getRoutePath } from "routes"
import { goBack } from "helpers/backButton.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { getConfigurationChangedKeys } from "helpers/objectChanges.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { MODAL, TOAST } from "sharedConstants"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import Paper from "components/UI/elements/Paper"
import ConfirmModal from "components/UI/components/ConfirmModal"

// selectors
import { getConfigurationHistoryItem } from "selectors/configurationHistory.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

// actions
import {
  retrieveConfigurationHistoryItem,
  revertConfigurationTo
} from "actions/configurationHistory.action"
import { showToast } from "actions/toast.action"

// helpers
import Username from "helpers/Username.helper"

class ConfigurationHistoryDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirmModal: Map({
        open: false,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      historyItem,
      retrieveConfigurationHistoryItem,
      match: {
        params: { id, cid, hid }
      }
    } = this.props

    if (!historyItem) {
      retrieveConfigurationHistoryItem(id, cid, hid).catch(_noop)
    }
  }

  confirmConfigurationRevert = () => {
    if (!this.state.confirmModal.get("isLoading")) {
      const {
        revertConfigurationTo,
        showToast,
        match: {
          params: { id, cid, hid }
        }
      } = this.props

      this.setState(prevState => ({
        confirmModal: prevState.confirmModal.set("isLoading", true)
      }))

      const revertToPromise = this.pendingPromises.create(revertConfigurationTo(id, cid, hid))
      revertToPromise.promise
        .then(() => {
          this.setState(prevState => ({
            confirmModal: prevState.confirmModal.set("open", false)
          }))
          showToast("Configuration has been reverted.", TOAST.TYPE.SUCCESS)
          this.pendingPromises.remove(revertToPromise)
        })
        .catch(() => {
          this.pendingPromises.remove(revertToPromise)
        })
    }
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: prevState.confirmModal
        .set("open", !prevState.confirmModal.get("open"))
        .set("isLoading", false)
    }))
  }

  render() {
    const {
      history,
      historyItem,
      usersAcl,
      match: {
        params: { id, cid }
      }
    } = this.props
    const { confirmModal } = this.state
    const isEditable = hasWritePermission(usersAcl)

    return (
      <section className="history-item-page">
        {historyItem && (
          <React.Fragment>
            <PaperHeader size="small" className="history-item-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(
                    history,
                    getRoutePath("workspace.configuration.configurationHistory.list", { id, cid })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Configuration Version History Detail</h3>
              </div>
              <Button
                className="revert-button"
                color="green"
                size="small"
                disabled={!isEditable || !historyItem.id}
                onClick={this.toggleConfirmModal}
              >
                <FontAwesomeIcon className="icon" icon={["far", "undo"]} /> Revert
              </Button>
            </PaperHeader>
            <Paper hasHeader={true} className="history-item-content">
              <table className="table history-item-summary">
                <thead>
                  <tr>
                    <th>Changes</th>
                    <th className="align-right">User</th>
                    <th className="align-right modified">Modified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {getConfigurationChangedKeys(
                        historyItem.configuration,
                        historyItem.getIn(["previous_history", "configuration"], Map())
                      ).join(", ")}
                    </td>
                    <td className="align-right">
                      <Username userId={historyItem.getIn(["configuration", "user_id"])} />
                    </td>
                    <td className="align-right">
                      <TimeAgo
                        date={moment
                          .utc(historyItem.getIn(["configuration", "created"]))
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="diff-view">
                <ReactDiffViewer
                  splitView={true}
                  oldValue={JSON.stringify(
                    historyItem.getIn(["previous_history", "configuration"], Map()),
                    null,
                    2
                  )}
                  newValue={JSON.stringify(historyItem.configuration.toJS(), null, 2)}
                />
              </div>
            </Paper>
            <ConfirmModal
              open={confirmModal.get("open")}
              type={MODAL.TYPE.SUCCESS}
              handleClose={this.toggleConfirmModal}
              handleConfirm={this.confirmConfigurationRevert}
              title="Revert configuration"
              text="Do you really want to revert configuration to viewed history item?"
              isLoading={confirmModal.get("isLoading")}
            />
          </React.Fragment>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  historyItem: getConfigurationHistoryItem(state, ownProps.match.params.hid),
  usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id)
})

ConfigurationHistoryDetail.propTypes = {
  usersAcl: PropTypes.instanceOf(Record),
  historyItem: PropTypes.instanceOf(Record),
  revertConfigurationTo: PropTypes.func.isRequired,
  retrieveConfigurationHistoryItem: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  retrieveConfigurationHistoryItem,
  revertConfigurationTo,
  showToast
})(ConfigurationHistoryDetail)
