import { OAUTH } from "sharedConstants"
import { List } from "immutable"
import _map from "lodash/map"
import OAuthModel from "models/oauth.model"

/*
 * List of Oauths - List([oauthRecord, oauthRecord])
 */
export default function oauthRepositoriesUpdate(state = null, { type, payload }) {
  switch (type) {
    case `${OAUTH.ACTION.LIST}_FULFILLED`:
      return List(_map(payload, oauth => new OAuthModel(oauth)))

    case `${OAUTH.ACTION.MODIFY}_FULFILLED`:
      return state.map(oauth => (oauth.id === payload.id ? new OAuthModel(payload) : oauth))

    default:
      return state
  }
}
