class DefaultSettingsProcessor {
  preProcessSettings(settings) {
    return settings
  }

  postProcessSettings(settings) {
    return settings
  }
}

export default DefaultSettingsProcessor
