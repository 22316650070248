const mapping = {
  dashboard: {
    path: "/",
    regex: "\\/",
    title: "Workspaces",
    headerTitle: "Workspaces"
  },
  login: {
    path: "/login",
    title: "Login"
  },
  "okta.callback": {
    path: "/okta_login",
    title: "Okta Callback Page"
  },
  logout: {
    path: "/logout",
    title: "Logout"
  },
  "password.reset": {
    path: "/password-reset",
    title: "Password reset"
  },
  "password.set": {
    path: "/set_password",
    title: "Set your password"
  },
  "project.configurationJob": {
    path: "/project/configurations/activity",
    title: "All Configuration Activity",
    regex: "\\/project\\/configurations\\/activity"
  },
  "project.workspaceJob": {
    path: "/project/workspaces/activity",
    title: "All Workspace Activity",
    regex: "\\/project/\\/workspaces\\/activity"
  },
  search: {
    path: "/search",
    regex: "\\/search",
    title: "Search"
  },
  "dawg.list": {
    path: "/dawgs",
    regex: "\\/dawgs",
    title: "DAWGs"
  },
  "dawg.show": {
    path: "/dawgs/:id",
    title: "View dawg",
    regex: "\\/dawgs\\/(\\d+)",
    previous: "dawg.list"
  },
  "dawg.workspace.add": {
    path: "/dawgs/:id/add-workspace",
    title: "Add workspace to Dawg",
    regex: "\\/dawgs\\/(\\d+)\\/add-workspace",
    previous: "dawg.show"
  },
  "dawg.dawgHistory.list": {
    path: "/dawgs/:id/history",
    title: "Dawg Version History"
  },
  "dawg.dawgHistory.show": {
    path: "/dawgs/:id/history/:hid",
    title: "Dawg Version History Detail"
  },
  "dawg.dawgJob.show": {
    path: "/dawgs/:id/activity/:aid",
    title: "Dawg activity detail"
  },
  "dawg.dawgJob.show.history": {
    path: "/dawgs/:id/activity/:aid/history/:hid",
    title: "Dawg activity detail history"
  },
  "dawg.dawgJob.list": {
    path: "/dawgs/:id/activity",
    title: "Dawg activity"
  },
  "dawg.workspaceJob.list": {
    path: "/dawgs/:id/workspaces/activity",
    title: "Dawg workspace activity"
  },
  "workspace.list": {
    path: "/workspaces",
    regex: "\\/workspaces",
    title: "Workspaces"
  },
  "workspace.show": {
    path: "/workspaces/:id",
    title: "View workspace",
    regex: "\\/workspaces\\/(\\d+)",
    previous: "workspace.list"
  },
  "workspace.workspaceHistory.list": {
    path: "/workspaces/:id/history",
    title: "Workspace Version History"
  },
  "workspace.workspaceHistory.show": {
    path: "/workspaces/:id/history/:hid",
    title: "Workspace Version History Detail"
  },
  "workspace.workspaceJob.list": {
    path: "/workspaces/:id/activity",
    title: "Workspace activity"
  },
  "workspace.workspaceJob.show": {
    path: "/workspaces/:id/activity/:aid",
    title: "Workspace activity detail"
  },
  "workspace.workspaceJob.show.history": {
    path: "/workspaces/:id/activity/:aid/history/:hid",
    title: "Workspace activity detail history"
  },
  "workspace.configuration.create": {
    path: "/workspaces/:id/create-configuration",
    title: "Create configuration"
  },
  "workspace.configurationJob.list": {
    path: "/workspaces/:id/configurations/activity",
    title: "Workspace configuration activity"
  },
  "workspace.configuration.show": {
    path: "/workspaces/:id/configurations/:cid",
    title: "Configuration detail"
  },
  "workspace.configuration.configurationHistory.list": {
    path: "/workspaces/:id/configurations/:cid/history",
    title: "Configuration Version History"
  },
  "workspace.configuration.configurationHistory.show": {
    path: "/workspaces/:id/configurations/:cid/history/:hid",
    title: "Configuration Version History Detail"
  },
  "workspace.configuration.data.preview.list": {
    path: "/workspaces/:id/configurations/:cid/data-preview/:type",
    title: "List of files"
  },
  "workspace.configuration.data.preview": {
    path: "/workspaces/:id/configurations/:cid/data-preview/:type/:did",
    title: "Data preview"
  },
  "workspace.configuration.configurationJob.list": {
    path: "/workspaces/:id/configurations/:cid/activity",
    title: "Configuration activity"
  },
  "workspace.configuration.configurationJob.show": {
    path: "/workspaces/:id/configurations/:cid/activity/:aid",
    title: "Configuration activity detail"
  },
  "workspace.configuration.configurationJob.show.history": {
    path: "/workspaces/:id/configurations/:cid/activity/:aid/history/:hid",
    title: "Configuration activity detail history"
  },
  "trash.list": {
    path: "/trash",
    title: "Trash"
  },
  administration: {
    path: "/administration",
    title: "Admin"
  },
  "administration.users": {
    path: "/administration/users",
    title: "Users - Administration"
  },
  "administration.user-permissions": {
    path: "/administration/user-permissions",
    title: "User permissions - Administration"
  },
  "administration.tags": {
    path: "/administration/tags",
    title: "Tags - Administration"
  },
  "administration.oauth": {
    path: "/administration/oauth-repositories",
    title: "OAuth repositories - Administration"
  },
  "user-settings": {
    path: "/user-settings",
    title: "User Settings"
  },
  "not-authorized": {
    path: "/not-authorized",
    title: "Not Authorized"
  },
  "not-found": {
    path: "/not-found",
    title: "Not Found"
  }
}

export const getRoutesMapping = () => mapping

export const getRoutePath = (...args) => getRoute(...args).path

export const getRouteRegex = name => getRoute(name).regex

export const getRoute = (name, params = {}, prefix = "") => {
  let route = { ...mapping[name] }
  if (Object.keys(route).length === 0) {
    throw new Error(`Route with name ${name} does not exist`)
  }
  route.path = replaceParamsInPath(route.path, params)
  route.path = applyPrefixToPath(route.path, prefix)
  return route
}

export const replaceParamsInPath = (path, params = {}) => {
  const paramsKeys = Object.keys(params)
  if (!paramsKeys.length) {
    return path
  }

  paramsKeys.forEach(property => {
    const reg = new RegExp(`:${property}(?![\\w\\d])`, "i")
    path = path.replace(reg, params[property])
  })

  return path
}

export const applyPrefixToPath = (path, prefix = "") => {
  if (!prefix.length) {
    return path
  }

  return `${prefix}${path}`
}

export const getRoutePrevious = pathname => {
  const routes = Object.keys(mapping)
  let previous = undefined
  routes.forEach(property => {
    if (mapping[property].regex) {
      const regExp = new RegExp(mapping[property].regex)
      const regexGroups = regExp.exec(pathname)
      if (regexGroups) {
        previous = {
          previous: mapping[property].previous,
          current: property,
          ...regexGroups
        }
      }
    }
  })
  return previous
}

export const getRouteHeaderTitle = pathname => {
  const routes = Object.keys(mapping)
  let headerTitle = undefined
  routes.forEach(property => {
    if (mapping[property].regex) {
      const regExp = new RegExp(mapping[property].regex)
      const regexGroups = regExp.exec(pathname)
      if (regexGroups) {
        headerTitle = mapping[property].headerTitle
      }
    }
  })
  return headerTitle
}
