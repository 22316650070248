import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, FieldArray } from "redux-form"
import PropTypes from "prop-types"
import { List, Map, Record } from "immutable"
import _get from "lodash/get"
import _toString from "lodash/toString"
import _toLower from "lodash/toLower"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// ui elements
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import SelectField from "components/UI/elements/SelectField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"

// actions
import { showToast } from "actions/toast.action"

// helpers
import { optionRequired } from "helpers/validators.helper"

// constants
import { TOAST, USER, PERMISSION } from "sharedConstants"

import "./AddUserAclModal.css"

class AddUserAclModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = values => {
    const { showToast, entityName } = this.props

    this.setState({ loading: true })

    this.createAcls(List(values["invite-users"]), isSuccess => {
      this.setState({ loading: false })
      if (isSuccess) {
        showToast(
          `Invites to the ${entityName === "dawg" ? "DAWG" : "workspace"} have been sent.`,
          TOAST.TYPE.SUCCESS
        )
        this.cancel()
      }
    })
  }

  createAcls = (inviteUsers, cbFn) => {
    if (inviteUsers.size === 0) {
      cbFn(true)
    } else {
      const { createAcl, entity } = this.props
      const inviteUser = inviteUsers.last()

      createAcl(entity.id, {
        permission: inviteUser.permission,
        user_id: inviteUser.user.value
      })
        .then(() => {
          this.createAcls(inviteUsers.pop(), cbFn)
        })
        .catch(() => {
          this.createAcls(inviteUsers.pop(), cbFn)
        })
    }
  }

  cancel = () => {
    const { handleClose, reset } = this.props
    handleClose()
    // reset after fade out
    setTimeout(reset, 500)
  }

  userSelectOptions = (index, inviteUserIds) => {
    const { users, entityUsers, authenticatedUser } = this.props
    const authUserId = _get(authenticatedUser, "data.id", 0)

    const entityUsersInMap = this.convertListToMapByKey(entityUsers, "user_id")

    return users
      .filter(
        user =>
          user.role !== USER.ROLE.SYSTEM &&
          !user.disabled &&
          entityUsersInMap &&
          !entityUsersInMap.get(user.id) &&
          inviteUserIds.indexOf(user.id) === -1 &&
          user.id !== authUserId
      )
      .map(user => ({
        index: index,
        value: user.id,
        name: user.name,
        email: user.email,
        label: user.name
      }))
      .sortBy(user => _toLower(user.name))
      .toArray()
  }

  convertListToMapByKey = (list, searchKey) => {
    if (!list) {
      return undefined
    }

    return list.reduce((acc, currentValue) => {
      const id = Map.isMap(currentValue) ? currentValue.get(searchKey) : currentValue[searchKey]
      return acc.set(id, currentValue)
    }, Map())
  }

  renderInviteUsers = ({ fields }) => {
    const { users } = this.props
    const inviteUserIdList = fields.getAll()
      ? fields
          .getAll()
          .filter(field => field.user)
          .map(field => field.user.value)
      : []
    const defaultInviteUser = {
      permission: PERMISSION.READ
    }

    if (fields.length === 0) {
      fields.push(defaultInviteUser)
    }

    return (
      <React.Fragment>
        {fields.map((member, index) => {
          const userIdValue = fields.get(index).user
          let isAdminSelected = false
          if (userIdValue && userIdValue.value) {
            const role = users.getIn([_toString(userIdValue.value), "role"])
            if (role === USER.ROLE.ADMIN) {
              isAdminSelected = true
              if (fields.get(index)["permission"] === PERMISSION.READ) {
                fields.get(index)["permission"] = PERMISSION.WRITE
              }
            }
          }

          return (
            <div className="form-row" key={index}>
              <Field
                name={`${member}.user`}
                component={SelectField}
                label="User"
                options={this.userSelectOptions(index, inviteUserIdList)}
                className="user-field"
                isSearchable={true}
                validate={optionRequired}
                maxDropdownHeight="138px"
              />
              <ToggleSwitchField
                name={`${member}.permission`}
                leftLabel="View"
                leftValue={PERMISSION.READ}
                rightLabel="Edit"
                rightValue={PERMISSION.WRITE}
                width="100px"
                label="Permission"
                disabled={isAdminSelected}
              />
              <IconButton
                color="red"
                type="button"
                onClick={() => fields.remove(index)}
                className="trash-icon"
                disabled={fields.length === 1}
              >
                <FontAwesomeIcon icon={["far", "trash-alt"]} />
              </IconButton>
              {isAdminSelected && (
                <p className="admin-permission-message">Admin's permissions can't be modified.</p>
              )}
            </div>
          )
        })}
        <Button
          color="link-primary"
          type="button"
          onClick={() => fields.push(defaultInviteUser)}
          className="invite-more-btn"
          size="small"
        >
          <FontAwesomeIcon icon={["far", "plus-circle"]} className="icon" />
          invite more people
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const { open, handleSubmit, entityName } = this.props

    return (
      <Modal
        open={open}
        handleClose={this.cancel}
        title={`Add user to ${entityName}`}
        className="add-entity-user-modal"
      >
        <Form
          className="add-entity-user-form"
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="invite-user-list">
            <FieldArray
              name="invite-users"
              component={this.renderInviteUsers}
              rerenderOnEveryChange={true}
            />
          </div>

          <div className="action-buttons">
            <Button
              type="button"
              color="transparent-grey"
              size="big"
              onClick={this.cancel}
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              size="big"
              className={this.state.loading ? "loading" : ""}
            >
              Add user
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }
}

AddUserAclModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createAcl: PropTypes.func.isRequired,
  entityUsers: PropTypes.instanceOf(List),
  entity: PropTypes.instanceOf(Record).isRequired,
  entityName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Map).isRequired,
  initialValues: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.object.isRequired
}

AddUserAclModal = reduxForm({
  form: "AddUserAcl",
  touchOnBlur: false
})(
  connect(
    state => ({
      authenticatedUser: state.authenticatedUser
    }),
    { showToast }
  )(AddUserAclModal)
)

export default AddUserAclModal
