import ace from "brace"

ace.define("ace/theme/meiro", ["require", "exports", "module", "ace/lib/dom"], function(
  acequire,
  exports,
  module
) {
  exports.isDark = true
  exports.cssClass = "ace-meiro"
  exports.cssText =
    ".ace-meiro .ace_gutter {\n" +
    "background: #dddddd;\n" +
    "color: #222222;\n" +
    "}\n" +
    ".ace-meiro .ace_print-margin {\n" +
    "width: 1px;\n" +
    "background: transparent;\n" +
    "}\n" +
    ".ace-meiro {\n" +
    "background-color: transparent;\n" +
    "color: #222222;\n" +
    "padding-bottom: 15px;\n" +
    "border-bottom-left-radius: 5px;\n" +
    "}\n" +
    ".ace-meiro .ace_cursor {\n" +
    "color: #000000;\n" +
    "}\n" +
    ".ace-meiro .ace_marker-layer .ace_selection {\n" +
    "background: rgba(250, 190, 83, 0.6);\n" +
    "}\n" +
    ".ace-meiro.ace_multiselect .ace_selection.ace_start {\n" +
    "box-shadow: 0 0 3px 0px transparent;\n" +
    "}\n" +
    ".ace-meiro .ace_marker-layer .ace_step {\n" +
    "background: rgb(250, 190, 83);\n" +
    "}\n" +
    ".ace-meiro .ace_marker-layer .ace_bracket {\n" +
    "margin: -1px 0 0 -1px;\n" +
    "border: 1px solid rgba(0, 0, 0, 0.3);\n" +
    "}\n" +
    ".ace-meiro .ace_marker-layer .ace_active-line {\n" +
    "background: rgba(250, 190, 83, 0.25);\n" +
    "}\n" +
    ".ace-meiro .ace_gutter-active-line {\n" +
    "background: rgba(250, 190, 83, 0.25);\n" +
    "}\n" +
    ".ace-meiro .ace_marker-layer .ace_selected-word {\n" +
    "border: 1px solid rgba(250, 190, 83, 0.6);\n" +
    "}\n" +
    ".ace-meiro .ace_invisible {\n" +
    "color: #BFBFBF\n" +
    "}\n" +
    ".ace-meiro .ace_entity.ace_name.ace_tag,\n" +
    ".ace-meiro .ace_keyword,\n" +
    ".ace-meiro .ace_meta.ace_tag,\n" +
    ".ace-meiro .ace_storage {\n" +
    "font-weight: bold;\n" +
    "color: #ED382A;\n" +
    "}\n" +
    ".ace-meiro .ace_punctuation,\n" +
    ".ace-meiro .ace_punctuation.ace_tag {\n" +
    "color: #000000\n" +
    "}\n" +
    ".ace-meiro .ace_constant.ace_character,\n" +
    ".ace-meiro .ace_constant.ace_language,\n" +
    ".ace-meiro .ace_constant.ace_numeric,\n" +
    ".ace-meiro .ace_constant.ace_other {\n" +
    "color: #9A5925;\n" +
    "}\n" +
    ".ace-meiro .ace_invalid {\n" +
    "color: #FD1224;\n" +
    "background-color: rgba(255, 0, 0, 0.15);\n" +
    "}\n" +
    ".ace-meiro .ace_invalid.ace_deprecated {\n" +
    "color: #FD1732;\n" +
    "background-color: transparent;\n" +
    "text-decoration:underline;\n" +
    "font-style: italic;\n" +
    "}\n" +
    ".ace-meiro .ace_support.ace_constant,\n" +
    ".ace-meiro .ace_support.ace_function {\n" +
    "color: #ED382A;\n" +
    "}\n" +
    ".ace-meiro .ace_fold {\n" +
    "border-color: #222222\n" +
    "}\n" +
    ".ace-meiro .ace_storage.ace_type,\n" +
    ".ace-meiro .ace_support.ace_class,\n" +
    ".ace-meiro .ace_support.ace_type {\n" +
    "font-style: italic;\n" +
    "color: #ED382A;\n" +
    "}\n" +
    ".ace-meiro .ace_entity.ace_name.ace_function,\n" +
    ".ace-meiro .ace_entity.ace_other,\n" +
    ".ace-meiro .ace_entity.ace_other.ace_attribute-name,\n" +
    ".ace-meiro .ace_variable {\n" +
    "color: #009ACD;\n" +
    "}\n" +
    ".ace-meiro .ace_variable.ace_parameter {\n" +
    "font-style: italic;\n" +
    "}\n" +
    ".ace-meiro .ace_string {\n" +
    "color: #3AA545\n" +
    "}\n" +
    ".ace-meiro .ace_comment {\n" +
    "color: #aaaaaa\n" +
    "}\n"

  var dom = acequire("../lib/dom")
  dom.importCssString(exports.cssText, exports.cssClass)
})
