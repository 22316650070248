import { DAWG } from "sharedConstants"
import { WorkspaceSimple as WorkspaceModel } from "models/workspace.model"
import { Map, fromJS } from "immutable"
import _toString from "lodash/toString"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"

/*
 * {
 *   isFetching: true/false
 *   data: {
 *     dawgId: {
 *       isFulfilled: false,
 *       workspaces: {
 *         wid: wObject, wid2, wObject2
 *       }
 *     }
 *   }
 * }
 */

const initialState = Map({
  isFetching: true,
  data: Map()
})

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case `${DAWG.WORKSPACE.ACTION.MODIFY}_PENDING`:
    case `${DAWG.WORKSPACE.ACTION.MODIFY_BULK}_PENDING`:
      return state.setIn(["data", _toString(meta.dawgId), "isModifying"], true)

    case `${DAWG.WORKSPACE.ACTION.LIST}_PENDING`:
      return state.set("isFetching", true)

    case `${DAWG.WORKSPACE.ACTION.LIST}_FULFILLED`:
      return state.set("isFetching", false).set(
        "data",
        Map({
          [payload.dawgId]: Map({
            isFulfilled: true,
            workspaces: Map(
              _mapKeys(
                _map(payload.data, ws => new WorkspaceModel(fromJS(ws))),
                "id"
              )
            )
          })
        })
      )

    case `${DAWG.WORKSPACE.ACTION.ADD}_FULFILLED`:
      return state.setIn(
        ["data", _toString(payload.dawg_id), "workspaces", _toString(payload.id)],
        new WorkspaceModel(fromJS(payload))
      )

    case `${DAWG.WORKSPACE.ACTION.MODIFY}_FULFILLED`:
      return state
        .setIn(
          ["data", _toString(payload.dawg_id), "workspaces", _toString(payload.id)],
          new WorkspaceModel(fromJS(payload))
        )
        .setIn(["data", _toString(payload.dawg_id), "isModifying"], false)

    case `${DAWG.WORKSPACE.ACTION.MODIFY}_REJECTED`: {
      return state.setIn(["data", _toString(meta.dawgId), "isModifying"], false)
    }

    case `${DAWG.WORKSPACE.ACTION.MODIFY_BULK}_FULFILLED`:
      const changedWsMap = _mapKeys(
        _map(payload, ws => new WorkspaceModel(fromJS(ws))),
        "id"
      )
      return state
        .setIn(["data", _toString(meta.dawgId), "isModifying"], false)
        .setIn(
          ["data", _toString(meta.dawgId), "workspaces"],
          state.getIn(["data", _toString(meta.dawgId), "workspaces"]).merge(changedWsMap)
        )

    case `${DAWG.WORKSPACE.ACTION.DELETE}_FULFILLED`:
      return state.deleteIn([
        "data",
        _toString(payload.dawgId),
        "workspaces",
        _toString(payload.workspaceId)
      ])

    default:
      return state
  }
}
