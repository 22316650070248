import _get from "lodash/get"
import { api } from "api"
import { DAWG } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchDawgWorkspaceList = (dawgId, loadFullStructure = 0) => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: DAWG.WORKSPACE.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().dawg.workspace.list(dawgId, offset, limit, loadFullStructure)
          )
          .setDataPath("workspaces")
          .setLoadFullStructure(loadFullStructure)
          .run()
        return {
          dawgId,
          data
        }
      })()
    }
  })
}

export const addWorkspaceToDawg = (dawgId, workspaceId) => async dispatch =>
  dispatch({
    type: DAWG.WORKSPACE.ACTION.ADD,
    payload: {
      promise: (async () => {
        const response = await api().workspace.modifyWorkspace(workspaceId, { dawg_id: dawgId })
        return _get(response, "workspace", {})
      })()
    }
  })

export const deleteWorkspaceFromDawg = (dawgId, workspaceId) => async dispatch =>
  dispatch({
    type: DAWG.WORKSPACE.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api().workspace.modifyWorkspace(workspaceId, { dawg_id: null })
        return { dawgId, workspaceId }
      })()
    }
  })

export const modifyDawgWorkspacesBulk = (dawgId, data, loadFullStructure = 0) => async dispatch =>
  dispatch({
    type: DAWG.WORKSPACE.ACTION.MODIFY_BULK,
    meta: {
      dawgId
    },
    payload: {
      promise: (async () => {
        const response = await api().dawg.workspace.modifyBulk(dawgId, data, loadFullStructure)
        return _get(response, "workspaces", [])
      })()
    }
  })

export const modifyDawgWorkspace = (
  dawgId,
  workspaceId,
  data,
  originData = {},
  loadFullStructure = 0
) => async dispatch =>
  dispatch({
    type: DAWG.WORKSPACE.ACTION.MODIFY,
    meta: {
      dawgId
    },
    payload: {
      promise: (async () => {
        const response = await api().workspace.modifyWorkspace(
          workspaceId,
          data,
          originData,
          loadFullStructure
        )
        return _get(response, "workspace", {})
      })()
    }
  })
