import { INVITE_FORM } from "sharedConstants"

export const setDefaultPermissions = (form, workspaceIds) => async dispatch => {
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.SET_DEFAULT_READ_PERMISSIONS}`,
    payload: () => {
      return { workspaceIds }
    }
  })
}

export const markAllWorkspaces = (form, index, value) => dispatch =>
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.MARK_ALL_WORKSPACES}`,
    payload: {
      index,
      value
    }
  })

export const setPermisionsAndMarkWorkspaces = (form, workspaceIds, index, value) => dispatch =>
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.SET_PERMISSIONS_MARK_ALL}`,
    payload: {
      workspaceIds,
      index,
      value
    }
  })

export const setActiveUser = (form, index = 0) => dispatch =>
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.SET_ACTIVE_USER}`,
    payload: {
      index
    }
  })

export const toggleAllSwitch = (form, index, value) => dispatch =>
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.TOGGLE_ALL_SWITCH}`,
    payload: {
      index,
      value
    }
  })

export const toggleAllUserPermissions = (form, index, value) => dispatch =>
  dispatch({
    type: `${form}_${INVITE_FORM.ACTION.TOGGLE_ALL_USER_PERMISSIONS}`,
    payload: {
      index,
      value
    }
  })
