import React, { PureComponent } from "react"

import "./WsConfStickyNav.css"

class WsConfStickyNav extends PureComponent {
  constructor(props) {
    super(props)
    this.stickyNavRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    const stickyNav = this.stickyNavRef.current
    if (stickyNav) {
      if (document.body.scrollTop > 230 || document.documentElement.scrollTop > 230) {
        stickyNav.style.top = "0px"
      } else {
        stickyNav.style.top = "-65px"
      }
    }
  }

  render() {
    return (
      <div className="ws-conf-sticky-nav" ref={this.stickyNavRef}>
        <div className="wrapper">{this.props.children}</div>
      </div>
    )
  }
}

export default WsConfStickyNav
