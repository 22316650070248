import React from "react"
import PropTypes from "prop-types"
import _isEmpty from "lodash/isEmpty"

import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import { MODAL } from "sharedConstants"

import "./ConfirmModal.css"

const ConfirmModal = ({
  open,
  type,
  handleClose,
  handleConfirm,
  title,
  text,
  htmlText = [],
  action,
  what,
  item,
  isLoading = false
}) => {
  return (
    <Modal open={open} handleClose={handleClose} title={title}>
      <div className="confirm-modal">
        {text && <p>{text}</p>}
        {!_isEmpty(htmlText) && htmlText}
        {item && !text && _isEmpty(htmlText) && (
          <p>
            {`Do you want to ${action} this ${what}: `}
            <strong>{item}</strong>?
          </p>
        )}
        {!item && !text && _isEmpty(htmlText) && <p>{`Do you want to ${action} this ${what}`}?</p>}
        <div className="action-buttons">
          <Button
            color="transparent-grey"
            className="cancel-button"
            size="big"
            onClick={handleClose}
            data-cy="cancel-modal"
          >
            Cancel
          </Button>
          <Button
            color={type === MODAL.TYPE.SUCCESS ? "primary" : "red"}
            size="big"
            className={isLoading ? "loading" : ""}
            onClick={handleConfirm}
            data-cy="confirm-modal"
          >
            {type === MODAL.TYPE.SUCCESS || type === MODAL.TYPE.CANCEL ? "confirm" : type}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string,
  what: PropTypes.string,
  item: PropTypes.string,
  text: PropTypes.string,
  htmlText: PropTypes.array,
  isLoading: PropTypes.bool
}

export default ConfirmModal
