import { List } from "immutable"

export const getWorkspaceDashboardConfJobs = (state, workspaceId) => {
  const data = state.workspaceConfigurationJobs.getIn([workspaceId, "data"])
  if (List.isList(data)) {
    return data.take(6)
  }
  return null
}

export const getWorkspaceConfJobs = (state, workspaceId) =>
  state.workspaceConfigurationJobs.getIn([workspaceId, "data"])

export const getWorkspaceConfJobsSelectionSettings = (state, workspaceId) =>
  state.workspaceConfigurationJobs.getIn([workspaceId, "selectionSettings"])
