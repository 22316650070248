import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Record } from "immutable"

// actions
import { retrieveWorkspace } from "actions/workspace.action"
import { retrieveWorkspaceJob, cancelWorkspaceJob } from "actions/workspaceJob.action"

// selectors
import { getWorkspaceRecord } from "selectors/workspace.selector"
import { getWorkspaceJobData } from "selectors/workspaceJob.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

// ui components
import JobDetail from "components/UI/DawgWsShared/JobDetail"

class WorkspaceJobShow extends PureComponent {
  render() {
    const {
      workspace,
      workspaceJob,
      usersAcl,
      retrieveWorkspace,
      retrieveWorkspaceJob,
      cancelWorkspaceJob
    } = this.props
    return (
      <JobDetail
        entityName="workspace"
        entity={workspace}
        entityJob={workspaceJob}
        usersAcl={usersAcl}
        retrieveEntity={retrieveWorkspace}
        retrieveEntityJob={retrieveWorkspaceJob}
        cancelEntityJob={cancelWorkspaceJob}
      />
    )
  }
}

WorkspaceJobShow.propTypes = {
  workspace: PropTypes.instanceOf(Record),
  workspaceJob: PropTypes.instanceOf(Record),
  usersAcl: PropTypes.instanceOf(Record),
  retrieveWorkspace: PropTypes.func.isRequired,
  retrieveWorkspaceJob: PropTypes.func.isRequired,
  cancelWorkspaceJob: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id, aid }
    }
  } = ownProps
  return {
    workspace: getWorkspaceRecord(state, id),
    workspaceJob: getWorkspaceJobData(state, aid),
    usersAcl: getUsersWorkspaceAcl(state, id)
  }
}

export default connect(mapStateToProps, {
  retrieveWorkspace,
  retrieveWorkspaceJob,
  cancelWorkspaceJob
})(WorkspaceJobShow)
