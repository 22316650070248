import { Record } from "immutable"

export const ConfigurationJobFull = Record({
  id: 0,
  user: null,
  configuration: null,
  status: "",
  stats: null,
  created: "",
  origin_created: ""
})

export const ConfigurationJob = Record({
  id: 0,
  user_id: null,
  configuration_id: null,
  status: "",
  stats: null,
  created: "",
  origin_created: ""
})
