import arrow from "./images/arrow.png"
import times from "./images/delete.png"
import disable from "./images/disable.png"
import play from "./images/play.png"
import trash from "./images/trash.png"

import waiting from "./images/waiting.png"
import warning from "./images/warning.png"
import running from "./images/running.png"
import error from "./images/error.png"
import finished from "./images/finished.png"
import canceled from "./images/canceled.png"

const conf = {
  style: [
    {
      selector: "node[label]",
      style: {
        content: "data(label)",
        width: 80,
        height: 80,
        "text-halign": "center",
        "text-valign": "bottom",
        "text-margin-y": 13,
        "text-wrap": "wrap",
        "text-max-width": "180px",
        "text-background-color": "#ffffff",
        "text-background-shape": "roundrectangle",
        "text-background-padding": "3px",
        "text-background-opacity": 1,
        "font-size": "13px",
        "background-fit": "contain",
        shape: "ellipse",
        "border-width": "2",
        "background-color": "#ffffff"
      }
    },
    {
      selector: "node[?clickable]",
      style: {
        "background-image": "data(icon)",
        "border-color": "data(border)"
      }
    },
    {
      selector: "node[?hidden]",
      style: {
        "background-color": "#facdcb",
        "background-fit": "contain contain",
        color: "#ed382a"
      }
    },
    {
      selector: "node[?disabled]",
      style: {
        "background-color": "#C4C4C4"
      }
    },
    {
      selector: "node[label]:selected",
      style: {
        "background-color": "#fadfb1"
      }
    },
    {
      selector: "node[?statuschip]",
      style: {
        width: 11,
        height: 11,
        shape: "ellipse",
        "overlay-opacity": 0,
        opacity: 0,
        "border-opacity": 0,
        "background-fit": "contain",
        padding: 2,
        "background-width-relative-to": "inner",
        "background-height-relative-to": "inner"
      }
    },
    {
      selector: "node[statuschip = 'waiting']",
      style: {
        "background-image": `${waiting}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[statuschip = 'running']",
      style: {
        "background-image": `${running}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[statuschip = 'warning']",
      style: {
        "background-image": `${warning}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[statuschip = 'error']",
      style: {
        "background-image": `${error}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[statuschip = 'finished']",
      style: {
        "background-image": `${finished}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[statuschip = 'canceled']",
      style: {
        "background-image": `${canceled}`,
        opacity: 1,
        "background-color": "data(color)"
      }
    },
    {
      selector: "node[?transparent]",
      style: {
        opacity: 0.3
      }
    },
    {
      selector: "edge",
      style: {
        "curve-style": "bezier",
        width: 2,
        "target-arrow-shape": "triangle",
        "source-arrow-shape": "circle",
        "line-color": "#C4C4C4",
        "target-arrow-color": "#6c6c6c",
        "arrow-scale": 1.2,
        "source-arrow-color": "#C4C4C4",
        "overlay-opacity": 0
      }
    },
    {
      selector: "edge.highlighted",
      style: {
        "target-arrow-color": "#555555",
        "line-color": "#555555",
        "source-arrow-color": "#555555"
      }
    },
    {
      selector: "#delete-edge",
      style: {
        "background-color": "#ed382a",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${trash}`,
        "background-fit": "contain"
      }
    },

    {
      selector: "#delete-node",
      style: {
        "background-color": "#ed382a",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${trash}`,
        "background-fit": "contain"
      }
    },

    {
      selector: "#disable-node[!disabled]",
      style: {
        "background-color": "#ed382a",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${disable}`,
        "background-fit": "contain"
      }
    },

    {
      selector: "#disable-node[?disabled]",
      style: {
        "background-color": "#41b05e",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${disable}`,
        "background-fit": "contain"
      }
    },

    {
      selector: "#action-node",
      style: {
        "background-color": "#41b05e",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${play}`,
        "background-fit": "contain"
      }
    },

    {
      selector: "#cancel-node",
      style: {
        "background-color": "#ed382a",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4,
        "border-opacity": 0,
        "background-image": `${times}`,
        "background-fit": "contain"
      }
    },

    {
      selector: ".eh-handle",
      style: {
        "background-color": "#999999",
        width: 26,
        height: 26,
        shape: "ellipse",
        "overlay-opacity": 0,
        "border-width": 4, // makes the handle easier to hit
        "border-opacity": 0,
        "background-image": `${arrow}`,
        "background-fit": "contain"
      }
    },

    {
      selector: ".eh-hover",
      style: {
        "background-color": "#F7F8F9"
      }
    },

    {
      selector: ".eh-source",
      style: {
        "border-width": 2,
        "border-color": "#777777"
      }
    },

    {
      selector: ".eh-target",
      style: {
        "border-width": 2,
        "border-color": "#777777",
        "background-color": "#F7F8F9"
      }
    },

    {
      selector: ".eh-preview, .eh-ghost-edge",
      style: {
        "background-color": "#F7F8F9",
        "line-color": "#777777",
        "target-arrow-color": "#777777",
        "source-arrow-color": "#777777"
      }
    }
  ],

  userZoomingEnabled: false,
  userPanningEnabled: true,
  boxSelectionEnabled: true,
  minZoom: 0.25,
  maxZoom: 1.2
}

export default conf
