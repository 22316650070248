import { TAG } from "sharedConstants"
import { Map, fromJS } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import Tag from "models/tag.model"

const initialState = Map(
  fromJS({
    data: {},
    isFetched: false
  })
)

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${TAG.ACTION.LIST}_FULFILLED`:
      return state
        .set(
          "data",
          state.get("data").merge(
            _mapKeys(
              _map(payload, tag => new Tag(tag)),
              "id"
            )
          )
        )
        .set("isFetched", true)

    case `${TAG.ACTION.CREATE}_FULFILLED`:
    case `${TAG.ACTION.MODIFY}_FULFILLED`:
      return state.set("data", state.get("data").merge({ [payload.id]: new Tag(payload) }))

    case `${TAG.ACTION.DELETE}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").filterNot(tag => tag.id === payload)
      )

    default:
      return state
  }
}
