import _toInteger from "lodash/toInteger"

export const getWorkspaceHistoryList = state => state.workspaceHistory.get("data")

export const getWorkspaceHistorySelectionSettings = state =>
  state.workspaceHistory.get("selectionSettings")

export const hasWorkspaceHistoryMoreItems = state => state.workspaceHistory.get("hasMoreItems")

export const getWorkspaceHistoryItem = (state, historyItemId) => {
  if (historyItemId === "0") {
    return state.workspaceHistory.get("data").find(historyItem => historyItem.id === null)
  } else {
    return state.workspaceHistory
      .get("data")
      .find(historyItem => historyItem.id === _toInteger(historyItemId))
  }
}

export const isWorkspaceHistoryFulfilled = state => state.workspaceHistory.get("isFulfilled")
