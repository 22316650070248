import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"

import TextField from "components/UI/elements/TextField"
import Button from "components/UI/elements/Button"
import { required, email } from "helpers/validators.helper"
import { TOAST } from "sharedConstants"
import { showToast } from "actions/toast.action"
import { getRoutePath } from "routes"
import { api } from "api"
import logo from "images/meiro-symbol-white-transparent.svg"

let recaptchaSiteKey = null
if (process.env.NODE_ENV === "production") {
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recaptchaToken: ""
    }
  }

  componentDidMount() {
    document.body.classList.add("toastify-on-top")
  }

  componentWillUnmount() {
    document.body.classList.remove("toastify-on-top")
  }

  onSubmit = async values => {
    if (!this.state.loading) {
      const { email } = values
      const { showToast, history } = this.props
      const { recaptchaToken: recaptcha_token } = this.state
      this.setState({ loading: true })
      await api()
        .user.resetPassword({ email, recaptcha_token })
        .then(() => {
          showToast(
            "The email with instructions has been sent. If you did not receive an email please contact your administrator.",
            TOAST.TYPE.SUCCESS
          )
          history.push(getRoutePath("login"))
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  setRecaptchaToken = recaptchaToken => {
    this.setState({ recaptchaToken })
  }

  render() {
    const { handleSubmit } = this.props
    const { recaptchaToken } = this.state

    return (
      <section className="password-reset user-form-screens">
        <div className="form-wrapper">
          <img src={logo} className="logo" alt="meiro logo" />
          <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
            <h2>Password reset</h2>
            <div className="form-row">
              <Field
                name="email"
                component={TextField}
                label="Email"
                validate={[required, email]}
                fullWidth={true}
                placeholder="Email"
                autoFocus
              />
            </div>
            {recaptchaSiteKey && (
              <div className="form-row recaptcha">
                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={this.setRecaptchaToken} />
              </div>
            )}
            <Button
              type="submit"
              color="primary"
              size="big"
              fullWidth={true}
              className={this.state.loading ? "loading" : ""}
              disabled={recaptchaSiteKey && !recaptchaToken}
            >
              Reset Password
            </Button>
            <Link className="underform-link" to={getRoutePath("login")}>
              Back to login
            </Link>
          </form>
        </div>
        <header className="caption">
          <div className="content-wrapper">
            <h1>Meiro Integrations</h1>
            <h2>Your way out of the data labyrinth.</h2>
          </div>
        </header>
      </section>
    )
  }
}

PasswordReset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

PasswordReset = reduxForm({
  form: "PasswordResetForm",
  touchOnBlur: false
})(connect(null, { showToast })(PasswordReset))

export default PasswordReset
