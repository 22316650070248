import { CONFIGURATION } from "sharedConstants"
import { ConfigurationJob as ConfigurationJobModel } from "models/configurationJob.model"
import SelectionSettingsModel from "models/selectionSettings.model"
import { Map, List, fromJS } from "immutable"

/*
 * Number of cached configuration jobs for each visited configuration is limited to
 * {CONFIGURATION.JOB.LOADING_LIMIT}. It means that when user leaves 'Configuration
 * Jobs List' page, the number of stored configuration jobs should be decreased to
 * first {CONFIGURATION.JOB.LOADING_LIMIT}. Also, when the user completely leaves
 * browsing workspace, this reducer should be set to initial state.
 *
 * state example:
 * Map({
 *   configurationId: Map({
 *     selectionSettings: SelectionSettingsModel,
 *     hasMoreItems: true/false,
 *     data: List([ ConfigurationJobModel ]),
 *     isFetching: false
 *   })
 * })
 */

const initialState = Map()

export default (state = initialState, { type, payload, meta }) => {
  const addNewJobToExistingList = () => {
    const stateTemp = state
      .setIn(
        [meta.configurationId, "data"],
        state
          .getIn([meta.configurationId, "data"])
          .unshift(new ConfigurationJobModel(fromJS(payload.job)))
      )
      .setIn([meta.configurationId, "isFetching"], false)

    if (state.getIn([meta.configurationId, "hasMoreItems"])) {
      // increase selectionSettings offset by 1
      return stateTemp.setIn(
        [meta.configurationId, "selectionSettings", "offset"],
        stateTemp.getIn([meta.configurationId, "selectionSettings", "offset"]) + 1
      )
    }
    return stateTemp
  }

  switch (type) {
    case `${CONFIGURATION.JOB.ACTION.LIST}_PENDING`:
    case `${CONFIGURATION.JOB.ACTION.RUN}_PENDING`:
    case `${CONFIGURATION.JOB.ACTION.RETRIEVE}_PENDING`:
      return state.setIn([meta.configurationId, "isFetching"], true)

    case `${CONFIGURATION.JOB.ACTION.LIST}_FULFILLED`:
      if (payload.jobs.selection_settings.offset === 0) {
        // initial load
        return state
          .setIn(
            [meta.configurationId, "selectionSettings"],
            new SelectionSettingsModel(payload.jobs.selection_settings)
          )
          .setIn(
            [meta.configurationId, "data"],
            List(payload.jobs.configuration_jobs.map(job => new ConfigurationJobModel(fromJS(job))))
          )
          .setIn(
            [meta.configurationId, "hasMoreItems"],
            payload.jobs.configuration_jobs.length === payload.jobs.selection_settings.limit
          )
          .setIn([meta.configurationId, "isFetching"], false)
      } else {
        // concat
        return state
          .setIn(
            [meta.configurationId, "selectionSettings"],
            new SelectionSettingsModel(payload.jobs.selection_settings)
          )
          .setIn(
            [meta.configurationId, "data"],
            state
              .getIn([meta.configurationId, "data"])
              .concat(
                payload.jobs.configuration_jobs.map(job => new ConfigurationJobModel(fromJS(job)))
              )
          )
          .setIn(
            [meta.configurationId, "hasMoreItems"],
            payload.jobs.configuration_jobs.length === payload.jobs.selection_settings.limit
          )
          .setIn([meta.configurationId, "isFetching"], false)
      }

    case CONFIGURATION.JOB.ACTION.REFRESH_LAST:
      if (payload.job) {
        const lastSavedJob = state.getIn([meta.configurationId, "data", 0])
        if (lastSavedJob) {
          /*
           * Now three situations can occurs:
           * a) jobs are completely same (same -> they have same id & status) => do nothing
           * b) jobs are same, but have different statuses => replace the job
           * c) payload job is completely new => add to redux state on first position
           */
          if (payload.job.id === lastSavedJob.id && payload.job.status !== lastSavedJob.status) {
            // case b)
            return state.setIn(
              [meta.configurationId, "data", 0],
              new ConfigurationJobModel(fromJS(payload.job))
            )
          }
          if (payload.job.id !== lastSavedJob.id) {
            // case c)
            return addNewJobToExistingList()
          }
          // case a)
          return state
        } else {
          // first job ever
          return state
            .setIn(
              [meta.configurationId, "selectionSettings"],
              new SelectionSettingsModel({ offset: 0, limit: 20 })
            )
            .setIn([meta.configurationId, "hasMoreItems"], false)
            .setIn(
              [meta.configurationId, "data"],
              List([new ConfigurationJobModel(fromJS(payload.job))])
            )
        }
      }
      return state

    case `${CONFIGURATION.JOB.ACTION.RUN}_FULFILLED`:
      if (List.isList(state.getIn([meta.configurationId, "data"]))) {
        return addNewJobToExistingList()
      }
      // otherwise do nothing, configuration job has been started directly from dataflow
      return state

    case CONFIGURATION.JOB.ACTION.CLEAR_CACHE:
      return initialState

    case `${CONFIGURATION.JOB.ACTION.RETRIEVE}_FULFILLED`:
    case CONFIGURATION.JOB.ACTION.RETRIEVE:
      const data = state.getIn([meta.configurationId, "data"])
      if (List.isList(data)) {
        const jobIndex = data.findIndex(job => job.id === payload.job.id)
        if (jobIndex !== -1) {
          return state.setIn(
            [meta.configurationId, "data", jobIndex],
            new ConfigurationJobModel(fromJS(payload.job))
          )
        }
        return addNewJobToExistingList()
      }
      return state
        .setIn(
          [meta.configurationId, "data"],
          List([new ConfigurationJobModel(fromJS(payload.job))])
        )
        .setIn([meta.configurationId, "isFetching"], false)

    /*
     * it's not necessary to do something, because api cannot return job with canceled
     * status immediately
     */
    case `${CONFIGURATION.JOB.ACTION.CANCEL}_FULFILLED`:
    default:
      return state
  }
}
