import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { reduxForm, Field } from "redux-form"

// helpers
import { required } from "helpers/validators.helper"

// ui components
import TextField from "components/UI/elements/TextField"
import Button from "components/UI/elements/Button"

class CreateConfigurationForm extends PureComponent {
  onSubmit = data => {
    this.props.onSubmit(data)
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div className="form-row">
          <Field
            name="name"
            component={TextField}
            label="Name*"
            validate={required}
            fullWidth={true}
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className="form-row">
          <Field
            name="description"
            component={TextField}
            label="Description"
            placeholder="Description"
            fullWidth={true}
          />
        </div>
        <Button
          type="submit"
          color="primary"
          size="small"
          fullWidth={true}
          className={this.props.loading ? "loading" : ""}
          disabled={!this.props.isEditable}
        >
          Create new configuration
        </Button>
      </form>
    )
  }
}

CreateConfigurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool
}

CreateConfigurationForm = reduxForm({
  form: "CreateConfigurationForm",
  touchOnBlur: false
})(CreateConfigurationForm)

export default CreateConfigurationForm
