import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, List, Map } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _trim from "lodash/trim"
import _forEach from "lodash/forEach"
import _isEmpty from "lodash/isEmpty"
import _toLower from "lodash/toLower"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _startsWith from "lodash/startsWith"
import ReactTooltip from "react-tooltip"
import AceEditor from "react-ace"

// UI components
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import ConfirmModal from "components/UI/components/ConfirmModal"

// constants, helpers
import { shortenString } from "helpers/string.helper"
import { isJSONObject } from "helpers/validators.helper"
import { TOAST, MODAL } from "sharedConstants"

import "./VariablesCard.css"

const VALUE_TYPE = "value"
const KEY_TYPE = "key"

class VariablesCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      form: List(),
      loading: false,
      formType: "form",
      variablesJsonString: "",
      deleteModal: Map({
        open: false,
        rowIndex: null
      })
    }
  }

  enableVariablesForm = () => {
    const {
      toggleEditMode,
      workspace: { variables }
    } = this.props

    if (Map.isMap(variables) && variables.size > 0) {
      this.setState(
        {
          form: variables
            .sortBy((value, key) =>
              _toLower(key.startsWith("#") ? key.substring(1, key.length) : key)
            )
            .map((value, key) => Map({ key, value }))
            .toList(),
          loading: false,
          formType: "form"
        },
        () => {
          toggleEditMode()()
        }
      )
    } else {
      this.setState(
        {
          form: List([Map({ key: "", value: "" })]),
          loading: false,
          formType: "form"
        },
        () => {
          toggleEditMode()()
        }
      )
    }
  }

  renderVariablesMessage = () => {
    const { variables } = this.props.workspace

    if (Map.isMap(variables)) {
      switch (variables.size) {
        case 0:
          return "You have no variables"
        case 1:
          return "You have 1 variable"
        default:
          return `You have ${variables.size} variables`
      }
    }
    return "You have no variables"
  }

  addVariablesRow = () => {
    this.setState(prevState => ({
      form: prevState.form.push(Map({ key: "", value: "" }))
    }))
  }

  removeVariablesRowModalOpen = index => () => {
    if (this.state.form.getIn([index, "key"])) {
      this.setState(prevState => ({
        deleteModal: prevState.deleteModal.set("open", true).set("rowIndex", index)
      }))
    } else {
      this.removeVariablesRow(index)()
    }
  }

  removeVariablesRowModalClose = () => {
    this.setState(prevState => ({
      deleteModal: prevState.deleteModal.set("open", false)
    }))
  }

  removeVariablesRow = rowIndex => () => {
    this.setState(prevState => ({
      form: prevState.form.delete(rowIndex),
      deleteModal: prevState.deleteModal.set("open", false)
    }))
  }

  handleVariablesRowChange = (index, type) => evt => {
    const form = this.state.form
      .setIn([index, type], evt.target.value)
      .setIn([index, "error"], false)

    this.setState({ form })
  }

  saveVariables = () => {
    if (!this.state.loading) {
      const { onSubmit, toggleEditMode, workspace } = this.props
      const { form, formType, variablesJsonString } = this.state

      if (formType === "form") {
        let valid = true
        const formCheck = form.map(row => {
          if (_trim(row.get("key")).length === 0) {
            valid = false
            return row.set("error", true)
          }
          return row
        })

        if (valid) {
          const variables = form.reduce((returnObj, row) => {
            returnObj[row.get("key")] = row.get("value")
            return returnObj
          }, {})

          this.setState({ loading: true })
          onSubmit(
            { variables },
            {
              variables: Map.isMap(workspace.variables)
                ? workspace.variables.toJS()
                : workspace.variables
            },
            "Workspace variables have been modified"
          )
            .then(toggleEditMode())
            .catch(err => {
              if (_get(err, "type") === "nothing_changed") {
                toggleEditMode()()
              } else {
                this.setState({
                  loading: false
                })
              }
            })
        } else {
          this.setState({
            form: formCheck
          })
        }
      } else if (formType === "json") {
        if (this.isVariablesJsonStringValid()) {
          this.setState({ loading: true })
          const variables = variablesJsonString.length ? JSON.parse(variablesJsonString) : {}
          onSubmit(
            { variables },
            {
              variables: Map.isMap(workspace.variables)
                ? workspace.variables.toJS()
                : workspace.variables
            },
            "Workspace variables have been modified"
          )
            .then(toggleEditMode())
            .catch(err => {
              if (_get(err, "type") === "nothing_changed") {
                toggleEditMode()()
              } else {
                this.setState({
                  loading: false
                })
              }
            })
        } else {
          this.setState({
            jsonError: true
          })
        }
      }
    }
  }

  renderVariablesSummary = () => {
    const { variables } = this.props.workspace

    if (Map.isMap(variables)) {
      if (variables.size > 0) {
        return (
          <Paper hasHeader={true} className="variables-summary">
            <table className="table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th className="align-right">Value</th>
                </tr>
              </thead>
              <tbody>
                {variables
                  .sortBy((value, key) =>
                    _toLower(key.startsWith("#") ? key.substring(1, key.length) : key)
                  )
                  .map((value, key) => {
                    const longValue = value.length > 40
                    const longKey = key.length > 40
                    return (
                      <tr key={key}>
                        <td>
                          {longKey ? (
                            <div>
                              <span data-tip data-for={`key-tooltip-${key}`}>
                                {shortenString(key, 40)}
                              </span>
                              <ReactTooltip
                                place="bottom"
                                id={`key-tooltip-${key}`}
                                className="variables-tooltip"
                              >
                                {key}
                              </ReactTooltip>
                            </div>
                          ) : (
                            key
                          )}
                        </td>
                        <td className="align-right">
                          {longValue ? (
                            <div>
                              <span data-tip data-for={`value-tooltip-${value}`}>
                                <span data-cy="value">{shortenString(value, 40)}</span>
                                <span
                                  className="var-copy-button"
                                  onClick={() => this.copyVariableToClipboard(value)}
                                >
                                  <FontAwesomeIcon icon={["fas", "copy"]} />
                                </span>
                              </span>
                              <ReactTooltip
                                place="bottom"
                                id={`value-tooltip-${value}`}
                                className="variables-tooltip"
                              >
                                {value}
                              </ReactTooltip>
                            </div>
                          ) : (
                            <React.Fragment>
                              <span data-cy="value">{value}</span>
                              <span
                                className="var-copy-button"
                                onClick={() => this.copyVariableToClipboard(value)}
                              >
                                <FontAwesomeIcon icon={["fas", "copy"]} />
                              </span>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    )
                  })
                  .toArray()}
              </tbody>
            </table>
          </Paper>
        )
      }
    }

    return null
  }

  renderVariablesForm = () => {
    const { form, deleteModal } = this.state
    const { isEditable } = this.props

    if (form.size) {
      return (
        <Paper hasHeader={true} className="variables-content">
          <form className="variables-form" autoComplete="off">
            <div className="labels">
              <p className="label">Keys *</p>
              <p className="label">Values</p>
            </div>
            {form.map((row, index) => (
              <div
                className={`text-field form-row ${row.get("error") ? "error" : ""}`}
                key={index}
                data-cy={`form-row-${index}`}
              >
                <input
                  type="text"
                  name="key"
                  value={row.get("key")}
                  onChange={this.handleVariablesRowChange(index, KEY_TYPE)}
                  placeholder="key"
                  disabled={!isEditable}
                />
                <input
                  type={_startsWith(row.get("key"), "#") ? "password" : "text"}
                  name="value"
                  value={row.get("value")}
                  onChange={this.handleVariablesRowChange(index, VALUE_TYPE)}
                  placeholder="value"
                  disabled={!isEditable}
                />
                <IconButton
                  color="red"
                  disabled={!isEditable}
                  aria-label="delete row"
                  onClick={this.removeVariablesRowModalOpen(index)}
                  type="button"
                  data-cy="delete-row"
                >
                  <FontAwesomeIcon icon={["far", "trash-alt"]} />
                </IconButton>
                {row.get("error") && <p className="error-message">Fill at least 'key' input</p>}
              </div>
            ))}
            <Button
              color="transparent-primary"
              onClick={this.addVariablesRow}
              disabled={!isEditable}
              type="button"
              size="small"
            >
              + Add more
            </Button>
          </form>
          <ConfirmModal
            open={deleteModal.get("open")}
            type={MODAL.TYPE.DELETE}
            handleClose={this.removeVariablesRowModalClose}
            handleConfirm={this.removeVariablesRow(deleteModal.get("rowIndex"))}
            title="Delete variable?"
            action="delete"
            what="variable"
            item={form.getIn([deleteModal.get("rowIndex"), "key"])}
          />
        </Paper>
      )
    } else {
      return (
        <Paper hasHeader={true} className="variables-content">
          <Button
            color="transparent-primary"
            size="small"
            onClick={this.addVariablesRow}
            disabled={!isEditable}
            type="button"
          >
            + Add more
          </Button>
        </Paper>
      )
    }
  }

  renderCodeEditor = () => {
    const { variablesJsonString, jsonError } = this.state
    return (
      <Paper hasHeader={true} className="variables-code-editor">
        <div className="ace-editor-wrapper">
          <AceEditor
            mode="json"
            theme="meiro"
            onChange={newValue => {
              this.setState({ variablesJsonString: newValue, jsonError: false })
            }}
            name="json_settings"
            value={variablesJsonString}
            editorProps={{ $blockScrolling: true }}
            setOptions={{ maxLines: 50 }}
            width="100%"
            wrapEnabled={true}
          />
        </div>
        {jsonError && (
          <p className="error-message">Must be a JSON object with key:value (string) pairs.</p>
        )}
      </Paper>
    )
  }

  isVariablesJsonStringValid = () => {
    const { variablesJsonString } = this.state
    if (isJSONObject(variablesJsonString)) {
      const variables = JSON.parse(variablesJsonString)
      let valid = true
      _forEach(variables, (value, key) => {
        // check if object has some complex values
        if (typeof value !== "string" || key.length === 0) {
          valid = false
        }
      })
      return valid
    } else if (variablesJsonString.length > 0) {
      return false
    }
    return true
  }

  toggleFormType = () => {
    const { formType, variablesJsonString, form } = this.state

    if (formType === "form") {
      const formToVariables = {}
      if (List.isList(form)) {
        form.forEach(value => {
          if (value.get("key")) {
            formToVariables[value.get("key")] = value.get("value")
          }
        })
      }
      this.setState({
        formType: "json",
        variablesJsonString: _isEmpty(formToVariables)
          ? "{}"
          : JSON.stringify(formToVariables, null, 2)
      })
    } else {
      if (this.isVariablesJsonStringValid()) {
        this.setState({
          formType: "form",
          form:
            variablesJsonString.length !== 0
              ? Map(JSON.parse(variablesJsonString))
                  .map((value, key) => Map({ key, value }))
                  .toList()
              : List([Map({ key: "", value: "" })])
        })
      } else {
        this.setState({
          jsonError: true
        })
      }
    }
  }

  copyVariablesJsonToClipboard = () => {
    if (!this.state.editMode) {
      const {
        showToastMessage,
        workspace: { variables }
      } = this.props
      const dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = JSON.stringify(variables.toJS(), null, 2)
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      showToastMessage("Variables JSON setting has been copied to clipboard", TOAST.TYPE.SUCCESS)
    }
  }

  copyVariableToClipboard = variable => {
    if (!this.state.editMode) {
      const { showToastMessage } = this.props
      const dummy = document.createElement("input")
      document.body.appendChild(dummy)
      dummy.value = variable
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      showToastMessage("Variable has been copied to clipboard", TOAST.TYPE.SUCCESS)
    }
  }

  render() {
    const { loading, formType } = this.state
    const {
      isEditable,
      editMode,
      toggleEditMode,
      readyToEdit,
      currentlyEditing = "",
      workspace: { variables }
    } = this.props

    const cardView = editMode ? "expanded" : "default"
    return (
      <div>
        <PaperHeader size="small" className="variables-header">
          <div className="title-wrapper">
            <h3>Variables </h3>
            {Map.isMap(variables) && variables.size > 0 && (
              <span
                className={`copy-button ${editMode ? "disabled" : ""}`}
                onClick={this.copyVariablesJsonToClipboard}
              >
                <FontAwesomeIcon icon={["fas", "copy"]} />
              </span>
            )}
          </div>
          <div className="right-part">
            {cardView === "default" && (
              <React.Fragment>
                <p className="variables-count">{this.renderVariablesMessage()}</p>
                <div className="vertical-line" />
                <Button
                  color="primary"
                  size="small"
                  disabled={!isEditable}
                  currentlyEditing={currentlyEditing}
                  tooltipId="managa-workspace-variables-tooltip-id"
                  type="button"
                  onClick={readyToEdit ? this.enableVariablesForm : _noop}
                  className={`manage-button ${!readyToEdit ? "cursor-wait" : ""}`}
                >
                  Manage variables
                </Button>
              </React.Fragment>
            )}
            {cardView === "expanded" && (
              <React.Fragment>
                <ToggleSwitch
                  name="variables-form-type"
                  width="7rem"
                  leftValue="form"
                  rightValue="json"
                  checked={formType}
                  handleToggle={this.toggleFormType}
                />
                <div className="vertical-line" />
                <Button color="link" onClick={toggleEditMode()} size="small" type="button">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="button"
                  size="small"
                  className={`save-button ${loading ? "loading" : ""}`}
                  disabled={!isEditable}
                  onClick={this.saveVariables}
                >
                  Save
                </Button>
              </React.Fragment>
            )}
          </div>
        </PaperHeader>
        {cardView === "expanded" && formType === "form" && this.renderVariablesForm()}
        {cardView === "expanded" && formType === "json" && this.renderCodeEditor()}
        {cardView === "default" && this.renderVariablesSummary()}
      </div>
    )
  }
}

VariablesCard.defaultProps = {
  readyToEdit: true
}

VariablesCard.propTypes = {
  workspace: PropTypes.instanceOf(Record).isRequired,
  isEditable: PropTypes.bool.isRequired,
  currentlyEditing: PropTypes.string,
  toggleEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showToastMessage: PropTypes.func.isRequired,
  readyToEdit: PropTypes.bool
}

export default VariablesCard
