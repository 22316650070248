import _get from "lodash/get"
import { api } from "api"
import { DAWG, TAG } from "sharedConstants"

export const fetchDawgList = (
  offset,
  limit,
  loadFullStructure = 1,
  orderBy = "id",
  orderDir = "DESC",
  nameFilter = "",
  tagIds = [],
  showEnabled = 1,
  showDisabled = 1
) => async dispatch =>
  dispatch({
    type: DAWG.ACTION.LIST,
    payload: {
      promise: (async () => {
        return await api().dawg.list(
          offset,
          limit,
          loadFullStructure,
          orderBy,
          orderDir,
          nameFilter,
          tagIds,
          showEnabled,
          showDisabled
        )
      })()
    }
  })

export const fetchLastDawgsJobs = (dawgIds, loadFullStructure) => async dispatch => {
  const response = await api().dawg.listLastJobs(dawgIds, loadFullStructure)
  return dispatch({
    type: DAWG.ACTION.LIST_LAST_JOBS,
    payload: _get(response, "last_dawg_jobs", [])
  })
}

export const createDawg = (data, loadFullStructure = 1) => async dispatch =>
  dispatch({
    type: DAWG.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api().dawg.create(data, loadFullStructure)
        return _get(response, "dawg", {})
      })()
    }
  })

export const modifyDawg = (id, data, originData = {}, loadFullStructure = 1) => async dispatch =>
  dispatch({
    type: DAWG.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().dawg.modify(id, data, originData, loadFullStructure)
        return _get(response, "dawg", {})
      })()
    }
  })

export const deleteDawg = id => async dispatch =>
  dispatch({
    type: DAWG.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api().dawg.delete(id)
        return id
      })()
    }
  })

export const retrieveDawg = (id, loadFullStructure = 1) => async dispatch =>
  dispatch({
    type: DAWG.ACTION.RETRIEVE,
    payload: {
      promise: (async () => {
        const response = await api().dawg.retrieve(id, loadFullStructure)
        return _get(response, "dawg", {})
      })()
    }
  })

export const assignTagToDawg = (dawgId, data, loadFullStructure = 1) => async dispatch =>
  dispatch({
    type: TAG.ACTION.ASSIGN_TAG_TO_DAWG,
    payload: {
      promise: (async () => {
        const response = await api().dawg.tag.assign(dawgId, data, loadFullStructure)
        return _get(response, "dawg_tag", {})
      })()
    }
  })

export const unassignTagFromDawg = (dawgId, tagId) => async dispatch =>
  dispatch({
    type: TAG.ACTION.DELETE_TAG_FROM_DAWG,
    payload: {
      promise: (async () => {
        await api().dawg.tag.delete(dawgId, tagId)
        return {
          dawg_id: dawgId,
          tag_id: tagId
        }
      })()
    }
  })
