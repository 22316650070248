import { CONFIGURATION } from "sharedConstants"
import ConfigurationModel from "models/configuration.model"
import { Map, fromJS } from "immutable"
import _toString from "lodash/toString"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"

/*
 * {
 *   isFetching: true/false
 *   data: {
 *     workspaceId: {
 *       isFulfilled: false,
 *       configurations: {
 *         confId: confObject, confId2, confObject2
 *       }
 *     }
 *   }
 * }
 */

const initialState = Map({
  isFetching: true,
  data: Map()
})

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case `${CONFIGURATION.ACTION.MODIFY}_PENDING`:
    case `${CONFIGURATION.ACTION.MODIFY_BULK}_PENDING`:
      return state.setIn(["data", _toString(meta.workspaceId), "isModifying"], true)

    case `${CONFIGURATION.ACTION.LIST}_PENDING`:
      return state.set("isFetching", true)

    case `${CONFIGURATION.ACTION.LIST}_FULFILLED`:
      return state.set("isFetching", false).set(
        "data",
        Map({
          [payload.workspaceId]: Map({
            isFulfilled: true,
            configurations: Map(
              _mapKeys(
                _map(payload.data, conf => new ConfigurationModel(fromJS(conf))),
                "id"
              )
            )
          })
        })
      )

    case `${CONFIGURATION.ACTION.CLONE}_FULFILLED`:
    case `${CONFIGURATION.ACTION.CREATE}_FULFILLED`:
    case `${CONFIGURATION.ACTION.RETRIEVE}_FULFILLED`:
      return state.setIn(
        ["data", _toString(payload.workspace_id), "configurations", _toString(payload.id)],
        new ConfigurationModel(fromJS(payload))
      )

    case `${CONFIGURATION.ACTION.MODIFY}_FULFILLED`:
      return state
        .setIn(
          ["data", _toString(payload.workspace_id), "configurations", _toString(payload.id)],
          new ConfigurationModel(fromJS(payload))
        )
        .setIn(["data", _toString(payload.workspace_id), "isModifying"], false)

    case `${CONFIGURATION.ACTION.MODIFY}_REJECTED`: {
      return state.setIn(["data", _toString(meta.workspaceId), "isModifying"], false)
    }

    case `${CONFIGURATION.ACTION.MODIFY_BULK}_FULFILLED`:
      const changedConfsMap = _mapKeys(
        _map(payload, conf => new ConfigurationModel(fromJS(conf))),
        "id"
      )
      return state
        .setIn(["data", _toString(meta.workspaceId), "isModifying"], false)
        .setIn(
          ["data", _toString(meta.workspaceId), "configurations"],
          state
            .getIn(["data", _toString(meta.workspaceId), "configurations"])
            .merge(changedConfsMap)
        )

    case `${CONFIGURATION.ACTION.DELETE}_FULFILLED`:
      return state.deleteIn([
        "data",
        _toString(payload.workspaceId),
        "configurations",
        _toString(payload.configurationId)
      ])

    default:
      return state
  }
}
