import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, FormSection } from "redux-form"
import PropTypes from "prop-types"
import _pick from "lodash/pick"

// ui components
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button"
import TextField from "components/UI/elements/TextField"

// helpers, constants
import { required } from "helpers/validators.helper"
import { TOAST } from "sharedConstants"

// actions
import { modifyOAuth } from "actions/oauth.action"
import { showToast } from "actions/toast.action"

class OAuthModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = values => {
    if (!this.state.loading) {
      const { modifyOAuth, showToast, handleClose, initialValues } = this.props
      this.setState({ loading: true })
      const oauth = _pick(values, ["name", "description", "credentials"])
      const previousOauth = _pick(initialValues, ["name", "description", "credentials"])
      modifyOAuth(values.id, oauth, previousOauth)
        .then(() => {
          handleClose()
          this.setState({ loading: false })
          showToast("OAuth repository has been modified.", TOAST.TYPE.SUCCESS)
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  closeWithoutSave = () => {
    const { handleClose, reset } = this.props
    reset()
    handleClose()
  }

  render() {
    const { open, handleSubmit } = this.props
    return (
      <Modal
        open={open}
        handleClose={this.closeWithoutSave}
        title="Modify OAuth repository"
        size="big"
      >
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-row">
            <Field
              name="name"
              component={TextField}
              label="Name *"
              placeholder="Name"
              validate={required}
              fullWidth={true}
              autoFocus
            />
          </div>
          <div className="form-row">
            <Field
              name="description"
              component={TextField}
              label="Description"
              fullWidth={true}
              placeholder="Description"
            />
          </div>
          <FormSection name="credentials">
            <div className="form-row">
              <Field
                name="client_id"
                component={TextField}
                label="Client ID *"
                validate={required}
                fullWidth={true}
                placeholder="Client ID"
              />
            </div>
            <div className="form-row">
              <Field
                name="#client_secret"
                component={TextField}
                label="Client Secret *"
                validate={required}
                fullWidth={true}
                placeholder="Client Secret"
                type="password"
              />
            </div>
          </FormSection>
          <Button
            type="submit"
            color="primary"
            size="big"
            fullWidth={true}
            className={this.state.loading ? "loading" : ""}
          >
            Modify
          </Button>
        </form>
      </Modal>
    )
  }
}

OAuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modifyOAuth: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

OAuthModal = reduxForm({
  form: "UserForm",
  touchOnBlur: false,
  enableReinitialize: true
})(connect(null, { modifyOAuth, showToast })(OAuthModal))

export default OAuthModal
