import React from "react"

const ArrayFieldReadOnlyTemplate = props => {
  return (
    <div className={`${props.className} custom-array-field`}>
      {props.title && (
        <h4 className="form-part-title not-collapsible array-title">{props.title}</h4>
      )}
      {props.items &&
        props.items.map(element => (
          <div key={element.index} className="custom-array-field-item">
            <div>{element.children}</div>
          </div>
        ))}
    </div>
  )
}

export default ArrayFieldReadOnlyTemplate
