import _get from "lodash/get"
import { api } from "api"
import { USER } from "sharedConstants"
import { history } from "components/App"
import { getRoutePath } from "routes"

export const login = data => async dispatch =>
  dispatch({
    type: USER.ACTION.LOGGED_IN,
    payload: {
      promise: (async () => {
        return await api().user.login(data)
      })()
    }
  })

export const oktaLogin = data => async dispatch =>
  dispatch({
    type: USER.ACTION.LOGGED_IN,
    payload: {
      promise: (async () => {
        return await api().user.oktaLogin(data)
      })()
    }
  })

export const refreshToken = () => async dispatch =>
  dispatch({
    type: USER.ACTION.REFRESH_TOKEN,
    payload: {
      promise: (async () => {
        return await api().user.refreshToken()
      })()
    }
  })

export const modifyAuthUser = (id, data) => async dispatch =>
  dispatch({
    type: USER.ACTION.MODIFY_AUTH_USER,
    payload: {
      promise: (async () => {
        const response = await api().user.modify(id, data)
        return _get(response, "user", {})
      })()
    }
  })

export const verifyUser = (token = "") => async dispatch => {
  if (!token) {
    dispatch({
      type: USER.ACTION.LOGGED_OUT
    })
    return
  }

  const { me } = await _userMe(token)
  if (me) {
    dispatch({
      type: USER.ACTION.RETRIEVE_LOGGED_IN_USER,
      payload: {
        ...me
      }
    })
  }

  return me
}

export const enableLogout = () => dispatch => {
  dispatch({
    type: USER.ACTION.ENABLE_LOGOUT
  })
}

export const disableLogout = () => dispatch => {
  dispatch({
    type: USER.ACTION.DISABLE_LOGOUT
  })
}

export const logout = (redirect = false) => dispatch => {
  if (redirect) {
    api().user.logout()
    history.push(getRoutePath("logout"))
  }
  dispatch({
    type: USER.ACTION.LOGGED_OUT
  })
}

export const me = (token = "") => async () => {
  return await _userMe(token)
}

export const setUiAttribute = (attribute, value) => dispatch =>
  dispatch({
    type: USER.ACTION.SET_UI_ATTRIBUTE,
    payload: {
      attribute,
      value
    }
  })

const _userMe = (token = "") => api().user.me(token)
