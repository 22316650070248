import { TOAST } from "sharedConstants"

const initialState = {
  id: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOAST.ACTION.SHOW:
      return {
        id: state.id + 1,
        timestamp: Date.now(),
        ...payload
      }
    default:
      return state
  }
}
