import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { Map } from "immutable"
import _noop from "lodash/noop"
import _get from "lodash/get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Waypoint } from "react-waypoint"
import ReactTooltip from "react-tooltip"
import _toString from "lodash/toString"

// constants, helpers
import { CONFIGURATION, TOAST } from "sharedConstants"
import { goBack } from "helpers/backButton.helper"
import { getRoutePath } from "routes"
import { formatBytes } from "helpers/dataUnits.helper"
import { BASE_API_URL } from "api"
import { shortenFilename } from "helpers/filename.helper"
import { getComponentIconSrc } from "helpers/component.helper"
import { copyStringToClipboard } from "helpers/string.helper"

// actions
import { fetchInDataPreviews, fetchOutDataPreviews } from "actions/dataPreview.action"
import { fetchConfigurationList } from "actions/configuration.action"
import { showToast } from "actions/toast.action"

// selectors
import { getConfigurationsDataPreviews } from "selectors/dataPreview.selector"
import {
  isConfigurationsFulfilled,
  getWorkspaceConfigurationsData
} from "selectors/configuration.selector"
import { getComponentsData } from "selectors/component.selector"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button"
import IconButton from "components/UI/elements/IconButton"
import { SearchFormUndestroyable } from "components/UI/components/SearchForm"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

import "./DataPreviewList.css"

class DataPreviewList extends PureComponent {
  metaKey = false

  componentDidMount() {
    const {
      fetchInDataPreviews,
      fetchOutDataPreviews,
      isConfigurationsFulfilled,
      fetchConfigurationList,
      filterValues,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    const nameFilter = _get(filterValues, "search", "")
    // fetch data previews only if it's not already fetched, files have
    // fluid id so it's not easily possible to detect changes (merge)
    if (type === "in") {
      fetchInDataPreviews(id, cid, 0, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT, nameFilter).catch(
        _noop
      )
    } else if (type === "out") {
      fetchOutDataPreviews(id, cid, 0, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT, nameFilter).catch(
        _noop
      )
    }
    if (!isConfigurationsFulfilled) {
      fetchConfigurationList(id).catch(_noop)
    }

    // command/ctrl key event handler
    window.addEventListener("keydown", this.handleKeyDown, false)
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    window.removeEventListener("keydown", this.handleKeyDown, false)
  }

  handleKeyUp = evt => {
    if (!evt.metaKey) {
      this.metaKey = false
    }
  }

  handleKeyDown = evt => {
    if (evt.metaKey) {
      this.metaKey = true
    }
  }

  loadMoreDataPreviews = () => {
    const {
      fetchInDataPreviews,
      fetchOutDataPreviews,
      dataPreviews,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    if (type === "in") {
      fetchInDataPreviews(
        id,
        cid,
        dataPreviews.getIn(["selectionSettings", "offset"]) +
          CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT,
        CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT,
        dataPreviews.getIn(["selectionSettings", "name_filter"])
      ).catch(_noop)
    } else if (type === "out") {
      fetchOutDataPreviews(
        id,
        cid,
        dataPreviews.getIn(["selectionSettings", "offset"]) +
          CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT,
        CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT,
        dataPreviews.getIn(["selectionSettings", "name_filter"])
      ).catch(_noop)
    }
  }

  renderWaypoint = () => {
    const { dataPreviews } = this.props
    if (!dataPreviews.get("isFetching") && dataPreviews.get("hasMoreItems")) {
      return <Waypoint onEnter={this.loadMoreDataPreviews} bottomOffset={-400} />
    }
  }

  goToFile = file => () => {
    const {
      history,
      location,
      configurationsData,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    if (this.metaKey) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
      window.open(
        publicUrl.origin +
          getRoutePath("workspace.configuration.data.preview", {
            id,
            cid,
            type,
            did: file.id
          }),
        "_blank"
      )
    } else {
      let configuration = null
      if (Map.isMap(configurationsData)) {
        configuration = configurationsData.get(_toString(file.parent_configuration_id))
      }
      history.push({
        pathname: getRoutePath("workspace.configuration.data.preview", {
          id,
          cid,
          type,
          did: file.id
        }),
        state: {
          file,
          previous: location.pathname,
          sourceConfiguration: configuration
        }
      })
    }
  }

  renderName = (fileName, parentConfigurationId = null, fileId) => {
    const { configurationsData, componentsData, showToast } = this.props
    const basicFilename = (
      <React.Fragment>
        <span data-tip data-for={`file-${fileId}`}>
          {shortenFilename(fileName, 50)}
          <span
            className="copy-button"
            onClick={evt => {
              evt.stopPropagation()
              copyStringToClipboard(fileName)
              showToast("Filename has been copied to clipboard.", TOAST.TYPE.SUCCESS)
            }}
          >
            <FontAwesomeIcon icon={["fas", "copy"]} />
          </span>
        </span>
        {fileName.length >= 50 && (
          <ReactTooltip id={`file-${fileId}`} place="bottom" className="filename-tooltip">
            {fileName}
          </ReactTooltip>
        )}
      </React.Fragment>
    )
    if (!parentConfigurationId || !Map.isMap(configurationsData) || !Map.isMap(componentsData)) {
      return basicFilename
    } else {
      const configuration = configurationsData.get(_toString(parentConfigurationId))
      if (!configuration) {
        return basicFilename
      } else {
        const component = componentsData.get(_toString(configuration.component_id))
        if (!component) {
          return basicFilename
        }
        const icon = component.icon ? component.icon : "dummy.png"
        return (
          <div className="conf-file-name">
            <img
              src={getComponentIconSrc(icon)}
              alt="component icon"
              data-tip
              data-for={`component-toltip-${configuration.id}`}
            />
            <div className="conf-file-name-wrapper">
              <span className="file-name" data-tip data-for={`file-${fileId}`}>
                {shortenFilename(fileName, 50)}
                <span
                  className="copy-button"
                  onClick={evt => {
                    evt.stopPropagation()
                    copyStringToClipboard(fileName)
                    showToast("Filename has been copied to clipboard.", TOAST.TYPE.SUCCESS)
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "copy"]} />
                </span>
              </span>
              {fileName.length >= 45 && (
                <ReactTooltip id={`file-${fileId}`} place="bottom" className="filename-tooltip">
                  {fileName}
                </ReactTooltip>
              )}
              <span className="conf-name">{configuration.name}</span>
            </div>
            <ReactTooltip id={`component-toltip-${configuration.id}`} place="bottom">
              {component.name}
            </ReactTooltip>
          </div>
        )
      }
    }
  }

  onSearchSubmit = filterName => {
    const {
      fetchInDataPreviews,
      fetchOutDataPreviews,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    if (type === "in") {
      fetchInDataPreviews(id, cid, 0, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT, filterName).catch(
        _noop
      )
    } else if (type === "out") {
      fetchOutDataPreviews(id, cid, 0, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT, filterName).catch(
        _noop
      )
    }
  }

  render() {
    const {
      dataPreviews,
      history,
      match: {
        params: { id, cid, type }
      }
    } = this.props
    return (
      <section className="files-list-section">
        {Map.isMap(dataPreviews) && (
          <React.Fragment>
            <PaperHeader size="small" className="files-list-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(
                    history,
                    getRoutePath("workspace.configuration.show", { id, cid })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Data {type}</h3>
              </div>
              <SearchFormUndestroyable
                placeholder="Search name"
                className="files-search"
                initialValues={{ search: "" }}
                onSubmit={this.onSearchSubmit}
                form="data-in-out-files-filter"
              />
              <Button
                color="primary"
                size="big"
                href={`${BASE_API_URL}/configuration_${
                  type === "in" ? "input" : "output"
                }_files?download_token=${dataPreviews.get("downloadToken")}`}
                download
                target="_blank"
              >
                <FontAwesomeIcon className="icon" icon={["far", "download"]} /> Download all
              </Button>
            </PaperHeader>
            {dataPreviews.has("data") && (
              <Paper hasHeader={true} className="files-list-content">
                {dataPreviews.get("data").size === 0 && (
                  <p className="no-files-message">No files have been found.</p>
                )}
                {dataPreviews.get("data").size > 0 && (
                  <React.Fragment>
                    <div className="total-data-info">
                      <span>
                        Files total: <strong>{dataPreviews.get("filesTotalCount")}</strong>
                      </span>
                      <span>
                        Total size:{" "}
                        <strong>{formatBytes(dataPreviews.get("totalDataVolume"))}</strong>
                      </span>
                    </div>
                    <div className="table table-content-clickable-row">
                      <div className="thead">
                        <div className="table-row">
                          <div className="table-head hover-overlap-helper">&nbsp;</div>
                          <div className="table-head">File Name</div>
                          <div className="table-head size-column">Size</div>
                          <div className="table-head rows-column">Rows</div>
                          <div className="table-head last-modified-column align-right">
                            Last modified
                          </div>
                          <div className="table-head action-column">&nbsp;</div>
                          <div className="table-head hover-overlap-helper">&nbsp;</div>
                        </div>
                      </div>
                      <div className="tbody">
                        {dataPreviews
                          .get("data")
                          .map(file => (
                            <div className="table-row" key={file.id} onClick={this.goToFile(file)}>
                              <div className="table-cell hover-overlap-helper">&nbsp;</div>
                              <div className="table-cell file-name">
                                {this.renderName(
                                  file.file_name,
                                  file.parent_configuration_id,
                                  file.id
                                )}
                              </div>
                              <div className="table-cell">{formatBytes(file.file_size)}</div>
                              <div className="table-cell">{file.file_rows_count}</div>
                              <div className="table-cell align-right">
                                <DateTimeWithTooltip
                                  dateTime={file.last_modified_date}
                                  uniqueTooltipId={`data-preview-${file.id}-tooltip`}
                                />
                              </div>
                              <div className="table-cell align-right">
                                <IconButton
                                  color="primary"
                                  type="anchor"
                                  href={`${BASE_API_URL}/configuration_file?file_download_token=${file.file_download_token}`}
                                  onClick={evt => {
                                    evt.stopPropagation()
                                  }}
                                  download
                                  target="_blank"
                                  className="download-button"
                                >
                                  <FontAwesomeIcon icon={["far", "download"]} />
                                </IconButton>
                              </div>
                              <div className="table-cell hover-overlap-helper">&nbsp;</div>
                            </div>
                          ))
                          .toArray()}
                      </div>
                    </div>
                    {this.renderWaypoint()}
                  </React.Fragment>
                )}
              </Paper>
            )}
          </React.Fragment>
        )}
      </section>
    )
  }
}

DataPreviewList.propTypes = {
  dataPreviews: PropTypes.instanceOf(Map),
  isConfigurationsFulfilled: PropTypes.bool.isRequired,
  configurationsData: PropTypes.instanceOf(Map),
  componentsData: PropTypes.instanceOf(Map),
  fetchInDataPreviews: PropTypes.func.isRequired,
  fetchOutDataPreviews: PropTypes.func.isRequired,
  fetchConfigurationList: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id, cid, type }
    }
  } = ownProps
  return {
    dataPreviews: getConfigurationsDataPreviews(state, cid, type),
    isConfigurationsFulfilled: isConfigurationsFulfilled(state),
    configurationsData: getWorkspaceConfigurationsData(state, id),
    componentsData: getComponentsData(state),
    filterValues: getFormValues("data-in-out-files-filter")(state)
  }
}

export default connect(mapStateToProps, {
  fetchInDataPreviews,
  fetchOutDataPreviews,
  fetchConfigurationList,
  showToast
})(DataPreviewList)
