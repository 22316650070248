import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _noop from "lodash/noop"
import _get from "lodash/get"
import { Map } from "immutable"
import moment from "moment"

// selectors
import { getOAuthRepositoriesFuseData } from "selectors/oauth.selector"

//actions
import { fetchOAuthList } from "actions/oauth.action"

// ui components
import SearchForm from "components/UI/components/SearchForm"
import OAuthModal from "./OAuthModal"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import IconButton from "components/UI/elements/IconButton"

// constants
import { MOMENT } from "sharedConstants"

import "./OAuthList.css"

class OAuthList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      oauthModal: Map({
        open: false,
        repository: null
      })
    }
  }

  componentDidMount() {
    this.props.fetchOAuthList().catch(_noop)
  }

  openOauthModal = repository => () => {
    this.setState(prevState => ({
      oauthModal: prevState.oauthModal.set("open", true).set("repository", repository.toJS())
    }))
  }

  closeOauthModal = () => {
    this.setState(prevState => ({
      oauthModal: prevState.oauthModal.set("open", false)
    }))
  }

  render() {
    const { repositories, searchValues } = this.props
    const { oauthModal } = this.state
    const filterTerm = _get(searchValues, "search", "")

    let repositoriesList = filterTerm
      ? repositories
          .get("data")
          .search(filterTerm)
          .toArray()
      : repositories.get("data").list.toArray()

    return (
      <section className="oauth-list">
        <PaperHeader className="oauth-list-header">
          <h3>OAuth repositories</h3>
          <SearchForm
            placeholder="Search for name"
            className="oauth-search"
            initialValues={{ search: "" }}
          />
        </PaperHeader>
        <Paper hasHeader={true}>
          <table className="table oauths">
            <thead>
              <tr>
                <th>Name</th>
                <th className="align-right">Last modified</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {repositoriesList.map(repository => (
                <tr key={repository.id}>
                  <td className="bigger-font">{repository.name}</td>
                  <td className="text-grey align-right">
                    {moment
                      .utc(repository.created)
                      .local()
                      .format(MOMENT.DATE_FORMAT)}
                  </td>
                  <td className="align-right action-column one-icon">
                    <IconButton
                      color="yellow"
                      onClick={this.openOauthModal(repository)}
                      data-cy="edit-oauth-button"
                    >
                      <FontAwesomeIcon icon={["far", "edit"]} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
        <OAuthModal
          open={oauthModal.get("open")}
          initialValues={oauthModal.get("repository")}
          handleClose={this.closeOauthModal}
        />
      </section>
    )
  }
}

OAuthList.propTypes = {
  fetchOAuthList: PropTypes.func.isRequired,
  repositories: PropTypes.instanceOf(Map).isRequired
}

const mapStateToProps = state => ({
  repositories: getOAuthRepositoriesFuseData(state),
  searchValues: getFormValues("SearchForm")(state)
})

export default connect(mapStateToProps, { fetchOAuthList })(OAuthList)
