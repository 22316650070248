import React, { PureComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import moment from "moment"
import TimeAgo from "react-timeago"

// helpers, contants
import { goBackInHistory } from "helpers/backButton.helper"
import { getRoutePath } from "routes"
import PendingPromise from "helpers/pendingPromise.helper"
import { api } from "api"
import Username from "helpers/Username.helper"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button"
import StatusElement from "components/UI/elements/StatusElement"

class DawgJobHistory extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      historyItem: _get(this.props, "location.state.historyItem", null)
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      history,
      location,
      match: {
        params: { id, aid, hid }
      }
    } = this.props

    if (!this.state.historyItem) {
      const historyItemRequest = this.pendingPromises.create(
        api().dawg.job.history.retrieve(id, aid, hid)
      )
      historyItemRequest.promise
        .then(response => {
          this.setState({
            historyItem: response.history
          })
          this.pendingPromises.remove(historyItemRequest)
        })
        .catch(() => {
          this.pendingPromises.remove(historyItemRequest)
        })
    } else {
      // erase state, otherwise page reload will not re-fetch activity detail history
      history.replace({
        pathname: location.path,
        state: {
          ...this.props.location.state,
          historyItem: null
        }
      })
    }
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
  }

  render() {
    const {
      history,
      match: {
        params: { id, aid }
      }
    } = this.props
    const { historyItem } = this.state

    return (
      <section className="activity-detail-history-page">
        {historyItem && (
          <React.Fragment>
            <PaperHeader className="activity-detail-history" size="small">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBackInHistory(
                    history,
                    getRoutePath("dawg.dawgJob.show", {
                      id,
                      aid
                    })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Activity detail history</h3>
              </div>
            </PaperHeader>
            <Paper className="activity-detail-history-content" hasHeader={true}>
              <table className="table history-item-summary">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th className="align-right">User</th>
                    <th className="align-right modified">Modified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <StatusElement
                        align="left"
                        status={historyItem.dawg_job.status}
                        showDuration={false}
                      />
                    </td>
                    <td className="align-right">
                      <Username userId={historyItem.dawg_job.user_id} />
                    </td>
                    <td className="align-right">
                      <TimeAgo
                        date={moment
                          .utc(historyItem.dawg_job.created)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="label">Settings</p>
              <div className="pre-wrapper">
                <pre>{JSON.stringify(historyItem.dawg_job.settings, null, 2)}</pre>
              </div>
              <p className="label">Stats</p>
              <div className="pre-wrapper">
                <pre>{JSON.stringify(historyItem.dawg_job.stats, null, 2)}</pre>
              </div>
            </Paper>
          </React.Fragment>
        )}
      </section>
    )
  }
}

export default DawgJobHistory
