import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { List, Record } from "immutable"
import { connect } from "react-redux"

// actions
import { listWorkspaceConfigurationJob } from "actions/configurationJob.action"

// selectors
import {
  getWorkspaceConfJobs,
  getWorkspaceConfJobsSelectionSettings
} from "selectors/workspaceConfigurationJob.selector"

import EntityChildrenJobsList from "components/UI/DawgWsShared/EntityChildrenJobsList"

class WorkspaceConfigurationJobList extends PureComponent {
  render() {
    const { jobs, selectionSettings, listWorkspaceConfigurationJob } = this.props
    return (
      <EntityChildrenJobsList
        jobs={jobs}
        selectionSettings={selectionSettings}
        listEntityChildrenJobs={listWorkspaceConfigurationJob}
        rootEntityName="workspace"
      />
    )
  }
}

WorkspaceConfigurationJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  listWorkspaceConfigurationJob: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  jobs: getWorkspaceConfJobs(state, ownProps.match.params.id),
  selectionSettings: getWorkspaceConfJobsSelectionSettings(state, ownProps.match.params.id)
})

export default connect(mapStateToProps, { listWorkspaceConfigurationJob })(
  WorkspaceConfigurationJobList
)
