import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Record } from "immutable"

// actions
import { retrieveDawg } from "actions/dawg.action"
import { retrieveDawgJob, cancelDawgJob } from "actions/dawgJob.action"

// selectors
import { getDawgRecord } from "selectors/dawg.selector"
import { getDawgJobData } from "selectors/dawgJob.selector"
import { getUsersDawgAcl } from "selectors/usersAcl.selector"

// ui components
import JobDetail from "components/UI/DawgWsShared/JobDetail"

class DawgJobShow extends PureComponent {
  render() {
    const { dawg, dawgJob, usersAcl, retrieveDawg, retrieveDawgJob, cancelDawgJob } = this.props
    return (
      <JobDetail
        entityName="dawg"
        entity={dawg}
        entityJob={dawgJob}
        usersAcl={usersAcl}
        retrieveEntity={retrieveDawg}
        retrieveEntityJob={retrieveDawgJob}
        cancelEntityJob={cancelDawgJob}
      />
    )
  }
}

DawgJobShow.propTypes = {
  dawg: PropTypes.instanceOf(Record),
  dawgJob: PropTypes.instanceOf(Record),
  usersAcl: PropTypes.instanceOf(Record),
  retrieveDawg: PropTypes.func.isRequired,
  retrieveDawgJob: PropTypes.func.isRequired,
  cancelDawgJob: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id, aid }
    }
  } = ownProps
  return {
    dawg: getDawgRecord(state, id),
    dawgJob: getDawgJobData(state, aid),
    usersAcl: getUsersDawgAcl(state, id)
  }
}

export default connect(mapStateToProps, {
  retrieveDawg,
  retrieveDawgJob,
  cancelDawgJob
})(DawgJobShow)
