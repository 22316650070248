import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Record } from "immutable"

// actions
import {
  listWorkspaceJob,
  refreshLastWorkspaceJob,
  cancelWorkspaceJob
} from "actions/workspaceJob.action"
import { showToast } from "actions/toast.action"

// selectors
import {
  getWorkspaceJobsData,
  getWorkspaceJobsSelectionSettings,
  haveWorkspaceJobsMoreItems,
  isWorkspaceJobsFetching
} from "selectors/workspaceJob.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

import JobList from "components/UI/DawgWsShared/JobList"

class WorkspaceJobList extends PureComponent {
  render() {
    const {
      jobs,
      usersAcl,
      listWorkspaceJob,
      refreshLastWorkspaceJob,
      cancelWorkspaceJob,
      selectionSettings,
      hasMoreJobs,
      isLoading,
      showToast
    } = this.props

    return (
      <JobList
        jobs={jobs}
        selectionSettings={selectionSettings}
        hasMoreJobs={hasMoreJobs}
        usersAcl={usersAcl}
        isLoading={isLoading}
        listEntityJob={listWorkspaceJob}
        refreshLastEntityJob={refreshLastWorkspaceJob}
        cancelEntityJob={cancelWorkspaceJob}
        showToast={showToast}
        entityName="workspace"
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    jobs: getWorkspaceJobsData(state),
    selectionSettings: getWorkspaceJobsSelectionSettings(state),
    hasMoreJobs: haveWorkspaceJobsMoreItems(state),
    isLoading: isWorkspaceJobsFetching(state),
    usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id)
  }
}

WorkspaceJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  hasMoreJobs: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  isLoading: PropTypes.bool.isRequired,
  listWorkspaceJob: PropTypes.func.isRequired,
  refreshLastWorkspaceJob: PropTypes.func.isRequired,
  cancelWorkspaceJob: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  listWorkspaceJob,
  refreshLastWorkspaceJob,
  cancelWorkspaceJob,
  showToast
})(WorkspaceJobList)
