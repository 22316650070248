import _toInteger from "lodash/toInteger"
import { createSelector } from "reselect"
import { List } from "immutable"

export const getWorkspaceJobsData = state => {
  if (state.workspaceJobs !== null) {
    return state.workspaceJobs.get("data")
  }
  return null
}

export const getWorkspaceJobsSelectionSettings = state => {
  if (state.workspaceJobs !== null) {
    return state.workspaceJobs.get("selectionSettings")
  }
  return null
}

export const haveWorkspaceJobsMoreItems = state => {
  if (state.workspaceJobs !== null) {
    return state.workspaceJobs.get("hasMoreItems") === true
  }
  return false
}

export const getWorkspaceJobData = (state, jobId) => {
  const data = getWorkspaceJobsData(state)
  if (List.isList(data)) {
    const jobIdInt = _toInteger(jobId)
    return data.find(job => job.id === jobIdInt)
  }
  return null
}

export const isWorkspaceJobsFetching = state => {
  if (state.workspaceJobs !== null) {
    return state.workspaceJobs.get("isFetching") === true
  }
  return true
}

export const getLastWorkspaceJobsData = createSelector(getWorkspaceJobsData, data => {
  if (List.isList(data)) {
    return data.slice(0, 3)
  }
  return null
})
