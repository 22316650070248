import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Prompt } from "react-router-dom"
import _noop from "lodash/noop"
import _toInteger from "lodash/toInteger"
import _toString from "lodash/toString"
import _includes from "lodash/includes"
import _get from "lodash/get"
import { Record, Map, List } from "immutable"

// ui components
import Header from "./Header"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Modal from "components/UI/elements/Modal"
import CloneModal from "./CloneModal"
import DescriptionCard from "components/UI/components/DescriptionCard"
import ConfigurationJobsCard from "./cards/Jobs"
import SettingsFormCard from "./cards/SettingsForm"
import CodeEditorCard from "./cards/CodeEditor"
import DataOutCard from "./cards/DataOut"
import DataInCard from "./cards/DataIn"
import VariablesInjection from "./cards/VariablesInjection"

// actions
import {
  fetchConfigurationList,
  modifyConfiguration,
  deleteConfiguration
} from "actions/configuration.action"
import { showToast } from "actions/toast.action"
import {
  runConfigurationJob,
  listConfigurationJob,
  refreshLastConfigurationJob,
  cancelConfigurationJob
} from "actions/configurationJob.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { enableLogout, disableLogout } from "actions/authenticatedUser.action"
import { clearCachedConfigurationJobs } from "actions/configurationJob.action"
import { clearCachedWorkspaceJobs } from "actions/workspaceJob.action"
import { clearCachedWorkspaceHistory } from "actions/workspaceHistory.action"
import { clearCachedConfigurationHistory } from "actions/configurationHistory.action"
import {
  fetchInDataPreviews,
  fetchOutDataPreviews,
  resetFilterForm
} from "actions/dataPreview.action"

// selectors
import { getWorkspaceRecord } from "selectors/workspace.selector"
import {
  getWorkspaceConfigurationData,
  getWorkspaceConfigurationsData,
  isConfigurationsFulfilled,
  getIsConfigurationsFetching
} from "selectors/configuration.selector"
import { getLastConfigurationJobsData } from "selectors/configurationJob.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"
import { getComponentsData } from "selectors/component.selector"
import { getConfigurationsDataPreviews } from "selectors/dataPreview.selector"

//helpers
import { getRoutePath } from "routes"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import { goBackInHistory } from "helpers/backButton.helper"
import { getUndefinedWorkspaceVariables } from "helpers/validators.helper"

// constants
import { MODAL, INTERVAL, TOAST, CONFIGURATION } from "sharedConstants"

import "./ConfigurationShow.css"

const initialState = {
  confirmModalVariables: Map({
    open: false,
    type: "",
    handleClose: () => {},
    handleConfirm: () => {},
    title: "",
    action: "",
    what: "",
    isLoading: false,
    text: "",
    htmlText: []
  }),
  cloneModalOpen: false,
  outDataPreviews: null,
  lastConfigurationsStatuses: Map({
    isFulfilled: false,
    data: Map()
  }),
  editModes: Map({
    configurationName: false,
    description: false,
    settings: false,
    codeEditor: false,
    dataIn: false,
    variables: false
  }),
  undefinedPlaceholdersModal: Map({
    open: false,
    placeholders: ""
  })
}

class ConfigurationShow extends PureComponent {
  runningCalls = {
    lastConfigurationJobs: false,
    configurationsLastJobs: false
  }

  constructor(props) {
    super(props)
    this.state = initialState
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const { location, showToast } = this.props
    this._init()
    // reset filter by name form in data in/out files
    this.props.resetFilterForm()

    // check OAuth error
    const urlParams = new URLSearchParams(location.search)
    const errorMessage = urlParams.get("error_message")
    if (errorMessage) {
      showToast(errorMessage, TOAST.TYPE.ERROR)
    }
  }

  componentWillUnmount() {
    this._cleanup()
  }

  _cleanup(clearState = false, clearCache = false) {
    this.props.enableLogout()
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
    this.runningCalls = {
      lastConfigurationJobs: false,
      configurationsLastJobs: false
    }

    if (clearState) {
      this.setState(initialState)
    }

    if (clearCache) {
      this.props.clearCachedConfigurationJobs()
      this.props.clearCachedWorkspaceJobs()
      this.props.clearCachedWorkspaceHistory()
      this.props.clearCachedConfigurationHistory()
    }
  }

  _init() {
    const { fetchConfigurationList, listConfigurationJob } = this.props
    const { id: workspaceId, cid: configurationId } = this.props.match.params

    fetchConfigurationList(workspaceId, 0).then(this.fetchLastConfigurationsJobs).catch(_noop)

    this._fetchBothLiveDataPreviews()

    this.runningCalls.lastConfigurationJobs = true
    listConfigurationJob(workspaceId, configurationId, 0, CONFIGURATION.JOB.LOADING_LIMIT, 0)
      .then(() => {
        this.runningCalls.lastConfigurationJobs = false
      })
      .catch(() => {
        this.runningCalls.lastConfigurationJobs = false
      })

    this.intervalId = setInterval(() => {
      this.refreshLastConfigurationJob()
      this.fetchLastConfigurationsJobs()
    }, INTERVAL.CONFIGURATION_SHOW)
  }

  componentDidUpdate(prevProps) {
    // refetch live data previews when configuration job finishes
    if (this.props.lastConfigurationJobs !== prevProps.lastConfigurationJobs) {
      if (
        List.isList(this.props.lastConfigurationJobs) &&
        List.isList(prevProps.lastConfigurationJobs)
      ) {
        const previousLastJob = prevProps.lastConfigurationJobs.get(0)
        const currentLastJob = this.props.lastConfigurationJobs.get(0)

        if (
          previousLastJob &&
          currentLastJob &&
          _includes(["waiting", "running"], previousLastJob.status) &&
          _includes(["canceled", "finished", "warning", "error"], currentLastJob.status)
        ) {
          this.fetchOutputLiveDataPreviews(0)
        }
      }
    }

    // component stays rendered, configuration change
    if (this.props.match.params.cid !== prevProps.match.params.cid) {
      // do component's data cleanup && also cache cleanup (conf/ws jobs)
      this._cleanup(true, true)
      this._init()
    }
  }

  modifyConfiguration = async (parameters, originParameters = {}, message = "") => {
    const { id: workspaceId, cid: configurationId } = this.props.match.params
    const { modifyConfiguration, showToast } = this.props
    await modifyConfiguration(workspaceId, configurationId, parameters, originParameters, 0).then(
      () => {
        showToast(message ? message : "Configuration has been modified.", TOAST.TYPE.SUCCESS)
      }
    )
  }

  deleteConfiguration = () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { id: workspaceId, cid: configurationId } = this.props.match.params
      const { deleteConfiguration, showToast, history } = this.props

      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      deleteConfiguration(workspaceId, configurationId)
        .then(() => {
          showToast("Configuration has been deleted.", TOAST.TYPE.SUCCESS)
          history.push(getRoutePath("workspace.show", { id: workspaceId }))
        })
        .catch(() => {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  toggleDeleteModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.intervalId = setInterval(() => {
        this.refreshLastConfigurationJob()
        this.fetchLastConfigurationsJobs()
      }, INTERVAL.CONFIGURATION_SHOW)
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this._cleanup()
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.DELETE)
          .set("handleClose", this.toggleDeleteModal)
          .set("handleConfirm", this.deleteConfiguration)
          .set("title", "Delete configuration")
          .set("action", "delete")
          .set("what", "configuration")
          .set("text", "")
          .set("htmlText", [])
      }))
    }
  }

  toggleConfigurationCancelModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.CANCEL)
          .set("handleClose", this.toggleConfigurationCancelModal)
          .set("handleConfirm", this.cancelConfigurationJob)
          .set("title", "Cancel configuration")
          .set("action", "cancel")
          .set("what", "configuration job")
          .set("text", "")
          .set("htmlText", [])
      }))
    }
  }

  cancelConfigurationJob = async () => {
    const {
      lastConfigurationJobs,
      cancelConfigurationJob,
      showToast,
      match: {
        params: { id, cid }
      }
    } = this.props
    if (List.isList(lastConfigurationJobs) && lastConfigurationJobs.size > 0) {
      const job = lastConfigurationJobs.get(0)
      this.runningCalls.lastConfigurationJobs = true
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      try {
        await cancelConfigurationJob(id, cid, job.id)
        showToast("Configuration job is being cancelled.", TOAST.TYPE.SUCCESS)
        this.runningCalls.lastConfigurationJobs = false
        this.toggleConfigurationCancelModal()
      } catch (err) {
        this.runningCalls.lastConfigurationJobs = false
        this.setState(prevState => ({
          confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
        }))
      }
    } else {
      this.toggleConfigurationCancelModal()
    }
  }

  runConfiguration = () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { showToast, runConfigurationJob } = this.props
      const { id: workspaceId, cid: configurationId } = this.props.match.params

      this.runningCalls.lastConfigurationJobs = true
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      runConfigurationJob(workspaceId, configurationId)
        .then(response => {
          this.runningCalls.lastConfigurationJobs = false
          showToast(
            "Configuration job has started.",
            TOAST.TYPE.SUCCESS,
            getRoutePath("workspace.configuration.configurationJob.show", {
              id: workspaceId,
              cid: configurationId,
              aid: response.value.job.id
            })
          )
          this.toggleRunModal()
        })
        .catch(() => {
          this.runningCalls.lastConfigurationJobs = false
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  toggleRunModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      const { workspace, configuration } = this.props
      let htmlText = [<p key="0">Do you really want to run this configuration?</p>]
      if (workspace && configuration) {
        const undefinedPlaceholders = getUndefinedWorkspaceVariables(
          Map.isMap(configuration.settings) ? configuration.settings.toJS() : {},
          workspace.variables
        )
        if (undefinedPlaceholders.length > 0) {
          htmlText = [
            <p key="0">Do you really want to run this configuration?</p>,
            <h4 key="1" className="notice">
              Notice:
            </h4>,
            <p key="2">
              Configuration contains placeholder(s) undefined in workspace variable(s):{" "}
              <strong>{undefinedPlaceholders.join(", ")}</strong>.
            </p>
          ]
        }
      }

      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.SUCCESS)
          .set("handleClose", this.toggleRunModal)
          .set("handleConfirm", this.runConfiguration)
          .set("title", "Run configuration")
          .set("text", "")
          .set("htmlText", htmlText)
      }))
    }
  }

  toggleDataEraseModal = () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", MODAL.TYPE.ERASE)
          .set("handleClose", this.toggleDataEraseModal)
          .set("handleConfirm", this.eraseConfigurationData)
          .set("title", "Erase configuration data")
          .set("text", "Do you really want to erase all configuration data?")
          .set("htmlText", [])
      }))
    }
  }

  eraseConfigurationData = async () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const {
        showToast,
        match: {
          params: { id, cid }
        }
      } = this.props
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      const eraseConfigurationDataRequest = this.pendingPromises.create(
        api().configuration.data.delete(id, cid)
      )
      try {
        await eraseConfigurationDataRequest.promise
        await this.fetchOutputLiveDataPreviews(0)
        showToast("Configuration data has been erased.", TOAST.TYPE.SUCCESS)
        this.toggleDataEraseModal()
      } catch (err) {
        if (!_get(err, "isCanceled")) {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        }
      } finally {
        this.pendingPromises.remove(eraseConfigurationDataRequest)
      }
    }
  }

  refreshLastConfigurationJob = () => {
    if (!this.runningCalls.lastConfigurationJobs) {
      this.runningCalls.lastConfigurationJobs = true
      const {
        refreshLastConfigurationJob,
        match: {
          params: { id, cid }
        }
      } = this.props
      refreshLastConfigurationJob(id, cid, 0)
        .then(() => {
          this.runningCalls.lastConfigurationJobs = false
        })
        .catch(() => {
          this.runningCalls.lastConfigurationJobs = false
        })
    }
  }

  fetchInputLiveDataPreviews = async offset => {
    const {
      fetchInDataPreviews,
      match: {
        params: { id, cid }
      }
    } = this.props
    await fetchInDataPreviews(id, cid, offset, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT).catch(
      _noop
    )
  }

  fetchOutputLiveDataPreviews = async offset => {
    const {
      fetchOutDataPreviews,
      match: {
        params: { id, cid }
      }
    } = this.props
    await fetchOutDataPreviews(id, cid, offset, CONFIGURATION.DATA_PREVIEW.LOADING_LIMIT).catch(
      _noop
    )
  }

  _fetchBothLiveDataPreviews = () => {
    this.fetchInputLiveDataPreviews(0)
    this.fetchOutputLiveDataPreviews(0)
  }

  fetchLastConfigurationsJobs = async () => {
    if (!this.runningCalls.configurationsLastJobs) {
      const {
        configuration,
        match: {
          params: { id }
        }
      } = this.props
      const { lastConfigurationsStatuses } = this.state
      if (configuration) {
        const inputs = configuration.getIn(["input_settings", "inputs"])
        if (Map.isMap(inputs)) {
          this.runningCalls.configurationsLastJobs = true
          const [...configurationIds] = inputs.keys()
          const lastConfJobsPromise = this.pendingPromises.create(
            api().workspace.configuration.listLastJobs(
              id,
              configurationIds.map(id => _toInteger(id))
            )
          )
          try {
            const response = await lastConfJobsPromise.promise
            if (response.last_configuration_jobs.length > 0) {
              const currentStatuses = Map(
                response.last_configuration_jobs.reduce((returnObj, job) => {
                  returnObj[job.configuration_id] = _get(job, "last_job.status")
                  return returnObj
                }, {})
              )

              if (!lastConfigurationsStatuses.get("data").equals(currentStatuses)) {
                if (lastConfigurationsStatuses.get("isFulfilled")) {
                  this.fetchInputLiveDataPreviews(0)
                }
                this.setState({
                  lastConfigurationsStatuses: Map({
                    isFulfilled: true,
                    data: currentStatuses
                  })
                })
              }
            }
          } catch (err) {
          } finally {
            this.runningCalls.configurationsLastJobs = false
            this.pendingPromises.remove(lastConfJobsPromise)
          }
        }
      }
    }
  }

  toggleCloneModal = () => {
    this.setState(prevState => ({
      cloneModalOpen: !prevState.cloneModalOpen
    }))
  }

  resetOauth = async () => {
    await this.modifyConfiguration({ oauth_settings: {} }, {}, "OAuth has been reset.")
  }

  onSettingsFormError = errors => {
    const { showToast } = this.props
    const errorsCount = errors.length
    showToast(
      `Validation failed: ${errorsCount} ${errorsCount > 1 ? "errors" : "error"} occurred.`,
      TOAST.TYPE.ERROR
    )
  }

  onSettingsFormSubmit = async data => {
    const { workspace, configuration } = this.props
    const res = await this.modifyConfiguration(
      data,
      {
        settings: Map.isMap(configuration.settings)
          ? configuration.settings.toJS()
          : configuration.settings
      },
      "Configuration's setting has been modified."
    )
    if (workspace) {
      const undefinedPlaceholders = getUndefinedWorkspaceVariables(
        data.settings,
        workspace.variables
      )
      if (undefinedPlaceholders.length > 0) {
        this.setState(prevState => ({
          undefinedPlaceholdersModal: prevState.undefinedPlaceholdersModal
            .set("open", true)
            .set("placeholders", undefinedPlaceholders.join(", "))
        }))
      }
    }
    return res
  }

  onWorkspaceVariablesInjectionSubmit = async data => {
    const { configuration } = this.props
    return await this.modifyConfiguration(
      data,
      {
        settings: Map.isMap(configuration.settings)
          ? configuration.settings.toJS()
          : configuration.settings
      },
      "Configuration's workspace variables have been injected."
    )
  }

  onJsonInvalidMessage = () => {
    this.props.showToast("JSON is not valid.", TOAST.TYPE.ERROR)
  }

  onCodeEditorsSaveSubmit = async data => {
    const { configuration } = this.props
    await this.modifyConfiguration(
      data,
      {
        settings: Map.isMap(configuration.settings)
          ? configuration.settings.toJS()
          : configuration.settings
      },
      "Code has been saved."
    )
  }

  onConfigurationInputsSubmit = async data => {
    const { configuration } = this.props
    const res = await this.modifyConfiguration(
      {
        input_settings: configuration.input_settings.merge({ inputs: data }).toJS()
      },
      {
        input_settings: configuration.input_settings.toJS()
      },
      "Filters have been modified."
    )
    this._fetchBothLiveDataPreviews()
    return res
  }

  undefinedPlaceholdersModalClose = () => {
    this.setState(prevState => ({
      undefinedPlaceholdersModal: prevState.undefinedPlaceholdersModal.set("open", false)
    }))
  }

  toggleEditMode =
    editMode =>
    (cb = _noop) =>
    () => {
      this.setState(
        prevState => ({
          editModes: prevState.editModes.set(editMode, !prevState.editModes.get(editMode))
        }),
        () => {
          let isEditing = false
          this.state.editModes.forEach(mode => {
            if (mode) {
              isEditing = true
            }
          })

          if (isEditing) {
            this.props.disableLogout()
          } else {
            this.props.enableLogout()
          }
          cb()
        }
      )
    }

  onCopyAuthLinkSuccessMessage = () => {
    this.props.showToast("Authorization link has been copied to clipboard.", TOAST.TYPE.SUCCESS)
  }

  render() {
    const {
      configuration,
      configurations,
      usersAcl,
      components,
      lastConfigurationJobs,
      isConfigurationsFulfilled,
      isConfigurationsFetching,
      history,
      inDataPreviews,
      outDataPreviews,
      workspace,
      showToast
    } = this.props
    const {
      confirmModalVariables,
      cloneModalOpen,
      lastConfigurationsStatuses,
      editModes,
      undefinedPlaceholdersModal
    } = this.state
    const { id: workspaceId } = this.props.match.params
    const isEditable = hasWritePermission(usersAcl)
    let currentlyEditing = ""
    editModes.forEach((mode, key) => {
      if (mode) {
        switch (key) {
          case "configurationName": {
            currentlyEditing = "configuration name"
            break
          }
          case "description": {
            currentlyEditing = "configuration description"
            break
          }
          case "settings": {
            currentlyEditing = "configuration settings"
            break
          }
          case "codeEditor": {
            currentlyEditing = "configuration code"
            break
          }
          case "dataIn": {
            currentlyEditing = "configuration data in filters"
            break
          }
          case "variables": {
            currentlyEditing = "workspace variables injection"
            break
          }
          default:
            currentlyEditing = ""
        }
      }
    })

    let hasVariablesInjectionCard = false
    let variablesInitialValues = { variables: [{}] }
    // show it for python code, cli code and r code components for now
    if (workspace && configuration && [8, 9, 27].includes(configuration.component_id)) {
      hasVariablesInjectionCard = true
      const vars = configuration.getIn(["settings", "parameters", "vars"])
      if (Map.isMap(vars) && vars.size > 0) {
        variablesInitialValues = {
          variables: [...vars.map((val, key) => ({ value: key, label: key })).values()]
        }
      }
    }

    return (
      <section className="configuration-detail">
        {configuration && isConfigurationsFulfilled && (
          <React.Fragment>
            <Header
              components={components}
              configuration={configuration}
              handleConfigurationModify={this.modifyConfiguration}
              backFunction={goBackInHistory(
                history,
                getRoutePath("workspace.show", { id: workspaceId })
              )}
              handleConfigurationDelete={this.toggleDeleteModal}
              handleConfigurationClone={this.toggleCloneModal}
              handleConfigurationRun={this.toggleRunModal}
              handleConfigurationCancel={this.toggleConfigurationCancelModal}
              handleDataErase={this.toggleDataEraseModal}
              isEditable={isEditable}
              currentlyEditing={currentlyEditing}
              toggleNameFormEditMode={this.toggleEditMode("configurationName")}
              nameFormEditMode={editModes.get("configurationName")}
              configurationStatus={
                List.isList(lastConfigurationJobs)
                  ? lastConfigurationJobs.getIn([0, "status"])
                  : null
              }
              isConfigurationReloading={isConfigurationsFetching}
            />
            <div className="two-column-layout">
              <div className="left-column">
                <DescriptionCard
                  form="ConfigurationDescriptionForm"
                  initialValues={{ description: configuration.description }}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  onSubmit={this.modifyConfiguration}
                  toggleEditMode={this.toggleEditMode("description")}
                  editMode={editModes.get("description")}
                  readyToEdit={!isConfigurationsFetching}
                />
                {components && (
                  <React.Fragment>
                    {hasVariablesInjectionCard && (
                      <VariablesInjection
                        isEditable={isEditable}
                        settings={configuration.settings}
                        variables={workspace.variables}
                        onInjectionSave={this.onWorkspaceVariablesInjectionSubmit}
                        authLink={configuration.auth_link}
                        oauthSettings={configuration.oauth_settings}
                        currentlyEditing={currentlyEditing}
                        toggleEditMode={this.toggleEditMode("variables")}
                        editMode={editModes.get("variables")}
                        readyToEdit={!isConfigurationsFetching}
                        initialValues={variablesInitialValues}
                      />
                    )}
                    <SettingsFormCard
                      configurationId={configuration.id}
                      component={components.get(_toString(configuration.component_id))}
                      isEditable={isEditable}
                      authLink={configuration.auth_link}
                      oauthSettings={configuration.oauth_settings}
                      handleOauthReset={this.resetOauth}
                      settings={configuration.settings}
                      onFormError={this.onSettingsFormError}
                      onFormSubmit={this.onSettingsFormSubmit}
                      onJsonInvalid={this.onJsonInvalidMessage}
                      copyMessageFunc={this.onCopyAuthLinkSuccessMessage}
                      currentlyEditing={currentlyEditing}
                      toggleEditMode={this.toggleEditMode("settings")}
                      editMode={editModes.get("settings")}
                      readyToEdit={!isConfigurationsFetching}
                    />
                    <CodeEditorCard
                      component={components.get(_toString(configuration.component_id))}
                      isEditable={isEditable}
                      settings={configuration.settings}
                      onCodeSave={this.onCodeEditorsSaveSubmit}
                      authLink={configuration.auth_link}
                      oauthSettings={configuration.oauth_settings}
                      currentlyEditing={currentlyEditing}
                      toggleEditMode={this.toggleEditMode("codeEditor")}
                      editMode={editModes.get("codeEditor")}
                      readyToEdit={!isConfigurationsFetching}
                    />
                  </React.Fragment>
                )}
              </div>
              <div className="right-column">
                <ConfigurationJobsCard jobs={lastConfigurationJobs} />
                <DataInCard
                  showToast={showToast}
                  dataPreviews={inDataPreviews}
                  configurations={configurations}
                  components={components}
                  inputs={
                    configuration.input_settings ? configuration.input_settings.get("inputs") : null
                  }
                  onInputsSave={this.onConfigurationInputsSubmit}
                  isEditable={isEditable}
                  loadMoreFiles={this.fetchInputLiveDataPreviews}
                  lastConfigurationsStatuses={lastConfigurationsStatuses.get("data")}
                  currentlyEditing={currentlyEditing}
                  toggleEditMode={this.toggleEditMode("dataIn")}
                  editMode={editModes.get("dataIn")}
                  readyToEdit={!isConfigurationsFetching}
                />
                <DataOutCard
                  dataPreviews={outDataPreviews}
                  loadMoreFiles={this.fetchOutputLiveDataPreviews}
                  showToast={showToast}
                />
              </div>
            </div>
            <CloneModal
              open={cloneModalOpen}
              handleClose={this.toggleCloneModal}
              configuration={configuration}
            />
          </React.Fragment>
        )}
        <Modal
          open={undefinedPlaceholdersModal.get("open")}
          handleClose={this.undefinedPlaceholdersModalClose}
          title="Notice: undefined workspace variables"
        >
          <p>
            Configuration contains placeholder(s) undefined in workspace variable(s):{" "}
            <strong>{undefinedPlaceholdersModal.get("placeholders")}</strong>
          </p>
        </Modal>
        <ConfirmModal
          open={confirmModalVariables.get("open")}
          type={confirmModalVariables.get("type")}
          handleClose={confirmModalVariables.get("handleClose")}
          handleConfirm={confirmModalVariables.get("handleConfirm")}
          title={confirmModalVariables.get("title")}
          action={confirmModalVariables.get("action")}
          what={confirmModalVariables.get("what")}
          isLoading={confirmModalVariables.get("isLoading")}
          text={confirmModalVariables.get("text")}
          htmlText={confirmModalVariables.get("htmlText")}
        />
        <Prompt
          when={currentlyEditing.length > 0}
          message={`Do you really want to leave current page and lose unsaved ${currentlyEditing}?`}
        />
      </section>
    )
  }
}

ConfigurationShow.propTypes = {
  fetchConfigurationList: PropTypes.func.isRequired,
  modifyConfiguration: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  workspace: PropTypes.instanceOf(Record),
  configuration: PropTypes.instanceOf(Record),
  configurations: PropTypes.instanceOf(Map),
  isConfigurationsFulfilled: PropTypes.bool.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  lastConfigurationJobs: PropTypes.instanceOf(List),
  components: PropTypes.instanceOf(Map),
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  listConfigurationJob: PropTypes.func.isRequired,
  refreshLastConfigurationJob: PropTypes.func.isRequired,
  runConfigurationJob: PropTypes.func.isRequired,
  enableLogout: PropTypes.func.isRequired,
  disableLogout: PropTypes.func.isRequired,
  inDataPreviews: PropTypes.instanceOf(Map),
  outDataPreviews: PropTypes.instanceOf(Map),
  fetchInDataPreviews: PropTypes.func.isRequired,
  fetchOutDataPreviews: PropTypes.func.isRequired,
  cancelConfigurationJob: PropTypes.func.isRequired,
  isConfigurationsFetching: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  workspace: getWorkspaceRecord(state, ownProps.match.params.id),
  configuration: getWorkspaceConfigurationData(
    state,
    ownProps.match.params.id,
    ownProps.match.params.cid
  ),
  configurations: getWorkspaceConfigurationsData(state, ownProps.match.params.id),
  isConfigurationsFulfilled: isConfigurationsFulfilled(state, ownProps.match.params.id),
  isConfigurationsFetching: getIsConfigurationsFetching(state),
  usersAcl: getUsersWorkspaceAcl(state, ownProps.match.params.id),
  components: getComponentsData(state),
  lastConfigurationJobs: getLastConfigurationJobsData(state, ownProps.match.params.cid),
  inDataPreviews: getConfigurationsDataPreviews(state, ownProps.match.params.cid, "in"),
  outDataPreviews: getConfigurationsDataPreviews(state, ownProps.match.params.cid, "out")
})

export default connect(mapStateToProps, {
  fetchConfigurationList,
  modifyConfiguration,
  deleteConfiguration,
  showToast,
  showLoadingBar,
  hideLoadingBar,
  listConfigurationJob,
  refreshLastConfigurationJob,
  runConfigurationJob,
  enableLogout,
  disableLogout,
  fetchInDataPreviews,
  fetchOutDataPreviews,
  clearCachedConfigurationJobs,
  clearCachedWorkspaceJobs,
  clearCachedWorkspaceHistory,
  clearCachedConfigurationHistory,
  cancelConfigurationJob,
  resetFilterForm
})(ConfigurationShow)
