import React, { Component } from "react"
import { reduxForm, Field } from "redux-form"
import { connect } from "react-redux"
import moment from "moment"
import _noop from "lodash/noop"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// actions
import { modifyAuthUser } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"

// constants, helpers
import { MOMENT, TOAST } from "sharedConstants"
import { required } from "helpers/validators.helper"
import _toInteger from "lodash/toInteger"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import PasswordField from "components/UI/components/PasswordField"
import TextField from "components/UI/elements/TextField"
import Avatar from "components/UI/elements/Avatar"

import "./UserSettings.css"

const usersEnabled =
  process.env.NODE_ENV === "production"
    ? "[[USERS_ENABLED]]"
    : process.env.REACT_APP_USERS_ENABLED ?? "1"
const readOnly = _toInteger(usersEnabled) !== 1

class UserSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPasswordEditable: false,
      isNameEditable: false
    }
  }

  editPassword = () => {
    this.setState({ isPasswordEditable: true, isNameEditable: false })
  }

  editName = () => {
    this.setState({ isNameEditable: true, isPasswordEditable: false })
  }

  savePassword = async values => {
    const { modifyAuthUser, showToast, authenticatedUser } = this.props
    const newPassword = values.password

    modifyAuthUser(authenticatedUser.data.id, { password: newPassword })
      .then(response => {
        showToast(`Password has been changed.`, TOAST.TYPE.SUCCESS)
        this.setState({ isPasswordEditable: false })
      })
      .catch(_noop)
  }

  saveName = async values => {
    const { modifyAuthUser, showToast, authenticatedUser } = this.props
    const newName = values.name

    modifyAuthUser(authenticatedUser.data.id, { name: newName })
      .then(() => {
        showToast("Name has been changed.", TOAST.TYPE.SUCCESS)
        this.setState({ isNameEditable: false })
      })
      .catch(_noop)
  }

  cancelEditPassword = () => {
    this.setState({ isPasswordEditable: false })
  }

  cancelEditName = () => {
    this.setState({ isNameEditable: false })
  }

  render() {
    const { isPasswordEditable, isNameEditable } = this.state
    const { authenticatedUser, handleSubmit } = this.props
    const passwordFormName = "meEditPassword"
    const nameFormName = "meEditName"

    let passwordContainer = null
    let headerButton = readOnly ? null : (
      <Button color="primary" size="small" onClick={this.editName} data-cy="edit-button">
        <FontAwesomeIcon icon={["fal", "pencil"]} /> Edit
      </Button>
    )

    if (!readOnly) {
      if (isPasswordEditable) {
        passwordContainer = (
          <div className="user">
            <div className="user-label">new password</div>
            <form
              id={passwordFormName}
              autoComplete="off"
              onSubmit={handleSubmit(this.savePassword)}
              className="form-row"
            >
              <PasswordField autoFocus />
              <Field
                name="repeat_password"
                component={TextField}
                className="repeat-pwd-field"
                fullWidth={true}
                type="password"
                placeholder="Repeat password"
              />
            </form>
          </div>
        )
      } else {
        passwordContainer = (
          <Button
            color="primary"
            size="small"
            onClick={this.editPassword}
            data-cy="change-pwd-button"
          >
            Change Password
          </Button>
        )
      }
    }

    let nameContainer = null
    if (isNameEditable) {
      nameContainer = (
        <form
          id={nameFormName}
          autoComplete="off"
          onSubmit={handleSubmit(this.saveName)}
          className="form-row"
        >
          <Field
            name="name"
            component={TextField}
            className="nameTextField"
            fullWidth={true}
            validate={required}
            type="text"
            data-cy="name-field"
            autoFocus
          />
        </form>
      )
      passwordContainer = null
    } else {
      nameContainer = (
        <div className="user-value" data-cy="username-label">
          {authenticatedUser.data.name}
        </div>
      )
    }

    if (isNameEditable && isPasswordEditable === false) {
      headerButton = (
        <div>
          <Button
            color="white"
            size="small"
            className="cancel-button"
            onClick={this.cancelEditName}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="green"
            size="small"
            className="save-button"
            data-cy="save-name-button"
            form={nameFormName}
          >
            <FontAwesomeIcon icon={["fas", "check"]} /> Save
          </Button>
        </div>
      )
    } else if (isPasswordEditable && isNameEditable === false) {
      headerButton = (
        <div>
          <Button
            color="white"
            size="small"
            className="cancel-button"
            onClick={this.cancelEditPassword}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="green"
            size="small"
            className="save-button"
            data-cy="save-password-button"
            form={passwordFormName}
          >
            <FontAwesomeIcon icon={["fas", "check"]} /> Save
          </Button>
        </div>
      )
    }

    return (
      <section className="wrapper user-settings">
        <PaperHeader
          className="user-settings-header"
          children={headerButton}
          titleText="User settings"
          size="small"
        />
        <Paper hasHeader={true} className="user-settings-content">
          <div className="non-editable-data">
            <div className="avatar">
              <Avatar
                email={authenticatedUser.data.email}
                name={authenticatedUser.data.name}
                gravatarSize={200}
                className="img"
              />
            </div>
            <div className="user-data non-editable">
              <div className="user first">
                <div className="user-label">email</div>
                <div className="user-value">{authenticatedUser.data.email}</div>
              </div>
              <div className="user">
                <div className="user-label">date added</div>
                <div className="user-value">
                  {moment
                    .utc(authenticatedUser.data.created)
                    .local()
                    .format(MOMENT.DATE_FORMAT)}
                </div>
              </div>
            </div>
          </div>
          <div className="editable-data">
            <div className="user-data">
              <div className="user first">
                <div className="user-label">name</div>
                {nameContainer}
              </div>
              <div className="edit-password">{passwordContainer}</div>
            </div>
          </div>
        </Paper>
      </section>
    )
  }
}

const validate = values => {
  const errors = {}
  if (values.password && values.password !== values.repeat_password) {
    errors.repeat_password = "Password doesn't match"
  }
  return errors
}

const mapStateToProps = state => {
  return {
    authenticatedUser: state.authenticatedUser,
    initialValues: {
      name: state.authenticatedUser.data.name
    }
  }
}

UserSettings = reduxForm({
  form: "UserNameForm",
  touchOnBlur: false,
  validate
})(UserSettings)

UserSettings = connect(mapStateToProps, { modifyAuthUser, showToast })(UserSettings)

export default UserSettings
