export default class PendingPromise {
  pendingPromises = []

  create(promise) {
    let hasCanceled_ = false

    const wrappedPromise = promise.then(
      result => {
        if (hasCanceled_) {
          const errorObject = { isCanceled: true }
          throw errorObject
        } else {
          return result
        }
      },
      error => {
        if (hasCanceled_) {
          const errorObject = { isCanceled: true }
          throw errorObject
        } else {
          throw error
        }
      }
    )

    const cancelablePromise = {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true
      }
    }

    this._appendPendingPromise(cancelablePromise)

    return cancelablePromise
  }

  _appendPendingPromise(promise) {
    this.pendingPromises = [...this.pendingPromises, promise]
  }

  remove(promise) {
    this.pendingPromises = this.pendingPromises.filter(p => p !== promise)
  }

  cancelAll() {
    this.pendingPromises.forEach(p => p.cancel())
  }
}
