import Modal from "components/UI/elements/Modal"
import React, { useState } from "react"
import styles from "./TransferOwnershipModal.module.scss"
import { useSelector } from "react-redux"
import Button from "components/UI/elements/Button"
import { getUsersData } from "selectors/user.selector"
import { USER } from "sharedConstants"
import { toLower } from "lodash"
import SelectField from "components/UI/elements/SelectField"

export default function TransferOwnershipModal({ isOpen, onClose, onSubmit, currentOwnerId }) {
  const authenticatedUser = useSelector(state => state.authenticatedUser)
  const disabled =
    authenticatedUser.data.role !== "admin" && authenticatedUser.data.id !== currentOwnerId

  const users = useSelector(getUsersData)
  const usersOptions = users
    .get("data")
    .filter(user => user.role !== USER.ROLE.SYSTEM && !user.disabled && user.id !== currentOwnerId)
    .map((user, index) => ({
      index: index,
      value: user.id,
      name: user.name,
      email: user.email,
      label: user.name
    }))
    .sortBy(user => toLower(user.name))
    .toArray()
  const [selectedUserId, setSelectedUserId] = useState(null)
  const selectedUser = usersOptions.find(({ value }) => value === selectedUserId)

  const [isLoading, setIsLoading] = useState(false)
  async function handleSubmit() {
    setIsLoading(true)
    await onSubmit(selectedUserId)
    setIsLoading(false)
  }

  return (
    <Modal open={isOpen} handleClose={onClose} title="Transfer ownership">
      <div className={styles.body}>
        {disabled && (
          <div className={styles.disabled}>Only admin or current owner can transfer ownership.</div>
        )}
        <SelectField
          meta={{}}
          input={{
            value: selectedUser,
            onChange: ({ value }) => setSelectedUserId(value)
          }}
          label="User"
          options={usersOptions}
          isSearchable
          disabled={disabled}
        />
        <div className={styles.buttons}>
          <Button type="button" color="transparent-grey" size="big" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type="submit"
            color="primary"
            size="big"
            className={isLoading ? "loading" : ""}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}
