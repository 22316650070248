import React from "react"
import ReactTooltip from "react-tooltip"

export const renderTooltip = (tooltipId, currentlyEditing) => {
  if (!currentlyEditing) {
    return null
  }
  return (
    <ReactTooltip id={tooltipId} place="bottom">
      {`You need to save/cancel changes of ${currentlyEditing} first.`}
    </ReactTooltip>
  )
}
