import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Record, Map } from "immutable"
import _isNil from "lodash/isNil"
import _includes from "lodash/includes"
import _noop from "lodash/noop"
import _get from "lodash/get"
import { Waypoint } from "react-waypoint"

// actions
import {
  listConfigurationJob,
  refreshLastConfigurationJob,
  cancelConfigurationJob
} from "actions/configurationJob.action"
import { showToast } from "actions/toast.action"

// selectors
import {
  getConfigurationJobsData,
  getConfigurationJobsSelectionSettings,
  haveConfigurationJobsMoreItems,
  isConfigurationJobsFetching
} from "selectors/configurationJob.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import StatusElement from "components/UI/elements/StatusElement"
import IconButton from "components/UI/elements/IconButton"
import Duration from "components/UI/elements/Duration"
import ConfirmModal from "components/UI/components/ConfirmModal"
import JobsLineChart from "components/UI/elements/JobsLineChart"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// helpers
import { getRoutePath } from "routes"
import { formatBytes } from "helpers/dataUnits.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBack } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"

// constants
import { CONFIGURATION, INTERVAL, MODAL, TOAST } from "sharedConstants"

class ConfigurationJobList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cancelModal: Map({
        open: false,
        jobId: null,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      listConfigurationJob,
      match: {
        params: { id, cid }
      }
    } = this.props

    listConfigurationJob(id, cid, 0, CONFIGURATION.JOB.LOADING_LIMIT, 0).catch(_noop)

    this.intervalId = setInterval(
      this.refreshLastConfigurationJob,
      INTERVAL.CONFIGURATION_JOB_REFRESH
    )
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
  }

  refreshLastConfigurationJob = () => {
    const {
      refreshLastConfigurationJob,
      isLoading,
      match: {
        params: { id, cid }
      }
    } = this.props
    if (!isLoading) {
      refreshLastConfigurationJob(id, cid, 0).catch(_noop)
    }
  }

  renderDataCell = (bytes, files) => {
    if (_isNil(bytes)) {
      return "–"
    }

    return (
      <span className="data-text">
        {formatBytes(bytes)}{" "}
        <span>
          in {files} {files === 1 ? "file" : "files"}
        </span>
      </span>
    )
  }

  loadMoreJobs = () => {
    const {
      listConfigurationJob,
      selectionSettings,
      match: {
        params: { id, cid }
      }
    } = this.props

    listConfigurationJob(
      id,
      cid,
      selectionSettings.offset + selectionSettings.limit,
      selectionSettings.limit,
      0
    ).catch(_noop)
  }

  renderWaypoint = () => {
    const { hasMoreJobs, isLoading } = this.props
    if (hasMoreJobs && !isLoading) {
      return <Waypoint onEnter={this.loadMoreJobs} bottomOffset={-250} />
    }
  }

  confirmCancelModal = async () => {
    if (!this.state.cancelModal.get("isLoading")) {
      const {
        cancelConfigurationJob,
        showToast,
        match: {
          params: { id, cid }
        }
      } = this.props
      const { cancelModal } = this.state

      this.setState(prevState => ({
        cancelModal: prevState.cancelModal.set("isLoading", true)
      }))

      const cancelPromise = this.pendingPromises.create(
        cancelConfigurationJob(id, cid, cancelModal.get("jobId"))
      )
      try {
        await cancelPromise.promise
        this.setState(prevState => ({
          cancelModal: prevState.cancelModal.set("open", false)
        }))
        showToast("Activity is being cancelled.", TOAST.TYPE.SUCCESS)
      } catch (err) {
        if (!_get(err, "isCanceled")) {
          this.setState(prevState => ({
            cancelModal: prevState.cancelModal.set("isLoading", false)
          }))
        }
      } finally {
        this.pendingPromises.remove(cancelPromise)
      }
    }
  }

  toggleCancelModal = (jobId = null) => evt => {
    evt.preventDefault()
    this.setState(prevState => ({
      cancelModal: prevState.cancelModal
        .set("jobId", jobId)
        .set("open", !prevState.cancelModal.get("open"))
        .set("isLoading", false)
    }))
  }

  render() {
    const {
      jobs,
      usersAcl,
      history,
      match: {
        params: { id, cid }
      }
    } = this.props
    const { cancelModal } = this.state
    const isEditable = hasWritePermission(usersAcl)

    return (
      <section className="job-list-page">
        {List.isList(jobs) && (
          <React.Fragment>
            <PaperHeader size="small" className="job-list-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBack(
                    history,
                    getRoutePath("workspace.configuration.show", { id, cid })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Configuration activity</h3>
              </div>
            </PaperHeader>
            {jobs.size > 2 && (
              <Paper className="jobs-chart">
                <JobsLineChart jobs={jobs} />
              </Paper>
            )}
            <Paper hasHeader={jobs.size < 3} className="job-list-content">
              {jobs.size > 0 && (
                <React.Fragment>
                  <div className="table table-content-clickable-row">
                    <div className="thead">
                      <div className="table-row">
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                        <div className="table-head status">Status</div>
                        <div className="table-head">Duration</div>
                        <div className="table-head">Modified</div>
                        <div className="table-head">Data in</div>
                        <div className="table-head">Data out</div>
                        <div className="table-head align-right">User</div>
                        <div className="table-head action-column">&nbsp;</div>
                        <div className="table-head hover-overlap-helper">&nbsp;</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {jobs.map(job => {
                        return (
                          <Link
                            to={getRoutePath("workspace.configuration.configurationJob.show", {
                              id,
                              cid,
                              aid: job.id
                            })}
                            className="table-row"
                            key={job.id}
                          >
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                            <div className="table-cell">
                              <StatusElement
                                align="left"
                                status={job.status}
                                showDuration={false}
                              />
                            </div>
                            <div className="table-cell duration">
                              <Duration
                                status={job.status}
                                history={job.stats ? job.stats.get("statuses_history") : Map()}
                                created={job.origin_created ? job.origin_created : job.created}
                                showRunningTime
                              />
                            </div>
                            <div className="table-cell">
                              <DateTimeWithTooltip
                                dateTime={job.created}
                                uniqueTooltipId={`configuration-job-${job.id}-tooltip`}
                              />
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/in"]),
                                job.getIn(["stats", "files_count", "/in"])
                              )}
                            </div>
                            <div className="table-cell">
                              {this.renderDataCell(
                                job.getIn(["stats", "data_volumes", "/out"]),
                                job.getIn(["stats", "files_count", "/out"])
                              )}
                            </div>
                            <div className="table-cell align-right">
                              <Username userId={job.user_id} />
                            </div>
                            <div className="table-cell action-column">
                              <IconButton
                                color="red"
                                onClick={this.toggleCancelModal(job.id)}
                                disabled={
                                  !_includes(["waiting", "running"], job.status) || !isEditable
                                }
                              >
                                <FontAwesomeIcon icon={["fas", "times"]} />
                              </IconButton>
                            </div>
                            <div className="table-cell hover-overlap-helper">&nbsp;</div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                  {this.renderWaypoint()}
                </React.Fragment>
              )}
              {jobs.size === 0 && (
                <p className="no-jobs-message">
                  The configuration doesn't have an activity history. Run the configuration at least
                  once.
                </p>
              )}
            </Paper>
            <ConfirmModal
              open={cancelModal.get("open")}
              type={MODAL.TYPE.CANCEL}
              handleClose={this.toggleCancelModal()}
              handleConfirm={this.confirmCancelModal}
              title="Cancel activity"
              action="cancel"
              what="activity"
              isLoading={cancelModal.get("isLoading")}
            />
          </React.Fragment>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: workspaceId, cid: configurationId } = ownProps.match.params
  return {
    jobs: getConfigurationJobsData(state, configurationId),
    selectionSettings: getConfigurationJobsSelectionSettings(state, configurationId),
    hasMoreJobs: haveConfigurationJobsMoreItems(state, configurationId),
    isLoading: isConfigurationJobsFetching(state, configurationId),
    usersAcl: getUsersWorkspaceAcl(state, workspaceId)
  }
}

ConfigurationJobList.propTypes = {
  jobs: PropTypes.instanceOf(List),
  selectionSettings: PropTypes.instanceOf(Record),
  usersAcl: PropTypes.instanceOf(Record),
  hasMoreJobs: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  listConfigurationJob: PropTypes.func.isRequired,
  refreshLastConfigurationJob: PropTypes.func.isRequired,
  cancelConfigurationJob: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {
  listConfigurationJob,
  refreshLastConfigurationJob,
  cancelConfigurationJob,
  showToast
})(ConfigurationJobList)
