import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map } from "immutable"
import moment from "moment"
import humanizeDuration from "humanize-duration"
import _includes from "lodash/includes"

/* istanbul ignore next */
const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms"
    }
  },
  delimiter: " ",
  spacer: ""
})

class Duration extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      seconds: 0,
      runningSeconds: 0
    }
  }

  componentDidMount() {
    const { history, status } = this.props

    if (_includes(["warning", "error", "finished", "canceled"], status)) {
      if (Map.isMap(history)) {
        const seconds = moment
          .duration(moment(history.get(status)).diff(moment(history.get("waiting"))))
          .asSeconds()
        const runningSeconds = moment
          .duration(moment(history.get(status)).diff(moment(history.get("running"))))
          .asSeconds()

        this.setState({
          seconds: Math.round(seconds),
          runningSeconds: Math.round(runningSeconds)
        })
      } else {
        this.setState({
          seconds: null,
          runningSeconds: null
        })
      }
    } else {
      this.refreshSeconds()
      this.intervalId = setInterval(this.refreshSeconds, 1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.created !== prevProps.created) {
      if (!this.intervalId) {
        this.refreshSeconds()
        this.intervalId = setInterval(this.refreshSeconds, 1000)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  refreshSeconds = () => {
    const { status, history, created } = this.props
    let seconds = 0
    let runningSeconds = null

    if (_includes(["warning", "error", "finished", "canceled"], status)) {
      clearInterval(this.intervalId)
      if (Map.isMap(history)) {
        seconds = moment
          .duration(moment(history.get(status)).diff(moment(history.get("waiting"))))
          .asSeconds()
        runningSeconds = moment
          .duration(moment(history.get(status)).diff(moment(history.get("running"))))
          .asSeconds()
      } else {
        seconds = null
        runningSeconds = null
      }
    } else {
      seconds = moment.duration(moment().diff(moment.utc(created))).asSeconds()
    }

    this.setState({
      seconds: seconds !== null ? Math.round(seconds) : null,
      runningSeconds: runningSeconds !== null ? Math.round(runningSeconds) : null
    })
  }

  render() {
    const { showRunningTime } = this.props
    const { seconds, runningSeconds } = this.state
    if (!showRunningTime || runningSeconds === null) {
      return (
        <React.Fragment>
          {seconds === null && "–"}
          {seconds !== null && shortEnglishHumanizer(seconds * 1000)}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {seconds === null && "–"}
          {seconds !== null && (
            <React.Fragment>
              <span className="running-seconds">
                {shortEnglishHumanizer(runningSeconds * 1000)}
              </span>{" "}
              <span className="total-seconds">of {shortEnglishHumanizer(seconds * 1000)}</span>
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }
  }
}

Duration.propTypes = {
  status: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Map),
  created: PropTypes.string.isRequired,
  showRunningTime: PropTypes.bool
}

export default Duration
