import { Record } from "immutable"

/*
 * settings and credentials are plain JS objects, because we don't
 * need to access inside or manipulate with them on frontend
 */
const OAuth = Record({
  id: 0,
  name: "",
  description: "",
  settings: {},
  credentials: {},
  created: ""
})

export default OAuth
