import _get from "lodash/get"
import { api } from "api"
import { TAG } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchTagsList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: TAG.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api().tag.list(offset, limit, "name", "ASC", loadFullStructure)
          )
          .setDataPath("tags")
          .setLoadFullStructure(0)
          .run()
        return data
      })()
    }
  })
}

export const deleteTag = tagId => async dispatch =>
  dispatch({
    type: TAG.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api().tag.delete(tagId)
        return tagId
      })()
    }
  })

export const createTag = data => async dispatch =>
  dispatch({
    type: TAG.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api().tag.create(data)
        return _get(response, "tag", {})
      })()
    }
  })

export const modifyTag = (tagId, data, originData = {}) => async dispatch =>
  dispatch({
    type: TAG.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api().tag.modify(tagId, data, originData)
        return _get(response, "tag", {})
      })()
    }
  })
