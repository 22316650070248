import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"

// constants
import { SCHEDULE_FORM_TYPE } from "sharedConstants"

const CommonHeader = ({
  handleClose,
  formType,
  handleSubmit,
  handleFormTypeChange,
  loading,
  handleEntireSchedulesDelete
}) => (
  <PaperHeader size="small" className="schedule-form-header">
    <div className="left-header-part">
      <div className="schedule-type-selection">
        <h3>
          Schedule:{" "}
          <Button
            color="transparent-grey"
            size="small"
            type="button"
            className={`schedule-variant-button ${
              formType === SCHEDULE_FORM_TYPE.DAY_OF_WEEK ? "active" : ""
            }`}
            onClick={handleFormTypeChange(SCHEDULE_FORM_TYPE.DAY_OF_WEEK)}
          >
            Day of the week
          </Button>
          {" | "}
          <Button
            color="transparent-grey"
            size="small"
            type="button"
            className={`schedule-variant-button ${
              formType === SCHEDULE_FORM_TYPE.DAY_OF_MONTH ? "active" : ""
            }`}
            onClick={handleFormTypeChange(SCHEDULE_FORM_TYPE.DAY_OF_MONTH)}
          >
            Day of the month
          </Button>
        </h3>
      </div>
    </div>
    <div className="edit-schedule-actions">
      <Button color="link" onClick={handleClose} size="small" type="button" className="cancel">
        Cancel
      </Button>
      {handleEntireSchedulesDelete !== null && (
        <React.Fragment>
          <Button
            className="delete-entire-schedule"
            size="small"
            type="button"
            color="white-red"
            onClick={handleEntireSchedulesDelete}
          >
            <FontAwesomeIcon className="icon" icon={["far", "trash-alt"]} /> Delete
          </Button>
        </React.Fragment>
      )}
      <Button
        className={loading ? "loading" : ""}
        size="small"
        type="button"
        color="primary"
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  </PaperHeader>
)

CommonHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFormTypeChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleEntireSchedulesDelete: PropTypes.func
}

export default CommonHeader
