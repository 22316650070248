import { ACL } from "sharedConstants"
import { Map } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import Acl, { DawgAclModel } from "models/acl.model"

/*
 * workspaces : { workspaceId: acl },
 * dawgs: { dawgId: acl }
 */

export default (state = Map({ workspaces: Map(), dawgs: Map() }), { type, payload }) => {
  switch (type) {
    case `${ACL.USER.ACTION.LIST}_FULFILLED`:
      return state.set(
        "workspaces",
        Map(
          _mapKeys(
            _map(payload, acl => new Acl(acl)),
            "workspace_id"
          )
        )
      )
    case `${ACL.USER.DAWG.ACTION.LIST}_FULFILLED`:
      return state.set(
        "dawgs",
        Map(
          _mapKeys(
            _map(payload, acl => new DawgAclModel(acl)),
            "dawg_id"
          )
        )
      )
    default:
      return state
  }
}
