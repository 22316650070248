import React from "react"
import PropTypes from "prop-types"

import "./Button.css"

import { renderTooltip } from "helpers/disabledTooltip.helper"

const Button = React.forwardRef(
  (
    {
      children,
      color,
      fullWidth,
      size,
      className,
      disabled,
      type = "button",
      href = "",
      tooltipId = "button-tooltip",
      currentlyEditing = "",
      ...buttonAttrs
    },
    ref
  ) => {
    if (href) {
      return (
        <React.Fragment>
          <span
            data-tip={currentlyEditing.length > 0}
            data-for={currentlyEditing.length > 0 ? tooltipId : false}
          >
            <a
              {...buttonAttrs}
              disabled={disabled || currentlyEditing.length > 0}
              className={`button ${className ? className : ""} ${color ? color : ""} ${size} ${
                fullWidth ? "full-width" : "shrinked"
              }`}
              href={href}
            >
              <span>{children}</span>
            </a>
          </span>
          {renderTooltip(tooltipId, currentlyEditing)}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <span
            data-tip={currentlyEditing.length > 0}
            data-for={currentlyEditing.length > 0 ? tooltipId : false}
          >
            <button
              {...buttonAttrs}
              disabled={disabled || currentlyEditing.length > 0}
              className={`button ${className ? className : ""} ${color ? color : ""} ${size} ${
                fullWidth ? "full-width" : "shrinked"
              }`}
              type={type}
              ref={ref}
            >
              <span>{children}</span>
            </button>
          </span>
          {renderTooltip(tooltipId, currentlyEditing)}
        </React.Fragment>
      )
    }
  }
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  currentlyEditing: PropTypes.string,
  tooltipId: PropTypes.string
}

export default Button
