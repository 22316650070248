import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import promiseMiddleware from "redux-promise-middleware"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { loadingBarMiddleware } from "react-redux-loading-bar"
import { createWhitelistFilter } from "redux-persist-transform-filter"

import rootReducer from "reducers"

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    createWhitelistFilter("authenticatedUser", ["data", "token", "loginTimestamp", "ui"])
  ],
  whitelist: ["authenticatedUser"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  undefined,
  compose(
    applyMiddleware(promiseMiddleware(), thunk, loadingBarMiddleware())
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)

export const persistor = persistStore(store)

export default store
