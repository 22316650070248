import React from "react"
import PropTypes from "prop-types"

import "./PaperHeader.css"

const PaperHeader = ({ className, children, size, titleText, subTitleText }) => (
  <div className={`paper-header ${className ? className : ""} ${size ? size : ""}`}>
    {titleText && (
      <div>
        <h3 className="title">{titleText}</h3>
        {subTitleText && <h4 className="subtitle">{subTitleText}</h4>}
      </div>
    )}
    {children}
  </div>
)

PaperHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string
}

export default PaperHeader
