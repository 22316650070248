import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Record } from "immutable"
import _isNil from "lodash/isNil"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _includes from "lodash/includes"
import _findIndex from "lodash/findIndex"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Map } from "immutable"
import moment from "moment"
import { Waypoint } from "react-waypoint"
import Linkify from "react-linkify"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

// actions
import { retrieveConfiguration } from "actions/configuration.action"
import { retrieveConfigurationJob, cancelConfigurationJob } from "actions/configurationJob.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { showToast } from "actions/toast.action"

// selectors
import { getWorkspaceConfigurationData } from "selectors/configuration.selector"
import { getConfigurationJobData } from "selectors/configurationJob.selector"
import { getUsersWorkspaceAcl } from "selectors/usersAcl.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import Duration from "components/UI/elements/Duration"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Button from "components/UI/elements/Button"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"

// models
import SelectionSettingsModel from "models/selectionSettings.model"

// helpers
import { getRoutePath } from "routes"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { api } from "api"
import { formatBytes } from "helpers/dataUnits.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { hasWritePermission } from "helpers/authenticatedUser.helper"
import { goBackInHistory } from "helpers/backButton.helper"
import Username from "helpers/Username.helper"

// constants
import { MOMENT, LOG, INTERVAL, TOAST, MODAL } from "sharedConstants"

class ConfigurationJobShow extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      timeline: null,
      logs: Map({
        isLoading: false,
        orderDir: "DESC",
        orderDirButtonEnabled: false
      }),
      cancelModal: Map({
        open: false,
        isLoading: false
      })
    }

    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const {
      configuration,
      configurationJob,
      retrieveConfiguration,
      retrieveConfigurationJob,
      match: {
        params: { id, cid, aid }
      }
    } = this.props

    if (_isNil(configuration)) {
      retrieveConfiguration(id, cid).catch(_noop)
    }

    if (_isNil(configurationJob)) {
      retrieveConfigurationJob(id, cid, aid, 0, true)
        .then(response => {
          if (_includes(["waiting", "running"], _get(response, "value.job.status"))) {
            this.intervalId = setInterval(this.refreshPageData, INTERVAL.CONFIGURATION_JOB_REFRESH)
          } else {
            this.setState(prevState => ({
              logs: prevState.logs.set("orderDirButtonEnabled", true)
            }))
          }
        })
        .catch(_noop)
    } else {
      if (_includes(["waiting", "running"], configurationJob.status)) {
        this.intervalId = setInterval(this.refreshPageData, INTERVAL.CONFIGURATION_JOB_REFRESH)
      } else {
        this.setState(prevState => ({
          logs: prevState.logs.set("orderDirButtonEnabled", true)
        }))
      }
    }

    const timelinePromise = this.pendingPromises.create(this.fetchWholeTimeline(true))
    timelinePromise.promise
      .then(response => {
        this.setState({
          timeline: List(response)
        })
        this.pendingPromises.remove(timelinePromise)
      })
      .catch(() => {
        this.pendingPromises.remove(timelinePromise)
      })

    this.setState(prevState => ({
      logs: prevState.logs.set("isLoading", true)
    }))
    const jobLogsPromise = this.pendingPromises.create(
      this.fetchConfigurationJobLogs(0, LOG.LOADING_LIMIT, true)
    )
    jobLogsPromise.promise
      .then(response => {
        this.setState(prevState => ({
          logs: prevState.logs
            .set("data", List(response.configuration_logs))
            .set("selectionSettings", new SelectionSettingsModel(response.selection_settings))
            .set("isLoading", false)
            .set("hasMoreItems", response.configuration_logs.length === LOG.LOADING_LIMIT)
        }))
        this.pendingPromises.remove(jobLogsPromise)
      })
      .catch(() => {
        this.pendingPromises.remove(jobLogsPromise)
      })
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
  }

  appendPendingPromise = promise => {
    this.pendingPromises = [...this.pendingPromises, promise]
  }

  removePendingPromise = promise =>
    (this.pendingPromises = this.pendingPromises.filter(p => p !== promise))

  fetchWholeTimeline = async (showLoading = false) => {
    const {
      showLoadingBar,
      hideLoadingBar,
      match: {
        params: { id, cid, aid }
      }
    } = this.props
    if (showLoading) {
      showLoadingBar()
    }
    const data = await new AllResourceItemsFetcher()
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api().configuration.job.history.list(
          id,
          cid,
          aid,
          offset,
          limit,
          loadFullStructure,
          "id",
          "DESC"
        )
      )
      .setDataPath("history_list")
      .setLoadFullStructure(0)
      .run()
    if (showLoading) {
      hideLoadingBar()
    }
    return data
  }

  fetchConfigurationJobLogs = async (offset, limit, showLoading = false, orderDir = null) => {
    const {
      showLoadingBar,
      hideLoadingBar,
      match: {
        params: { id, cid, aid }
      }
    } = this.props
    const { logs } = this.state
    if (showLoading) {
      showLoadingBar()
    }
    const response = await api()
      .configuration.job.log.list(
        id,
        cid,
        aid,
        offset,
        limit,
        0,
        "id",
        orderDir ? orderDir : logs.get("orderDir")
      )
      .catch(_noop)
    if (showLoading) {
      hideLoadingBar()
    }
    return response
  }

  refreshPageData = () => {
    const {
      retrieveConfigurationJob,
      match: {
        params: { id, cid, aid }
      }
    } = this.props

    if (!this.state.logs.get("isLoading")) {
      retrieveConfigurationJob(id, cid, aid, 0, false)
        .then(response => {
          const timelinePromise = this.pendingPromises.create(this.fetchWholeTimeline(false))
          timelinePromise.promise
            .then(response => {
              this.setState({
                timeline: List(response)
              })
              this.pendingPromises.remove(timelinePromise)
            })
            .catch(() => {
              this.pendingPromises.remove(timelinePromise)
            })

          this.refreshLogsData()

          if (!_includes(["waiting", "running"], _get(response, "payload.job.status"))) {
            clearInterval(this.intervalId)
            this.setState(prevState => ({
              logs: prevState.logs.set("orderDirButtonEnabled", true)
            }))
            return
          }
        })
        .catch(_noop)
    }
  }

  refreshLogsData = () => {
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading", true)
      }))

      const jobLogsPromise = this.pendingPromises.create(
        this.fetchConfigurationJobLogs(0, 10, false)
      )
      jobLogsPromise.promise
        .then(response => {
          const lastLogItem = this.state.logs.getIn(["data", 0])
          if (lastLogItem) {
            const index = _findIndex(response.configuration_logs, {
              id: lastLogItem.id
            })
            if (index === -1) {
              this.setState(prevState => ({
                logs: prevState.logs
                  .set("data", prevState.logs.get("data").unshift(...response.configuration_logs))
                  .set(
                    "selectionSettings",
                    prevState.logs
                      .get("selectionSettings")
                      .set(
                        "offset",
                        prevState.logs.getIn(["selectionSettings", "offset"]) +
                          response.configuration_logs.length
                      )
                  )
                  .set("isLoading", false)
              }))
            } else {
              this.setState(prevState => ({
                logs: prevState.logs
                  .set(
                    "data",
                    prevState.logs
                      .get("data")
                      .unshift(...response.configuration_logs.slice(0, index))
                  )
                  .set(
                    "selectionSettings",
                    prevState.logs
                      .get("selectionSettings")
                      .set("offset", prevState.logs.getIn(["selectionSettings", "offset"]) + index)
                  )
                  .set("isLoading", false)
              }))
            }
          } else {
            this.setState(prevState => ({
              logs: prevState.logs
                .set("data", List(response.configuration_logs))
                .set(
                  "selectionSettings",
                  prevState.logs
                    .get("selectionSettings")
                    .set(
                      "offset",
                      prevState.logs.getIn(["selectionSettings", "offset"]) +
                        response.configuration_logs.length
                    )
                )
                .set("isLoading", false)
            }))
          }
          this.pendingPromises.remove(jobLogsPromise)
        })
        .catch(err => {
          if (!_get(err, "isCanceled")) {
            clearInterval(this.intervalId)
            this.props.showToast(
              "Auto-refresh failed because of network connection, please refresh the page.",
              TOAST.TYPE.ERROR
            )
          }
          this.pendingPromises.remove(jobLogsPromise)
        })
    }
  }

  loadMoreLogs = () => {
    if (!this.state.logs.get("isLoading")) {
      this.setState(prevState => ({
        logs: prevState.logs.set("isLoading", true)
      }))

      const { logs } = this.state
      const jobLogsPromise = this.pendingPromises.create(
        this.fetchConfigurationJobLogs(
          logs.getIn(["selectionSettings", "offset"]) + logs.getIn(["selectionSettings", "limit"]),
          LOG.LOADING_LIMIT,
          true
        )
      )
      jobLogsPromise.promise
        .then(response => {
          const lastLogItem = logs.get("data").last()
          const index = _findIndex(response.configuration_logs, {
            id: lastLogItem.id
          })

          this.setState(prevState => ({
            logs: prevState.logs
              .set(
                "data",
                prevState.logs
                  .get("data")
                  .concat(
                    response.configuration_logs.slice(index + 1, response.configuration_logs.length)
                  )
              )
              .set(
                "selectionSettings",
                new SelectionSettingsModel({
                  ...response.selection_settings,
                  offset: response.selection_settings.offset + index + 1
                })
              )
              .set("hasMoreItems", response.configuration_logs.length === LOG.LOADING_LIMIT)
              .set("isLoading", false)
          }))
          this.pendingPromises.remove(jobLogsPromise)
        })
        .catch(() => {
          this.pendingPromises.remove(jobLogsPromise)
        })
    }
  }

  renderWaypoint = () => {
    const { logs } = this.state

    if (logs.get("hasMoreItems") && !logs.get("isLoading")) {
      return <Waypoint onEnter={this.loadMoreLogs} bottomOffset={-250} />
    }
  }

  toggleCancelModal = () => {
    this.setState(prevState => ({
      cancelModal: prevState.cancelModal
        .set("open", !prevState.cancelModal.get("open"))
        .set("isLoading", false)
    }))
  }

  confirmCancelModal = () => {
    if (!this.state.cancelModal.get("isLoading")) {
      const {
        cancelConfigurationJob,
        showToast,
        match: {
          params: { id, cid, aid }
        }
      } = this.props

      this.setState(prevState => ({
        cancelModal: prevState.cancelModal.set("isLoading", true)
      }))

      const cancelPromise = this.pendingPromises.create(cancelConfigurationJob(id, cid, aid))
      cancelPromise.promise
        .then(() => {
          this.setState(prevState => ({
            cancelModal: prevState.cancelModal.set("open", false).set("isLoading", false)
          }))
          showToast("Activity is being cancelled.", TOAST.TYPE.SUCCESS)
          this.pendingPromises.remove(cancelPromise)
        })
        .catch(err => {
          if (!_get(err, "isCanceled")) {
            this.setState(prevState => ({
              cancelModal: prevState.cancelModal.set("isLoading", false)
            }))
          }
          this.pendingPromises.remove(cancelPromise)
        })
    }
  }

  reverseLogsOrder = () => {
    const { logs } = this.state
    this.setState({
      logs: logs.set("isLoading", true)
    })
    const jobLogsPromise = this.pendingPromises.create(
      this.fetchConfigurationJobLogs(
        0,
        LOG.LOADING_LIMIT,
        true,
        logs.get("orderDir") === "ASC" ? "DESC" : "ASC"
      )
    )
    jobLogsPromise.promise
      .then(response => {
        this.setState(prevState => ({
          logs: prevState.logs
            .set("data", List(response.configuration_logs))
            .set("selectionSettings", new SelectionSettingsModel(response.selection_settings))
            .set("isLoading", false)
            .set("hasMoreItems", response.configuration_logs.length === LOG.LOADING_LIMIT)
            .set("orderDir", prevState.logs.get("orderDir") === "ASC" ? "DESC" : "ASC")
        }))
        this.pendingPromises.remove(jobLogsPromise)
      })
      .catch(() => {
        this.pendingPromises.remove(jobLogsPromise)
      })
  }

  render() {
    const {
      configurationJob,
      usersAcl,
      history,
      match: {
        params: { id, cid, aid }
      }
    } = this.props
    const { timeline, logs, cancelModal } = this.state

    const stats = configurationJob ? configurationJob.stats : null
    const isEditable = hasWritePermission(usersAcl)

    return (
      <section className="job-detail-page">
        <div className="job-timeline-stats">
          <div className="job-detail-timeline">
            <PaperHeader size="small" className="job-timeline-header">
              <div className="navigation-block">
                <Button
                  className="back-link"
                  onClick={goBackInHistory(
                    history,
                    getRoutePath("workspace.configuration.configurationJob.list", { id, cid })
                  )}
                  size="small"
                  color="none"
                >
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Back
                </Button>
                <h3>Timeline</h3>
              </div>
              <Button
                size="small"
                color="red"
                onClick={this.toggleCancelModal}
                disabled={
                  !_includes(["waiting", "running"], _get(configurationJob, "status")) ||
                  !isEditable
                }
              >
                <FontAwesomeIcon icon={["fas", "times"]} className="icon" /> Cancel
              </Button>
            </PaperHeader>
            {List.isList(timeline) && (
              <Paper hasHeader={true} className="job-detail-timeline-content">
                <div className="table table-content-clickable-row">
                  <div className="thead">
                    <div className="table-row">
                      <div className="table-head hover-overlap-helper">&nbsp;</div>
                      <div className="table-head status">Status</div>
                      <div className="table-head">Modified</div>
                      <div className="table-head align-right">User</div>
                      <div className="table-head hover-overlap-helper">&nbsp;</div>
                    </div>
                  </div>
                  <div className="tbody">
                    {timeline.map(historyItem => (
                      <Link
                        className="table-row"
                        key={historyItem.id}
                        to={{
                          pathname: getRoutePath(
                            "workspace.configuration.configurationJob.show.history",
                            { id, cid, aid, hid: historyItem.id }
                          ),
                          state: {
                            historyItem,
                            previous: this.props.location.pathname
                          }
                        }}
                      >
                        <div className="table-cell hover-overlap-helper">&nbsp;</div>
                        <div className="table-cell">
                          <StatusElement
                            align="left"
                            status={_get(historyItem, "configuration_job.status")}
                            showDuration={false}
                          />
                        </div>
                        <div className="table-cell">
                          <DateTimeWithTooltip
                            dateTime={_get(historyItem, "configuration_job.created")}
                            uniqueTooltipId={`history-item-${historyItem.id}-tooltip`}
                          />
                        </div>
                        <div className="table-cell align-right">
                          <Username userId={_get(historyItem, "configuration_job.user_id", "")} />
                        </div>
                        <div className="table-cell hover-overlap-helper">&nbsp;</div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Paper>
            )}
          </div>
          <div className="job-detail-stats">
            <PaperHeader size="small" className="job-stats-header">
              <h3>Stats</h3>
            </PaperHeader>
            {configurationJob && !_isNil(stats) && (
              <Paper className="job-stats-content">
                <table className="table stats-table">
                  <tbody>
                    <tr>
                      <td className="head-cell">Data volumes</td>
                      <td>
                        <span>In:</span> {formatBytes(stats.getIn(["data_volumes", "/in"]))}
                      </td>
                      <td>
                        <span>Out:</span> {formatBytes(stats.getIn(["data_volumes", "/out"]))}
                      </td>
                    </tr>
                    <tr>
                      <td className="head-cell">Files count</td>
                      <td>
                        <span>In:</span> {stats.getIn(["files_count", "/in"])}
                      </td>
                      <td>
                        <span>Out:</span> {stats.getIn(["files_count", "/out"])}
                      </td>
                    </tr>
                    <tr>
                      <td className="head-cell">Duration</td>
                      <td colSpan="2" className="duration">
                        <Duration
                          status={configurationJob.status}
                          history={
                            Map.isMap(stats.get("statuses_history"))
                              ? stats.get("statuses_history")
                              : Map()
                          }
                          created={
                            configurationJob.origin_created
                              ? configurationJob.origin_created
                              : configurationJob.created
                          }
                          showRunningTime
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            )}
          </div>
        </div>
        <React.Fragment>
          <PaperHeader
            className="job-logs-header"
            size="small"
            titleText="logs"
            subTitleText="Configuration logs are archived after 30 days"
          >
            <span data-tip data-for="disabled-reverse-button">
              <Button
                color="white"
                size="small"
                className={`reverse-button ${logs.get("orderDir")}`}
                disabled={!logs.get("orderDirButtonEnabled")}
                onClick={this.reverseLogsOrder}
              >
                <FontAwesomeIcon icon={["fas", "sort-alt"]} className="icon" /> Reverse order
              </Button>
              {!logs.get("orderDirButtonEnabled") && (
                <ReactTooltip id="disabled-reverse-button" place="left">
                  Logs order can't be reversed while job is still running.
                </ReactTooltip>
              )}
            </span>
          </PaperHeader>
          {List.isList(logs.get("data")) && logs.get("data").size > 0 && (
            <Paper hasHeader={true} className="job-logs-content">
              <React.Fragment>
                <table className="table logs-table">
                  <thead>
                    <tr>
                      <th className="level">Level</th>
                      <th className="time">Time</th>
                      <th className="text">Text</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.get("data").map(row => (
                      <tr key={row.id}>
                        <td className={`level ${row.level}`}>{row.level}</td>
                        <td className={`time ${row.level}`}>
                          {moment
                            .utc(row.created)
                            .local()
                            .format(MOMENT.DATE_TIME_WITH_SECONDS)}
                        </td>
                        <td className={`text ${row.level}`}>
                          <Linkify properties={{ target: "_blank" }}>{row.text}</Linkify>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {this.renderWaypoint()}
              </React.Fragment>
            </Paper>
          )}
        </React.Fragment>
        <ConfirmModal
          open={cancelModal.get("open")}
          type={MODAL.TYPE.CANCEL}
          handleClose={this.toggleCancelModal}
          handleConfirm={this.confirmCancelModal}
          title="Cancel activity"
          action="cancel"
          what="activity"
          isLoading={cancelModal.get("isLoading")}
        />
      </section>
    )
  }
}

ConfigurationJobShow.propTypes = {
  configuration: PropTypes.instanceOf(Record),
  usersAcl: PropTypes.instanceOf(Record)
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { id, cid, aid }
    }
  } = ownProps
  return {
    configuration: getWorkspaceConfigurationData(state, id, cid),
    configurationJob: getConfigurationJobData(state, cid, aid),
    usersAcl: getUsersWorkspaceAcl(state, id)
  }
}

export default connect(mapStateToProps, {
  retrieveConfiguration,
  retrieveConfigurationJob,
  showLoadingBar,
  hideLoadingBar,
  showToast,
  cancelConfigurationJob
})(ConfigurationJobShow)
